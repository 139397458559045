import { PagePath } from '../constants'
import { usePersonalSpaceId } from '../store/features/apis/slices/space/hooks'
import { useLocation, useParams } from 'react-router-dom'

export const useIsMyProjectsPage = (): boolean => {
  const { pathname } = useLocation()
  const personalSpaceId = usePersonalSpaceId()
  const { spaceId } = useParams<{ spaceId?: string }>()
  const isPersonalSpace = personalSpaceId === spaceId
  const path: string = pathname.replace(/^\/|\/$/g, '')

  return path === PagePath.MY_PROJECTS || isPersonalSpace
}

/* eslint-disable no-console */
import { use } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

export async function i18nInit(): Promise<void> {
  const context = require['context']('@/locales', true, /\.json$/)

  try {
    await use(
      resourcesToBackend((language: string, namespace: string, callback: Function) => {
        try {
          const path = `./${language}/${namespace}.json`

          if (context.keys().includes(path)) {
            const resources = context(path)
            callback(null, resources)
          } else {
            console.error(`Translation file not found: ${path}`)
            callback(new Error(`Translation file not found: ${path}`), null)
          }
        } catch (error) {
          console.error('Error loading translation:', error)
          callback(error, null)
        }
      })
    )
      .use(initReactI18next)
      .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false
        },
        react: {
          useSuspense: false
        }
      })
  } catch (error) {
    console.error('Error initializing i18next:', error)
    throw error
  }
}

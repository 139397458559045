import { StatusLabel } from './StatusLabel'
import { TableRowProps } from '../../../../components/table/enhancedTable/interfaces'
import { getCoreHours, milisecondsToMinutes } from '../../../../helpers/coreHours'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Job } from '../../../../store/features/apis/slices/user/interfaces'
import { CustomChip } from '../../../../components/chips/CustomChip'
import { StyledTooltip } from '../../../../components/tooltip/StyledTooltip'
import format from 'date-fns/format'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import { isNil } from 'lodash'

export const useUsageOverviewData = (jobs: Job[]): { data: TableRowProps[] } => {
  const { t } = useTranslation(['profileArea'])
  const jobsSource = jobs || []
  const gpuPerformanceFactorThreshold = 0.98

  const data = jobsSource?.map((job: Job, i): TableRowProps => {
    const coreHours = getCoreHours(job)
    return {
      tableRowId: `job-${i}`,
      tableRowCells: [
        {
          id: 'projectName',
          title: job.projectName,
          value: job.projectName,
          component: <Box>{job.projectName}</Box>,
          formattedValue: job.projectName,
          sx: { width: '28.5%' }
        },
        {
          id: 'type',
          value: job.jobType,
          component: <Box>{t(`profile.usageTable.jobType.${job.jobType}`)}</Box>,
          formattedValue: t(`profile.usageTable.jobType.${job.jobType}`),
          sx: { width: '12.5%' }
        },
        {
          id: 'startTime',
          value: format(new Date(job.startDate), 'yyyy-MM-dd HH:mm:ss'),
          component: <FormattedDate date={new Date(job.startDate)} showTime />,
          formattedValue: format(new Date(job.startDate), 'yyyy-MM-dd HH:mm:ss'),
          sx: { width: '12.5%' }
        },
        {
          id: 'duration',
          value: milisecondsToMinutes(job.duration),
          component: <Box>{milisecondsToMinutes(job.duration).toFixed(0)} min</Box>,
          formattedValue: milisecondsToMinutes(job.duration).toFixed(0),
          sx: { width: '12.5%' }
        },
        {
          id: 'processors',
          value: job.numberOfCores,
          component: <Box>{job.numberOfCores}</Box>,
          formattedValue: job.numberOfCores,
          sx: { width: '12.5%' }
        },
        {
          id: 'coreHours',
          value: coreHours.hours,
          component: (
            <Stack direction="row" gap={1} alignItems="center">
              <Typography variant="text">{coreHours.formatted}</Typography>
              {!isNil(job.gpuPerformanceFactor) && job.gpuPerformanceFactor < gpuPerformanceFactorThreshold && (
                <StyledTooltip
                  title={t('profile.usageTable.computingQuotaNotification.discountedTooltip')}
                  placement="bottom"
                  enterDelay={500}
                >
                  <span>
                    <CustomChip
                      label={t('profile.usageTable.computingQuotaNotification.discounted')}
                      color={'success'}
                      sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                    />
                  </span>
                </StyledTooltip>
              )}
            </Stack>
          ),
          formattedValue: coreHours.formatted,
          sx: { width: '12.5%' }
        },
        {
          id: 'status',
          value: job.status,
          component: <StatusLabel value={job.status} />,
          formattedValue: t(`profile.usageTable.jobStatus.${job.status}`),
          sx: { width: 'auto', textAlign: 'right' }
        }
      ]
    }
  })

  return { data }
}

import { Badge, BadgeProps, Tab, TabProps } from '@mui/material'
import { styled } from '@mui/system'
import { isNil } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const StyledBadge = styled(Badge)<BadgeProps>({
  '& .MuiBadge-badge': {
    position: 'relative',
    top: 2,
    right: 'auto',
    left: 'auto',
    bottom: 'auto',
    minWidth: 12,
    height: 12,
    padding: 0,
    marginLeft: 3,
    fontSize: 8,
    fontWeight: 700,
    transform: 'none',
    transformOrigin: 'initial'
  }
})

interface CommentsTabProps extends TabProps {
  totalNumberOfComments: number
}

export const CommentsTab = ({ totalNumberOfComments, ...props }: CommentsTabProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')

  if (isNil(totalNumberOfComments)) {
    return <Tab label={t('comments')} {...props} />
  }

  return (
    <Tab
      {...props}
      label={
        <StyledBadge badgeContent={totalNumberOfComments} color="success" qa-attribute="number-of-comments">
          {t('comments')}
        </StyledBadge>
      }
    />
  )
}

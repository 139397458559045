import { DetailsPanelStyled, DetailsPanelTypes } from './GenericDetailsPanel'
import { SimulationCards } from './components/SimulationCard'
import { MultiSelectionDetails } from './MultiSelectionDetails'
import { ActionButtons } from './components/ActionButtons'
import { DetailListDataProps, DetailsList, ResourceType } from './components/DetailsList'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { useGetFolderDetails } from '../../store/features/apis/slices/folder/helpers'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getFoldersFromFolder } from '../../store/features/apis/slices/folder/foldersSlice'
import { getSortingModeV2 } from '../../store/features/uiSlice/uiSlice'
import { useErrorHandler } from '../../hooks/useErrorHandler'
import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'

export const FolderDetailsPanel = ({
  spaceId: activeSpaceId,
  folderId: activeFolderId
}: {
  spaceId?: string
  folderId?: string
}): React.ReactElement => {
  const dispatch = useAppDispatch()
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const { t } = useTranslation('detailsPanel')
  let { spaceId, folderId } = useParams<{ spaceId: string; folderId: string }>()
  if (!isNil(activeSpaceId) && !isNil(activeFolderId)) {
    spaceId = activeSpaceId
    folderId = activeFolderId
  }
  const { data: folder, isLoading, error } = useGetFolderDetails({ spaceId, folderId })
  const { handleNotFound } = useErrorHandler()

  const detailsList: DetailListDataProps[] = React.useMemo(() => {
    if (isLoading || isNil(folder)) return []
    return [
      { name: t('created'), value: format(new Date(folder.createdAt), 'MMM d, yyyy') },
      { name: t('lastModified'), value: format(new Date(folder.lastModifiedAt), 'MMM d, yyyy') }
    ]
  }, [folder, isLoading])

  React.useEffect(() => {
    if (!isLoading) {
      if (!isNil(error) || isNil(folder)) {
        handleNotFound()
      }
      if (!isNil(folder?.parentFolderId)) {
        dispatch(
          getFoldersFromFolder({
            spaceId,
            folderId: folder.parentFolderId,
            sortingModeV2
          })
        )
      }
    }
  }, [folder, spaceId, sortingModeV2, isLoading, error])

  if (isLoading || isNil(folder)) {
    return null
  }

  return (
    <Box qa-attribute="folder-details-panel" className="folder-details-panel-outside-click">
      <ActionButtons panelType={DetailsPanelTypes.FOLDER_DETAILS} folderId={folderId} />
      <Typography
        variant="title"
        color="text.primary"
        paragraph
        sx={{ whiteSpace: 'normal', wordBreak: 'break-word', hyphens: 'auto' }}
      >
        {folder?.name}
      </Typography>
      <SimulationCards panelType={DetailsPanelTypes.FOLDER_DETAILS} />
      <DetailsList resourceType={ResourceType.FOLDER} detailsListData={detailsList} />
    </Box>
  )
}

const FolderDetailsPanelDelayed = (): React.ReactElement => {
  const { selectedItems } = useMultiSelect()
  const showMultiSelectionDetails =
    selectedItems.projects?.length + selectedItems.folders?.length + selectedItems.spaces?.length > 0

  return (
    <DetailsPanelStyled>
      {showMultiSelectionDetails ? <MultiSelectionDetails /> : <FolderDetailsPanel />}
    </DetailsPanelStyled>
  )
}

export default FolderDetailsPanelDelayed

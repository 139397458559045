import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

interface SeatsAreFullTemplateProps extends ModalTemplateProps {
  title: string
}

export const SeatsAreFullTemplate = ({
  title,
  onClose,
  showCloseButton
}: SeatsAreFullTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  const handleContactSupportOnClick = React.useCallback((): void => {
    window['Intercom']?.('show')
    onClose()
  }, [])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleContactSupportOnClick}>
        <Typography>{t('seatsAreFull.description')}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('seatsAreFull.cancel')}
        </Button>
        <Button onClick={handleContactSupportOnClick} color="primary" variant="contained">
          {t('seatsAreFull.contactSupport')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { OrganizationSettingsDisplay } from '../../containers/dashboard/organizationSettings/OrganizationSettingsDisplay'
import * as React from 'react'

const OrganizationSettingsPage = (): React.ReactElement => {
  return (
    <MainDisplay>
      <OrganizationSettingsDisplay />
    </MainDisplay>
  )
}

export default OrganizationSettingsPage

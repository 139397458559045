import {
  ModelTrainingPipelinesListItem,
  ModelTrainingPipelinesListItemSkeleton
} from './ModelTrainingPipelinesListItem'
import * as React from 'react'
import Stack, { StackProps } from '@mui/material/Stack'
import { TrainingPipeline } from '@/store/features/apis/slices/ai/interfaces'

type ModelTrainingPipelinesListProps = StackProps & {
  trainingPipelines: TrainingPipeline[]
}

type ModelTrainingPipelinesListSkeletonProps = Omit<ModelTrainingPipelinesListProps, 'trainingPipelines'> & {
  itemCount?: number
}

export const ModelTrainingPipelinesListSkeleton = ({
  itemCount = 5,
  ...props
}: ModelTrainingPipelinesListSkeletonProps): React.ReactElement => {
  return (
    <Stack direction="column" spacing={3} {...props}>
      {[...Array(itemCount)].map((_, index) => (
        <ModelTrainingPipelinesListItemSkeleton key={index} />
      ))}
    </Stack>
  )
}

export const ModelTrainingPipelinesList = ({
  trainingPipelines,
  ...props
}: ModelTrainingPipelinesListProps): React.ReactElement => {
  const [selectedTrainingPipeline, setSelectedTrainingPipeline] = React.useState<TrainingPipeline['training_uuid']>()

  const handleTrainingPipelineClick = React.useCallback((trainingPipeline: TrainingPipeline) => {
    setSelectedTrainingPipeline((prev) =>
      prev === trainingPipeline.training_uuid ? null : trainingPipeline.training_uuid
    )
  }, [])

  return (
    <Stack direction="column" spacing={3} qa-attribute="aiModel--trainingPipelinesList" {...props}>
      {trainingPipelines?.map((trainingPipeline) => (
        <ModelTrainingPipelinesListItem
          key={trainingPipeline.training_uuid}
          trainingPipeline={trainingPipeline}
          onClick={handleTrainingPipelineClick}
          isOpen={selectedTrainingPipeline === trainingPipeline.training_uuid}
        />
      ))}
    </Stack>
  )
}

import { CreateFolderSubmit } from './folder/CreateFolderSubmit'
import { UpdateFolderSubmit } from './folder/UpdateFolderSubmit'
import { Button } from '../../button/Button'
import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { ModalTemplateProps } from '../interfaces'
import {
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidRequiredNumberOfChars
} from '../../../helpers/validationHelpers'
import { useErrorHandler } from '../../../hooks/useErrorHandler'
import { useValidation } from '../../../hooks/useValidation'
import { ModalInputText } from '../inputComponents/ModalInputText'
import { useGetFolderDetails } from '../../../store/features/apis/slices/folder/helpers'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import { first, isNil } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

interface FolderModalTemplateProps extends ModalTemplateProps {
  title: string
  folderId?: string
  targetPath?: string[]
  onFolderCreated?: (newFolderId: string) => void
}

export const FolderModalTemplate = ({
  onClose,
  showCloseButton,
  title,
  folderId,
  targetPath,
  onFolderCreated
}: FolderModalTemplateProps): React.ReactElement => {
  const { t } = useTranslation(['modals'])

  const { currentPath } = useCurrentPath()
  const selectedPath = targetPath ?? currentPath
  const { data: folder, isLoading } = useGetFolderDetails({ spaceId: first(selectedPath), folderId })
  const [folderName, setFolderName] = React.useState('')

  React.useEffect(() => {
    if (!isNil(folder?.name)) {
      setFolderName(folder.name)
    }
  }, [folder?.name])

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    name: { valid: true, message: '' }
  })

  const { validateField } = useValidation()

  const isEditModal = !isNil(folderId)

  const isButtonDisabled = Object.values(validationErrors).some(({ valid }) => !valid) || isLoading

  const validationRules: ValidationRules = {
    name: [isRequired, isNotEmpty, isValidRequiredNumberOfChars(1)]
  }

  const handleFolderNameValidation = (value: string): void => {
    const validation = validateField(value, validationRules.name)
    if (!isNil(validation)) {
      setValidationErrors({ ...validationErrors, name: validation })
    }
  }

  const validateForm = (): boolean => {
    const validation = validateField(folderName, validationRules.name)
    if (!isNil(validation)) {
      setValidationErrors({ ...validationErrors, name: validation })

      return validation.valid
    }
    return true
  }

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        <ModalInputText
          helperText={validationErrors.name.message}
          error={!validationErrors.name.valid}
          onChange={(event): void => setFolderName(event.target.value)}
          placeholder={t('folder.placeholders.folderName')}
          label={t('folder.fields.folderName')}
          value={folderName}
          autoFocus={true}
          required
          isLoading={isLoading}
          onBlur={(): void => handleFolderNameValidation(folderName)}
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('folder.actions.cancel')}
        </Button>

        {isEditModal ? (
          <UpdateFolderSubmit
            validateForm={validateForm}
            isButtonDisabled={isButtonDisabled}
            selectedPath={selectedPath}
            folderId={folderId}
            folderName={folderName}
            handleApiHookErrors={handleApiHookErrors}
          />
        ) : (
          <CreateFolderSubmit
            onFolderCreated={onFolderCreated}
            validateForm={validateForm}
            isButtonDisabled={isButtonDisabled}
            selectedPath={selectedPath}
            folderName={folderName}
            handleApiHookErrors={handleApiHookErrors}
          />
        )}
      </ModalActions>
    </React.Fragment>
  )
}

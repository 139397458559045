import { SearchMember } from './SearchMember'
import { SelectMembers } from './SelectMembers'
import { ModalTemplateProps } from '../../interfaces'
import * as React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type MembersSelectionProps = {
  setHasValidationErrors: React.Dispatch<React.SetStateAction<boolean>>
} & ModalTemplateProps

export const InviteMembers = ({ setHasValidationErrors }: MembersSelectionProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const anchorEl = React.useRef(null)
  const [searchString, setSearchString] = React.useState<string>('')
  const [isSelectMembersOpen, setSelectMembersOpen] = React.useState(false)

  const handleSearchMemberOnFocus = React.useCallback(() => {
    setSelectMembersOpen(true)
  }, [])

  const onConfirmCallback = React.useCallback((): void => {
    setSearchString('')
    setSelectMembersOpen(false)
  }, [])

  return (
    <Box>
      <Typography variant="textBoldWithEllipsis" paragraph>
        {t('teamSpace.inviteMembersToTheSpace')}
      </Typography>
      <SearchMember
        searchString={searchString}
        setSearchString={setSearchString}
        setHasValidationErrors={setHasValidationErrors}
        onFocus={handleSearchMemberOnFocus}
        ref={anchorEl}
      />
      <SelectMembers
        onConfirmCallback={onConfirmCallback}
        isOpen={isSelectMembersOpen}
        setIsOpen={setSelectMembersOpen}
        searchString={searchString}
        searchMemberRef={anchorEl.current}
      />
    </Box>
  )
}

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as React from 'react'

type FormattedDateProps = {
  date: Date
  disabled?: boolean
  showTime?: boolean
}

export const FormattedDate = ({ date, disabled, showTime }: FormattedDateProps): React.ReactElement => {
  return (
    <Stack direction="column" color={disabled ? 'text.disabled' : 'text.primary'}>
      <Typography variant="text">{date.toLocaleDateString()}</Typography>
      {showTime && (
        <Typography variant="text" fontSize="11px" color={disabled ? 'text.disabled' : 'grey.700'}>
          {date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}
        </Typography>
      )}
    </Stack>
  )
}

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

export default <T>(initialState?: T): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(initialState)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return (): void => {
      isMounted.current = false
    }
  }, [])

  const setStateIfMounted: Dispatch<SetStateAction<T>> = (newState: T) => {
    if (isMounted.current) {
      setState(newState)
    }
  }

  return [state, setStateIfMounted]
}

import { EnhancedTableRowProps } from '../../../components/table/enhancedTable/interfaces'
import { EnhancedTableCells } from '../../../components/table/enhancedTable/EnhancedTableCells'
import * as React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { ActionButton } from '@/components/button/ActionButton'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const ManageTeamsTableRow = ({ tableRowData, isSelected }: EnhancedTableRowProps): React.ReactElement => {
  const isItemSelected = isSelected(tableRowData.tableRowId)
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <TableRow
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={tableRowData.tableRowId}
      selected={isItemSelected}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: 'none',
        border: '1px solid',
        borderRadius: '4px',
        borderColor: 'grey.200',
        marginX: 1,
        marginY: 2,
        ...(isHovered && { boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', transition: 'box-shadow .3s' })
      }}
      onMouseEnter={(): void => {
        setIsHovered(true)
      }}
      onMouseLeave={(): void => {
        setIsHovered(false)
      }}
    >
      <EnhancedTableCells tableCellsData={tableRowData.tableRowCells} />
      {tableRowData.actionButton && (
        <TableCell component="td" align="right" width="50px" sx={{ paddingRight: 4 }}>
          <ActionButton
            onClick={(event): void => {
              tableRowData.actionButton(event, tableRowData.tableRowId)
            }}
            qa-attribute="table-row-action--button"
          >
            <SpriteIcon spriteId="ellipsis-vertical" color="text.primary" width={16} height={16} />
          </ActionButton>
        </TableCell>
      )}
    </TableRow>
  )
}

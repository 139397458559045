export const apiTags = {
  FOLDER_DETAILS: 'FolderDetails',
  FOLDERS_FROM_FOLDER: 'FoldersFromFolder',
  FOLDERS_FROM_SPACE: 'FoldersFromSpace',

  PROJECTS: 'Projects',

  PROJECT_DETAILS: 'ProjectDetails',
  PROJECT_PERMISSIONS: 'ProjectPermissions',
  PROJECT_SIMULATIONS: 'ProjectSimulations',

  SPACES: 'Spaces',
  SPACE_DETAILS: 'SpaceDetails',
  SPACE_USERS_PERMISSIONS: 'SpaceUsersPermissions',

  USER: 'User',
  USER_JOBS: 'UserJobs',
  USER_API_KEYS: 'UserApiKeys',
  USER_QUOTA: 'UserQuota',
  USER_BALANCES: 'UserBalances',
  USER_INVITATIONS: 'UserInvitations',

  USERS: 'Users',

  ORGANIZATION_DETAILS: 'OrganizationDetails',
  ORGANIZATION_SETTINGS: 'OrganizationSettings',
  ORGANIZATION_QUOTA_BUDGET: 'OrganizationQuotaBudget',
  ORGANIZATION_QUOTA_BUDGETS: 'OrganizationQuotaBudgets',
  ORGANIZATION_BALANCES: 'OrganizationBalances',
  ORGANIZATION_DOMAINS: 'OrganizationDomains',

  INVITATIONS: 'Invitations',

  USER_MODELS: 'UserModels',
  USER_MODEL: 'UserModel',
  USER_MODEL_DETAILS: 'UserModelDetails',
  USER_MODEL_VERSIONS: 'UserModelVersions',
  USER_MODEL_VERSION: 'UserModelVersion',

  TRAINING_PIPELINE: 'TrainingPipeline',
  TRAINING_PIPELINES: 'TrainingPipelines',
  TRAINING_RUN: 'TrainingRun',
  TRAINING_RUN_EVENTS_LOG: 'TrainingRunEventsLog',
  TRAINING_RUN_METRICS: 'TrainingRunMetrics',

  ORGANIZATION_USER_AI_PERMISSION: 'OrganizationUserAiPermission',
  ORGANIZATION_USERS_AI_PERMISSIONS: 'OrganizationUsersAiPermissions'
}

import { Box, styled } from '@mui/material'

export const RowStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<{ selected?: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignContent: 'center',
  alignItems: 'center',
  gap: '24px',
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.grey['200']}`,
  backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.default,
  transition: '.3s background',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.action.hover
  }
}))

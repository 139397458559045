import { CardData } from './interfaces'
import { CardIcon } from './CardIcon'
import { CardName } from './CardName'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { isEmpty } from 'lodash'

interface CardStyledProps {
  selected?: boolean
  notInteractive?: boolean
}

export const CardStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'notInteractive'
})<CardStyledProps>(({ theme, selected, notInteractive }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'center',
  alignItems: 'center',
  gap: '4px',
  padding: theme.spacing(3),
  border: `1px solid ${selected ? theme.palette.border.selected : theme.palette.grey['200']}`,
  borderRadius: '4px',
  backgroundColor: selected ? theme.palette.action.selected : theme.palette.background.default,
  color: notInteractive ? {} : selected ? theme.palette.text.selected : theme.palette.text.primary,
  ':hover': notInteractive
    ? {}
    : {
        backgroundColor: `${selected ? theme.palette.action.selected : theme.palette.grey['100']}`,
        border: `1px solid ${selected ? theme.palette.border.selected : theme.palette.grey['200']}`
      },
  cursor: notInteractive ? 'standard' : 'pointer',
  userSelect: 'none',
  transition: '.3s background, .3s border, .3s color'
}))

const CardDataStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gridGap: theme.spacing(1),
  minWidth: '0'
}))

const CardInfoStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignContent: 'center',
  alignItems: 'flex-start',
  gridGap: theme.spacing(1),
  minWidth: '0'
}))

export const Card = ({
  title,
  children,
  shared,
  selected,
  useTeamIcon,
  onClick,
  onContextMenu,
  className
}: CardData): React.ReactElement => {
  return (
    <CardStyled
      data-testid="card"
      className={className}
      selected={selected}
      onClick={onClick}
      onContextMenu={onContextMenu}
      qa-attribute={`${getQaSelectorText(title)}--card`}
    >
      <CardIcon data-testid="card-icon" shared={shared} addExtraMargin useTeamIcon={useTeamIcon} />
      <CardDataStyled>
        <CardInfoStyled>
          {!isEmpty(title) && <CardName data-testid="card-name">{title}</CardName>}
          {children}
        </CardInfoStyled>
      </CardDataStyled>
    </CardStyled>
  )
}

import { StackCardProps } from './interfaces'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import * as React from 'react'

export const StackCard = ({ children, outlined }: StackCardProps): React.ReactElement => {
  return (
    <Paper elevation={0} variant={outlined ? 'outlined' : 'elevation'}>
      <Stack direction="row" alignItems={outlined ? 'center' : 'baseline'} gap={1}>
        {children}
      </Stack>
    </Paper>
  )
}

import * as React from 'react'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import format from 'date-fns/format'
import Stack from '@mui/material/Stack'
import Chip, { ChipProps } from '@mui/material/Chip'
import styled from '@mui/system/styled'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { ModalTemplateProps } from '@/components/modal/interfaces'
import { TrainingRunEventLog } from '@/store/features/apis/slices/ai/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '@/components/modal/Modal'
import { Button } from '@/components/button/Button'
import {
  useGetTrainingPipelineQuery,
  useGetTrainingRunEventsLogQuery,
  useGetTrainingRunQuery
} from '@/store/features/apis/slices/ai/aiSlice'

interface ModelTrainingRunLogsTemplateProps extends ModalTemplateProps {
  title: string
  modelUuid: string
  trainingRunId: string
}

const getLogParameter = (log: TrainingRunEventLog, name: string): string => {
  const parameter = log.parameters.find((param) => param.name === name)
  return parameter?.value || ''
}

const LogLevelCustomChip = styled(Chip)(({ theme }) => ({
  flexShrink: 0,
  width: theme.spacing(13),
  height: theme.spacing(6),
  fontSize: '.8125rem',
  fontWeight: 700,
  letterSpacing: '0.03em',
  lineHeight: '1.2',
  '&.MuiChip-colorInfo': {
    backgroundColor: theme.palette.grey[150],
    color: theme.palette.text.primary
  },
  '&.MuiChip-colorError': {
    backgroundColor: theme.palette.error[200],
    color: theme.palette.error[800]
  },
  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
    textOverflow: 'unset'
  }
}))

export const ModelTrainingRunLogsTemplate = ({
  title,
  onClose,
  showCloseButton,
  modelUuid,
  trainingRunId
}: ModelTrainingRunLogsTemplateProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining', 'modals'])
  const { data: trainingRun, isLoading: isLoadingTrainingRun } = useGetTrainingRunQuery({ trainingRunId })
  const { data: eventsLogs, isLoading: isLoadingEventLogs } = useGetTrainingRunEventsLogQuery({ trainingRunId })
  const { data: trainingPipeline, isLoading: isLoadingTrainingPipeline } = useGetTrainingPipelineQuery(
    { modelUuid, trainingUuid: trainingRun?.training_uuid },
    { skip: isNil(trainingRun) }
  )

  const isLoading = isLoadingTrainingRun || isLoadingEventLogs || isLoadingTrainingPipeline

  // Separate logs by day
  const logsByDay = React.useMemo(() => {
    if (!eventsLogs) {
      return []
    }

    const logsByDay = eventsLogs.reduce((acc, log) => {
      const date = format(new Date(log.timestamp), 'MMM d, yyyy')

      if (!acc[date]) {
        acc[date] = []
      }

      acc[date].push(log)

      return acc
    }, {} as Record<string, TrainingRunEventLog[]>)

    return Object.entries(logsByDay)
  }, [eventsLogs])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        {!isLoading && (
          <>
            <Typography variant="h4" paddingY={3}>
              {trainingPipeline?.training_name}
            </Typography>
            {logsByDay?.length === 0 && (
              <Typography variant="textV2" paddingY={3}>
                {t('viewLogs.noLogs', { ns: 'modals' })}
              </Typography>
            )}
            {logsByDay?.map(([date, logs]) => (
              <React.Fragment key={date}>
                <Divider sx={{ marginTop: 4, marginBottom: 4 }}>
                  <Typography variant="textV2" paddingX={3}>
                    {date}
                  </Typography>
                </Divider>
                <Stack direction="column" border={1} borderBottom={0} borderColor="border.default">
                  {logs.map((log, index) => (
                    <Stack
                      key={`${log.timestamp}-${index}`}
                      direction="row"
                      alignItems="center"
                      paddingX={3}
                      paddingY={4}
                      spacing={6}
                      borderBottom={1}
                      borderColor="border.default"
                    >
                      <LogLevelCustomChip
                        label={log.level}
                        color={log.level.toLowerCase() as ChipProps['color']}
                        size="small"
                      />
                      <Typography variant="textV2">{format(new Date(log.timestamp), 'HH:mm')}</Typography>
                      <Typography variant="textV2">
                        {t(`trainingRunEventsLogMessages.${log.messageId}`, log.messageId, {
                          train_size: getLogParameter(log, 'train_size'),
                          test_size: getLogParameter(log, 'test_size'),
                          nodal_inputs_size: getLogParameter(log, 'nodal_inputs_size'),
                          global_inputs_size: getLogParameter(log, 'global_inputs_size'),
                          nodal_inputs_names: getLogParameter(log, 'nodal_inputs_names'),
                          global_inputs_names: getLogParameter(log, 'global_inputs_names'),
                          total_number_of_trained_models: getLogParameter(log, 'total_number_of_trained_models')
                        })}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </React.Fragment>
            ))}
          </>
        )}
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('viewLogs.actions.close', { ns: 'modals' })}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import { Folder, PathIDs } from './interfaces'
import { apiTags } from '../../apiTags'
import { isSpace } from '../../helpers'
import { ApplicationApi } from '../../ApplicationApi'

const folderSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getFolderDetails: builder.query<Folder, { spaceId: string; folderId: string }>({
      query: ({ spaceId, folderId }) => `api/v1/spaces/${spaceId}/folders/${folderId}`,
      providesTags: (_result, _error, { spaceId, folderId }) => [
        { type: apiTags.FOLDER_DETAILS, spaceId, folderId: 'EVERY_CHILD' },
        { type: apiTags.FOLDER_DETAILS, spaceId, folderId }
      ]
    }),
    createNewFolder: builder.mutation<Folder, PathIDs & { folderName: string }>({
      query: ({ spaceId, folderId, folderName }) => ({
        url: isSpace({ spaceId, folderId })
          ? `api/v1/spaces/${spaceId}/folders`
          : `api/v1/spaces/${spaceId}/folders/${folderId}`,
        method: 'POST',
        body: { name: folderName }
      }),
      invalidatesTags: (_result, _error, { spaceId, folderId }) => [
        { type: apiTags.FOLDERS_FROM_SPACE, spaceId },
        { type: apiTags.FOLDERS_FROM_FOLDER },
        isSpace({ folderId, spaceId })
          ? { type: apiTags.SPACE_DETAILS, spaceId }
          : { type: apiTags.FOLDER_DETAILS, spaceId, folderId }
      ]
    }),
    updateFolder: builder.mutation<void, PathIDs & { parentItemId: string; folderName: string }>({
      query: ({ spaceId, folderId, folderName }) => ({
        url: `api/v1/spaces/${spaceId}/folders/${folderId}`,
        method: 'PUT',
        body: { name: folderName }
      }),
      invalidatesTags: (_result, _error, { spaceId, folderId }) => [
        { type: apiTags.FOLDER_DETAILS, spaceId, folderId },
        { type: apiTags.FOLDERS_FROM_SPACE, spaceId },
        { type: apiTags.FOLDERS_FROM_FOLDER }
      ]
    }),
    deleteFolder: builder.mutation<void, PathIDs & { parentItemId: string }>({
      query: ({ spaceId, folderId }) => ({
        url: `api/v1/spaces/${spaceId}/folders/${folderId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { spaceId, parentItemId }) => [
        { type: apiTags.FOLDERS_FROM_SPACE, spaceId },
        { type: apiTags.FOLDERS_FROM_FOLDER },
        isSpace({ spaceId, folderId: parentItemId })
          ? { type: apiTags.SPACE_DETAILS, spaceId }
          : { type: apiTags.FOLDER_DETAILS, spaceId, folderId: parentItemId }
      ]
    })
  })
})

export const {
  useGetFolderDetailsQuery,
  useCreateNewFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation
} = folderSlice

import { useContextMenuItems } from './useContextMenuItems'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { closeContextMenu } from '../../store/features/uiSlice/uiSlice'
import * as React from 'react'
import { CircularProgress, Menu } from '@mui/material'
import { isEmpty, isNil } from 'lodash'

export enum ContextMenuType {
  PROJECT,
  FOLDER,
  MULTI_SELECTION,
  SPACE,
  INVITATION,
  MODEL_VERSION,
  MODEL_TRAINING_PIPELINE
}

export const ContextMenu = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const menuRef = React.useRef(null)

  const { isOpen, x, y } = useAppSelector((state) => state.ui.contextMenu)

  const items = useContextMenuItems()

  const closeMenu = (): void => {
    dispatch(closeContextMenu())
  }

  React.useEffect(() => {
    const mainDisplay = document.querySelector<HTMLElement>('[qa-attribute="main-display"]')
    mainDisplay.style.overflowY = isOpen ? 'hidden' : 'overlay'
  }, [isOpen])

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  return (
    <Menu
      ref={menuRef}
      open={isOpen}
      anchorReference="anchorPosition"
      anchorPosition={!isNil(x) && !isNil(y) ? { top: y, left: x } : undefined}
      onClose={closeMenu}
      sx={{
        position: 'unset'
      }}
      disablePortal
      hideBackdrop
      disableScrollLock
    >
      {isOpen && isEmpty(items) && <CircularProgress />}
      {items}
    </Menu>
  )
}

import { CommentDataProps } from './Comments'
import { useGetCommentsQuery } from '../../../../store/features/apis/slices/forum/forumSlice'
import { useGetProjectTopicIdQuery } from '../../../../store/features/apis/slices/project/projectSlice'
import { isNil } from 'lodash'

interface CommentsProps {
  publicProjectName: string
  ownerName: string
  commentsPage: number
}

interface CommentsData {
  comments: CommentDataProps[]
  totalNumberOfComments: number
  isLoading: boolean
}

export const useComments = ({ publicProjectName, ownerName, commentsPage }: CommentsProps): CommentsData => {
  try {
    const {
      data: projectTopicId,
      isFetching: isTopicIdFetching,
      isSuccess: isTopicIdSuccess,
      isError
    } = useGetProjectTopicIdQuery(
      {
        publicProjectName,
        ownerName
      },
      { skip: isNil(publicProjectName) || isNil(ownerName) }
    )

    const {
      data,
      isFetching: areCommentsFetching,
      isSuccess: isCommentsSuccess
    } = useGetCommentsQuery(
      { topicId: projectTopicId, page: commentsPage },
      { skip: isNil(projectTopicId) || isTopicIdFetching || !isTopicIdSuccess || isError }
    )

    if (isError) {
      return { comments: [], totalNumberOfComments: 0, isLoading: false }
    }

    return {
      comments: isTopicIdSuccess && isCommentsSuccess ? data.comments : undefined,
      totalNumberOfComments: isTopicIdSuccess && isCommentsSuccess ? data.totalNumberOfComments : undefined,
      isLoading: isTopicIdFetching || areCommentsFetching
    }
  } catch (e) {
    return { comments: [], totalNumberOfComments: 0, isLoading: false }
  }
}

import { SwitchStyled } from '../../../../components/elements/Switch'
import { useAppDispatch } from '../../../../store/hooks'
import { ModalTemplate } from '../../../../store/features/uiSlice/interfaces'
import { openModal } from '../../../../store/features/uiSlice/uiSlice'
import { useGetOrganizationId } from '../../../../store/features/apis/slices/organization/hooks'
import { UserOrganizationStatus } from '../../../../store/features/apis/slices/organization/interfaces'
import { useGetOrganizationV2UsersQuery } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { UserV2 } from '../../../../store/features/apis/slices/user/interfaces'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { t } from 'i18next'
import { isNil } from 'lodash'

interface IsAdminSwitchProps {
  user: UserV2
  userOrganizationStatus: UserOrganizationStatus
  disabled?: boolean
  isAdmin: boolean
}

export const IsAdminSwitch = ({
  user,
  userOrganizationStatus,
  disabled,
  isAdmin
}: IsAdminSwitchProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const organizationId = useGetOrganizationId()
  const { data: organizationUsers } = useGetOrganizationV2UsersQuery(
    { organizationId },
    { skip: isNil(organizationId) }
  )

  const isCurrentUserAdmin = userOrganizationStatus === UserOrganizationStatus.ADMIN

  const handleAdminSwitchClick = React.useCallback((checked: boolean, user: UserV2): void => {
    dispatch(
      openModal({
        template: checked ? ModalTemplate.ASSIGN_ORGANIZATION_ADMIN : ModalTemplate.UNASSIGN_ORGANIZATION_ADMIN,
        showCloseButton: true,
        modalProps: {
          title: t(checked ? 'assignOrganizationAdmin.title' : 'unassignOrganizationAdmin.title', { ns: 'modals' }),
          selectedUser: user
        }
      })
    )
  }, [])

  if (!isAdmin) {
    return <Typography>{isCurrentUserAdmin && 'Admin'}</Typography>
  }

  const organizationAdmins = organizationUsers?.filter((user) => user.status === UserOrganizationStatus.ADMIN)
  const isLastAdminRemaining = Boolean(organizationAdmins?.length === 1)

  return (
    <SwitchStyled
      qa-attribute={`${user?.email}-isAdmin--featureToggle`}
      onChange={(event): void => handleAdminSwitchClick(event.target.checked, user)}
      color="primary"
      checked={isCurrentUserAdmin}
      disabled={disabled || (isCurrentUserAdmin && isLastAdminRemaining)}
    />
  )
}

import { ProjectCardProps } from './interfaces'
import { OwnerName } from './OwnerName'
import { StyledTooltip } from '../tooltip/StyledTooltip'
import * as React from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SimulationCardStyled } from '@/containers/detailsPanel/components/SimulationCard'
import { OpenProjectButton } from '@/containers/SmartButtons/OpenProjectButton'
import { getQaSelectorText } from '@/helpers/qaSelectors'
import { getHumanReadableDate, getResolvedThumbnailFullServicePath } from '@/utils/helpers'
import { SpriteIcon } from '@/assets/SpriteIcon'

const imgSimulationDemo = require('@/assets/png/no-preview.png')

interface ProjectCardStyledProps {
  selected?: boolean
}

const ProjectCardStyled = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<ProjectCardStyledProps>(({ theme, selected }) => ({
  position: 'relative',
  boxSizing: 'border-box',
  border: `1px solid ${selected ? theme.palette.border.selected : theme.palette.grey['200']}`,
  borderRadius: '4px',
  backgroundColor: selected && theme.palette.action.selected,
  boxShadow: selected ? '0px 2px 4px rgba(0, 0, 0, 0.06)' : '',
  ':hover': {
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.grey['150'],
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)'
  },
  cursor: 'default',
  userSelect: 'none',
  transition: '.3s border, .3s background, .3s color, .3s box-shadow',
  '& > .MuiButton-root': {
    position: 'absolute',
    top: 7,
    right: 8
  }
}))

const ProjectDetailsStyled = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<ProjectCardStyledProps>(({ theme, selected }) => ({
  borderTop: `1px solid ${selected ? theme.palette.border.selected : theme.palette.grey['200']}`,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  transition: '.3s border'
}))

export const ProjectCard = ({
  projectCardData,
  onContextMenu,
  onClick,
  className
}: ProjectCardProps): React.ReactElement => {
  const { t } = useTranslation(['tooltips'])
  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  return (
    <Grid xs={12} item justifyContent="center">
      <ProjectCardStyled
        key={projectCardData.projectIdExt}
        direction="column"
        selected={projectCardData.selected}
        onClick={(event): void => {
          onClick(event, projectCardData.projectIdExt)
        }}
        onContextMenu={onContextMenu}
        qa-attribute={`${getQaSelectorText(projectCardData.name)}--project-card`}
        onMouseEnter={(): void => {
          setIsHovered(true)
        }}
        onMouseLeave={(): void => {
          setIsHovered(false)
        }}
        className={className}
      >
        {projectCardData.selected || isHovered ? (
          <OpenProjectButton projectIdExt={projectCardData.projectIdExt} variant="outlined" sx={{ zIndex: 1 }} />
        ) : null}
        <SimulationCardStyled
          imageUrl={getResolvedThumbnailFullServicePath(projectCardData.thumbnailUrl) ?? imgSimulationDemo}
          alignSelf="center"
          isBottomBorderFlat
          sx={{ width: '100%' }}
          qa-attribute={`${getQaSelectorText(projectCardData.name)}--project-card-thumbnail`}
        />
        <ProjectDetailsStyled selected={projectCardData.selected}>
          <Stack direction="column" spacing={2} sx={{ p: 3 }}>
            <Typography
              variant="titleLight"
              color={projectCardData.selected ? 'primary.main' : 'text.primary'}
              sx={{ transition: '.3s color' }}
              qa-attribute={`project-title--label`}
            >
              {projectCardData.name}
            </Typography>
            <Box sx={{ marginTop: '4px!important' }}>
              <OwnerName ownerName={projectCardData.ownerName} />
            </Box>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="subtitle" color="grey.700" flexGrow={1}>
                {getHumanReadableDate(projectCardData.lastModifiedAt)}
              </Typography>
              {projectCardData?.public && (
                <StyledTooltip title={t('public', { ns: 'tooltips' })}>
                  <Typography variant="fontAwesomeIcon" color="grey.500" fontSize="0">
                    <SpriteIcon spriteId="earth" width={10} height={10} color="grey.600" data-icon="earth-americas" />
                  </Typography>
                </StyledTooltip>
              )}
              {projectCardData?.shared && (
                <StyledTooltip title={t('shared', { ns: 'tooltips' })}>
                  <Typography variant="fontAwesomeIcon" color="grey.500" fontSize="0">
                    <SpriteIcon spriteId="share-nodes" width={10} height={10} color="grey.600" />
                  </Typography>
                </StyledTooltip>
              )}
            </Stack>
          </Stack>
        </ProjectDetailsStyled>
      </ProjectCardStyled>
    </Grid>
  )
}

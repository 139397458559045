import { defaultThemePaletteOptions } from './themeOptions/palette'
import { defaultThemeTypographyOptions } from './themeOptions/typography'
import { defaultThemeComponentOptions } from './themeOptions/components'
import { defaultThemeSpacingOptions } from './themeOptions/defaultThemeSpacingOptions'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: defaultThemePaletteOptions,
  typography: defaultThemeTypographyOptions,
  components: defaultThemeComponentOptions,
  spacing: defaultThemeSpacingOptions
})

export default theme

import { AccessPreferences } from './settings/accessPreferences/AccessPreferences'
import { DomainsManagement } from './settings/domains/DomainsManagement'
import { ProjectPreferences } from './settings/projectsPreferences/ProjectPreferences'
import { useIsAdmin, useRedirectIfPermissionDenied } from '../../../permissions/areas'
import { useAttributes } from '../../../hooks/newWorld'
import * as React from 'react'
import Stack from '@mui/material/Stack'

export const OrganizationSettingsDisplay = (): React.ReactElement => {
  const { data: isAdmin, isLoading: isLoadingAdminPermission } = useIsAdmin()
  const { data: attributes } = useAttributes()
  useRedirectIfPermissionDenied([[isLoadingAdminPermission, isAdmin]])

  const showProjectPreferences = attributes?.isProfessional

  return (
    <Stack direction="column" gap={5} paddingY={4}>
      <AccessPreferences />
      {showProjectPreferences && <ProjectPreferences />}
      <DomainsManagement />
    </Stack>
  )
}

import { ApiKeysDisplay } from '../apiKeys/ApiKeysDisplay'
import { UsageOverviewDisplay } from '../usageOverview/UsageOverviewDisplay'
import { HomeDisplay } from '../home/HomeDisplay'
import { SecurityDisplay } from '../security/SecurityDisplay'
import { InvitationsDisplay } from '../invitations/InvitationsDisplay'
import { ProfilePages, ProfilePagesType } from '../../../../hooks/useIsProfileSectionPage'
import Box from '@mui/material/Box'
import * as React from 'react'

const ProfileTabContents = ({ activeTab }: { activeTab: ProfilePagesType }): React.ReactElement => {
  switch (activeTab) {
    case ProfilePages.USAGE_OVERVIEW:
      return <UsageOverviewDisplay />
    case ProfilePages.MY_INVITATIONS:
      return <InvitationsDisplay />
    case ProfilePages.API_KEYS:
      return <ApiKeysDisplay />
    case ProfilePages.SECURITY:
      return <SecurityDisplay />
    case ProfilePages.PROFILE:
    default:
      return <HomeDisplay />
  }
}

export const ProfileDisplay = ({ activeTab }: { activeTab: ProfilePagesType }): React.ReactElement => {
  return (
    <Box p={2}>
      <ProfileTabContents activeTab={activeTab} />
    </Box>
  )
}

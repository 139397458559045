import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { ManageTeamsDisplay } from '../../containers/dashboard/manageTeams/ManageTeamsDisplay'
import * as React from 'react'

const ManageSpacesPage = (): React.ReactElement => {
  return (
    <MainDisplay>
      <ManageTeamsDisplay />
    </MainDisplay>
  )
}

export default ManageSpacesPage

import { PermissionType, ScopeType, SpacePermission } from './interfaces'
import { SharedWithUser } from './SharedWithUser'
import * as React from 'react'
import { isNil } from 'lodash'
import { Box, SelectChangeEvent } from '@mui/material'

interface SharedWithUsersProps {
  projectPermissions: SpacePermission[]
  onPermissionChange: (email: string, permission: string) => void
}

export const SharedWithUsers = ({
  projectPermissions,
  onPermissionChange
}: SharedWithUsersProps): React.ReactElement => {
  const [userPermissions, setUserPermissions] = React.useState<SpacePermission[]>(
    projectPermissions.filter(({ scope }) => scope.scopeType === ScopeType.USER && !isNil(scope.username))
  )

  const handlePermissionOnChange = React.useCallback((event: SelectChangeEvent<string>, username: string) => {
    setUserPermissions((prevState) =>
      prevState.map((item) => {
        if (item.scope.username === username) {
          return {
            ...item,
            permission: event.target.value as PermissionType
          }
        }
        return item
      })
    )
    onPermissionChange(username, event.target.value)
  }, [])

  return (
    <Box>
      {userPermissions.map((permission) => {
        return (
          <SharedWithUser
            key={permission.scope.username}
            username={permission.scope.username}
            permissionType={permission.permission}
            onSelectChange={handlePermissionOnChange}
          />
        )
      })}
    </Box>
  )
}

export type ConsoleLogMethod = 'log' | 'error' | 'warn' | 'debug' | 'info'

export enum KibanaSeverity {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  TRACE = 'TRACE',
  DEBUG = 'DEBUG'
}

export interface KibanaPayload {
  SPAN?: string
  TRACE?: string
  USER?: string | number
  env?: string // autofilled by kibana
  service?: string // autofilled by kibana, but can be overriden
  message?: string | object
  stack_trace?: string
  user_agent?: string
  logger?: string
  severity?: KibanaSeverity
  thread?: string
  app_version?: string
  client_url?: string
  '@timestamp'?: string // ISO 8601 Extended Format
}

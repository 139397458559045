import { NavigationItemType } from './AiModelSelectionNavigation'
import { PathSelector } from '../../spaces/interfaces'
import { NavigationItemTitle } from '../../navigation/NavigationItemTitle'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@/store/features/apis/slices/space/interfaces'
import { FolderSelectorNavigationTree } from '@/components/modal/templates/moveProject/NavigationTreePathSelector'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { setFullPath } from '@/store/features/uiSlice/uiSlice'

type TeamSpacesNavigationItemType = NavigationItemType & {
  spaces: Space[]
}

export const TeamSpacesNavigationItem = ({
  spaces,
  isSelected,
  isOpen,
  handleOnClick
}: TeamSpacesNavigationItemType): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const fullPath = useAppSelector((state) => state.ui.fullPath)

  const onSelected = (path: PathSelector): void => {
    dispatch(setFullPath(path))
  }

  return (
    <>
      <NavigationItemTitle spriteId="user-group" label={t('spaces')} onClick={handleOnClick} isSelected={isSelected} />
      {isOpen &&
        spaces.map((space) => (
          <FolderSelectorNavigationTree
            key={space.spaceId}
            path={[space.spaceId]}
            label={space.name}
            setSelectedPath={onSelected}
            selectedPath={fullPath}
          />
        ))}
    </>
  )
}

import { NavigationItemExternal } from '../navigation/NavigationItemExternal'
import { getUrl } from '../../helpers/navigation'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const PublicProjects = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <NavigationItemExternal
      icon={<SpriteIcon spriteId="globe" width={13} height={13} color="grey.600" style={{ marginTop: -1 }} />}
      label={t('publicProjects')}
      href={getUrl('/projects/')}
    />
  )
}

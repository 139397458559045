import theme from '../../theme/defaultTheme'
import { Box, Collapse, CollapseProps, Typography } from '@mui/material'
import * as React from 'react'
import { SpriteIcon } from '@/assets/SpriteIcon'

type RotateProp = number | null

interface CollapsePropsExtended extends CollapseProps {
  collapseText: string
  collapsedIconRotationValue: RotateProp
  textTransform?: string
  textColor?: string
  fontSize?: string
  letterSpacing?: string
  fontWeight?: number
  defaultCollapseValue?: boolean
  noMarginLeft?: boolean
  'qa-attribute'?: string
}

export const CollapseWrapper = ({
  collapseText,
  textTransform,
  textColor,
  fontSize,
  letterSpacing,
  fontWeight,
  defaultCollapseValue,
  collapsedIconRotationValue,
  noMarginLeft,
  'qa-attribute': qaAttribute,
  ...props
}: CollapsePropsExtended): React.ReactElement => {
  const [collapsed, setCollapsed] = React.useState(defaultCollapseValue ?? false)

  const handleChange = (): void => setCollapsed((value) => !value)

  return (
    <Box qa-attribute={qaAttribute}>
      <Typography
        onClick={handleChange}
        sx={{
          fontWeight: fontWeight ?? 700,
          fontSize: fontSize ?? '.6875rem',
          letterSpacing: letterSpacing ?? '.1em',
          userSelect: 'none',
          cursor: 'pointer',
          marginLeft: !noMarginLeft && theme.spacing(2),
          marginBottom: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          textTransform: textTransform ?? 'none'
        }}
        color={textColor ?? 'text.primary'}
      >
        <Box
          component="span"
          style={{
            marginRight: theme.spacing(1),
            width: theme.spacing(5),
            height: theme.spacing(5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <SpriteIcon
            spriteId="angle-right"
            rotatingAngle={collapsed ? collapsedIconRotationValue : 0}
            color="text.primary"
            width={7}
          />
        </Box>
        {collapseText}
      </Typography>
      <Collapse
        in={collapsed}
        {...props}
        sx={{
          marginBottom: theme.spacing(3),
          opacity: 1,
          transition: '.3s margin, .3s height, .3s opacity, .3s visibility 0s',
          '&.MuiCollapse-hidden': {
            marginBottom: theme.spacing(1),
            opacity: 0,
            transitionDelay: '.3s margin, .3s height, .3s opacity, .3s visibility .3s'
          }
        }}
      ></Collapse>
    </Box>
  )
}

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { getSortingModeV2, setSortingDirection, setSortingModeV2 } from '../../../store/features/uiSlice/uiSlice'
import { SortingDirection, SortingModeV2 } from '../../../store/features/uiSlice/interfaces'
import { Order } from '../../../components/table/enhancedTable/interfaces'
import * as React from 'react'
import { Box, Stack, SxProps, TableSortLabel, Theme } from '@mui/material/'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const SortingIcon = ({
  isActive,
  order,
  sx
}: {
  order: Order
  isActive?: boolean
  sx?: SxProps<Theme>
}): React.ReactElement => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 2,
        ...sx
      }}
    >
      <SpriteIcon
        spriteId="angle-right"
        color={isActive && order === 'desc' ? 'primary.main' : 'grey.550'}
        rotatingAngle={270}
        width={10}
        height={10}
      />
      <SpriteIcon
        spriteId="angle-right"
        color={isActive && order === 'asc' ? 'primary.main' : 'grey.550'}
        rotatingAngle={90}
        width={10}
        height={10}
      />
    </Box>
  )
}

export const RowViewSorter = (): React.ReactElement => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const currentSortMode = useAppSelector(getSortingModeV2)
  const currentSortDirection = useAppSelector((state) => state.ui.sortingDirection)
  const isNameActive = currentSortMode === SortingModeV2.NAME
  const isLastModifiedActive = currentSortMode === SortingModeV2.LAST_MODIFIED_AT

  const handleSortDirection = (): void => {
    if (currentSortDirection === SortingDirection.ASC) {
      dispatch(setSortingDirection(SortingDirection.DESC))
    } else {
      dispatch(setSortingDirection(SortingDirection.ASC))
    }
  }

  const handleSorting = (sortingModeV2: SortingModeV2): void => {
    dispatch(setSortingModeV2(sortingModeV2))
    handleSortDirection()
  }

  return (
    <Stack direction="row" justifyContent="space-between" paddingX={5} paddingBottom={1}>
      <TableSortLabel
        active={isNameActive}
        direction={currentSortDirection}
        onClick={(): void => handleSorting(SortingModeV2.NAME)}
        IconComponent={(): React.ReactElement => <SortingIcon order={currentSortDirection} isActive={isNameActive} />}
      >
        {t('rowSorting.name')}
      </TableSortLabel>
      <TableSortLabel
        active={isLastModifiedActive}
        direction={currentSortDirection}
        onClick={(): void => handleSorting(SortingModeV2.LAST_MODIFIED_AT)}
        IconComponent={(): React.ReactElement => (
          <SortingIcon order={currentSortDirection} isActive={isLastModifiedActive} />
        )}
      >
        {t('rowSorting.lastModified')}
      </TableSortLabel>
    </Stack>
  )
}

import { TableRowCellProps } from './interfaces'
import * as React from 'react'
import { SxProps, TableCell, Theme } from '@mui/material'
import { isNil } from 'lodash'

const EnhancedTableCell = ({
  title,
  component,
  lastCell,
  sx,
  onClick
}: {
  title: string
  component: React.ReactElement
  lastCell?: boolean
  sx?: SxProps<Theme>
  onClick?: (event: React.MouseEvent) => void
}): React.ReactElement => {
  return (
    <TableCell sx={{ width: lastCell ? '100%' : 'auto', ...sx }} onClick={onClick}>
      {!isNil(component) ? component : title}
    </TableCell>
  )
}

export const EnhancedTableCells = ({ tableCellsData }: { tableCellsData: TableRowCellProps[] }): React.ReactElement => {
  return (
    <React.Fragment>
      {tableCellsData.map((tableCellData, index, array) => (
        <EnhancedTableCell
          key={tableCellData.id}
          title={tableCellData?.title}
          component={tableCellData?.component}
          lastCell={index === array.length - 1}
          sx={tableCellData?.sx}
          onClick={tableCellData?.onCellClick}
        />
      ))}
    </React.Fragment>
  )
}

import { useTheme } from '@mui/system'

export const useJobsStatusMap = (): Record<string, { color: string }> => {
  const theme = useTheme()

  return {
    NOT_READY: {
      color: theme.palette.primary.main
    },
    READY: {
      color: theme.palette.primary.main
    },
    RUNNING: {
      color: theme.palette.primary.main
    },
    FINISHED: {
      color: theme.palette.success.main
    },
    ERROR: {
      color: theme.palette.error.main
    },
    FINISHING_UP: {
      color: theme.palette.success.main
    }, // new
    ERROR_FINISHING_UP: {
      color: theme.palette.error.main
    }, // new
    STARTED: {
      color: theme.palette.success.main
    },
    CANCELING: {
      color: theme.palette.warning.main
    },
    CANCELED: {
      color: theme.palette.warning.main
    },
    CANCELED_FINISHING_UP: {
      color: theme.palette.warning.main
    }, // new
    PREPARING: {
      color: theme.palette.success.main
    }, // new
    QUEUED: {
      color: theme.palette.primary.main
    }
  }
}

import { FilterSelector } from './headerFilterTypes/FilterSelector'
import { TableFilter } from './interfaces'
import { Box, Popover } from '@mui/material'
import * as React from 'react'
import { isNil } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface HeaderFilterDialogProps {
  filter: TableFilter
  setFilters: React.Dispatch<React.SetStateAction<TableFilter<any>[]>>
  id: string
}

export const HeaderFilterDialog = ({ filter, setFilters, id }: HeaderFilterDialogProps): React.ReactElement => {
  const anchorEl = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const isFilterActive =
    filter.type === 'date' ? !isNil(filter.value.fromDate) && !isNil(filter.value.toDate) : filter.value

  return (
    <>
      <SpriteIcon
        style={{ cursor: 'pointer' }}
        spriteId={isFilterActive ? 'filled-filter' : 'filter'}
        ref={anchorEl}
        onClick={(): void => {
          setOpen(true)
        }}
      />
      <Popover
        id={'p1'}
        open={open}
        anchorEl={anchorEl.current}
        anchorReference="anchorEl"
        onClose={(): void => {
          setOpen(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box sx={{ padding: 2 }}>
          <FilterSelector filter={filter} setFilters={setFilters} id={id} />
        </Box>
      </Popover>
    </>
  )
}

import { useIsMobile } from './useIsMobile'
import { useCurrentPath } from './useCurrentPath'
import { useIsSearchMode } from './useIsSearchMode'
import { useDashboardNavigate } from './useDashboardNavigate'
import { deselectItem, getSelectedItems, selectItem, selectSingleItem } from '../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { SelectedItemType, SelectedItems } from '../store/features/uiSlice/interfaces'
import { useUnselectOnOutsideClick } from '../hooks/useUnselectOnOutsideClick'
import { filterSearchParams } from '../helpers/navigation'
import { pathToRoute } from '../helpers/router'
import { PathSelector } from '../containers/spaces/interfaces'
import { PagePath } from '../constants'
import { usePersonalSpaceId } from '../store/features/apis/slices/space/hooks'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { first } from 'lodash'

type MultiSelectResult = {
  selectedItems: SelectedItems
  isSelectedItem: (id: string, type: SelectedItemType) => boolean
  getFirstSelectedProject: () => string | undefined
  handleMultiSelection: (event: React.MouseEvent<HTMLElement>, id: string, type: SelectedItemType) => void
}

export const useMultiSelect = (): MultiSelectResult => {
  const isMobile = useIsMobile()
  const { navigateNoReset } = useDashboardNavigate()
  const dispatch = useAppDispatch()
  const selectedItems = useAppSelector(getSelectedItems)
  const [searchParams] = useSearchParams()
  const { currentPath } = useCurrentPath()
  const isSearchMode = useIsSearchMode()
  const personalSpaceId = usePersonalSpaceId()

  useUnselectOnOutsideClick('.main-display', ['MuiButtonBase-root', 'right-sidebar', 'selectable-card'])

  const isSelectedItem = (id: string, type: SelectedItemType): boolean => {
    if (type === 'space') {
      return selectedItems.spaces.includes(id)
    }
    if (type === 'folder') {
      return selectedItems.folders.includes(id)
    }
    return selectedItems.projects.includes(id)
  }

  const isMultiSelectKey = (event: React.MouseEvent<HTMLElement>): boolean => {
    if (isMobile || isSearchMode) {
      return false
    }

    return event.ctrlKey || event.metaKey
  }

  const openProjectPage = (projectId: string): void => {
    const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
    const projectUrl = `/projects/${projectId}`
    navigateNoReset(`${projectUrl}${filteredParams}`)
  }

  const navigateToParent = React.useCallback(
    (currentPath: PathSelector, searchParams: URLSearchParams): void => {
      const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
      const inAiSection = currentPath[0]?.includes(PagePath.AI_MODELS)
      if (inAiSection) {
        return
      }
      if (isSearchMode) {
        currentPath = [PagePath.SEARCH]
      }
      if (currentPath.length === 1 && currentPath[0] === personalSpaceId) {
        currentPath = []
      }
      navigateNoReset(`/${pathToRoute(currentPath)}${filteredParams}`)
    },
    [isSearchMode, personalSpaceId]
  )

  const wereItemsSelected = React.useRef<boolean>(false)
  React.useEffect(() => {
    if (selectedItems.projects.length > 0 || selectedItems.folders.length > 0 || selectedItems.spaces.length > 0) {
      wereItemsSelected.current = true
    } else if (wereItemsSelected.current) {
      navigateToParent(currentPath, searchParams)
      wereItemsSelected.current = false
    }
  }, [selectedItems.projects, selectedItems.folders, selectedItems.spaces, currentPath])

  const wasSingleProjectSelected = React.useRef<boolean>(false)
  React.useEffect(() => {
    if (
      selectedItems.projects.length === 1 &&
      selectedItems.folders.length === 0 &&
      selectedItems.spaces.length === 0
    ) {
      wasSingleProjectSelected.current = true
    } else if (wasSingleProjectSelected.current) {
      navigateToParent(currentPath, searchParams)
      wasSingleProjectSelected.current = false
    }
  }, [selectedItems.projects, selectedItems.folders, selectedItems.spaces, currentPath])

  return {
    selectedItems,
    isSelectedItem,
    getFirstSelectedProject: (): string => {
      return first(selectedItems.projects)
    },
    handleMultiSelection: (event: React.MouseEvent<HTMLElement>, id: string, type: SelectedItemType): void => {
      if (!isMobile) {
        if (isMultiSelectKey(event)) {
          if (isSelectedItem(id, type)) {
            dispatch(deselectItem({ id, type }))
          } else {
            const noItemsSelected =
              selectedItems.projects.length === 0 &&
              selectedItems.folders.length === 0 &&
              selectedItems.spaces.length === 0
            dispatch(selectItem({ id, type }))
            if (type === 'project' && noItemsSelected) {
              openProjectPage(id)
            }
          }
        } else {
          dispatch(selectSingleItem({ id, type }))
          if (type === 'project') {
            openProjectPage(id)
          }
        }
      }
    }
  }
}

import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useApproveInvitationMutation } from '../../../../store/features/apis/slices/invitation/inviteSlice'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

interface ApproveInvitationModalTemplateProps extends ModalTemplateProps {
  title: string
  inviteId: string
}

export const ApproveInvitationModalTemplate = ({
  title,
  onClose,
  showCloseButton,
  inviteId
}: ApproveInvitationModalTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const [approveInvitation, { isLoading }] = useApproveInvitationMutation()

  const { handleApiHookErrors } = useErrorHandler({})

  const onClick = React.useCallback(async () => {
    await approveInvitation({ inviteId })
      .unwrap()
      .then((_) => {
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: t('approveInvitation.approvedSuccessfully')
          })
        )

        onClose()
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [inviteId])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={onClick} submitDisabled={isLoading}>
        <Typography>{t('approveInvitation.approveLabel')}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('approveInvitation.cancel')}
        </Button>
        <Button onClick={onClick} color="primary" variant="contained" disabled={isLoading}>
          {t('approveInvitation.approve')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

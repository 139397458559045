import { useAttributes } from './newWorld'
import { useGetOrganizationSettingsQuery } from '../store/features/apis/slices/organization/organizationSlice'
import { useGetOrganizationId } from '../store/features/apis/slices/organization/hooks'
import { isNil } from 'lodash'

interface ProjectPreferences {
  allowPublicProjects: boolean
  allowPrivateProjects: boolean
  isLoading?: boolean
}

export const useProjectPreferences = (): ProjectPreferences => {
  const organizationId = useGetOrganizationId()
  const { data: attributes, isLoading: isLoadingAttributes } = useAttributes()
  const { data: settings, isLoading: isLoadingSettings } = useGetOrganizationSettingsQuery(
    { organizationId },
    { skip: isNil(organizationId) }
  )

  if (isLoadingAttributes || isLoadingSettings) {
    return {
      allowPublicProjects: false,
      allowPrivateProjects: false,
      isLoading: true
    }
  }

  const isProfessionalAccount = attributes?.isProfessional
  const allowPublicProjects = !isProfessionalAccount || settings?.allowPublicProjects
  const allowPrivateProjects = isProfessionalAccount

  return {
    allowPublicProjects,
    allowPrivateProjects,
    isLoading: false
  }
}

import { UserQuotaProgressBar } from './UserQuotaProgressBar'
import { convertSecondsToHoursMinutes } from './helpers'
import { BalanceSecondsDetail } from '../../store/features/apis/slices/organization/interfaces'
import { Skeleton, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'

interface UserQuotaRowProps {
  title: string
  balances: BalanceSecondsDetail
  isLoading: boolean
}

type CalculatedData = {
  percentageAvailable: number
  used: string
  total: string
  available: string
}

export const UserQuotaRow = ({ title, balances, isLoading }: UserQuotaRowProps): React.ReactElement => {
  const { t } = useTranslation('productPlan')

  const calculateData = React.useCallback((balances: BalanceSecondsDetail): CalculatedData => {
    const usedValue = balances.total - balances.available
    const totalValue = balances.total
    const available = convertSecondsToHoursMinutes(balances.available)
    const percentageAvailable = (balances.available / totalValue) * 100
    const used = convertSecondsToHoursMinutes(usedValue)
    const total = convertSecondsToHoursMinutes(totalValue)
    return { percentageAvailable, used, total, available }
  }, [])

  if (isLoading) {
    return (
      <>
        <Skeleton variant="rectangular" width={'50%'} height={18} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width={'50%'} height={12} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" width={'100%'} height={4} sx={{ mb: 2 }} />
      </>
    )
  }

  if (isNil(balances) || isEmpty(balances) || balances.total === 0) {
    return null
  }

  const { percentageAvailable, used, total, available } = calculateData(balances)

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="column">
        <Typography
          color="primary"
          sx={{
            fontSize: '.8125rem',
            fontWeight: 500
          }}
        >
          {title}
        </Typography>
        <Stack direction="row" gap={1} flexWrap="wrap">
          <Typography variant="textLight" color={'text'}>
            {t('remaining', { available })}
          </Typography>
          <Typography variant="textLight" color={'grey.700'}>
            {t('usedFromAvailable', { used, total })}
          </Typography>
        </Stack>
      </Stack>
      <UserQuotaProgressBar percentage={percentageAvailable} />
    </Stack>
  )
}

import * as React from 'react'
import { isNil, sortBy } from 'lodash'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { TrainingRunMetric } from '@/store/features/apis/slices/ai/interfaces'
import { useGetTrainingRunMetricsQuery } from '@/store/features/apis/slices/ai/aiSlice'

type TrainingRunMetricsChartProps = {
  trainingRunId: string
  metricName: TrainingRunMetric['name']
}

export const TrainingRunMetricsChart = ({
  trainingRunId,
  metricName
}: TrainingRunMetricsChartProps): React.ReactElement => {
  const { data, isLoading } = useGetTrainingRunMetricsQuery(
    { trainingRunId, metric: metricName },
    { skip: isNil(trainingRunId) || isNil(metricName) }
  )

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height={350} animation="wave" />
  }

  return (
    <Box
      border={1}
      borderColor="border.default"
      borderRadius={1}
      p={2}
      sx={{ backgroundColor: 'white' }}
      qa-attribute="aiModel--trainingRun--metricsChart"
    >
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={sortBy(data, 'step')}>
          <XAxis minTickGap={25} dataKey="step" height={20} />
          <YAxis width={40} />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5" />
          <Line type="monotone" dataKey="value" stroke="#ff7300" yAxisId={0} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  )
}

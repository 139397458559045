import { useGetApiKeysTableBodyData, useGetApiKeysTableHeaderData } from './tableDataHooks'
import { EnhancedTable } from '../../../../components/table/enhancedTable/EnhancedTable'
import { Button } from '../../../../components/button/Button'
import { ModalTemplate } from '../../../../store/features/uiSlice/interfaces'
import { openModal } from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { useGetUserApiKeysQuery } from '../../../../store/features/apis/slices/user/userSlice'
import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const ApiKeysDisplay = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['profileArea'])

  const { data: apiKeys, isLoading, isFetching } = useGetUserApiKeysQuery()
  const tableHeaderData = useGetApiKeysTableHeaderData()
  const tableData = useGetApiKeysTableBodyData(apiKeys)

  const handleOnGenerateKeyClick = React.useCallback((): void => {
    dispatch(
      openModal({
        template: ModalTemplate.GENERATE_KEY,
        showCloseButton: true
      })
    )
  }, [dispatch])

  return (
    <Stack
      mt={2.5}
      mb={4}
      direction="column"
      padding={6}
      border={1}
      borderRadius={1}
      borderColor="grey.200"
      qa-attribute="api-keys--table"
    >
      <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={2}>
        <Typography variant="tableTitle">{t('apiKeys.title')}</Typography>
        <Button
          startIcon={<SpriteIcon spriteId="plus" height=".8125rem" width=".8125rem" color="common.white" />}
          onClick={handleOnGenerateKeyClick}
          variant="contained"
        >
          {t('apiKeys.buttons.generateKey')}
        </Button>
      </Stack>

      <EnhancedTable
        isLoading={isLoading || isFetching}
        tableHeaderData={tableHeaderData}
        tableBodyData={tableData}
        defaultSortByColumn={2}
      />
    </Stack>
  )
}

import { useAttributes } from './newWorld'
import { useGetSettingsMapQuery } from '../store/features/apis/slices/user/userSlice'

export const useLoadEssentialResources = (): { isLoading: boolean; data: boolean } => {
  const { isError: isErrorAttributes, isLoading: isLoadingAttributes } = useAttributes()
  const { isError: isErrorSettingsMap, isLoading: isLoadingSettingsMap } = useGetSettingsMapQuery()

  if (isLoadingAttributes || isLoadingSettingsMap) {
    return { isLoading: true, data: undefined }
  }

  return { isLoading: false, data: !isErrorAttributes && !isErrorSettingsMap }
}

import { styled } from '@mui/system'
import * as React from 'react'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

const BreadcrumbsStyled = styled(MuiBreadcrumbs)(() => ({
  '.MuiBreadcrumbs-ol': { overflow: 'hidden', maxWidth: '100%', flexWrap: 'nowrap!important' },
  '.MuiBreadcrumbs-li': { overflow: 'hidden' },
  '.MuiBreadcrumbs-li:not(.MuiBreadcrumbs-separator)': { minWidth: 12 }
}))

export const BreadcrumbsRow = ({ children }): React.ReactElement => {
  return <BreadcrumbsStyled separator="›">{children}</BreadcrumbsStyled>
}

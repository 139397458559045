import { METRICS_TYPES } from './interfaces'

// Note: for R2 metrics, the range is from -Infinity to 1, but we need to transform it to 0 to 100 to display it in the UI.
// 1 means that the model is perfect, 0 means that the model is as good as a model that predicts the mean of the target variable, and negative values mean that the model is worse than a model that predicts the mean of the target variable, so we changed the minimum value to -10 to have a better visualization, and the  0 is not too close to 1 in the UI.
const transformR2Range = (value: number): number => {
  value = Math.max(value, -10)

  const oldMin = -10
  const oldMax = 1
  const newMin = 0
  const newMax = 100

  return ((value - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin
}

// Note: for MAE metrics, the range is from 0 to Infinity, but we need to transform it to 0 to 100 to display it in the UI.
const transformMAERange = (value: number): number => {
  return Math.min(value, 100)
}

export const transformMetricValue = (metricName: string, value: number): number => {
  if (metricName.includes(METRICS_TYPES.R2)) {
    return transformR2Range(value)
  } else if (metricName.includes(METRICS_TYPES.MAE)) {
    return transformMAERange(value)
  } else if (metricName.includes(METRICS_TYPES.APE)) {
    return value * 100
  }
  return value
}

export const formatMetricValue = (metricName: string, value: number): string => {
  if (metricName.includes(METRICS_TYPES.APE)) {
    return `${value * 100}%`
  }
  return value.toString()
}

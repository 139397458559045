import { MoveItemModalProps } from './interfaces'
import { CancelButton, ConfirmButton, CreateFolder } from './Buttons'
import { getMoveElementPayload } from './helpers'
import { useParentInformation } from './hooks'
import { useMoveToasts } from './toasts'
import { AvailableSpacesSelector } from './AvailableSpacesSelector'
import { PathSelector } from '../../../../containers/spaces/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { ResourceType } from '../../../../containers/detailsPanel/components/DetailsList'
import { pathToRoute } from '../../../../helpers/router'
import { setCurrentFolderPage, setCurrentProjectPage } from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { useMoveItemMutation } from '../../../../store/features/apis/slices/genericItem/genericItemSlice'
import { isNil, last } from 'lodash'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { useCreateFolderOperation } from '@/operations/folder/createFolderOperation'
import { useIsSearchMode } from '@/hooks/useIsSearchMode'

export const MoveItemModalTemplate = ({
  onClose,
  showCloseButton,
  title,
  items
}: MoveItemModalProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const isSearchMode = useIsSearchMode()
  const fullPath = useAppSelector((state) => state.ui.fullPath)

  const [moveElement, { isLoading, isError, error, isSuccess }] = useMoveItemMutation()
  const { t } = useTranslation(['modals', 'tooltips'])

  const { parentSpaceId, parentFolderId } = useParentInformation(items)

  const [createFolder, isCreateFolderAllowed, isCreateFolderLoading] = useCreateFolderOperation()

  const [targetPath, setTargetPath] = React.useState<PathSelector>([parentSpaceId, parentFolderId].filter(Boolean))

  // We can use the current full path to automatically expanded the path until the selected folder only if the user is not in search mode
  const initialPath = React.useRef(!isSearchMode ? fullPath : undefined)

  const [showSuccessfulToast, showErrorToast] = useMoveToasts(items, targetPath)

  React.useEffect(() => {
    if (isError) {
      showErrorToast()
      onClose()
    }
  }, [isError, error])

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(setCurrentFolderPage(undefined))
      dispatch(setCurrentProjectPage(undefined))

      if (items.some(({ type }) => type === ResourceType.FOLDER)) {
        onClose(pathToRoute(targetPath))
      } else {
        const firstProject = items.find(({ type }) => type === ResourceType.PROJECT)
        onClose(`projects/${firstProject.id}`)
      }

      showSuccessfulToast()
    }
  }, [isSuccess])

  const onMoveClick = async (): Promise<void> => {
    moveElement({
      spaceId: parentSpaceId,
      folderId: parentFolderId,
      payload: getMoveElementPayload(items, targetPath)
    })
  }

  const isMovingToCurrentLocation =
    last(targetPath) === parentFolderId || (last(targetPath) === parentSpaceId && isNil(parentFolderId))
  const isMoveDisabled = isLoading || isNil(targetPath) || isMovingToCurrentLocation

  return (
    <>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>

      <ModalContent onSubmit={onMoveClick} submitDisabled={isMoveDisabled}>
        <AvailableSpacesSelector
          parentId={parentFolderId ?? parentSpaceId}
          setTargetPath={(newPath: PathSelector): void => {
            initialPath.current = undefined
            setTargetPath(newPath)
          }}
          targetPath={targetPath}
          items={items}
          expandedPath={initialPath.current ?? targetPath} // Expand until the selected path, if it's not, open the current forlder path}
        />
      </ModalContent>

      <ModalActions>
        <CreateFolder
          disabled={!isCreateFolderAllowed || isLoading || isCreateFolderLoading}
          onClick={(): void => {
            createFolder({
              pathSelector: targetPath,
              onFolderCreated: (newFolderId: string): void => {
                setTargetPath((prevTargetPath) => [...prevTargetPath, newFolderId])
              }
            })
          }}
        />
        <CancelButton onClick={onClose} disabled={isLoading} label={t('cancel')} />
        <ConfirmButton onClick={onMoveClick} disabled={isMoveDisabled} label={t('move.move')} />
      </ModalActions>
    </>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OperationWithPermission } from '@/operations/interfaces'
import { ModelStage, ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { useAppDispatch } from '@/store/hooks'
import { openModal } from '@/store/features/uiSlice/uiSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'

export const useUnreleaseModelVersionOperation = (version: ModelVersion): OperationWithPermission<void> => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()

  const canUnreleaseModelVersion = version?.stage === ModelStage.PRODUCTION

  const onClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.UNRELEASE_MODEL_VERSION,
        showCloseButton: true,
        modalProps: {
          title: t('unreleaseModelVersion.title'),
          version
        }
      })
    )
  }, [version, t])

  return [onClick, canUnreleaseModelVersion]
}

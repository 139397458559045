import { useAvailableSpacesToMoveElement } from './hooks'
import { FolderSelectorNavigationTree } from './NavigationTreePathSelector'
import { MoveItem } from './interfaces'
import { Box } from '@mui/system'
import { PathSelector } from '@/containers/spaces/interfaces'

interface AvailableSpacesSelectorProps {
  items: MoveItem[]
  targetPath: PathSelector
  setTargetPath: (newPath: PathSelector) => void
  parentId: string
  expandedPath: PathSelector
}

export const AvailableSpacesSelector = ({
  items,
  targetPath,
  setTargetPath,
  parentId,
  expandedPath
}: AvailableSpacesSelectorProps): React.ReactElement => {
  const [spaces, areAvailableSpacesLoading] = useAvailableSpacesToMoveElement(items)
  if (areAvailableSpacesLoading) {
    return null
  }

  return (
    <Box height={300} className="u-overflowOverlay">
      {spaces.map((space) => (
        <FolderSelectorNavigationTree
          key={space.spaceId}
          isSingleSpace={spaces.length === 1}
          currentFolderId={parentId} // disable current parent path
          from={items.map(({ id }) => id)} // disable entire subtree of the current parent path
          path={[space.spaceId]}
          label={space.name}
          selectedPath={targetPath}
          setSelectedPath={setTargetPath}
          expandedPath={expandedPath}
        />
      ))}
    </Box>
  )
}

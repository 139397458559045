import { URLConstants, navigateToUrl } from '../utils/windowUtils'
import { post } from '../helpers/network'
import { getCookie, getUsername } from '../helpers/cookies'
import * as React from 'react'
import { isNil } from 'lodash'

interface ValidateProps {
  valid: boolean
}

export const useValidateUser = (): { isLoggedIn: boolean } => {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false)

  const currentSessionId = React.useRef(getCookie()['sid'])

  React.useEffect(() => {
    const validateUser = async (): Promise<void> => {
      const username = getUsername()

      if (getCookie()['sid'] !== currentSessionId.current) {
        navigateToUrl(window.location.href)
        return
      }

      if (isNil(username)) {
        navigateToUrl(URLConstants.LOGOUT_URL)
        return
      }

      const response = await post<ValidateProps>(URLConstants.VALIDATE_URL, {})
      if (!response.data.valid) {
        navigateToUrl(URLConstants.LOGOUT_URL)
        return
      }

      setIsLoggedIn(true)
    }

    validateUser()

    window.addEventListener('focus', validateUser)
    return () => window.removeEventListener('focus', validateUser)
  }, [])

  return { isLoggedIn }
}

import { defaultThemePaletteOptions } from './palette'
import { defaultThemeSpacingOptions } from './defaultThemeSpacingOptions'
import { Components } from '@mui/material/styles/components'
import { createTheme } from '@mui/system'

const latoRegular = require('../../assets/webfonts/lato-regular.woff2')
const latoLight = require('../../assets/webfonts/lato-light.woff2')
const latoItalic = require('../../assets/webfonts/lato-italic.woff2')
const latoBoldItalic = require('../../assets/webfonts/lato-bolditalic.woff2')
const latoBold = require('../../assets/webfonts/lato-bold.woff2')

// We need to get the spacing calculated by the theme, before create our one
const { spacing } = createTheme({ spacing: defaultThemeSpacingOptions })

export const defaultThemeComponentOptions: Components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${latoRegular}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(${latoLight}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${latoItalic}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(${latoBoldItalic}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${latoBold}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      .u-overflowOverlay {
        overflow-x: auto !important;
        overflow-y: overlay;
        scrollbar-gutter: stable;
      }
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        opacity: 0;
        visibility: hidden;
        border-radius: 0;
      }
      :hover::-webkit-scrollbar {
        opacity: 1;
        visibility: visible;
      }
      ::-webkit-scrollbar-thumb {
        background: ${defaultThemePaletteOptions.grey['250']};
        opacity: 0;
        visibility: hidden;
        border-radius: 0;

        &:hover {
          background: ${defaultThemePaletteOptions.grey['400']};
        }
      }
      :hover::-webkit-scrollbar-thumb {
        opacity: 1;
        visibility: visible;
      }
      ::-webkit-scrollbar-track {
        background: ${defaultThemePaletteOptions.grey['100']};
        opacity: 0;
        visibility: hidden;
        border-radius: 0;
      }
      :hover::-webkit-scrollbar-track {
        opacity: 1;
        visibility: visible;
      }
    `
  },
  MuiTab: {
    styleOverrides: {
      root: {
        padding: '0px',
        margin: '0px',
        minWidth: '0px',
        minHeight: '24px',
        fontWeight: '400',
        fontSize: '.8125rem',
        lineHeight: '1.23em',
        letterSpacing: '0.01em',
        justifyContent: 'flex-start',
        '&:hover': { color: defaultThemePaletteOptions.text.selected }
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        minHeight: '24px'
      },
      flexContainer: {
        gap: spacing(3)
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: 13,
        fontWeight: '400',
        '&.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 2px ${defaultThemePaletteOptions.primary['900']}`
        }
      },
      containedInfo: {
        color: defaultThemePaletteOptions.text.primary,
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: defaultThemePaletteOptions.grey['150']
        }
      }
    }
  },
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true
    },
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': {
          boxShadow: `0px 0px 0px 2px ${defaultThemePaletteOptions.primary['900']}`
        }
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        '&:active': { boxShadow: 'none' }
      }
    }
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: 0
      }
    }
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${defaultThemePaletteOptions.grey['200']}` // color when the input is not focused
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${defaultThemePaletteOptions.grey['200']}` // color when the input is hovered
        },
        '&.Mui-error:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${defaultThemePaletteOptions.error['200']}` // color when the input is hovered and in error state
        },
        '&:after': {
          borderBottom: `1px solid ${defaultThemePaletteOptions.primary['200']}` // color when the input is focused
        },
        '&.Mui-error:after': {
          borderBottom: `1px solid ${defaultThemePaletteOptions.primary['200']}` // color when the input is focused and in error state
        }
      },
      root: {
        height: '32px',
        transition: '.3s border, .3s color, .3s box-shadow',
        padding: '0',
        '&:placeholder': {
          color: defaultThemePaletteOptions.grey['600']
        },
        '&:hover': {
          boxShadow: '0 4px 4px -3px rgba(0,0,0,.07)'
        },
        '&.Mui-focused': {
          boxShadow: '0 4px 4px -3px rgba(0, 118, 168, 0.25)',
          background: 'transparent'
        },
        '& input': {
          padding: '0'
        },
        '& .MuiSelect-select:focus': {
          background: 'transparent'
        },
        '&.MuiInputBase-multiline': {
          height: 'fit-content',
          minHeight: spacing(16)
        },
        '&.MuiAutocomplete-inputRoot': {
          height: 'fit-content',
          minHeight: spacing(8)
        }
      }
    }
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        '& legend': {
          marginBottom: 0,
          border: 'none',
          width: 'auto'
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        minHeight: spacing(8),
        height: '32px'
      }
    }
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        alignItems: 'center',
        padding: spacing(4),
        borderRadius: spacing(1),
        fontSize: '.8125rem',
        color: defaultThemePaletteOptions.text.primary,
        '&.MuiAlert-snackbarInfo': {
          borderLeft: `4px solid ${defaultThemePaletteOptions.info['main']}!important`,
          background: `${defaultThemePaletteOptions.info['100']}!important`,
          color: 'white!important'
        },
        '&.MuiAlert-standardInfo': {
          borderLeft: `4px solid ${defaultThemePaletteOptions.grey['400']}`,
          background: defaultThemePaletteOptions.grey['100']
        },
        '&.MuiAlert-standardSuccess': {
          borderLeft: `4px solid ${defaultThemePaletteOptions.success['main']}`,
          background: defaultThemePaletteOptions.success['100']
        },
        '&.MuiAlert-standardWarning': {
          borderLeft: `4px solid ${defaultThemePaletteOptions.warning['main']}`,
          background: defaultThemePaletteOptions.warning['100']
        },
        '&.MuiAlert-standardError': {
          borderLeft: `4px solid ${defaultThemePaletteOptions.error['main']}`,
          background: defaultThemePaletteOptions.error['100']
        },
        '& .MuiAlert-message': {
          padding: 0,
          overflow: 'hidden'
        },
        '& .MuiAlert-action': {
          flexShrink: 0,
          marginTop: '-8px',
          marginBottom: '-8px',
          paddingBlock: '0'
        }
      }
    }
  },
  MuiPopover: {
    defaultProps: {
      elevation: 0
    },
    styleOverrides: {
      root: {
        '& .MuiPaper-root': {
          boxShadow: '2px 2px 20px rgba(0, 0, 0, 0.05)',
          border: `1px solid ${defaultThemePaletteOptions.grey['200']}`
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        height: spacing(7),
        padding: `0 ${spacing(3)}`,
        borderTop: '1px solid transparent',
        borderBottom: '1px solid transparent',
        transition: '.3s border, .3s background, .3s color, .3s box-shadow',
        '&:hover': {
          background: defaultThemePaletteOptions.action.hover,
          borderTop: `1px solid ${defaultThemePaletteOptions.grey['200']}`,
          borderBottom: `1px solid ${defaultThemePaletteOptions.grey['200']}`
        },
        '&.Mui-selected': {
          background: defaultThemePaletteOptions.action.selected,
          borderTop: `1px solid ${defaultThemePaletteOptions.border.primary}`,
          borderBottom: `1px solid ${defaultThemePaletteOptions.border.primary}`
        },
        '&:active': {
          boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1) inset'
        },
        '& .MuiListItemIcon-root': {
          marginRight: spacing(1),
          minWidth: spacing(5),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        border: `1px solid ${defaultThemePaletteOptions.grey['200']}`,
        borderRadius: spacing(1),
        width: spacing(5),
        height: spacing(5),
        transition: '.3s color, .3s background',
        color: 'transparent',
        '&.Mui-checked': {
          color: defaultThemePaletteOptions.primary['main'],
          '&.Mui-disabled': {
            color: defaultThemePaletteOptions.grey['450']
          }
        },
        '& .MuiSvgIcon-root ': {
          width: spacing(5),
          height: spacing(5)
        }
      }
    }
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${defaultThemePaletteOptions.grey['200']}`,
        '&-hover:hover': {
          background: 'none'
        }
      },
      head: {
        borderBottomWidth: 0
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '.8125rem',
        padding: spacing(2, 0),
        borderBottom: 'none',
        background: 'none',
        '&-head': {
          fontWeight: '700'
        }
      }
    }
  },
  MuiTablePagination: {
    styleOverrides: {
      selectLabel: {
        margin: '0px'
      },
      displayedRows: {
        margin: '0px'
      }
    }
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: '8px',
        borderRadius: '4px'
      },
      bar: {
        borderRadius: '4px'
      },
      colorPrimary: {
        backgroundColor: defaultThemePaletteOptions.grey['150']
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '0.75rem'
      }
    }
  }
}

import { useProjectById } from '../../components/modal/templates/moveProject/hooks'
import { OperationWithPermission } from '../interfaces'
import { FullProject } from '../../store/features/apis/slices/project/interfaces'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { filterSearchParams } from '../../helpers/navigation'
import { useSearchParams } from 'react-router-dom'

export const canDeleteProject = (project: FullProject): boolean => {
  return project?.requesterPermissions?.canDeleteProject
}

export const useDeleteProjectOperation = (projectId: string): OperationWithPermission<void> => {
  const { navigateNoReset } = useDashboardNavigate()
  const { project } = useProjectById(projectId)
  const [searchParams] = useSearchParams()

  return [
    (): void => {
      const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
      navigateNoReset(`/projects/${projectId}/delete${filteredParams}`)
    },
    canDeleteProject(project)
  ]
}

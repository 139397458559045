import { useAppDispatch } from '../../store/hooks'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { openModal } from '../../store/features/uiSlice/uiSlice'
import { useGetFolderDetails, useGetSpaceDetails } from '../../store/features/apis/slices/folder/helpers'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { OperationWithPermission } from '../interfaces'
import { t } from 'i18next'
import { first, last } from 'lodash'
import { PathSelector } from '@/containers/spaces/interfaces'

interface CreateFolderPayload {
  pathSelector: PathSelector
  onFolderCreated: (newFolderId: string) => void
}

export const useCreateFolderOperation = (): OperationWithPermission<CreateFolderPayload | void> => {
  const dispatch = useAppDispatch()

  const { currentPath } = useCurrentPath()

  const spaceId = first(currentPath)
  const folderLocationId = last(currentPath)
  const isSpace = spaceId === folderLocationId

  const { data: folderLocation, isLoading: isFolderLocationLoading } = useGetFolderDetails({
    spaceId,
    folderId: folderLocationId
  })

  const { data: space, isLoading: isSpaceLoading } = useGetSpaceDetails({ spaceId: first(currentPath) })

  const onClick = (payload: CreateFolderPayload): void => {
    dispatch(
      openModal({
        template: ModalTemplate.CREATE_FOLDER,
        showCloseButton: true,
        modalProps: {
          targetPath: payload?.pathSelector,
          onFolderCreated: payload?.onFolderCreated,
          title: t('folder.createTitle', { ns: 'modals' })
        }
      })
    )
  }

  const isAllowed = isSpace
    ? space?.requesterPermissions?.canCreateContentInRoot
    : folderLocation?.requesterPermissions?.canCreateContentInFolder

  return [onClick, isAllowed, isFolderLocationLoading || isSpaceLoading]
}

import { ContextMenuWrapper } from '../../../../components/contextMenu/ContextMenuWrapper'
import { ModalTemplate } from '../../../../store/features/uiSlice/interfaces'
import { openModal } from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { UserOrganizationStatus } from '../../../../store/features/apis/slices/organization/interfaces'
import { UserV2 } from '../../../../store/features/apis/slices/user/interfaces'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { isEmpty } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface UserContextMenuProps {
  user: UserV2
  userOrganizationStatus: UserOrganizationStatus
  isCommunityPlan: boolean
}

export const UserContextMenu = ({
  user,
  userOrganizationStatus,
  isCommunityPlan,
  ...props
}: UserContextMenuProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals', 'contextMenu'])

  const handleRemoveClick = React.useCallback((): void => {
    dispatch(
      openModal({
        template: ModalTemplate.REMOVE_ORGANIZATION_USER,
        showCloseButton: true,
        modalProps: {
          title: t('removeOrganizationUser.title', { ns: 'modals' }),
          selectedUser: user
        }
      })
    )
  }, [user])

  const handleAssignCoreHours = React.useCallback((): void => {
    dispatch(
      openModal({
        template: ModalTemplate.ASSIGN_CORE_HOURS,
        showCloseButton: true,
        modalProps: {
          title: t('assignCoreHours.title', { ns: 'modals' }),
          selectedUser: user
        }
      })
    )
  }, [user])

  const menuItems = React.useMemo(() => {
    const isSelectedUserAdmin = userOrganizationStatus === UserOrganizationStatus.ADMIN
    return [
      !isSelectedUserAdmin ? { label: t('remove', { ns: 'contextMenu' }), onClick: handleRemoveClick } : undefined,
      !isCommunityPlan ? { label: t('manageCpuGpu', { ns: 'contextMenu' }), onClick: handleAssignCoreHours } : undefined
    ].filter(Boolean)
  }, [userOrganizationStatus, handleRemoveClick, handleAssignCoreHours, isCommunityPlan])

  if (isEmpty(menuItems)) {
    return null
  }

  return (
    <ContextMenuWrapper {...props} menuItems={menuItems}>
      <SpriteIcon spriteId="ellipsis-vertical" color="text.primary" width={16} height={16} />
    </ContextMenuWrapper>
  )
}

import { PaletteOptions } from '@mui/material/styles/createPalette'

declare module '@mui/material/styles/createPalette' {
  export interface TypeText {
    primary: string
    secondary: string
    disabled: string
    selected: string
    warning: string
  }

  interface HoverOptions {
    primary: '#024462'
  }

  export interface PaletteOptions {
    border?: {
      primary?: string
      selected?: string
      default?: string
      disabled?: string
    }
    text?: Partial<TypeText>
    hover?: HoverOptions
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    background: true
  }
}

export const defaultThemePaletteOptions: PaletteOptions = {
  primary: {
    main: '#0076A8',
    negative: 'white',
    '100': '#E0EFF5',
    '200': '#C4E0EB',
    '300': '#A8D0E1',
    '700': '#3894BB',
    '800': '#004765',
    '900': '#54A3C5'
  } as any,
  secondary: {
    main: '#323232'
  },
  action: {
    hover: '#F6F6F6',
    selected: '#E0EFF5'
  },
  text: {
    primary: '#333333',
    selected: '#0076A8',
    warning: '#CC9C00',
    disabled: '#A5A5A5'
  },
  border: {
    primary: '#C4E0EB',
    selected: '#8CC1D8',
    default: '#E5E5E5',
    disabled: '#F6F6F6'
  },
  grey: {
    '100': '#F6F6F6',
    '150': '#EEEEEE',
    '200': '#E5E5E5',
    '250': '#D9D9D9',
    '300': '#CCCCCC',
    '350': '#F3F3F3',
    '400': '#B2B2B2',
    '450': '#A5A5A5',
    '500': '#999999',
    '550': '#333333',
    '600': '#7F7F7F',
    '700': '#666666',
    '800': '#4C4C4C'
  } as any, // TODO: find a way to add custom color tones
  divider: '#E5E5E5',
  success: {
    main: '#05A019',
    '100': '#e6f5e8',
    '200': '#cdecd1',
    '300': '#b4e2ba',
    '800': '#02400A'
  },
  warning: {
    main: '#DDAA00',
    '100': '#FCF6E5',
    '200': '#f8eecc',
    '300': '#f5e5b2',
    '800': '#584400'
  },
  error: {
    main: '#e42e38',
    '100': '#fceaeb',
    '200': '#fad5d7',
    '300': '#f7c0c3',
    '800': '#981E24'
  },
  info: {
    main: '#999999',
    '100': '#333333'
  },
  hover: {
    primary: '#024462'
  },
  background: { paper: 'white' }
}

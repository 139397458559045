import { MultiSelectionDetails } from './MultiSelectionDetails'
import { SimulationCards } from './components/SimulationCard'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { PagePath } from '../../constants'
import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

export const DetailsPanelStyled = styled(Box)(({ theme }) => ({
  width: '288px',
  minHeight: '100%',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(20),
  paddingInline: theme.spacing(5),
  borderLeft: `1px solid ${theme.palette.grey['200']}`,
  overflow: 'hidden'
}))

export enum DetailsPanelTypes {
  LOCATION_DETAILS,
  PROJECT_DETAILS,
  FOLDER_DETAILS,
  MULTI_SELECTION_DETAILS,
  SEARCH_DETAILS
}

const GenericDetailsInfoMessageStyled = styled(Box)({
  width: '100%',
  marginTop: '20px',
  textAlign: 'center'
})

const GenericDetailsPanel = (): React.ReactElement => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const title = React.useMemo(() => {
    const path: string = pathname.replace(/^\/|\/$/g, '')
    if (path === PagePath.SHARED_WITH_ME) return t('sharedWithMe')
    if (path === PagePath.TEAM_SPACES) return t('spaces')
    return ''
  }, [])

  return (
    <Box qa-attribute="location-details-panel">
      <Typography sx={{ mt: 2 }} variant="title" color="text.primary" paragraph>
        {title}
      </Typography>
      <SimulationCards panelType={DetailsPanelTypes.LOCATION_DETAILS} />
      <GenericDetailsInfoMessageStyled>
        <Typography variant="subtitle" color="grey.700">
          {t('location.message')}
        </Typography>
      </GenericDetailsInfoMessageStyled>
    </Box>
  )
}

const GenericDetailsPanelDelayed = (): React.ReactElement => {
  const { selectedItems } = useMultiSelect()
  const showMultiSelectionDetails =
    selectedItems.projects?.length + selectedItems.folders?.length + selectedItems.spaces?.length > 0

  return (
    <DetailsPanelStyled>
      {showMultiSelectionDetails ? <MultiSelectionDetails /> : <GenericDetailsPanel />}
    </DetailsPanelStyled>
  )
}

export default GenericDetailsPanelDelayed

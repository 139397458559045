import { getQaSelectorText } from '../../helpers/qaSelectors'
import { PagePath } from '../../constants'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'

const NavbarItemStyled = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  userSelect: 'none',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: `${isSelected ? theme.palette.action.selected : theme.palette.grey['100']}`,
    border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.grey['200']}`
  },
  border: `1px solid ${isSelected ? theme.palette.primary.main : 'transparent'}`,
  backgroundColor: isSelected ? theme.palette.action.selected : 'transparent',
  transition: '.3s background, .3s border, .3s color'
}))

interface NavbarItemProps {
  label: string
  route: string | PagePath
  isSelected?: boolean
}

export const NavbarItem = ({ label, route, isSelected }: NavbarItemProps): React.ReactElement => {
  const { navigate } = useDashboardNavigate()

  const onClick = React.useCallback(() => {
    navigate(`/${route}`)
  }, [route, navigate])

  return (
    <NavbarItemStyled
      isSelected={isSelected}
      onClick={onClick}
      direction="row"
      alignItems="center"
      spacing={1}
      borderRadius={1}
      paddingX={3}
      paddingY={2}
      qa-attribute={`${getQaSelectorText(label)}--navbar-item`}
    >
      <Typography
        sx={{ whiteSpace: 'nowrap' }}
        variant="textBoldWithEllipsis"
        color={isSelected ? 'primary' : 'grey.700'}
      >
        {label}
      </Typography>
    </NavbarItemStyled>
  )
}

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getSortingModeV2, setSortingDirection, setSortingModeV2 } from '../../store/features/uiSlice/uiSlice'
import { SortingDirection, SortingModeV2 } from '../../store/features/uiSlice/interfaces'
import { ActionButton } from '../../components/button/ActionButton'
import * as React from 'react'
import { Box, MenuItem, MenuItemProps, Select, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

interface MenuItemStyledProps extends MenuItemProps {
  descendingOrder?: boolean
}

const MenuItemStyled = (menuItemProps: MenuItemStyledProps): React.ReactElement => {
  const { descendingOrder, ...props } = menuItemProps

  return (
    <MenuItem {...props}>
      <Stack direction="row" gap={1} alignItems="center">
        {props.selected ? (
          <SpriteIcon
            width={20}
            data-icon={props.selected ? 'arrow-down' : 'arrow-up'}
            spriteId="arrow-down"
            rotatingAngle={descendingOrder ? 0 : 180}
            color={props.selected ? 'primary.main' : 'text'}
          />
        ) : (
          <Box width={20} />
        )}
        <Typography color={'text'}>{props.children}</Typography>
      </Stack>
    </MenuItem>
  )
}

export const Sort = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals', 'toolbar', 'tooltips'])
  const currentSortingMode = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const isDescendingOrder = sortingDirection === SortingDirection.DESC

  const getSortingLabelFromSortingMode = (sortingModeV2: SortingModeV2): string => {
    return t(`sortMenu.${sortingModeV2}`, { ns: 'toolbar' })
  }

  const handleSortDirectionClick = (sortingDirection: SortingDirection): void => {
    dispatch(
      setSortingDirection(sortingDirection === SortingDirection.ASC ? SortingDirection.DESC : SortingDirection.ASC)
    )
  }

  const handleSortModeClick = (sortingModeV2: string): void => {
    dispatch(setSortingModeV2(sortingModeV2 as SortingModeV2))
  }

  return (
    <Stack direction="row" gap={2}>
      <ActionButton
        onClick={(): void => handleSortDirectionClick(sortingDirection)}
        title={t('changeSortDirection', { ns: 'tooltips' })}
        tabIndex={TAB_INDEXES.SORT_DIRECTION}
      >
        <SpriteIcon
          spriteId="arrow-down"
          width={11}
          rotatingAngle={isDescendingOrder ? 0 : 180}
          rotationTime={0}
          data-icon={isDescendingOrder ? 'arrow-down' : 'arrow-up'}
        />
      </ActionButton>
      <Select
        labelId="sort-select"
        id="sort-select"
        variant="standard"
        value={currentSortingMode}
        onChange={(event): void => handleSortModeClick(event.target.value)}
        qa-attribute={`sort--select`}
        inputProps={{ tabIndex: TAB_INDEXES.SORT_MODE }}
      >
        {Object.entries(SortingModeV2).map(([key, value]) => (
          <MenuItemStyled key={key} value={value} descendingOrder={isDescendingOrder}>
            {getSortingLabelFromSortingMode(value)}
          </MenuItemStyled>
        ))}
      </Select>
    </Stack>
  )
}

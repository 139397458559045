import { Box, styled } from '@mui/material'
import * as React from 'react'

const ProgressBarBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey['150'],
  height: 4,
  borderRadius: 4,
  width: '100%'
}))

const ProgressBar = styled(Box)<{ percentage: number }>(({ theme, percentage }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 4,
  borderRadius: 4,
  width: `${percentage >= 0 && percentage <= 100 ? percentage : 0}%`,
  transition: 'width .6s ease-in-out'
}))

export const UserQuotaProgressBar = ({ percentage }: { percentage: number }): React.ReactElement => {
  return (
    <ProgressBarBox>
      <ProgressBar percentage={percentage} />
    </ProgressBarBox>
  )
}

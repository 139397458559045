import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import MuiAvatar from '@mui/material/Avatar'
import { styled } from '@mui/material/styles'

export const MenuUserSection = styled(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '14px',
  height: 'fit-content',
  padding: 0
}))

export const AvatarStyled = styled(MuiAvatar)({
  height: '32px',
  width: '32px'
})

export const UserNavigationMenuItemStyled = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'fakeLink'
})<{ fakeLink?: boolean }>(({ theme, fakeLink }) => ({
  display: 'flex',
  padding: fakeLink ? `${theme.spacing(1)} ${theme.spacing(3)}` : '0',
  gap: 4,
  minHeight: '18px',
  transition: 'background .3s,border .3s',

  a: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    width: '100%',
    padding: `${theme.spacing()} ${theme.spacing(3)}`,
    cursor: 'pointer',
    gap: 4
  }
}))

export const NavigationMenuItemStyled = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  height: 'auto',
  gap: 4,
  minHeight: 'initial',
  margin: 0,
  padding: 0,
  border: 'none',
  transition: 'background .3s, border .3s',

  a: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    width: '100%',
    height: 'auto',
    lineHeight: '1em',
    padding: theme.spacing(2.5),
    cursor: 'pointer'
  },
  '&:hover': {
    height: 'auto',
    minHeight: 'initial',
    margin: 0,
    padding: 0,
    border: 'none',
    a: {
      height: 'auto',
      lineHeight: '1em',
      padding: theme.spacing(2.5),
      color: theme.palette.text.selected
    }
  }
}))
export const NavigationContainer = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center'
}))

export const NavigationItem = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  justifyContent: 'center',
  position: 'relative',

  borderBottom: `3px solid transparent`,
  borderTop: `3px solid transparent`,
  color: theme.palette.text.primary,
  marginLeft: '22px',
  fontSize: '13px',
  fontFamily: 'var(--lato-font)',

  cursor: 'pointer',

  '&:hover': {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  '&.Mui-selected': {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  a: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit'
  }
}))

export const UserMenuStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHovered'
})<{ isHovered: boolean }>(({ theme, isHovered }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  marginLeft: theme.spacing(2.5),
  cursor: 'pointer',
  alignItems: 'center',
  height: '100%',
  borderBottom: `3px solid ${isHovered ? theme.palette.primary.main : 'transparent'}`,
  borderTop: '3px solid transparent',
  color: isHovered ? theme.palette.primary.main : theme.palette.text.primary,
  ':hover': {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  }
}))

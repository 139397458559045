import { usePersonalSpaceId } from '../../../store/features/apis/slices/space/hooks'
import { getCurrentProjectPage, getSortingModeV2 } from '../../../store/features/uiSlice/uiSlice'
import {
  PagingMetadata,
  Project,
  ProjectScope,
  ProjectScopes
} from '../../../store/features/apis/slices/project/interfaces'
import { useAppSelector } from '../../../store/hooks'
import { getUsername } from '../../../helpers/cookies'
import { PathSelector } from '../../spaces/interfaces'
import { PagePath } from '../../../constants'
import { useGetProjects } from '../../../store/features/apis/slices/project/helpers'
import { useGetSearchProjectsQuery } from '../../../store/features/apis/slices/project/projectsSlice'
import { useIsSearchMode } from '../../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import { first, isEmpty, isNil, last } from 'lodash'
import * as React from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

export const useGetProjectScope = (): ProjectScope => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const searchSection = searchParams.get('section')
  const { spaceId } = useParams<{ spaceId?: string }>()
  const personalSpaceId = usePersonalSpaceId()

  return React.useMemo((): ProjectScope => {
    if (!isEmpty(pathname)) {
      // remove the leading slash and trailing slash, but not slashes in between
      const path: string = pathname.replace(/^\/|\/$/g, '')
      if (path === PagePath.SHARED_WITH_ME || searchSection === PagePath.SHARED_WITH_ME) {
        return ProjectScopes.PROJECTS_SHARED_WITH_ME
      }
      if (path === PagePath.MY_PROJECTS || spaceId === personalSpaceId || searchSection === PagePath.MY_PROJECTS) {
        return ProjectScopes.MY_PROJECTS
      }
    }
    return ProjectScopes.ALL_PROJECTS
  }, [pathname, spaceId, searchSection, personalSpaceId])
}

const useUpdateCopiedProjectThumbnailWithOriginalAfterCopy = (
  data: { items: Project[]; pagingMetadata: PagingMetadata },
  isLoading: boolean
): { items: Project[]; pagingMetadata: PagingMetadata } => {
  const latestCopiedProject = useAppSelector((state) => state.ui.latestCopiedProject)

  return React.useMemo(() => {
    if (!isLoading && !isNil(data) && !isNil(latestCopiedProject)) {
      const items = [...data.items]
      const updatedItems = items.map((item) => {
        if (item.projectIdExt === latestCopiedProject.projectIdExt && isNil(item.thumbnailUrl)) {
          return { ...item, thumbnailUrl: latestCopiedProject.thumbnailUrl }
        }
        return item
      })
      return { ...data, items: updatedItems }
    }
    return data
  }, [data, latestCopiedProject, isLoading])
}

export const useFolderedProjects = (
  manualPath?: PathSelector
): {
  projects: Project[]
  isLoading: boolean
  metadata: any
} => {
  const { currentPath, isLoading: isLoadingCurrentPath } = useCurrentPath()
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const targetPage = useAppSelector(getCurrentProjectPage)
  const isSearchMode = useIsSearchMode()

  const targetPath = manualPath ?? currentPath // manualPath needs to refetch a specific path

  const spaceId = first(targetPath)
  const folderId = last(targetPath)
  const { data, isLoading } = useGetProjects(
    { spaceId, folderId, sortingModeV2, sortingDirection, targetPage },
    { skip: isLoadingCurrentPath || isNil(currentPath) || isSearchMode }
  )

  const updatedData = useUpdateCopiedProjectThumbnailWithOriginalAfterCopy(data, isLoading)

  return {
    projects: updatedData?.items ?? [],
    metadata: data?.pagingMetadata,
    isLoading: isNil(currentPath) ? true : isLoading
  }
}

export const MAX_SEARCH_RESULTS = 20

export const useSearchProjects = (): {
  projects: Project[]
  hideNextPage: boolean
  isLoading: boolean
} => {
  const targetPage = useAppSelector(getCurrentProjectPage)
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const [searchParams] = useSearchParams()
  const searchSection = searchParams.get('section')
  const username = getUsername()
  const isSearchMode = useIsSearchMode()
  const scope = useGetProjectScope()
  const personalSpaceId = usePersonalSpaceId()

  const { data, isLoading, isFetching } = useGetSearchProjectsQuery(
    {
      sortingModeV2,
      sortingDirection,
      targetPage,
      username,
      scope,
      query: searchParams.get('q'),
      filterby: 'project_text',
      spaceId: searchSection === PagePath.MY_PROJECTS ? personalSpaceId : searchSection,
      projectsPerPage: MAX_SEARCH_RESULTS + 1,
      offset: MAX_SEARCH_RESULTS
    },
    { skip: !isSearchMode }
  )

  const isNextProjectPageAvailable = isEmpty(data) ? false : Boolean(data.projects.length > MAX_SEARCH_RESULTS)
  const projects = isNextProjectPageAvailable ? data?.projects.slice(0, -1) : data?.projects // remove the element used to check if there is a next page

  return { projects: projects ?? [], hideNextPage: !isNextProjectPageAvailable, isLoading: isFetching || isLoading }
}

export const useSharedWithMeProjects = (
  skip?: boolean
): {
  projects: Project[]
  hideNextPage: boolean
  isLoading: boolean
  numberOfProjects: number
} => {
  const { currentPath } = useCurrentPath()
  const targetPage = useAppSelector(getCurrentProjectPage)
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const username = getUsername()

  const { data, isLoading, isFetching } = useGetSearchProjectsQuery(
    {
      sortingModeV2,
      sortingDirection,
      targetPage,
      username,
      scope: ProjectScopes.PROJECTS_SHARED_WITH_ME,
      spaceId: first(currentPath)
    },
    { skip: skip || isNil(currentPath) }
  )

  const isNextProjectPageAvailable = isEmpty(data) ? false : Boolean(data.projects.length > MAX_SEARCH_RESULTS)
  const projects = isNextProjectPageAvailable ? data?.projects.slice(0, -1) : data?.projects // remove the element used to check if there is a next page

  return {
    projects: projects ?? [],
    hideNextPage: !isNextProjectPageAvailable,
    isLoading: isFetching || isLoading,
    numberOfProjects: data?.numberOfProjects
  }
}

import { convertRuntimeValue } from './helpers'
import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { ModalInputText } from '@/components/modal/inputComponents/ModalInputText'
import { ModalInputSelect } from '@/components/modal/inputComponents/ModalInputSelect'
import { FormRow } from '@/components/modal/inputComponents/FormRow'
import { AiModelParameter, MaxRuntimeRange } from '@/store/features/uiSlice/interfaces'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { setAiModelTrainingSettingsFormData } from '@/store/features/uiSlice/uiSlice'
import { SchemaType, TrainModelUserOptionsType } from '@/store/features/apis/slices/ai/interfaces'
import { SchemaGeneratedForm } from '@/components/schemaForm/SchemaGeneratedForm'
import { ValidationErrors } from '@/hooks/useErrorHandler'

type SettingsProps = {
  userOptionsSchema: SchemaType
  validationErrors?: ValidationErrors
}

export const Settings = ({ userOptionsSchema, validationErrors }: SettingsProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('aiTraining')
  const aiModelTrainingSettings = useAppSelector((store) => store.ui.aiModelTrainingSettings)

  const aiModelParameterOptions = React.useMemo(
    () => Object.values(AiModelParameter).map((value) => ({ value, label: value })),
    [AiModelParameter]
  )

  const maxRuntimeRangeOptions = React.useMemo(
    () => Object.values(MaxRuntimeRange).map((value) => ({ value, label: value })),
    [MaxRuntimeRange]
  )

  const handleOnChange = (key: string, value: string | boolean | number): void => {
    if (key === 'maxRuntime') {
      value = convertRuntimeValue(value as number, aiModelTrainingSettings?.formData?.maxRuntimeRange, true)
    }
    const updatedAiModelTrainingSettings = { ...aiModelTrainingSettings?.formData, [key]: value, isDirty: true }
    dispatch(setAiModelTrainingSettingsFormData(updatedAiModelTrainingSettings))
  }

  const handleUserOptionsFormChange = (userOptions: Record<string, any>): void => {
    const updatedAiModelTrainingSettings = {
      ...aiModelTrainingSettings?.formData,
      userOptions: userOptions as TrainModelUserOptionsType
    }
    dispatch(setAiModelTrainingSettingsFormData(updatedAiModelTrainingSettings))
  }

  return (
    <Stack sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'divider', borderRadius: 1, padding: 5 }} gap={4}>
      <Typography variant="title">{t('modelSettings.settings')}</Typography>

      <Box>
        {!isNil(userOptionsSchema) && (
          <SchemaGeneratedForm
            schema={userOptionsSchema}
            i18nPrefix="modelSettings.userOptions"
            t={t}
            onChange={handleUserOptionsFormChange}
            showOnly={['train_outlier_detector']} // currently only one option is supported
          />
        )}

        <FormRow label={t('modelSettings.maxRuntime')}>
          <Stack direction="row" gap={2} width={'100%'}>
            <ModalInputText
              onChange={(event): void => handleOnChange('maxRuntime', parseInt(event.target.value, 10))}
              value={convertRuntimeValue(
                aiModelTrainingSettings?.formData?.maxRuntime ?? (24 as number),
                aiModelTrainingSettings?.formData?.maxRuntimeRange ?? MaxRuntimeRange.HOURS,
                false
              ).toString()}
              type="number"
              qaSelectorText="max-runtime-value"
              error={!validationErrors['maxRuntime']?.valid}
              helperText={validationErrors['maxRuntime']?.message}
            />
            <ModalInputSelect
              defaultValue={MaxRuntimeRange.HOURS}
              value={aiModelTrainingSettings?.formData?.maxRuntimeRange ?? MaxRuntimeRange.HOURS}
              onChange={(event): void => handleOnChange('maxRuntimeRange', event.target.value)}
              options={maxRuntimeRangeOptions}
              qaSelectorText="max-runtime-range"
              fullWidth
            />
          </Stack>
        </FormRow>

        <ModalInputSelect
          value={aiModelTrainingSettings?.formData?.aiModelParameter ?? AiModelParameter.DEFAULT}
          label={t('modelSettings.aIModelParameter')}
          onChange={(event): void => handleOnChange('aiModelParameter', event.target.value)}
          options={aiModelParameterOptions}
          disabled // currently disabled because it's not yet supported
        />
      </Box>
    </Stack>
  )
}

import { FolderDataGridComponents } from './FolderDataGrid'
import { FolderPagination } from './FolderPagination'
import { getViewingMode } from '../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../store/hooks'
import { CreateFolderPlaceholder } from '../CreateFolderPlaceholder'
import { ViewMode } from '../../../store/features/uiSlice/interfaces'
import { Folder } from '../../../store/features/apis/slices/folder/interfaces'
import * as React from 'react'
import { isEmpty } from 'lodash'
import Grid from '@mui/material/Grid'

export const FoldersArea = ({ folders }: { folders: Folder[] }): React.ReactElement => {
  const displayType = useAppSelector(getViewingMode)

  return (
    <>
      <Grid
        container
        sx={{
          display: `${displayType === ViewMode.GRID ? 'grid' : 'block'}`,
          gridTemplateColumns: 'repeat(auto-fill,minmax(230px, 1fr))',
          gridGap: '12px',
          mb: displayType === ViewMode.GRID ? 3 : 0
        }}
      >
        {isEmpty(folders) ? <CreateFolderPlaceholder displayType={displayType} /> : <FolderDataGridComponents />}
      </Grid>
      <FolderPagination />
    </>
  )
}

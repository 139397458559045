import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { Button } from '../../button/Button'
import { ModalTemplateProps } from '../interfaces'
import { useAppDispatch } from '../../../store/hooks'
import { addSnackbarToState } from '../../../store/features/uiSlice/uiSlice'
import { Space } from '../../../store/features/apis/slices/space/interfaces'
import { useDeleteSpaceMutation } from '../../../store/features/apis/slices/space/spaceSlice'
import { useErrorHandler } from '../../../hooks/useErrorHandler'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

interface TrashSpaceModalTemplateProps extends ModalTemplateProps {
  title: string
  selectedTeamSpace: Space
}

export const TrashSpaceModalTemplate = ({
  title,
  onClose,
  showCloseButton,
  selectedTeamSpace
}: TrashSpaceModalTemplateProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { handleApiHookErrors } = useErrorHandler()

  const [deleteOrganizationSpaces] = useDeleteSpaceMutation()

  const handleSpaceDeletion = React.useCallback((organizationId: string, spaceId: string) => {
    deleteOrganizationSpaces({ organizationId, spaceId })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: t(`teamSpace.teamSpaceDeletionSuccess`, { spaceName: selectedTeamSpace.name })
          })
        )
        onClose()
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent
        onSubmit={(): void => {
          handleSpaceDeletion(selectedTeamSpace.organizationId, selectedTeamSpace.spaceId)
        }}
      >
        <Typography>{t('trash.space.willBeDeleted', { spaceName: selectedTeamSpace.name })}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        <Button
          onClick={(): void => {
            handleSpaceDeletion(selectedTeamSpace.organizationId, selectedTeamSpace.spaceId)
          }}
          color="error"
          variant="contained"
        >
          {t('trash.trash')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

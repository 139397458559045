import { ProjectCardProps } from './interfaces'
import { ProjectDetails } from './ProjectDetails'
import { getHumanReadableDate } from '../../utils/helpers'
import { RowStyled } from '../rows/RowStyled'
import { RowDate } from '../rows/RowDate'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import * as React from 'react'
import { Grid } from '@mui/material'

export const ProjectRow = ({
  projectCardData,
  onContextMenu,
  onClick,
  className
}: ProjectCardProps): React.ReactElement => {
  return (
    <Grid>
      <RowStyled
        data-testid="project-row"
        selected={projectCardData.selected}
        onClick={(event): void => {
          onClick(event, projectCardData.projectIdExt)
        }}
        onContextMenu={onContextMenu}
        qa-attribute={`${getQaSelectorText(projectCardData.name)}--project-row`}
        className={className}
      >
        <ProjectDetails
          projectName={projectCardData.name}
          thumbnailUrl={projectCardData.thumbnailUrl}
          ownerName={projectCardData.ownerName}
        />
        <RowDate data-testid="folder-date">{getHumanReadableDate(projectCardData.lastModifiedAt)}</RowDate>
      </RowStyled>
    </Grid>
  )
}

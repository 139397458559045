import { SpriteId } from './SpriteMap'
import React from 'react'
import { Box, Palette, useTheme } from '@mui/material'

type SpriteIdProps = React.ComponentProps<typeof Box> &
  React.ComponentProps<'svg'> & { spriteId: SpriteId; rotatingAngle?: number; rotationTime?: number }

export const SpriteIcon = React.forwardRef<SVGSVGElement, SpriteIdProps>(
  (
    { spriteId, rotatingAngle = 0, rotationTime = 0.3, color, style, width = 17, height = 17, ...svgProps },
    ref
  ): React.ReactElement => {
    const { palette } = useTheme()
    const fill = color && color.includes('.') ? getPaletteColor(palette, color) : 'currentColor'
    const rotationDeg = `rotate(${rotatingAngle}deg)`

    return (
      <svg
        {...svgProps}
        fill={fill}
        width={width}
        height={height}
        style={{ ...style, transform: rotationDeg, transition: `transform ${rotationTime}s` }}
        ref={ref}
      >
        <use href={`#sprite-${spriteId}`} />
      </svg>
    )
  }
)

const getPaletteColor = (palette: Palette, color: string): string => {
  return color.split('.').reduce((acc, part) => acc && acc[part], palette)
}

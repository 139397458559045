import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { ManageInvitationsDisplay } from '../../containers/dashboard/manageInvitations/ManageInvitationsDisplay'
import * as React from 'react'

const ManageInvitationsPage = (): React.ReactElement => {
  return (
    <MainDisplay>
      <ManageInvitationsDisplay />
    </MainDisplay>
  )
}

export default ManageInvitationsPage

import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

interface ProjectDescriptionProps {
  description: string
}

export const ProjectDescription = ({ description }: ProjectDescriptionProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')

  return (
    <Box sx={{ margin: '20px 0px 0px 0' }} qa-attribute="project-description">
      <Stack direction="column">
        <Typography variant="title" color="text.primary" paragraph sx={{ mb: 3 }}>
          {t('projectDescription')}
        </Typography>
        <Typography variant="text" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
          {description}
        </Typography>
      </Stack>
    </Box>
  )
}

import { OrderBy } from './CheckboxList'
import { SortingIcon } from '../../../containers/dashboard/mainDisplay/RowViewSorter'
import theme from '../../../theme/defaultTheme'
import * as React from 'react'
import { Checkbox, ListItem, ListItemIcon, ListItemText, TableSortLabel } from '@mui/material'

interface CheckboxListHeaderProps {
  onToggleAllClick: () => void
  allItemsChecked: boolean
  someItemsChecked: boolean
  orderBy: OrderBy
  onOrderByClick: (order: OrderBy) => void
  headerTitle: string
  disabled?: boolean
}

export const CheckboxListHeader = ({
  onToggleAllClick,
  allItemsChecked,
  someItemsChecked,
  orderBy,
  onOrderByClick,
  headerTitle,
  disabled
}: CheckboxListHeaderProps): React.ReactElement => {
  return (
    <ListItem
      key={'checkbox-list-header'}
      sx={{ padding: `0 0 ${theme.spacing(2)} ${theme.spacing(1)}`, gridGap: theme.spacing(3) }}
    >
      <ListItemIcon sx={{ minWidth: 'initial' }}>
        <Checkbox
          onClick={(): void => {
            onToggleAllClick()
          }}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': 'checkbox-list-header' }}
          indeterminate={someItemsChecked !== allItemsChecked}
          checked={allItemsChecked}
          disabled={disabled}
        />
      </ListItemIcon>
      <ListItemText primary={headerTitle} />
      <TableSortLabel
        active
        direction={orderBy}
        onClick={(): void => {
          onOrderByClick(orderBy === 'asc' ? 'desc' : 'asc')
        }}
        IconComponent={(): React.ReactElement => <SortingIcon order={orderBy} isActive />}
      />
    </ListItem>
  )
}

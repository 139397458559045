import Typography from '@mui/material/Typography/Typography'
import { styled } from '@mui/system'

export const CollapsedBreadcrumbsPlaceholder = styled(Typography)(({ theme }) => ({
  overflow: 'visible',
  textUnderlineOffset: theme.spacing(1),
  textDecoration: 'underline',
  fontSize: '.75rem',
  transition: '.3s color',
  ':hover': {
    color: theme.palette.primary
  }
}))

import * as React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { DistributionType } from '@/store/features/apis/slices/ai/interfaces'

type InternalDistributionSelectProps = {
  sharedWithOrganization: boolean
  onChange: (sharedWithOrganization: boolean) => void
}

export const InternalDistributionSelect = ({
  sharedWithOrganization,
  onChange
}: InternalDistributionSelectProps): React.ReactElement => {
  const { t } = useTranslation(['adminArea'])

  return (
    <Select
      variant="standard"
      value={sharedWithOrganization ? DistributionType.MY_ORGANIZATION : DistributionType.ONLY_ME}
      onChange={(event): void => onChange(event.target.value === DistributionType.MY_ORGANIZATION)}
    >
      {Object.entries(DistributionType).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {t(`ai.internalDistribution.options.${value}`)}
        </MenuItem>
      ))}
    </Select>
  )
}

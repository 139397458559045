import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { useOrganizationName } from '../../../../hooks/newWorld'
import { deleteCookie } from '../../../../helpers/cookies'
import { Trans, useTranslation } from 'react-i18next'
import * as React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { Box } from '@mui/material'
import { StyledTooltip } from '@/components/tooltip/StyledTooltip'

interface WelcomeTemplateProps extends ModalTemplateProps {}

export const WelcomeTemplate = ({ onClose, showCloseButton }: WelcomeTemplateProps): React.ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation('modals')
  const { data: organizationName } = useOrganizationName({ skipCondition: false })

  React.useEffect(() => {
    deleteCookie('welcome')
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete('welcome')
    setSearchParams(newSearchParams, { replace: true })
  }, [searchParams])

  if (isNil(organizationName) || organizationName === '') {
    return null
  }

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>
        <StyledTooltip title={t('welcome.title', { organizationName })}>
          <Box>{t('welcome.title', { organizationName })}</Box>
        </StyledTooltip>
      </ModalTitle>
      <ModalContent onSubmit={onClose}>
        <Stack direction="column" gap={3}>
          <Typography variant="text">
            <Trans i18nKey="welcome.description.0" t={t} values={{ organizationName }} />
          </Typography>
          <Typography variant="text">
            <Trans i18nKey="welcome.description.1" t={t} values={{ organizationName }}>
              <Link
                href="#"
                onClick={(): void => {
                  window['Intercom']?.('show')
                }}
              />
            </Trans>
          </Typography>
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('welcome.gotIt')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import * as React from 'react'
import { Box, Stack, Typography } from '@mui/material'

interface TooltipComponentProps {
  title: string
  body: React.ReactElement
}

export const TooltipComponent = ({ title, body }: TooltipComponentProps): React.ReactElement => {
  return (
    <Stack direction={'column'}>
      <Typography
        variant="titleLight"
        sx={{
          padding: 3,
          borderBottom: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'grey.200',
          backgroundColor: 'grey.100'
        }}
      >
        {title}
      </Typography>
      <Box p={3}>{body}</Box>
    </Stack>
  )
}

import { useProjectById } from './../../components/modal/templates/moveProject/hooks'
import { OperationId, OperationWithPermission, OperationsList } from '../interfaces'
import { filterSearchParams, openProjectInWorkbench } from '../../helpers/navigation'
import { FullProject } from '../../store/features/apis/slices/project/interfaces'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { useSearchParams } from 'react-router-dom'

type OverloadParameters = {
  (operationId: OperationId.OPEN, { projectId }: { projectId?: string }): OperationWithPermission<string>
  (operationId: OperationId.SHARE, { projectId }: { projectId?: string }): OperationWithPermission<void>
}

export const canShareProject = (project: FullProject): boolean => {
  return (
    project?.requesterPermissions?.canShareProjectWithSpaceMembers ||
    project?.requesterPermissions?.canShareProjectWithOrganizationMembers ||
    project?.requesterPermissions?.canShareProjectWithAnyone
  )
}

export const useProjectOperation: OverloadParameters = (
  operationId: OperationId,
  { projectId }: { spaceId?: string; folderId?: string; projectId?: string }
): OperationWithPermission<unknown> => {
  const { navigateNoReset } = useDashboardNavigate()
  const { project } = useProjectById(projectId)
  const [searchParams] = useSearchParams()

  const operationsList: OperationsList = {
    [OperationId.OPEN]: {
      onClick: (projectId: string) => {
        openProjectInWorkbench(projectId)
      },
      isAllowed: true
    },
    [OperationId.SHARE]: {
      onClick: () => {
        const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
        navigateNoReset(`/projects/${projectId}/share${filteredParams}`)
      },
      isAllowed: canShareProject(project)
    }
  }

  const { onClick, isAllowed } = operationsList[operationId]
  return [onClick, isAllowed]
}

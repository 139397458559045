import { UserListDataType } from './interfaces'
import { OrderBy } from '../../components/list/checkboxList/CheckboxList'

export const sortUsers = (usersList: UserListDataType[], orderBy: OrderBy): UserListDataType[] => {
  const regularUsers = usersList
    .filter((user) => !user.alwaysOnTop)
    .sort((a, b) => ((orderBy === 'asc' ? a.email > b.email : a.email < b.email) ? 1 : -1))
  const alwaysOnTopUsers = usersList
    .filter((user) => user.alwaysOnTop)
    .sort((a, b) => ((orderBy === 'asc' ? a.email > b.email : a.email < b.email) ? 1 : -1))

  return [...alwaysOnTopUsers, ...regularUsers]
}

import { useValidation } from '../../../../hooks/useValidation'
import { ValidationRules, isValidInput, isValidNullableInput } from '../../../../helpers/validationHelpers'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import * as React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

type SearchMemberType = {
  searchString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  setHasValidationErrors: React.Dispatch<React.SetStateAction<boolean>>
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void
}

export const SearchMember = React.forwardRef(
  (
    { searchString, setSearchString, setHasValidationErrors, onFocus }: SearchMemberType,
    ref: React.Ref<HTMLInputElement>
  ): React.ReactElement => {
    const { t } = useTranslation('modals')
    const { validateField } = useValidation()

    const validationRules: ValidationRules = {
      teamMemberSearch: [isValidNullableInput, isValidInput]
    }

    const { validationErrors, setValidationErrors } = useErrorHandler({
      teamMemberSearch: { valid: true, message: '' }
    })

    React.useEffect(() => {
      setHasValidationErrors(Object.values(validationErrors).some(({ valid }) => !valid))
    }, [validationErrors])

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setSearchString(event.target.value)
    }

    const handleOnClick = (): void => {
      setSearchString(searchString)
    }

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter') {
        setSearchString(searchString)
      }
    }

    const handleValidation = (value: string): void => {
      const validation = validateField(value, validationRules.teamMemberSearch)
      if (!isNil(validation)) {
        setValidationErrors({ ...validationErrors, teamMemberSearch: validation })
      }
    }

    return (
      <TextField
        id="search-team-members"
        ref={ref}
        helperText={validationErrors['teamMemberSearch'].message}
        error={!validationErrors['teamMemberSearch'].valid}
        fullWidth
        placeholder={t('teamSpace.addMembersByEmail')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ px: 1 }}>
              <SpriteIcon
                spriteId="magnifying"
                width=".8125rem"
                onClick={handleOnClick}
                style={{ cursor: 'pointer' }}
              />
            </InputAdornment>
          )
        }}
        value={searchString}
        variant="standard"
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChange}
        qa-attribute="search-team-members--textfield"
        onBlur={(): void => {
          handleValidation(searchString)
        }}
        onFocus={onFocus}
      />
    )
  }
)

import { CounterCardProps } from './interfaces'
import { StackCard } from '../stackCard/StackCard'
import Typography from '@mui/material/Typography'
import { isNil } from 'lodash'
import * as React from 'react'

export const CounterCard = ({
  currentAmount,
  maxAmount,
  description,
  outlined
}: CounterCardProps): React.ReactElement => {
  return (
    <StackCard outlined={outlined}>
      {isNil(maxAmount) && <Typography variant="summaryCardCounterTotal">{currentAmount}</Typography>}
      {!isNil(maxAmount) && (
        <>
          <Typography variant="summaryCardCounter">{currentAmount}</Typography>
          <Typography variant="summaryCardCounterMax">/ {maxAmount}</Typography>
        </>
      )}
      {!isNil(description) && (
        <Typography variant={outlined ? 'summaryCardDescriptionBold' : 'summaryCardDescription'}>
          {description}
        </Typography>
      )}
    </StackCard>
  )
}

import * as React from 'react'
import { CircularProgress, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ProjectDetails } from '@/components/projects/ProjectDetails'
import { useGetProjectDetailsQuery } from '@/store/features/apis/slices/project/projectSlice'
import { ApiVersion } from '@/store/features/apis/helpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import {
  AiAvailableAnalysisType,
  useGetSupportedAnalysisTypes
} from '@/containers/ai/trainingDataCart/hooks/useGetSupportedAnalysisTypes'

type ProjectDetailsDisplayType = {
  projectId: string
  onAnalysisSelect: (supportedAnalysis: AiAvailableAnalysisType[]) => void
}

export const ProjectDetailsDisplay = ({
  projectId,
  onAnalysisSelect
}: ProjectDetailsDisplayType): React.ReactElement => {
  const { t } = useTranslation(['modals', 'aiTraining'])
  const { handleApiHookErrors } = useErrorHandler()

  const {
    data: project,
    isLoading: isLoadingProject,
    isError: isErrorProject,
    error: errorProject
  } = useGetProjectDetailsQuery({ apiVersion: ApiVersion.V3, projectId }, { skip: isNil(projectId) })

  const { data: supportedAnalysis, isLoading: isLoadingSupportedAnalysis } = useGetSupportedAnalysisTypes(projectId)

  React.useEffect(() => {
    if (isErrorProject) {
      handleApiHookErrors(errorProject)
    }
  }, [isErrorProject, errorProject])

  React.useEffect(() => {
    if (!isLoadingSupportedAnalysis && !isNil(supportedAnalysis)) {
      onAnalysisSelect(supportedAnalysis)
    }
  }, [isLoadingSupportedAnalysis, supportedAnalysis])

  if (isLoadingProject || isLoadingSupportedAnalysis) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  if (isEmpty(supportedAnalysis)) {
    return null
  }

  return (
    <Stack>
      <ProjectDetails
        projectName={project.projectName}
        thumbnailUrl={project.thumbnailUrl}
        ownerName={project.ownerName}
      />
      <List>
        {supportedAnalysis.map(({ type, numberOfSuccessfulRuns }) => {
          return (
            <ListItem key={`${type}+${numberOfSuccessfulRuns}`} disablePadding sx={{ paddingLeft: '44px' }}>
              <Typography>&#x2022;</Typography>
              <ListItemText
                primary={`${type} - ${t('projectsCart.runs', { ns: 'aiTraining' })} ${numberOfSuccessfulRuns}`}
                sx={{ paddingLeft: '8px' }}
              />
            </ListItem>
          )
        })}
      </List>
    </Stack>
  )
}

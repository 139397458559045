import { closeModal } from '../../../../store/features/uiSlice/uiSlice'
import { PathSelector } from '../../../../containers/spaces/interfaces'
import { Button } from '../../../button/Button'
import { useCreateNewFolderMutation } from '../../../../store/features/apis/slices/folder/folderSlice'
import { useModalEvents } from '../../../../hooks/useModalEvents'
import { first, isNil, last } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'

interface CreateFolderSubmitProps {
  isButtonDisabled: boolean
  selectedPath: PathSelector
  folderName: string
  validateForm: () => boolean
  handleApiHookErrors: (error: FetchBaseQueryError | SerializedError) => void
  onFolderCreated: (newFolderId: string) => void
}

export const CreateFolderSubmit = ({
  isButtonDisabled,
  selectedPath,
  folderName,
  validateForm,
  handleApiHookErrors,
  onFolderCreated
}: CreateFolderSubmitProps): React.ReactElement => {
  const { t } = useTranslation(['modals'])
  const dispatch = useDispatch()

  const [createNewFolder, { data, isLoading, isError, error, isSuccess }] = useCreateNewFolderMutation()

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  React.useEffect(() => {
    if (isSuccess && !isNil(data)) {
      dispatch(closeModal())
      onFolderCreated?.(data.folderId)
    }
  }, [isSuccess, data])

  const onClick = (): void => {
    if (validateForm()) {
      createNewFolder({
        spaceId: first(selectedPath),
        folderId: last(selectedPath),
        folderName
      })
    }
  }

  const isSubmitDisabled = isButtonDisabled || isLoading

  useModalEvents(onClick, isSubmitDisabled)

  return (
    <Button onClick={onClick} disabled={isSubmitDisabled} color="primary" variant="contained">
      {t('folder.actions.createFolder')}
    </Button>
  )
}

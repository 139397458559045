import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { Trans, useTranslation } from 'react-i18next'
import * as React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

interface DomainVerificationTemplateProps extends ModalTemplateProps {
  domain: string
}

export const DomainVerificationTemplate = ({
  domain,
  onClose,
  showCloseButton
}: DomainVerificationTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>
        {t('domainVerification.title')} - {domain}
      </ModalTitle>
      <ModalContent onSubmit={onClose}>
        <Stack direction="column" gap={3}>
          <Typography variant="text">
            <Trans i18nKey="domainVerification.description.0" t={t} values={{ domain }} />
          </Typography>
          <Typography variant="text">
            <Trans i18nKey="domainVerification.description.1" t={t}>
              <Link
                href="#"
                onClick={(): void => {
                  window['Intercom']?.('show')
                }}
              />
            </Trans>
          </Typography>
          <Typography variant="text">{t('domainVerification.description.2')}</Typography>
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('domainVerification.gotIt')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import {
  getCurrentProjectPage,
  getCurrentSelectedProjectId,
  getSortingModeV2,
  setCurrentProjectPage,
  setIsLoading
} from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { PROJECT_PAGE_SIZE } from '../../../../store/features/apis/slices/project/helpers'
import { getUsername } from '../../../../helpers/cookies'
import { useGetProjectScope } from '../hooks'
import { getProjects, getSearchProjects } from '../../../../store/features/apis/slices/project/projectsSlice'
import { ProjectAction } from '../../../../hooks/useActionModalHandler'
import { useCurrentPath } from '../../../../hooks/useCurrentPath'
import { usePersonalSpaceId } from '../../../../store/features/apis/slices/space/hooks'
import { PagePath } from '../../../../constants'
import { first, isNil, last } from 'lodash'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

export const useNavigateToPage = (): void => {
  const dispatch = useAppDispatch()
  const selectedProjectId = useAppSelector(getCurrentSelectedProjectId)
  const { currentPath: selectedPath } = useCurrentPath()
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const currentPage = useAppSelector(getCurrentProjectPage)

  React.useEffect(() => {
    const goToProjectPage = async (page: number): Promise<void> => {
      const spaceId = first(selectedPath)
      if (isNil(spaceId)) {
        return
      }

      dispatch(setIsLoading(true))

      const result = await dispatch(
        getProjects({
          spaceId,
          folderId: last(selectedPath),
          targetPage: page,
          sortingModeV2,
          sortingDirection
        })
      )

      if (result.isSuccess) {
        const projects = result.data.items
        const isProjectInPage = projects.some((project) => project.projectIdExt === selectedProjectId)
        if (!isProjectInPage) {
          const pagingMetadata = result.data.pagingMetadata
          if (pagingMetadata?.totalPages < page) {
            dispatch(setIsLoading(false))
            return
          }
          goToProjectPage(page + 1)
        } else {
          dispatch(setIsLoading(false))
          dispatch(setCurrentProjectPage(page))
        }
      }
    }

    if (!isNil(selectedProjectId)) {
      goToProjectPage(currentPage ?? 1)
    }

    return () => {
      dispatch(setIsLoading(false))
    }
  }, [selectedProjectId, selectedPath, sortingModeV2, sortingDirection])
}

export const useNavigateToInfinitePage = (): void => {
  const dispatch = useAppDispatch()
  const currentProjectPage = useAppSelector(getCurrentProjectPage)
  const selectedProjectId = useAppSelector(getCurrentSelectedProjectId)
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const username = getUsername()
  const [searchParams] = useSearchParams()
  const searchSection = searchParams.get('section')
  const scope = useGetProjectScope()
  const personalSpaceId = usePersonalSpaceId()

  React.useEffect(() => {
    // don't navigate to shared project when copying
    if (!window.location.href.includes(`/${ProjectAction.COPY}/`)) {
      const goToProjectPage = async (page: number): Promise<void> => {
        dispatch(setIsLoading(true))
        const { data, isSuccess } = await dispatch(
          getSearchProjects({
            sortingModeV2,
            sortingDirection,
            targetPage: page,
            username,
            scope,
            query: searchParams.get('q'),
            filterby: 'project_text',
            spaceId: searchSection === PagePath.MY_PROJECTS ? personalSpaceId : searchSection
          })
        )

        if (isSuccess) {
          const isProjectInPage = data.projects.some((project) => project.projectIdExt === selectedProjectId)
          if (!isProjectInPage) {
            if (data.projects.length !== PROJECT_PAGE_SIZE) {
              dispatch(setIsLoading(false))
              return
            }
            goToProjectPage(page + 1)
          } else {
            dispatch(setIsLoading(false))
            dispatch(setCurrentProjectPage(page))
          }
        }
      }

      if (!isNil(selectedProjectId)) {
        goToProjectPage(currentProjectPage ?? 1)
      }
    }
  }, [selectedProjectId, sortingModeV2, sortingDirection, searchSection, personalSpaceId])
}

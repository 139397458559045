import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const NoInvitationsAlert = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Alert icon={false} severity="info">
      <Typography variant="text" color="text.primary">
        {t('noInvitationsFound')}
      </Typography>
    </Alert>
  )
}

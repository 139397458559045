import { RowViewSorter } from './mainDisplay/RowViewSorter'
import { useAppSelector } from '../../store/hooks'
import { getViewingMode } from '../../store/features/uiSlice/uiSlice'
import { ContextMenu } from '../../components/contextMenu/ContextMenu'
import { ViewMode } from '../../store/features/uiSlice/interfaces'
import { OperationToolbar } from '../OperationToolbar/OperationToolbar'
import { Box, Grid } from '@mui/material'
import * as React from 'react'
import { TAB_INDEXES } from '@/constants/tabIndexes'

interface MainDisplayProps {
  children?: React.ReactNode
  isOperationToolbarEnabled?: boolean
}

export const MainDisplay = ({ children, isOperationToolbarEnabled }: MainDisplayProps): React.ReactElement => {
  const displayType = useAppSelector(getViewingMode)

  return (
    <Grid
      item
      xs
      sm
      md
      lg
      xl
      sx={{
        maxHeight: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '0'
      }}
    >
      {isOperationToolbarEnabled && <OperationToolbar />}
      {isOperationToolbarEnabled && displayType === ViewMode.LIST && <RowViewSorter />}
      <Box
        qa-attribute="main-display"
        className="u-overflowOverlay"
        sx={{
          paddingX: 5,
          position: 'relative'
        }}
        tabIndex={TAB_INDEXES.MAIN_DISPLAY}
      >
        {children}
      </Box>
      <ContextMenu />
    </Grid>
  )
}

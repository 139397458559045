import { Button } from '../button/Button'
import { Alert, IconButton, Snackbar, Stack } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getSnackbarState, setSnackbarState } from '@/store/features/uiSlice/uiSlice'
import { CustomSnackbarCloseReason } from '@/store/features/uiSlice/interfaces'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const GlobalSnackbar = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const snackbarState = useAppSelector(getSnackbarState)
  const { t } = useTranslation()

  const handleClose = React.useCallback(
    (snackbarId: string, reason?: CustomSnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return
      }
      const snackbar = snackbarState.find((snackbar) => snackbar.id === snackbarId)
      if (!snackbar) {
        return
      }
      snackbar.onClose?.(snackbarId, reason)
      dispatch(setSnackbarState(snackbarState.filter((snackbar) => snackbar.id !== snackbarId)))
    },
    [snackbarState]
  )

  return (
    <Stack direction="column" gap={1}>
      {snackbarState.map(({ id, severity, message, autoHideDuration, showUndoButton }, index) => {
        return (
          <Snackbar
            key={id}
            open={Boolean(id)}
            onClose={(_, reason: CustomSnackbarCloseReason): void => {
              handleClose(id, reason)
            }}
            autoHideDuration={autoHideDuration ?? 6000}
            sx={{ marginBottom: index * 16 }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              className={severity === 'info' ? 'MuiAlert-snackbarInfo' : undefined}
              severity={severity}
              onClose={(): void => {
                handleClose(id)
              }}
              icon={false}
              action={
                <IconButton
                  onClick={(): void => {
                    handleClose(id)
                  }}
                  color="inherit"
                  sx={{ borderRadius: '4px', '&:hover': severity === 'info' && { backgroundColor: 'action.disabled' } }}
                >
                  <SpriteIcon spriteId="times" width={'.8125rem'} height={'.8125rem'} />
                </IconButton>
              }
            >
              {message}
              {showUndoButton && (
                <Button
                  onClick={(): void => {
                    handleClose(id, 'undo')
                  }}
                  variant="contained"
                  color="info"
                  size="small"
                  sx={{ marginLeft: 4 }}
                >
                  {t('undo')}
                </Button>
              )}
            </Alert>
          </Snackbar>
        )
      })}
    </Stack>
  )
}

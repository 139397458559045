/* eslint-disable no-console */
import { TeamRowDisplay } from './TeamRowDisplay'
import { useContextMenu } from '../../../hooks/useContextMenu'
import { TableRowProps } from '../../../components/table/enhancedTable/interfaces'
import { useOrganizationTeamSpaces } from '../../../store/features/apis/slices/space/hooks'
import { ContextMenuType } from '../../../components/contextMenu/ContextMenu'
import { useGetUserSpacesQuery } from '../../../store/features/apis/slices/space/spacesSlice'
import { useIsAdmin } from '../../../permissions/areas'
import { isEmpty } from 'lodash'
import * as React from 'react'

export const useGetTeamSpacesTableBodyData = (): { tableData: TableRowProps[]; isLoading: boolean } => {
  const { show } = useContextMenu()

  const { data: isAdmin } = useIsAdmin()
  const { spaces: organizationSpaces, isLoading: isLoadingOrganizationSpaces } = useOrganizationTeamSpaces()
  const { data: userSpaces, isLoading: isLoadingUserSpaces, isFetching: isFetchingUserSpaces } = useGetUserSpacesQuery()

  const tableData = React.useMemo(() => {
    if (isLoadingOrganizationSpaces || isEmpty(organizationSpaces) || isLoadingUserSpaces || isFetchingUserSpaces) {
      return []
    }

    const spacesData = isAdmin
      ? organizationSpaces
      : userSpaces.filter((space) => space.spaceType === 'TEAM' && space.requesterPermission.canManage)

    return spacesData.map((space) => ({
      tableRowId: space.spaceId,
      tableRowCells: [
        {
          id: space.spaceId,
          title: space.name,
          component: <TeamRowDisplay space={space} />
        }
      ],
      actionButton: (event: React.MouseEvent): void => {
        show({ event, type: ContextMenuType.SPACE, space })
      }
    }))
  }, [organizationSpaces, isLoadingOrganizationSpaces, userSpaces, isLoadingUserSpaces, isFetchingUserSpaces, isAdmin])

  return { tableData, isLoading: isLoadingOrganizationSpaces || isLoadingUserSpaces || isFetchingUserSpaces }
}

import { TrainingDataProjectsManagement } from './TrainingDataProjectsManagement'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getSelectedItems, openModal } from '@/store/features/uiSlice/uiSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { AiSectionPaths } from '@/constants'
import { usePostTrainingModelMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { Button } from '@/components/button/Button'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'

export const TrainingDataCart = (): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const { modelUuid } = useParams<{ modelUuid: string }>()
  const [searchParams] = useSearchParams()
  const selectedProjects = useAppSelector(getSelectedItems).projects
  const [successfulRuns, setSuccessfulRuns] = React.useState(0)
  const aiModelTrainingSettings = useAppSelector((store) => store.ui.aiModelTrainingSettings)
  const [postTrainingModel, { isLoading }] = usePostTrainingModelMutation()
  const { navigate } = useDashboardNavigate()

  const isOnSecondStep = React.useMemo(
    () => searchParams.get('page') === AiSectionPaths.dataSettings.value,
    [searchParams]
  )

  const isAlertShown = successfulRuns < 20 && selectedProjects.length > 0
  const isNextButtonDisabled = isOnSecondStep ? !aiModelTrainingSettings?.isValid : successfulRuns < 20

  React.useEffect(() => {
    if (isEmpty(selectedProjects)) {
      setSuccessfulRuns(0)
    }
  }, [selectedProjects])

  const handleNextButtonClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.SELECT_AI_TRAINING_ANALYSIS_TYPE,
        showCloseButton: true,
        modalProps: {
          title: t('selectAiTrainingAnalysisType.title', { ns: 'modals' })
        }
      })
    )
  }, [])

  const handleStartTrainingButtonClick = React.useCallback(() => {
    postTrainingModel({
      modelUuid,
      payload: {
        training_name: aiModelTrainingSettings.formData.versionName,
        projects: selectedProjects,
        active_deadline_seconds: aiModelTrainingSettings.formData.maxRuntime.toString(),
        user_options: aiModelTrainingSettings.formData.userOptions
      }
    })
      .unwrap()
      .then(() => {
        navigate(`/models/${modelUuid}`)
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [aiModelTrainingSettings?.formData, selectedProjects, modelUuid])

  const handleSuccessfulRunIncrement = React.useCallback((successfulRuns: number) => {
    setSuccessfulRuns((prevValue) => prevValue + successfulRuns)
  }, [])

  return (
    <Stack
      sx={{
        width: '300px',
        height: 'fit-content',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'divider',
        borderRadius: 1,
        maxHeight: '70vh'
      }}
      flexShrink={0}
      padding={5}
      gap={5}
    >
      <Stack gap={1}>
        <Typography variant="subtitle" textTransform="uppercase" color="grey.600">
          {isOnSecondStep ? t('projectsCart.stepTwoOfTwo') : t('projectsCart.stepOneOfTwo')}
        </Typography>
        <Typography variant="title">{t('projectsCart.trainingData')}</Typography>
      </Stack>
      <Stack gap={5} paddingRight={5} marginRight={-5} sx={{ overflowY: 'scroll' }}>
        <TrainingDataProjectsManagement
          selectedProjects={selectedProjects}
          handleSuccessfulRunIncrement={handleSuccessfulRunIncrement}
        />
      </Stack>
      <Stack gap={2} paddingTop={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="title">{t('projectsCart.totalRuns')}</Typography>
          <Typography variant="titleLight">{`${successfulRuns} ${t('projectsCart.runs')}`}</Typography>
        </Stack>
        {isAlertShown && (
          <Stack gap={1} direction="row" alignItems="center">
            <SpriteIcon color="grey.600" spriteId="circle-info" width={20} height={20} />
            <Typography variant="summaryCardDescription" paddingRight={1} component="p" sx={{ overflow: 'hidden' }}>
              {t('projectsCart.notEnoughSuccessfulRuns')}
            </Typography>
          </Stack>
        )}
        <Button
          onClick={isOnSecondStep ? handleStartTrainingButtonClick : handleNextButtonClick}
          color="primary"
          variant="contained"
          disabled={isNextButtonDisabled || isLoading}
          isLoading={isLoading}
          size="large"
          sx={{ flexShrink: 0 }}
        >
          {isOnSecondStep ? t('projectsCart.startTraining') : t('projectsCart.next')}
        </Button>
      </Stack>
    </Stack>
  )
}

export const categories = {
  AEROSPACE: 'Aerospace & Defense',
  ARCHITECTURE_CONSTRUCTION: 'AEC',
  AUTOMOTIVE: 'Automotive & Transportation',
  CONSUMER_PRODUCTS: 'Consumer Products',
  ELECTRONICS: 'Electronics & High Tech',
  ENERGY: 'Energy',
  ENGINEERING_SERVICES: 'Engineering Services',
  INDUSTRIAL_EQUIPMENT: 'Machinery & Industrial Equipment',
  LIFE_SCIENCE_MEDICAL: 'Life Sciences & Healthcare',
  MANUFACTURING: 'Manufacturing',
  MARINE_SHIPBUILDING: 'Marine',
  OTHER: 'Other'
}

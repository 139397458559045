import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { useAppSelector } from '@/store/hooks'
import { EnhancedTableWithExternalState } from '@/components/table/enhancedTable/EnhancedTableWithExternalState'
import { useSelectModelTrainingData } from '@/containers/ai/dataSelection/tableHooks/useSelectModelTrainingData'
import { useSelectModelTrainingDataHeader } from '@/containers/ai/dataSelection/tableHooks/useSelectModelTrainingDataHeader'
import { PagePath } from '@/constants'
import { SortingDirection, SortingModeV2 } from '@/store/features/uiSlice/interfaces'
import { ProjectScopes } from '@/store/features/apis/slices/project/interfaces'
import { getUsername } from '@/helpers/cookies'
import { useGetSearchProjectsQuery } from '@/store/features/apis/slices/project/projectsSlice'

export const SharedWithMeProjectsSelection = ({ onSelect, onSelectAll, selectedProjects }): React.ReactElement => {
  const { t } = useTranslation('modals')
  const [projectsPerPage, setProjectsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(1)
  const [order, setOrder] = React.useState<SortingDirection>(SortingDirection.DESC)
  const [orderBy, setOrderBy] = React.useState<SortingModeV2>(SortingModeV2.LAST_MODIFIED_AT)
  const username = getUsername()

  const fullPath = useAppSelector((state) => state.ui.fullPath)

  const isInSharedWithMeProjects = fullPath?.includes(PagePath.SHARED_WITH_ME)

  const { data, isLoading, isFetching } = useGetSearchProjectsQuery(
    {
      sortingModeV2: orderBy,
      sortingDirection: order,
      targetPage: page,
      username,
      scope: ProjectScopes.PROJECTS_SHARED_WITH_ME,
      spaceId: PagePath.SHARED_WITH_ME,
      projectsPerPage: projectsPerPage + 1,
      offset: projectsPerPage
    },
    { skip: !isInSharedWithMeProjects }
  )

  const projects = data?.projects.slice(0, projectsPerPage)
  const projectIds = React.useMemo(() => projects?.map((project) => project.projectIdExt), [projects])

  const selectedOnPage = React.useMemo(() => {
    if (isNil(projectIds)) {
      return
    }
    return selectedProjects.filter((selectedProjectId: string) => projectIds.includes(selectedProjectId))
  }, [selectedProjects, projectIds])

  const tableHeaderData = useSelectModelTrainingDataHeader()
  const tableBodyData = useSelectModelTrainingData(projects)

  const onSelectAllCheckboxClick = (): void => {
    if (isNil(projects)) {
      return
    }
    onSelectAll(projectIds)
  }

  if (isEmpty(tableBodyData) && (!isLoading || !isFetching)) {
    return (
      <Stack width="100%" direction="row" justifyContent="center" alignItems="center">
        <Typography>{t('teamSpace.noItemsFound')}</Typography>
      </Stack>
    )
  }

  return (
    <EnhancedTableWithExternalState
      isLoading={isLoading || isFetching}
      tableHeaderData={tableHeaderData}
      tableBodyData={tableBodyData}
      page={page}
      setPage={setPage}
      hasMorePages={data?.projects.length > projects?.length}
      rowsPerPage={projectsPerPage}
      setRowsPerPage={setProjectsPerPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      onSelect={onSelect}
      onSelectAll={onSelectAllCheckboxClick}
      selected={selectedOnPage}
      flexGrow={1}
    />
  )
}

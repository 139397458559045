import { PathSelector } from './../../../containers/spaces/interfaces'
import {
  AiModelTrainingSettingsType,
  AiSectionPathValue,
  ContextMenu,
  ModalQueueItem,
  SelectItems,
  SelectedItem,
  SelectedItems,
  SnackbarState,
  SnackbarStateWithId,
  SortingDirection,
  SortingModeV2,
  UiState,
  ViewMode
} from './interfaces'
import { UserListDataType } from '../../../containers/list/interfaces'
import { RoutedActionModalTemplates } from '../../../hooks/useActionModalHandler'
import { createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../configureStore'

const initialState: UiState = {
  // "sortingModeV2" because "sortingMode" was used previously with different values,
  // and was set persistently on localStorage
  sortingModeV2: SortingModeV2.LAST_MODIFIED_AT,
  sortingDirection: SortingDirection.DESC,
  viewingMode: ViewMode.GRID,
  selectedNavigationTreeNode: undefined,
  fullPath: undefined,
  selectedSpaces: [],
  selectedFolders: [],
  selectedProjects: [],
  modalsQueue: [],
  searchUserString: '',
  searchSpaceString: '',
  snackbarState: [],
  contextMenu: {
    isOpen: false,
    type: undefined,
    x: 0,
    y: 0,
    folder: undefined,
    project: undefined
  },
  showRightPanel: true,
  currentProjectPage: 1,
  currentFolderPage: 1,
  checkboxListItems: [],
  latestCopiedProject: undefined,
  isLoading: false,
  mobileSidebarOpen: false,
  aiSectionPath: undefined,
  aiModelTrainingSettings: {
    formData: undefined,
    isValid: false
  }
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewingMode = action.payload ?? ViewMode.GRID
    },
    setSortingModeV2: (state, action: PayloadAction<SortingModeV2>) => {
      state.sortingModeV2 = action.payload ?? SortingModeV2.LAST_MODIFIED_AT
      state.currentFolderPage = 1
      state.currentProjectPage = 1
    },
    setSortingDirection: (state, action: PayloadAction<SortingDirection>) => {
      state.sortingDirection = action.payload ?? SortingDirection.DESC
      state.currentFolderPage = 1
      state.currentProjectPage = 1
    },
    setFullPath: (state, action: PayloadAction<PathSelector>) => {
      state.fullPath = action.payload
    },
    selectItem: (state, action: PayloadAction<SelectedItem>) => {
      const { id, type } = action.payload
      if (type === 'space') {
        state.selectedSpaces.unshift(id)
      } else if (type === 'folder') {
        state.selectedFolders.unshift(id)
      } else {
        state.selectedProjects.unshift(id)
      }
    },
    selectItems: (state, action: PayloadAction<SelectItems>) => {
      const { items, type } = action.payload
      if (type === 'space') {
        state.selectedSpaces = items
      } else if (type === 'folder') {
        state.selectedFolders = items
      } else {
        state.selectedProjects = items
      }
    },
    selectSingleItem: (state, action: PayloadAction<SelectedItem>) => {
      const { id, type } = action.payload
      if (type === 'space') {
        state.selectedSpaces = [id]
        state.selectedFolders = []
        state.selectedProjects = []
      } else if (type === 'folder') {
        state.selectedFolders = [id]
        state.selectedSpaces = []
        state.selectedProjects = []
      } else {
        state.selectedProjects = [id]
        state.selectedSpaces = []
        state.selectedFolders = []
      }
    },
    deselectItem: (state, action: PayloadAction<SelectedItem>) => {
      const { id, type } = action.payload
      if (type === 'space') {
        state.selectedSpaces = state.selectedSpaces.filter((spaceId) => spaceId !== id)
      } else if (type === 'folder') {
        state.selectedFolders = state.selectedFolders.filter((folderId) => folderId !== id)
      } else {
        state.selectedProjects = state.selectedProjects.filter((projectId) => projectId !== id)
      }
    },
    deselectAllItems: (state) => {
      state.selectedSpaces = []
      state.selectedFolders = []
      state.selectedProjects = []
    },
    openModal: (state, action: PayloadAction<ModalQueueItem>) => {
      state.modalsQueue.push(action.payload)
    },
    closeModal: (state) => {
      state.modalsQueue.pop()
    },
    closeAllActionModals: (state) => {
      state.modalsQueue = state.modalsQueue.filter((modal) => !RoutedActionModalTemplates.includes(modal.template))
    },
    setSearchUserString: (state, action: PayloadAction<string>) => {
      state.searchUserString = action.payload ?? ''
      state.selectedSpaces = []
      state.selectedFolders = []
      state.selectedProjects = []
    },
    setSearchSpaceString: (state, action: PayloadAction<string>) => {
      state.searchSpaceString = action.payload ?? ''
      state.selectedSpaces = []
      state.selectedFolders = []
      state.selectedProjects = []
    },
    addSnackbarToState: (state, action: PayloadAction<SnackbarState>) => {
      state.snackbarState = [...state.snackbarState, { ...action.payload, id: nanoid() }]
    },
    setSnackbarState: (state, action: PayloadAction<SnackbarStateWithId[]>) => {
      state.snackbarState = action.payload
    },
    openContextMenu: (state, action: PayloadAction<ContextMenu>) => {
      const {
        x,
        y,
        type,
        folder,
        project,
        space,
        user,
        apiKey,
        invitation,
        organizationUser,
        version,
        trainingPipeline
      } = action.payload
      state.contextMenu = {
        isOpen: true,
        x,
        y,
        type,
        folder,
        project,
        space,
        user,
        apiKey,
        invitation,
        organizationUser,
        version,
        trainingPipeline
      }
    },
    closeContextMenu: (state) => {
      state.contextMenu = { isOpen: false }
    },
    setShowRightPanel: (state, action: PayloadAction<boolean>) => {
      state.showRightPanel = action.payload ?? false
    },
    setCurrentProjectPage: (state, action: PayloadAction<number>) => {
      state.currentProjectPage = action.payload ?? 1
    },
    setCurrentFolderPage: (state, action: PayloadAction<number>) => {
      state.currentFolderPage = action.payload ?? 1
    },
    resetPagination: (state) => {
      state.currentProjectPage = 1
      state.currentFolderPage = 1
    },
    setCheckboxListItems: (state, action: PayloadAction<UserListDataType[]>): void => {
      state.checkboxListItems = action.payload ?? []
    },
    setLatestCopiedProject: (state, action: PayloadAction<{ thumbnailUrl: string; projectIdExt: string }>): void => {
      state.latestCopiedProject = action.payload
    },
    setIsLoading: (state, action: PayloadAction<boolean>): void => {
      state.isLoading = action.payload ?? false
    },
    setMobileSidebarOpen: (state, action: PayloadAction<boolean>): void => {
      state.mobileSidebarOpen = action.payload ?? false
    },
    setAiSectionPath: (state, action: PayloadAction<AiSectionPathValue[]>) => {
      state.aiSectionPath = action.payload
    },
    setAiModelTrainingSettingsFormData: (state, action: PayloadAction<AiModelTrainingSettingsType['formData']>) => {
      state.aiModelTrainingSettings.formData = action.payload
    },
    setAiModelTrainingSettingsIsValid: (state, action: PayloadAction<boolean>) => {
      state.aiModelTrainingSettings.isValid = action.payload
    }
  }
})

export const {
  setViewMode,
  setSortingModeV2,
  setSortingDirection,
  selectItem,
  selectItems,
  selectSingleItem,
  deselectItem,
  deselectAllItems,
  openModal,
  closeModal,
  closeAllActionModals,
  addSnackbarToState,
  setSnackbarState,
  openContextMenu,
  closeContextMenu,
  setShowRightPanel,
  setCurrentProjectPage,
  setCurrentFolderPage,
  setCheckboxListItems,
  setFullPath,
  setLatestCopiedProject,
  setIsLoading,
  setSearchUserString,
  setSearchSpaceString,
  resetPagination,
  setMobileSidebarOpen,
  setAiSectionPath,
  setAiModelTrainingSettingsFormData,
  setAiModelTrainingSettingsIsValid
} = uiSlice.actions

export const getSortingModeV2 = (state: RootState): SortingModeV2 => state.ui.sortingModeV2

export const getSelectedItems = (state: RootState): SelectedItems => ({
  spaces: state.ui.selectedSpaces ?? [],
  folders: state.ui.selectedFolders ?? [],
  projects: state.ui.selectedProjects ?? []
})

export const getModalsQueue = (state: RootState): ModalQueueItem[] => state.ui.modalsQueue ?? []

export const getSnackbarState = (state: RootState): SnackbarStateWithId[] => state.ui.snackbarState

export const getViewingMode = (state: RootState): ViewMode => state.ui.viewingMode

export const getShowRightPanel = (state: RootState): boolean => state.ui.showRightPanel

export const getCurrentProjectPage = (state: RootState): number => state.ui.currentProjectPage

export const getCurrentFolderPage = (state: RootState): number => state.ui.currentFolderPage

export const getCurrentSelectedProjectId = (state: RootState): string => state.ui.selectedProjects[0]

export const uiReducer = uiSlice.reducer

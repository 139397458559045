import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ModelVersionsList, ModelVersionsListSkeleton } from '@/components/aiModels/versionsList/ModelVersionsList'
import { useGetFilteredModelVersionsQuery, useGetTrainingPipelinesQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { ModelStage, TrainingPipelineStatus } from '@/store/features/apis/slices/ai/interfaces'
import { ModelTrainingPipelinesList } from '@/components/aiModels/trainingPipelinesList/ModelTrainingPipelinesList'

export const ModelAllVersionsSkeleton = (): React.ReactElement => {
  return (
    <Box>
      <Skeleton variant="rounded" width={150} height={20} animation="wave" />
      <ModelVersionsListSkeleton mt={5} />
    </Box>
  )
}

export const ModelAllVersions = ({ modelUuid }: { modelUuid: string }): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const { data: modelVersions, isLoading: isLoadingModelVersions } = useGetFilteredModelVersionsQuery({
    modelUuid,
    stages: [ModelStage.ARCHIVED, ModelStage.NONE, ModelStage.STAGING]
  })
  const { data: rawTrainingPipelines, isLoading: isLoadingTrainingPipelines } = useGetTrainingPipelinesQuery({
    modelUuid
  })

  const trainingPipelines = React.useMemo(
    () => rawTrainingPipelines?.filter((pipeline) => pipeline.status !== TrainingPipelineStatus.SUCCEEDED),
    [rawTrainingPipelines]
  )

  const isLoading = isLoadingModelVersions || isLoadingTrainingPipelines
  const isEmptyList =
    (isNil(modelVersions) || isEmpty(modelVersions)) && (isNil(trainingPipelines) || isEmpty(trainingPipelines))
  const isEmptyModelVersions = isNil(modelVersions) || isEmpty(modelVersions)

  if (isLoading) {
    return <ModelAllVersionsSkeleton />
  }

  return (
    <Box qa-attribute="aiModel--allVersions">
      <Typography variant="h4" fontWeight="bold" color="grey.700">
        {t('modelDetails.allVersions')}
      </Typography>
      {isEmptyList ? (
        <Typography variant="textV2">{t('modelDetails.noAvailableVersions')}</Typography>
      ) : (
        <>
          {!isEmptyModelVersions && <ModelVersionsList versions={modelVersions} mt={5} />}
          <ModelTrainingPipelinesList trainingPipelines={trainingPipelines} mt={isEmptyModelVersions ? 5 : 3} />
        </>
      )}
    </Box>
  )
}

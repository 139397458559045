import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { ModalInputText } from '../../inputComponents/ModalInputText'
import { ModalInputTextArea } from '../../inputComponents/ModalInputTextArea'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import {
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidMaximumNumberOfChars,
  isValidNullableInput,
  isValidRequiredNumberOfChars
} from '../../../../helpers/validationHelpers'
import { useValidation } from '../../../../hooks/useValidation'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { usePostModelMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { deselectAllItems } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'

type ConfirmPublicProjectPermissionType = ModalTemplateProps & {
  title: string
}

export const CreateAiModel = ({
  title,
  onClose,
  showCloseButton
}: ConfirmPublicProjectPermissionType): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { validateField } = useValidation()
  const { navigate } = useDashboardNavigate()
  const { handleApiHookErrors } = useErrorHandler()

  const [postModel, { isLoading }] = usePostModelMutation()

  const [modelName, setModelName] = React.useState('')
  const [modelDescription, setModelDescription] = React.useState('')

  const validationRules: ValidationRules = {
    modelName: [isRequired, isNotEmpty, isValidRequiredNumberOfChars(1), isValidMaximumNumberOfChars(255)],
    modelDescription: [isValidNullableInput, isValidMaximumNumberOfChars(255)]
  }

  const { validationErrors, setValidationErrors } = useErrorHandler({
    modelName: { valid: true, message: '' },
    modelDescription: { valid: true, message: '' }
  })

  const isSubmitButtonDisabled =
    isEmpty(modelName) || !validationErrors.modelName?.valid || !validationErrors.modelDescription?.valid

  const handleConfirmOnClick = (): void => {
    postModel({ name: modelName, description: modelDescription })
      .unwrap()
      .then((data) => {
        dispatch(deselectAllItems())
        onClose()
        navigate(`/models/${data.model_uuid}/create-version?page=data-selection`, {
          state: {
            modelName,
            modelDescription
          }
        })
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }

  const handleModelNameChange = React.useCallback(
    (value: string) => {
      setModelName(value)
      const validation = validateField(value, validationRules.modelName)
      if (!isNil(validation)) {
        setValidationErrors({ ...validationErrors, modelName: validation })
      }
    },
    [validationRules]
  )

  const handleModelDescriptionChange = React.useCallback(
    (value: string) => {
      setModelDescription(value)
      const validation = validateField(value, validationRules.modelDescription)
      if (!isNil(validation)) {
        setValidationErrors({ ...validationErrors, modelDescription: validation })
      }
    },
    [validationRules]
  )

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleConfirmOnClick}>
        <ModalInputText
          onChange={(event): void => {
            handleModelNameChange(event.target.value)
          }}
          value={modelName}
          label={t('createAiModel.modelName')}
          required
          autoFocus
          helperText={validationErrors['modelName'].message}
          error={!validationErrors['modelName'].valid}
        />
        <ModalInputTextArea
          onChange={(event): void => {
            handleModelDescriptionChange(event.target.value)
          }}
          value={modelDescription}
          label={t('createAiModel.modelDescription')}
          helperText={validationErrors['modelDescription'].message}
          error={!validationErrors['modelDescription'].valid}
          placeholder={t('createAiModel.optional')}
        />
      </ModalContent>
      <ModalActions>
        <Button
          onClick={handleConfirmOnClick}
          color="primary"
          variant="contained"
          disabled={isSubmitButtonDisabled}
          isLoading={isLoading}
        >
          {t('createAiModel.selectTrainingData')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

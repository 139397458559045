import { ModalInputSelect } from '../../inputComponents/ModalInputSelect'
import { SharingControl } from '../../../../store/features/apis/slices/space/interfaces'
import { useOrganizationName } from '../../../../hooks/newWorld'
import { useIsInOrganization } from '../../../../permissions/areas'
import { NonProfessionalLicenseAlert } from '../project/PrivacySelectorAlerts'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem, Stack, Typography } from '@mui/material'
import { SpriteIcon } from '@/assets/SpriteIcon'

type PermissionType = {
  icon: React.ReactElement
  title: string
  description: string
  hideDescription?: boolean
}

const Permission = ({ icon, title, description, hideDescription }: PermissionType): React.ReactElement => {
  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={1} alignItems="center">
        {icon}
        <Typography variant="text">{title}</Typography>
      </Stack>
      {!hideDescription && <Typography variant="subtitle">{description}</Typography>}
    </Stack>
  )
}

type SharingPermissionType = {
  permission: SharingControl
  hideDescription?: boolean
}

const SharingPermission = ({ permission, hideDescription }: SharingPermissionType): React.ReactElement => {
  const { t } = useTranslation('modals')
  const { data: isInOrganization } = useIsInOrganization()
  const { data: organizationName, isLoading: isOrganizationNameLoading } = useOrganizationName({
    skipCondition: !isInOrganization
  })

  switch (permission) {
    case SharingControl.NO_SHARING:
      return (
        <Permission
          icon={<SpriteIcon width={12} height={12} spriteId="lock" color={'grey.600'} />}
          title={t('teamSpace.sharingPermissions.noSharing')}
          description={t('teamSpace.sharingPermissions.noSharingDescription')}
          hideDescription={hideDescription}
        />
      )
    case SharingControl.WITHIN_SPACE:
      return (
        <Permission
          icon={<SpriteIcon width={12} height={12} spriteId="user-group" color={'grey.600'} />}
          title={t('teamSpace.sharingPermissions.withinSpace')}
          description={t('teamSpace.sharingPermissions.withinSpaceDescription')}
          hideDescription={hideDescription}
        />
      )
    case SharingControl.WITHIN_ORGANIZATION:
      return (
        !isOrganizationNameLoading && (
          <Permission
            icon={<SpriteIcon width={12} height={12} spriteId="building" color={'grey.600'} />}
            title={organizationName}
            description={t('teamSpace.sharingPermissions.withinOrganizationDescription')}
            hideDescription={hideDescription}
          />
        )
      )
    case SharingControl.WITH_ANYONE:
      return (
        <Permission
          icon={<SpriteIcon width={12} height={12} spriteId="globe" color={'grey.600'} />}
          title={t('teamSpace.sharingPermissions.withAnyone')}
          description={t('teamSpace.sharingPermissions.withAnyoneDescription')}
          hideDescription={hideDescription}
        />
      )
  }
}

type SharingPermissionsType = {
  sharingPermission: SharingControl
  handleSharingPermissionChange: (sharingPermission: SharingControl) => void
  isProfessionalOrganization: boolean
}

export const SharingPermissions = ({
  sharingPermission,
  handleSharingPermissionChange,
  isProfessionalOrganization
}: SharingPermissionsType): React.ReactElement => {
  const { t } = useTranslation('modals')
  const [isClicked, setIsClicked] = React.useState(false)

  const options = React.useMemo(() => {
    if (!isProfessionalOrganization) {
      return [
        {
          value: SharingControl.WITH_ANYONE,
          component: (
            <MenuItem key={SharingControl.WITH_ANYONE} value={SharingControl.WITH_ANYONE} sx={{ height: '54px' }}>
              <SharingPermission permission={SharingControl.WITH_ANYONE} />
            </MenuItem>
          )
        }
      ]
    }
    return Object.keys(SharingControl).map((permission) => {
      return {
        value: permission,
        component: (
          <MenuItem key={permission} value={permission} sx={{ height: '54px' }}>
            <SharingPermission permission={permission as SharingControl} />
          </MenuItem>
        )
      }
    })
  }, [isProfessionalOrganization])

  return (
    <React.Fragment>
      <ModalInputSelect
        value={sharingPermission}
        label={t('teamSpace.sharingPermission')}
        onChange={(event): void => handleSharingPermissionChange(event.target.value as SharingControl)}
        options={options}
        disabled={!isProfessionalOrganization}
        renderValue={(permission: SharingControl): React.ReactElement => (
          <SharingPermission permission={permission} hideDescription />
        )}
        onClick={(): void => setIsClicked(true)}
        sx={{ maxWidth: 210 }}
      />
      {!isProfessionalOrganization && isClicked && <NonProfessionalLicenseAlert />}
    </React.Fragment>
  )
}

import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const SidebarCardSection = ({
  title,
  children
}: {
  title: string
  children: React.ReactNode
}): React.ReactElement => {
  return (
    <Box>
      <Typography variant="overline" color="grey.700" display="block" mb={2}>
        {title}
      </Typography>
      {children}
    </Box>
  )
}

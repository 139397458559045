import { ApiVersion } from '../../helpers'
import { ApplicationApi } from '../../ApplicationApi'
import { get } from '../../../../../helpers/network'
import { AnalysisType } from '@/constants/propForAnalysisType'

type SimulationSpec = {
  id: string
  analysis?: { type: AnalysisType }
}

const specSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getSimulationSpecIdsAnalysisTypes: builder.query<SimulationSpec[], { simulationSpecIds: string[] }>({
      queryFn: async ({ simulationSpecIds }) => {
        try {
          const promises = simulationSpecIds.map(async (specId) => {
            const url = `${window.location.origin}/api/${ApiVersion.V1}/spec/simulation/${specId}`

            const response = await get<SimulationSpec>(url)
            if (response.status >= 400 && response.status < 500) {
              return null
            }
            if (response.status >= 500) {
              throw new Error(response.statusText)
            }
            return response.data
          })

          const results = await Promise.all(promises)
          const validResults = results.filter(Boolean)
          return { data: validResults }
        } catch (error) {
          return { error }
        }
      }
    })
  })
})

export const { useGetSimulationSpecIdsAnalysisTypesQuery } = specSlice

import { useSearchProjects } from '../hooks'
import { ProjectDataGridComponents } from '../ProjectDataGrid'
import { InfiniteProjectsPagination } from '../pagination/InfiniteProjectsPagination'
import { NoSearchResultsWarning } from '../NoSearchResultsWarning'
import { ViewMode } from '../../../../store/features/uiSlice/interfaces'
import { getViewingMode } from '../../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../../store/hooks'
import { useCenteredCircularProgress } from '../../../../components/progress/CenteredCircularProgress'
import theme from '../../../../theme/defaultTheme'
import Grid from '@mui/material/Grid'
import * as React from 'react'
import { isEmpty } from 'lodash'

export const SearchedProjectsView = (): React.ReactElement => {
  const displayType = useAppSelector(getViewingMode)
  const { projects, isLoading, hideNextPage } = useSearchProjects()

  useCenteredCircularProgress(isLoading)

  if (isEmpty(projects) && !isLoading) {
    return <NoSearchResultsWarning />
  }

  return (
    <>
      <Grid
        sx={{
          display: `${displayType === ViewMode.GRID ? 'grid' : 'block'}`,
          gridTemplateColumns: 'repeat(auto-fill,minmax(230px, 1fr))',
          gridGap: theme.spacing(3),
          mb: displayType === ViewMode.GRID ? 3 : 0
        }}
      >
        <ProjectDataGridComponents projects={projects} />
      </Grid>
      <InfiniteProjectsPagination projects={projects} isLoading={isLoading} hideNextPage={hideNextPage} />
    </>
  )
}

import { NavigationItemType } from './AiModelSelectionNavigation'
import { PathSelector } from '../../spaces/interfaces'
import { NavigationItemTitle } from '../../navigation/NavigationItemTitle'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { first, isEmpty, last } from 'lodash'
import { setFullPath } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { FolderSelectorNavigationTree } from '@/components/modal/templates/moveProject/NavigationTreePathSelector'
import { useGetFolders } from '@/store/features/apis/slices/folder/helpers'

type PersonalSpaceNavigationItemType = NavigationItemType & {
  personalSpacePath: PathSelector
}

export const PersonalSpaceNavigationItem = ({
  personalSpacePath,
  isSelected,
  isOpen,
  handleOnClick
}: PersonalSpaceNavigationItemType): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const onSelected = (path: PathSelector): void => {
    dispatch(setFullPath(path))
  }

  return (
    <React.Fragment>
      <NavigationItemTitle
        spriteId="rectangle-history"
        label={t('myProjects')}
        onClick={handleOnClick}
        isSelected={isSelected}
      />
      {isOpen && <SubFolders path={personalSpacePath} onSelected={onSelected} />}
    </React.Fragment>
  )
}

const SubFolders = ({
  path,
  onSelected
}: {
  path: PathSelector
  onSelected: (path: PathSelector) => void
}): React.ReactElement => {
  const fullPath = useAppSelector((state) => state.ui.fullPath)
  const { data: folders, isLoading } = useGetFolders({ spaceId: first(path), folderId: last(path) })
  if (isEmpty(folders) || isLoading) {
    return null
  }

  return (
    <>
      {folders.map((folder) => (
        <FolderSelectorNavigationTree
          key={folder.folderId}
          label={folder.name}
          path={[...path, folder.folderId]}
          setSelectedPath={onSelected}
          selectedPath={fullPath}
          expandedPath={fullPath}
        />
      ))}
    </>
  )
}

import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import { ModalTemplateProps } from '@/components/modal/interfaces'
import { ModelStage } from '@/store/features/apis/slices/ai/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '@/components/modal/Modal'
import { Button } from '@/components/button/Button'
import { useGetFilteredModelVersionsQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { VersionsHistory } from '@/components/aiModels/versionsHistory/VersionsHistory'

interface ReleaseHistoryTemplateProps extends ModalTemplateProps {
  title: string
  modelId: string
}

export const ReleaseHistoryTemplate = ({
  title,
  onClose,
  showCloseButton,
  modelId
}: ReleaseHistoryTemplateProps): React.ReactElement => {
  const { t } = useTranslation(['modals'])
  const { data: allReleasedVersion } = useGetFilteredModelVersionsQuery({
    modelUuid: modelId,
    stages: [ModelStage.ARCHIVED, ModelStage.PRODUCTION]
  })

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        <Box border={1} borderColor="border.default" paddingX={3} paddingY={2}>
          <VersionsHistory versions={allReleasedVersion} fullHistory />
        </Box>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('viewAiModelReleaseHistory.actions.close')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import { tableHeaderData } from './ManageTeamsTableData'
import { useGetTeamSpacesTableBodyData } from './hooks'
import { ManageTeamsTableRow } from './ManageTeamsTableRow'
import { SearchSpaces } from './SearchSpaces'
import { EnhancedTable } from '../../../components/table/enhancedTable/EnhancedTable'
import { useIsTeamsAdminAreaEnabled, useRedirectIfPermissionDenied } from '../../../permissions/areas'
import { useCenteredCircularProgress } from '../../../components/progress/CenteredCircularProgress'
import { useAppSelector } from '../../../store/hooks'
import * as React from 'react'
import { Stack, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

export const ManageTeamsDisplay = (): React.ReactElement => {
  const { t } = useTranslation()
  const searchString = useAppSelector<string>((state) => state.ui.searchSpaceString)
  const teamsAdminAreaPermission = useIsTeamsAdminAreaEnabled()
  const [isLoadingPermission, isAllowedToVisitPage] = teamsAdminAreaPermission
  useRedirectIfPermissionDenied([teamsAdminAreaPermission])

  const { tableData: tableBodyData, isLoading: isLoadingTeamSpacesTableBodyData } = useGetTeamSpacesTableBodyData()

  useCenteredCircularProgress(isLoadingTeamSpacesTableBodyData || isLoadingPermission)

  const tableBodyDataFiltered = React.useMemo(() => {
    if (isEmpty(searchString)) {
      return tableBodyData
    }

    return tableBodyData.filter((rowData) => {
      return rowData.tableRowCells.some((cellData) => {
        return cellData.title.toLowerCase().includes(searchString.toLowerCase())
      })
    })
  }, [tableBodyData, searchString])

  if (isLoadingTeamSpacesTableBodyData || isLoadingPermission || !isAllowedToVisitPage) {
    return null
  }

  // https://simscale.atlassian.net/browse/ENG-40868
  // if (isEmpty(tableBodyData)) {
  //   return <ManageTeamsStartPage />
  // }

  return (
    <Stack direction="column" gap={5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" height={48}>
        <Stack direction="row" gap={1} alignItems="flex-end">
          <Typography variant="summaryCardCounterTotal">{tableBodyDataFiltered.length}</Typography>
          <Typography variant="text">{tableBodyDataFiltered.length === 1 ? t('space') : t('spaces')}</Typography>
        </Stack>
        <SearchSpaces />
      </Stack>
      <EnhancedTable
        tableHeaderData={tableHeaderData}
        tableBodyData={tableBodyDataFiltered}
        displayPagination
        rowComponent={ManageTeamsTableRow}
      />
    </Stack>
  )
}

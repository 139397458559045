import { useAppSelector } from '../../../../store/hooks'
import { usePermissionsPayload } from '../../../../hooks/usePermissionsPayload'
import { SpacePermissions } from '../share/interfaces'
import { UserListDataType } from '../../../../containers/list/interfaces'
import * as React from 'react'
import { compact, isNil } from 'lodash'

export const getTeamSpaceUserPermissionsFromCheckboxListData = (): SpacePermissions => {
  const { getPermission } = usePermissionsPayload()
  const checkboxListItems = useAppSelector<UserListDataType[]>((state) => state.ui.checkboxListItems)
  const membersWithPermission = React.useMemo(
    () => checkboxListItems.filter((item) => !isNil(item.permission)),
    [checkboxListItems]
  )

  const teamSpaceUserPermissions = React.useMemo(() => {
    return membersWithPermission
      ? compact(membersWithPermission.map((item) => getPermission(item.username, item.permission)))
      : []
  }, [checkboxListItems])

  return { permissions: teamSpaceUserPermissions } as SpacePermissions
}

import { SearchedProjectsView } from '../containers/dashboard/mainDisplay/desktopViews/SearchedProjectsView'
import { FolderedProjectsView } from '../containers/dashboard/mainDisplay/desktopViews/FolderedProjectsView'
import { MainDisplay } from '../containers/dashboard/MainDisplay'
import { RightSidebar } from '../containers/dashboard/rightSidebar/RightSidebar'
import { SharedWithMeView } from '../containers/dashboard/mainDisplay/desktopViews/SharedWithMeView'
import { useIsSearchMode } from '../hooks/useIsSearchMode'
import { useIsSharedWithMePage } from '../hooks/useIsSharedWithMePage'
import { useCurrentPath } from '../hooks/useCurrentPath'
import * as React from 'react'

type PageListType = 'foldered' | 'searched' | 'sharedWithMe'

const ProjectsListPage = ({ children }: { children?: React.ReactNode }): React.ReactElement => {
  const isSearchMode = useIsSearchMode()
  const isSharedWithMePage = useIsSharedWithMePage()
  const { isLoading } = useCurrentPath()

  const previousListType = React.useRef<PageListType | null>(null)
  const listType = React.useMemo(() => {
    if (isLoading) return previousListType.current
    let newListType: PageListType = null
    if (isSearchMode) {
      newListType = 'searched'
    } else if (isSharedWithMePage) {
      newListType = 'sharedWithMe'
    } else {
      newListType = 'foldered'
    }
    previousListType.current = newListType
    return newListType
  }, [isSearchMode, isSharedWithMePage, isLoading])

  return (
    <>
      <MainDisplay isOperationToolbarEnabled>
        {listType === 'foldered' && <FolderedProjectsView />}
        {listType === 'searched' && <SearchedProjectsView />}
        {listType === 'sharedWithMe' && <SharedWithMeView />}
      </MainDisplay>
      <RightSidebar>{children}</RightSidebar>
    </>
  )
}

export default ProjectsListPage

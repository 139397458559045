export enum InviteStatus {
  PENDING_ADMIN_APPROVAL = 'PENDING_ADMIN_APPROVAL',
  PENDING_USER_CONFIRMATION = 'PENDING_USER_CONFIRMATION',
  EXPIRED = 'EXPIRED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export type Invite = {
  inviteId: string
  email: string
  organizationName?: string
  organizationId: string
  status: InviteStatus
  invitedBy?: number
  invitedAt?: string
  approvedBy?: number
  approvedAt?: string
  expiresAt?: string
}

export type InviteResponse = Omit<Invite, 'invitedBy' | 'invitedAt' | 'approvedBy' | 'approvedAt' | 'expiresAt'>

export type InvitesResponse = {
  invites: Invite[]
}

import { MaxRuntimeRange } from '@/store/features/uiSlice/interfaces'

export const convertRuntimeValue = (value: number, range: MaxRuntimeRange, toSeconds: boolean): number => {
  switch (range) {
    case MaxRuntimeRange.HOURS:
      return toSeconds ? value * 60 * 60 : Math.ceil(value / 60 / 60)
    case MaxRuntimeRange.DAYS:
      return toSeconds ? value * 24 * 60 * 60 : Math.ceil(value / 24 / 60 / 60)
    default:
      throw new Error('Invalid range specified')
  }
}

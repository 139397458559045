import { PathSelector } from './../../containers/spaces/interfaces'
import { openModal } from '../../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../../store/hooks'
import { OperationWithPermission } from '../interfaces'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { useGetFolderDetails, useGetSpaceDetails } from '../../store/features/apis/slices/folder/helpers'
import { t } from 'i18next'
import { first, last } from 'lodash'

export const useCreateProjectOperation = (targetFolderPath: PathSelector): OperationWithPermission<void> => {
  const dispatch = useAppDispatch()

  const spaceId = first(targetFolderPath)
  const folderId = last(targetFolderPath)
  const isSpace = spaceId === folderId

  const { data: space, isLoading: isLoadingSpace } = useGetSpaceDetails({ spaceId })
  const { data: folder, isLoading: isLoadingFolder } = useGetFolderDetails({ spaceId, folderId })

  const parentPermission = isSpace
    ? space?.requesterPermissions?.canCreateContentInRoot
    : folder?.requesterPermissions?.canCreateContentInFolder

  return [
    (): void => {
      dispatch(
        openModal({
          template: ModalTemplate.CREATE_PROJECT,
          modalProps: { title: t('project.createTitle', { ns: 'modals' }), targetFolderPath }
        })
      )
    },
    parentPermission,
    isSpace ? isLoadingSpace : isLoadingSpace || isLoadingFolder
  ]
}

import { NavigationItemTitle } from '../navigation/NavigationItemTitle'
import { PagePath } from '../../constants'
import { useIsAiAreaEnabled } from '../../permissions/areas'
import { t } from 'i18next'
import * as React from 'react'
import { Divider, Stack } from '@mui/material'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const AiModelTrainingSection = (): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const isAiAreaEnabledResult = useIsAiAreaEnabled()
  const [isLoadingPermission, isAllowedToVisitPage] = isAiAreaEnabledResult

  if (isLoadingPermission || !isAllowedToVisitPage) {
    return null
  }

  return (
    <Stack direction="column" gap={2} pb={4} qa-attribute="aiModel-training-section">
      <Divider />
      <NavigationItemTitle
        spriteId="circle-nodes"
        label={t('aiModelTraining')}
        onClick={(): void => navigate(PagePath.AI_MODELS)}
        tabIndex={TAB_INDEXES.AI_MODEL_TRAINING_SECTION}
      />
    </Stack>
  )
}

import { getQaSelectorText } from '../../helpers/qaSelectors'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setMobileSidebarOpen } from '../../store/features/uiSlice/uiSlice'
import { Box, MenuItem, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { SpriteId } from '@/assets/SpriteMap'

const NavigationItemTitleStyled = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  height: theme.spacing(9),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(3),
  userSelect: 'none',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: `${isSelected ? theme.palette.action.selected : theme.palette.grey['100']}`,
    border: `1px solid ${isSelected ? theme.palette.border.primary : theme.palette.grey['200']}`
  },
  border: `1px solid ${isSelected ? theme.palette.border.primary : 'transparent'}`,
  backgroundColor: isSelected ? theme.palette.action.selected : 'transparent',
  transition: '.3s background, .3s border, .3s color'
}))

export interface NavigationItemTitleProps {
  label: string
  spriteId?: SpriteId
  onClick?: () => void
  isSelected?: boolean
  tabIndex?: number
}

export const NavigationItemTitle = ({
  label,
  spriteId,
  onClick,
  isSelected,
  tabIndex
}: NavigationItemTitleProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const isMobileSidebarOpen = useAppSelector((state) => state.ui.mobileSidebarOpen)

  const handleOnClick = React.useCallback(() => {
    if (isMobileSidebarOpen) {
      dispatch(setMobileSidebarOpen(!isMobileSidebarOpen))
    }
    onClick()
  }, [isMobileSidebarOpen])

  return (
    <NavigationItemTitleStyled
      isSelected={isSelected}
      onClick={handleOnClick}
      qa-attribute={`${getQaSelectorText(label)}--space-tree-item`}
      tabIndex={tabIndex}
    >
      {spriteId && (
        <Box sx={{ width: 20, height: 20 }}>
          {<SpriteIcon spriteId={spriteId} width={13} height={13} color="grey.600" />}
        </Box>
      )}
      <Typography
        sx={{ whiteSpace: 'nowrap' }}
        variant="textBoldWithEllipsis"
        textTransform="uppercase"
        color={isSelected ? 'primary' : 'secondary'}
      >
        {label}
      </Typography>
    </NavigationItemTitleStyled>
  )
}

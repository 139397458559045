import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ModelVersionsList, ModelVersionsListSkeleton } from '@/components/aiModels/versionsList/ModelVersionsList'
import { useGetFilteredModelVersionsQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { ModelStage } from '@/store/features/apis/slices/ai/interfaces'

export const ModelReleasedVersionsSkeleton = (): React.ReactElement => {
  return (
    <Box>
      <Skeleton variant="rounded" width={150} height={20} animation="wave" />
      <ModelVersionsListSkeleton itemCount={1} mt={5} />
    </Box>
  )
}

export const ModelReleasedVersions = ({ modelId }: { modelId: string }): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const { data: modelVersions, isLoading } = useGetFilteredModelVersionsQuery({
    modelUuid: modelId,
    stages: [ModelStage.PRODUCTION]
  })

  if (isLoading) {
    return <ModelReleasedVersionsSkeleton />
  }

  if (!isLoading && (isNil(modelVersions) || isEmpty(modelVersions))) {
    return null
  }

  return (
    <Box qa-attribute="aiModel--releasedVersions">
      <Typography variant="h4" fontWeight="bold" color="grey.700">
        {t('modelDetails.releasedVersions')}
      </Typography>
      <ModelVersionsList versions={modelVersions} defaultSelectedVersion={modelVersions[0]?.version} mt={5} />
    </Box>
  )
}

import { TableRowProps } from '../components/table/enhancedTable/interfaces'
import { BudgetType, QuotaBudget } from '../store/features/apis/slices/organization/interfaces'
import { Job } from '../store/features/apis/slices/user/interfaces'
import { isNil } from 'lodash'

type CoreHoursType = 'CPU' | 'GPU'

const GPU_FACTOR = 50 // 1 GPU core hour is equivalent to 50 CPU core hours

export const getCoreHours = (job: Job): { hours: number; formatted: string } => {
  const hasNewFields = !isNil(job.cpuComputingSeconds) && !isNil(job.gpuComputingSeconds)

  const { coreHours, isGPU } = hasNewFields ? newGetCoreHours(job) : oldGetCoreHours(job)

  const formattedCoreHours = coreHours === Infinity ? 'Unrestricted' : coreHours.toFixed(2)
  return {
    hours: coreHours,
    formatted: `${formattedCoreHours} ${isGPU ? 'GPU' : 'CPU'}h`
  }
}

const newGetCoreHours = (job: Job): { coreHours: number; isGPU: boolean } => {
  return {
    coreHours: (job.gpuComputingSeconds || job.cpuComputingSeconds) / 3600,
    isGPU: job.gpuComputingSeconds > 0
  }
}

const oldGetCoreHours = (job: Job): { coreHours: number; isGPU: boolean } => {
  const isGPU = ['PACEFISH', 'WIND_COMFORT'].includes(job.toolType)
  const scaleFactor = isGPU ? GPU_FACTOR : 1
  const coreHours = millisecondsToHours(job.computingTime) / scaleFactor

  return { coreHours, isGPU }
}

export const getFormattedQuotaBudget = (type: CoreHoursType, quotaBudget: QuotaBudget): string => {
  if (quotaBudget.budgetType === BudgetType.UNLIMITED) {
    return `Unrestricted ${type}`
  }
  const quantity = (quotaBudget.budgetType === BudgetType.ZERO ? 0 : quotaBudget.quantity) / 3600
  return `${Math.floor(quantity)} ${type}`
}

export const getTotalCoreHours = (data: TableRowProps[], type: CoreHoursType): number => {
  return data
    .filter((job) => job.tableRowCells[5].formattedValue?.toString().includes(`${type}h`))
    .reduce((acc, job) => acc + parseFloat(job.tableRowCells[5].formattedValue?.toString().split(' ')?.[0]), 0)
}

export const milisecondsToMinutes = (miliseconds: number): number => miliseconds / 60000
const millisecondsToHours = (miliseconds: number): number => miliseconds / 3600000

import { ModalInputAutocomplete } from '../../components/modal/inputComponents/ModalInputAutocomplete'
import useStateIfMounted from '../../hooks/useStateIfMounted'
import { useDebounce } from '../../hooks/useDebounce'
import { FormRow } from '../../components/modal/inputComponents/FormRow'
import { useGetProjectsTagsQuery } from '../../store/features/apis/slices/project/projectsSlice'
import { useTranslation } from 'react-i18next'
import * as React from 'react'

interface ProjectTagsAutocompleteProps {
  tags: string[]
  onChange: (tags: string[]) => void
  isDisabled?: boolean
}

export const ProjectTagsAutocomplete = ({
  tags,
  onChange,
  isDisabled
}: ProjectTagsAutocompleteProps): React.ReactElement => {
  const { t } = useTranslation(['modals'])
  const [tagInputValue, setTagInputValue] = useStateIfMounted<string>('')
  const [debouncedTagInputValue, setDebouncedValue] = useDebounce(tagInputValue, 500)
  const { data: fetchedTags } = useGetProjectsTagsQuery({ prefix: debouncedTagInputValue, limit: 10 })
  const fetchedTagsMap = fetchedTags?.map((item) => item.name) ?? []
  const canAddTag = tags.length < 12

  return (
    <FormRow label={t('project.placeholders.tags')}>
      <ModalInputAutocomplete
        options={fetchedTagsMap}
        values={tags}
        onChange={(tags): void => {
          setTagInputValue('')
          setDebouncedValue('')
          onChange(tags)
        }}
        onInputChange={(inputValue): void => {
          setTagInputValue(inputValue)
        }}
        label={canAddTag ? t('project.placeholders.tags') : undefined}
        isDisabled={isDisabled || !canAddTag}
      />
    </FormRow>
  )
}

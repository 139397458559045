import { OrganizationQuotaWidget } from './OrganizationQuotaWidget'
import { IndividualQuotaWidget } from './IndividualQuotaWidget'
import { useIsInOrganization } from '../../permissions/areas'
import { useAttributes } from '../../hooks/newWorld'
import * as React from 'react'
import Box from '@mui/material/Box'

export const UserQuotaWidget = (): React.ReactElement => {
  const { data: isInOrganization, isLoading: isLoadingIsInOrganization } = useIsInOrganization()
  const { data: attributes, isLoading: isLoadingAttributes } = useAttributes()

  if (isLoadingIsInOrganization || isLoadingAttributes) {
    return null
  }

  return (
    <Box p={4} pb={6}>
      {isInOrganization && attributes?.isProfessional ? <OrganizationQuotaWidget /> : <IndividualQuotaWidget />}
    </Box>
  )
}

const functionsToOmitFromStackTrace = ['createStackTrace', 'prepareAndSendLogData', 'sendLog']

export const createStackTrace = (subtractFromStack: number = 0): string => {
  if (functionsToOmitFromStackTrace.some((fn) => new Error().stack?.includes(fn))) {
    return ''
  }

  return new Error().stack
    ?.split('\n')
    .filter((line) => line !== 'Error')
    .slice(subtractFromStack)
    .join('\n')
}

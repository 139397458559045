import { TeamSpaceMembersSelection } from '../../../../containers/list/TeamSpaceMembersSelection'
import * as React from 'react'
import { Paper, Popper } from '@mui/material'
import { isNil } from 'lodash'

type SelectMembersType = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchString: string
  searchMemberRef: HTMLInputElement
  onConfirmCallback?: () => void
}

export const SelectMembers = ({
  isOpen,
  setIsOpen,
  searchString,
  onConfirmCallback,
  searchMemberRef
}: SelectMembersType): React.ReactElement => {
  const popperRef = React.useRef(null)

  const handleClickOutside = React.useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent) => {
    const target = event.target as HTMLElement
    if (popperRef.current && !popperRef.current.contains(target) && target.id !== 'search-team-members') {
      setIsOpen(false)
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    !isNil(searchMemberRef) && (
      <Popper
        id="members-list-popper"
        open={isOpen}
        anchorEl={searchMemberRef}
        ref={popperRef}
        placement="bottom-start"
        sx={{ zIndex: 1300, width: 424 }}
      >
        <Paper elevation={3}>
          <TeamSpaceMembersSelection searchString={searchString} onConfirmCallback={onConfirmCallback} />
        </Paper>
      </Popper>
    )
  )
}

import { apiTags } from './../../apiTags'
import { Space, TeamSpaceProps } from './interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { SpacePermissions } from '../../../../../components/modal/templates/share/interfaces'

const spaceSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    createSpace: builder.mutation<
      Space,
      { organizationId: string; payload: TeamSpaceProps; skipCacheInvalidation?: boolean }
    >({
      query: ({ organizationId, payload }) => ({
        url: `api/v1/organizations/${organizationId}/spaces`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, _error, { skipCacheInvalidation }) =>
        skipCacheInvalidation ? [] : [{ type: apiTags.SPACES }]
    }),
    deleteSpace: builder.mutation<void, { organizationId: string; spaceId: string }>({
      query: ({ organizationId, spaceId }) => ({
        url: `api/v1/organizations/${organizationId}/spaces/${spaceId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: apiTags.SPACES }]
    }),
    getSpaceDetails: builder.query<Space, { spaceId: string }>({
      query: ({ spaceId }) => `api/v1/spaces/${spaceId}`,
      transformResponse: (response: Space) => {
        if (response.spaceType === 'PERSONAL') {
          response.name = 'My Projects' // hardcoded name for default space
          return response
        }

        return response
      },
      providesTags: (_result, _error, { spaceId }) => [{ type: apiTags.SPACE_DETAILS, spaceId }]
    }),
    editSpace: builder.mutation<Space, { spaceId: string; payload: TeamSpaceProps; skipCacheInvalidation?: boolean }>({
      query: ({ spaceId, payload }) => {
        return {
          url: `api/v1/spaces/${spaceId}`,
          method: 'PUT',
          body: payload
        }
      },
      invalidatesTags: (_result, _error, { spaceId, skipCacheInvalidation }) => {
        if (skipCacheInvalidation) {
          return []
        } else {
          return [{ type: apiTags.SPACES }, { type: apiTags.SPACE_DETAILS, spaceId }]
        }
      }
    }),
    getSpaceUsersPermissions: builder.query<SpacePermissions, { spaceId: string }>({
      query: ({ spaceId }) => {
        return `api/v2/permissions/spaces/${spaceId}`
      },
      providesTags: (_result, _error, { spaceId }) => [{ type: apiTags.SPACE_USERS_PERMISSIONS, spaceId }]
    }),
    setSpaceUsersPermissions: builder.mutation<Permissions, { spaceId: string; permissions: SpacePermissions }>({
      query: ({ spaceId, permissions }) => {
        return {
          url: `api/v2/permissions/spaces/${spaceId}`,
          method: 'PUT',
          body: permissions
        }
      },
      invalidatesTags: (_result, _error, { spaceId }) => [
        { type: apiTags.PROJECT_DETAILS }, // Required because the project action buttons and context menu items depend on project permissions.
        { type: apiTags.SPACES }, // Required because the list of visible space depends by the user permissions.
        { type: apiTags.SPACE_DETAILS, spaceId }, // Required because the space permission flags depends by the user permissions.
        { type: apiTags.FOLDER_DETAILS, spaceId, folderId: 'EVERY_CHILD' }, // Required because the space permission flags is also impacting the folder permissions.
        { type: apiTags.SPACE_USERS_PERMISSIONS, spaceId }
      ]
    })
  })
})

export const {
  useCreateSpaceMutation,
  useEditSpaceMutation,
  useDeleteSpaceMutation,
  useGetSpaceDetailsQuery,
  useGetSpaceUsersPermissionsQuery,
  useSetSpaceUsersPermissionsMutation
} = spaceSlice

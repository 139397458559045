import { getSchemaDefaultValues } from './helpers'
import { SwitchStyled } from '../elements/Switch'
import * as React from 'react'
import { TFunction } from 'react-i18next'
import { SchemaType } from '@/store/features/apis/slices/ai/interfaces'
import { FormRow } from '@/components/modal/inputComponents/FormRow'

type SchemaGeneratedFormProps = {
  schema: SchemaType
  i18nPrefix: string
  t: TFunction
  onChange: (value: Record<string, any>) => void
  showOnly?: string[]
}

export const SchemaGeneratedForm = ({
  schema,
  i18nPrefix,
  t,
  onChange,
  showOnly
}: SchemaGeneratedFormProps): React.ReactElement => {
  const [formValues, setFormValues] = React.useState<Record<string, any>>(getSchemaDefaultValues(schema.properties))

  const schemaPropertiesArray = React.useMemo(
    () =>
      showOnly
        ? Object.keys(schema.properties).filter((key) => showOnly.includes(key))
        : Object.keys(schema.properties),
    [showOnly]
  )

  const handleFormChange = (key: string, value: string | boolean | number): void => {
    setFormValues({ ...formValues, [key]: value })
  }

  React.useEffect(() => {
    onChange(formValues)
  }, [formValues])

  return (
    <>
      {schemaPropertiesArray.map((key) => {
        const property = schema.properties[key]
        return (
          <FormRow key={key} label={t(`${i18nPrefix}.${key}`, property.title)}>
            {property.type === 'boolean' && (
              <SwitchStyled
                onChange={(_, checked): void => handleFormChange(key, checked)}
                checked={formValues[key] ?? false}
              />
            )}
          </FormRow>
        )
      })}
    </>
  )
}

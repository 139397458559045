import { GeneralInformation } from './GeneralInformation'
import { Settings } from './Settings'
import { OutlierIdentification } from './OutlierIdentification'
import * as React from 'react'
import { Stack } from '@mui/material'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  setAiModelTrainingSettingsFormData,
  setAiModelTrainingSettingsIsValid,
  setAiSectionPath
} from '@/store/features/uiSlice/uiSlice'
import { AiSectionPaths } from '@/constants'
import { Model, TrainModelUserOptionsType } from '@/store/features/apis/slices/ai/interfaces'
import { AiModelParameter, MaxRuntimeRange } from '@/store/features/uiSlice/interfaces'
import { useGetTrainingUserOptionsSchemaQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { getSchemaDefaultValues } from '@/components/schemaForm/helpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { isRequired } from '@/helpers/validationHelpers'
import { useValidation } from '@/hooks/useValidation'

export const AiModelDataSettings = ({ model }: { model: Model }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('aiTraining')
  const { data: trainingUserOptionsSchema } = useGetTrainingUserOptionsSchemaQuery(
    { modelUuid: model?.uuid },
    { skip: isNil(model) }
  )
  const aiModelTrainingSettingsFormData = useAppSelector((store) => store.ui.aiModelTrainingSettings?.formData)

  const { validationErrors, setValidationErrors } = useErrorHandler({
    versionName: { valid: true, message: '' },
    maxRuntime: { valid: true, message: '' }
  })
  const { validateField } = useValidation()

  const validateSettingsForm = React.useCallback((): void => {
    if (aiModelTrainingSettingsFormData?.isDirty) {
      const errors = { ...validationErrors }

      errors['versionName'] = validateField(aiModelTrainingSettingsFormData?.versionName, [isRequired])
      errors['maxRuntime'] = { valid: true, message: '' }
      if (isNaN(aiModelTrainingSettingsFormData?.maxRuntime)) {
        errors['maxRuntime'] = { valid: false, message: t('modelSettings.validationErrors.maxRuntime.required') }
      } else if (aiModelTrainingSettingsFormData?.maxRuntime < 3600) {
        errors['maxRuntime'] = {
          valid: false,
          message: t('modelSettings.validationErrors.maxRuntime.min', { hours: 1 })
        }
      } else if (aiModelTrainingSettingsFormData?.maxRuntime > 7 * 86400) {
        errors['maxRuntime'] = {
          valid: false,
          message: t('modelSettings.validationErrors.maxRuntime.max', { days: 7 })
        }
      }

      setValidationErrors(errors)
      const isValid = Object.values(errors).every((error) => error.valid)
      dispatch(setAiModelTrainingSettingsIsValid(isValid))
    }
  }, [aiModelTrainingSettingsFormData, t, setValidationErrors])

  React.useEffect(() => {
    validateSettingsForm()
  }, [aiModelTrainingSettingsFormData])

  React.useEffect(() => {
    dispatch(
      setAiModelTrainingSettingsFormData({
        versionName: '',
        maxRuntime: 86400,
        maxRuntimeRange: MaxRuntimeRange.HOURS,
        aiModelParameter: AiModelParameter.DEFAULT,
        userOptions: getSchemaDefaultValues<TrainModelUserOptionsType>(trainingUserOptionsSchema?.properties),
        isDirty: false
      })
    )
  }, [trainingUserOptionsSchema])

  React.useEffect(() => {
    if (!isNil(model)) {
      dispatch(
        setAiSectionPath([
          AiSectionPaths.aiModelTraining,
          { label: model.name, value: model.uuid },
          AiSectionPaths.dataSelection,
          AiSectionPaths.dataSettings
        ])
      )
    }
  }, [model])

  return (
    <Stack direction="column" gap={5} minWidth={800}>
      <GeneralInformation validationErrors={validationErrors} />
      <Settings userOptionsSchema={trainingUserOptionsSchema} validationErrors={validationErrors} />
      <OutlierIdentification />
    </Stack>
  )
}

import { EnhancedTableHeadProps, Order } from './interfaces'
import { HeaderFilterDialog } from './HeaderFilterDialog'
import { SortingIcon } from '../../../containers/dashboard/mainDisplay/RowViewSorter'
import * as React from 'react'
import { Checkbox, Stack, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material'

export const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableHeaderData,
  useRowSelectionCheckbox,
  filters,
  setFilters
}: EnhancedTableHeadProps): React.ReactElement => {
  const handleSortOnClick = React.useCallback((order: Order, orderBy: string) => onRequestSort(order, orderBy), [])

  return (
    <TableHead>
      <TableRow>
        {useRowSelectionCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all items'
              }}
            />
          </TableCell>
        )}
        {tableHeaderData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={headCell.sx}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack direction="row" alignItems="center" flexWrap="nowrap" display="inline-flex" gap={1}>
              {filters?.some(({ id }) => id === headCell.id) && (
                <HeaderFilterDialog
                  id={headCell.id}
                  filter={filters.find(({ id }) => id === headCell.id)}
                  setFilters={setFilters}
                />
              )}
              <TableSortLabel
                active={!headCell.disableSorting}
                hideSortIcon={headCell.disableSorting}
                direction={order}
                onClick={(): void => {
                  handleSortOnClick(order === 'asc' ? 'desc' : 'asc', headCell.id)
                }}
                IconComponent={(): React.ReactElement => (
                  <SortingIcon isActive={orderBy === headCell.id} order={order} sx={{ margin: 0, flexShrink: 0 }} />
                )}
              >
                <Typography
                  variant={headCell.noEllipsis ? 'textBold' : 'textBoldWithEllipsis'}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

import { SpacesSection } from '../../containers/spaces/SpacesSection'
import { AdminSection } from '../admin/AdminSection'
import { useGeneralBlockingError } from '../../hooks/useGeneralBlockingError'
import { BoxWithScrollShadow } from '../../components/BoxWithScrollShadow'
import { AiModelTrainingSection } from '../ai/AiModelTrainingSection'
import { BoxProps } from '@mui/material'
import * as React from 'react'

type NavigationProps = BoxProps

export const Navigation = ({ ...props }: NavigationProps): React.ReactElement => {
  const isBlockingError = useGeneralBlockingError()

  if (isBlockingError) {
    return null
  }

  return (
    <BoxWithScrollShadow {...props}>
      <SpacesSection />
      <AiModelTrainingSection />
      <AdminSection />
    </BoxWithScrollShadow>
  )
}

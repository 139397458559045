import { FormRow } from './FormRow'
import { StyledTooltip } from '../../tooltip/StyledTooltip'
import { getQaSelectorText } from '../../../helpers/qaSelectors'
import { BaseTextFieldProps, TextField } from '@mui/material'
import * as React from 'react'

interface ModalInputTextAreaProps extends BaseTextFieldProps {
  label: string
  placeholder?: string
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  required?: boolean
  tooltip?: string | React.ReactElement
}

export const ModalInputTextArea = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  required,
  tooltip,
  ...props
}: ModalInputTextAreaProps): React.ReactElement => {
  const qaSelectorText = getQaSelectorText(label)
  return (
    <FormRow label={label} required={required} helperText={helperText} error={error}>
      <StyledTooltip title={tooltip} placement="right">
        <TextField
          fullWidth
          multiline
          minRows={2}
          maxRows={10}
          required={required}
          placeholder={placeholder}
          size="small"
          variant="standard"
          value={value}
          onChange={onChange}
          error={error}
          qa-attribute={`${qaSelectorText}--textfield`}
          {...props}
        />
      </StyledTooltip>
    </FormRow>
  )
}

import { useEffect, useState } from 'react'

type UseScrollShadowProps = {
  scrollElementRef: React.RefObject<Element>
  bottomElementRef: React.RefObject<Element>
}

type ScrollWithShadow = {
  boxShadow: string
  eventHandler: (event: React.UIEvent<Element>) => void
}

export const useScrollShadow = (props?: UseScrollShadowProps): ScrollWithShadow => {
  const [boxShadow, setBoxShadow] = useState('none')

  useEffect(() => {
    onScrollChange()
  }, [
    props?.scrollElementRef?.current?.getBoundingClientRect()?.bottom,
    props?.bottomElementRef?.current?.getBoundingClientRect()?.bottom
  ])

  const handleScrollChange = (): void => {
    const showShadow =
      props?.scrollElementRef?.current?.getBoundingClientRect()?.bottom <
      props?.bottomElementRef?.current?.getBoundingClientRect()?.bottom
    if (showShadow) {
      setBoxShadow('inset 0 -15px 10px -10px rgba(0, 0, 0, 0.12)')
    } else {
      setBoxShadow('none')
    }
  }

  const onScrollChange = (): void => {
    setTimeout(() => {
      handleScrollChange()
    }, 100)
  }

  const eventHandler = (_): void => {
    onScrollChange()
  }

  return { boxShadow, eventHandler }
}

import { TableHeaderCell } from '../../../../components/table/enhancedTable/interfaces'
import { useTranslation } from 'react-i18next'

export const useUsageOverviewHeaders = (): TableHeaderCell[] => {
  const { t } = useTranslation(['profileArea'])

  return [
    {
      id: 'projectName',
      label: t('profile.usageTable.headers.projectName'),
      numeric: false,
      sx: { width: '28.5%' }
    },
    {
      id: 'type',
      label: t('profile.usageTable.headers.type'),
      numeric: false,
      sx: { width: '12.5%' }
    },
    {
      id: 'startTime',
      label: t('profile.usageTable.headers.startTime'),
      numeric: false,
      sx: { width: '12.5%' }
    },
    {
      id: 'duration',
      label: t('profile.usageTable.headers.duration'),
      numeric: false,
      sx: { width: '12.5%' }
    },
    {
      id: 'processors',
      label: t('profile.usageTable.headers.processors'),
      numeric: false,
      sx: { width: '12.5%' }
    },
    {
      id: 'coreHours',
      label: t('profile.usageTable.headers.coreHours'),
      numeric: false,
      sx: { width: '12.5%' }
    },
    {
      id: 'status',
      label: t('profile.usageTable.headers.status'),
      numeric: false,
      sx: { width: 'auto', textAlign: 'right' }
    }
  ]
}

import { FolderCardData } from './interfaces'
import { getHumanReadableDate } from '../../utils/helpers'
import { RowDate } from '../rows/RowDate'
import { Row } from '../rows/Row'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import * as React from 'react'

export const FolderRow = ({
  folder,
  selected,
  onClick,
  onContextMenu,
  className
}: FolderCardData): React.ReactElement => {
  return (
    <Row
      title={folder.name}
      selected={selected}
      onClick={(event): void => {
        onClick(event, folder.folderId)
      }}
      onContextMenu={onContextMenu}
      qa-attribute={`${getQaSelectorText(folder.name)}--folder-row`}
      className={className}
    >
      <RowDate data-testid="folder-date">{getHumanReadableDate(folder.lastModifiedAt)}</RowDate>
    </Row>
  )
}

import { PagePath } from '../constants'
import { useGetProjectDetailsQuery } from '../store/features/apis/slices/project/projectSlice'
import { ApiVersion } from '../store/features/apis/helpers'
import { useLocation, useParams } from 'react-router-dom'
import * as React from 'react'
import { isNil } from 'lodash'

export const useIsSharedWithMePage = (): boolean => {
  const { pathname } = useLocation()
  const path: string = pathname.replace(/^\/|\/$/g, '')
  const { projectId } = useParams<{ projectId?: string }>()
  const project = useGetProjectDetailsQuery({ apiVersion: ApiVersion.V3, projectId }, { skip: isNil(projectId) })

  const isSharedProject: boolean = React.useMemo(() => {
    return project.isSuccess && project.data?.isSharedWithRequester && isNil(project.data?.spaceId)
  }, [project?.isSuccess, project?.data?.isSharedWithRequester, project?.data?.spaceId])

  return path === PagePath.SHARED_WITH_ME || isSharedProject
}

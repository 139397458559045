import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { first, isEmpty, isNil, last } from 'lodash'
import { useAppSelector } from '@/store/hooks'
import { useGetProjectsQuery } from '@/store/features/apis/slices/project/projectsSlice'
import { EnhancedTableWithExternalState } from '@/components/table/enhancedTable/EnhancedTableWithExternalState'
import { useSelectModelTrainingData } from '@/containers/ai/dataSelection/tableHooks/useSelectModelTrainingData'
import { useSelectModelTrainingDataHeader } from '@/containers/ai/dataSelection/tableHooks/useSelectModelTrainingDataHeader'
import { PagePath } from '@/constants'
import { SortingDirection, SortingModeV2 } from '@/store/features/uiSlice/interfaces'

export const MyProjectsSelection = ({ onSelect, onSelectAll, selectedProjects }): React.ReactElement => {
  const { t } = useTranslation('modals')
  const [projectsPerPage, setProjectsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(1)
  const [order, setOrder] = React.useState<SortingDirection>(SortingDirection.DESC)
  const [orderBy, setOrderBy] = React.useState<SortingModeV2>(SortingModeV2.LAST_MODIFIED_AT)

  const fullPath = useAppSelector((state) => state.ui.fullPath)
  const spaceId = first(fullPath)
  const folderId = last(fullPath)

  const isInTeamSpaces = fullPath?.includes(PagePath.TEAM_SPACES)

  const { data, isLoading, isFetching } = useGetProjectsQuery(
    { spaceId, folderId, sortingModeV2: orderBy, sortingDirection: order, targetPage: page, projectsPerPage },
    { skip: isNil(spaceId) }
  )

  const projectIds = React.useMemo(() => data?.items.map((project) => project.projectIdExt), [data])

  const selectedOnPage = React.useMemo(() => {
    if (isNil(projectIds)) {
      return
    }
    return selectedProjects.filter((selectedProjectId: string) => projectIds.includes(selectedProjectId))
  }, [selectedProjects, projectIds])

  const tableHeaderData = useSelectModelTrainingDataHeader()
  const tableBodyData = useSelectModelTrainingData(data?.items)

  const onSelectAllCheckboxClick = (): void => {
    onSelectAll(projectIds)
  }

  if ((isEmpty(tableBodyData) || isInTeamSpaces) && (!isLoading || !isFetching)) {
    return (
      <Stack width="100%" direction="row" justifyContent="center" alignItems="center">
        <Typography>{t('teamSpace.noItemsFound')}</Typography>
      </Stack>
    )
  }

  return (
    <EnhancedTableWithExternalState
      isLoading={isLoading || isFetching}
      tableHeaderData={tableHeaderData}
      tableBodyData={tableBodyData}
      totalTableItemsCount={data?.pagingMetadata.totalItems}
      page={page}
      setPage={setPage}
      rowsPerPage={projectsPerPage}
      setRowsPerPage={setProjectsPerPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      onSelect={onSelect}
      onSelectAll={onSelectAllCheckboxClick}
      selected={selectedOnPage}
      flexGrow={1}
    />
  )
}

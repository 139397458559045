import { AdvancedSettings } from './project/AdvancedSettings'
import { ShareWithSupportBox } from './project/ShareWithSupportBox'
import { PrivacySelector } from './project/PrivacySelector'
import { ProjectDescriptionTooltip, ProjectNameTooltip } from './project/ProjectTooltips'
import { GroupName, PermissionType, ScopeType } from './share/interfaces'
import { PrivateProjectAlert } from './project/PrivateProjectAlert'
import { Button } from '../../button/Button'
import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { ModalTemplateProps } from '../interfaces'
import { useAppDispatch, useAppSelector } from '.././../../store/hooks'
import {
  ValidationResultProps,
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidInput,
  isValidMaximumNumberOfChars,
  isValidRequiredNumberOfChars,
  isValidTagInput,
  itDoesNotStartWithEmptySpace
} from '../../../helpers/validationHelpers'
import { categories } from '../helpers/categoriesHelper'
import { get } from '../../../helpers/network'
import { deleteCookie, getUsername } from '../../../helpers/cookies'
import { addSnackbarToState, closeModal, openModal } from '../../../store/features/uiSlice/uiSlice'
import useStateIfMounted from '../../../hooks/useStateIfMounted'
import { PathSelector } from '../../../containers/spaces/interfaces'
import { useErrorHandler } from '../../../hooks/useErrorHandler'
import { useValidation } from '../../../hooks/useValidation'
import { ModalInputText } from '../inputComponents/ModalInputText'
import { ModalInputTextArea } from '../inputComponents/ModalInputTextArea'
import { ModalInputSelect } from '../inputComponents/ModalInputSelect'
import { ProjectTagsAutocomplete } from '../../../containers/tags/ProjectTagsAutocomplete'
import { usePersonalSpaceId } from '../../../store/features/apis/slices/space/hooks'
import { LegacyProjectPermissions } from '../../../store/features/apis/slices/project/interfaces'
import { filterSearchParams, openProjectInWorkbench } from '../../../helpers/navigation'
import { ModalTemplate } from '../../../store/features/uiSlice/interfaces'
import { isPathAnInvalidSpace } from '../../../store/features/apis/slices/space/helpers'
import {
  useCreateProjectMutation,
  useGetProjectDetailsQuery,
  useGetProjectPermissionsQuery,
  usePostProjectPermissionsMutation,
  useUpdateProjectMutation
} from '../../../store/features/apis/slices/project/projectSlice'
import { useDashboardNavigate } from '../../../hooks/useDashboardNavigate'
import { ApiVersion } from '../../../store/features/apis/helpers'
import { useProjectPreferences } from '../../../hooks/useProjectPreferences'
import * as React from 'react'
import { first, isEmpty, isNil, last } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

interface ProjectModalTemplateProps extends ModalTemplateProps {
  title: string
  projectIdExt?: string
  targetFolderPath?: PathSelector
}

type ShareWithSupportType = {
  ownerName: string
  publicProjectName: string
  sharingPermission?: PermissionType
  sharingPermissionDuration?: Date
}

export const ProjectModalTemplate = ({
  onClose,
  onCancel,
  title,
  projectIdExt,
  targetFolderPath
}: ProjectModalTemplateProps): React.ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { navigate, navigateNoReset } = useDashboardNavigate()
  const dispatch = useAppDispatch()
  const [newProjectIdExt, setNewProjectIdExt] = React.useState<string | undefined>(undefined)
  const [isGettingNewProject, setIsGettingNewProject] = React.useState<boolean>(false)

  const [
    createProject,
    {
      isLoading: isCreateProjectLoading,
      error: createProjectError,
      isError: isCreateProjectError,
      data: locationUrl,
      isSuccess: isCreateProjectSuccess
    }
  ] = useCreateProjectMutation()
  const [
    updateProject,
    {
      isLoading: isUpdateProjectLoading,
      isSuccess: isUpdateProjectSuccess,
      isError: isUpdateProjectError,
      error: updateProjectError
    }
  ] = useUpdateProjectMutation()

  const [
    postProjectPermissions,
    {
      isLoading: isLoadingShareWithSupport,
      isError: isErrorShareWithSupport,
      error: errorShareWithSupport,
      isSuccess: isSuccessShareWithSupport
    }
  ] = usePostProjectPermissionsMutation()

  const initialIsSharedWithSupport = React.useRef(false)

  const { t } = useTranslation(['modals'])

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    projectName: { valid: true, message: '' },
    description: { valid: true, message: '' },
    projectCategory: { valid: true, message: '' }
  })

  const isFormValid = Object.values(validationErrors).every((validation) => validation.valid)

  const { validateField } = useValidation()

  const personalSpaceId = usePersonalSpaceId()

  const isEditModal = !isNil(projectIdExt)

  const {
    data: project,
    isLoading: isGettingProject,
    isError: hasGettingProjectFailed
  } = useGetProjectDetailsQuery({ apiVersion: ApiVersion.V3, projectId: projectIdExt }, { skip: isNil(projectIdExt) })

  const {
    data: fetchedProjectPermissions,
    isFetching: isFetchingProjectPermissions,
    isLoading: isLoadingProjectPermissions
  } = useGetProjectPermissionsQuery(
    { ownerName: project?.ownerName, publicProjectName: project?.publicProjectName },
    { skip: isNil(project) || !project.requesterPermissions?.canWriteProject }
  )

  const { allowPublicProjects, allowPrivateProjects, isLoading: isLoadingProjectPreferences } = useProjectPreferences()

  const isFormDirty = React.useRef(false)

  const modalsQueue = useAppSelector((state) => state.ui.modalsQueue)

  const isLoading = isUpdateProjectLoading || isCreateProjectLoading || isGettingNewProject || isLoadingShareWithSupport
  const disabledSubmit = isLoading || !isFormValid || modalsQueue.length > 1

  const [projectName, setProjectName] = useStateIfMounted<string>('')
  const [publicProjectName, setPublicProjectName] = useStateIfMounted<string>('')
  const [projectDescription, setProjectDescription] = useStateIfMounted<string>('')
  const [projectCategory, setProjectCategory] = useStateIfMounted<string>('')
  const [projectOwnerName, setProjectOwnerName] = useStateIfMounted<string>('')
  const [isSharedWithSupport, setIsSharedWithSupport] = useStateIfMounted<boolean>(false)
  const [defaultUnitSystem, setDefaultUnitSystem] = useStateIfMounted<string>('SI')
  const [tags, setTags] = useStateIfMounted<string[]>([])
  const [privateProject, setPrivateProject] = useStateIfMounted<boolean>(
    !isEditModal ? allowPrivateProjects : undefined
  )
  const [privateProjectMessage, setPrivateProjectMessage] = useStateIfMounted<string>(null)
  const [projectPermissions, setProjectPermissions] = useStateIfMounted<LegacyProjectPermissions>({
    readPreview: !allowPrivateProjects,
    read: !allowPrivateProjects,
    write: false,
    billableAction: false,
    getCopy: !allowPrivateProjects
  })
  const [isSharingPermissionLoaded, setIsSharingPermissionLoaded] = useStateIfMounted<boolean>(false)
  const [sharingPermissionDuration, setSharingPermissionDuration] = useStateIfMounted<Date>()

  const validationRules: ValidationRules = {
    projectName: [isRequired, isNotEmpty, isValidRequiredNumberOfChars(privateProject ? 1 : 5), isValidInput],
    description: privateProject
      ? [itDoesNotStartWithEmptySpace, isValidRequiredNumberOfChars(1), isValidMaximumNumberOfChars(2000)]
      : [
          isRequired,
          isNotEmpty,
          itDoesNotStartWithEmptySpace,
          isValidRequiredNumberOfChars(5),
          isValidMaximumNumberOfChars(2000)
        ],
    projectCategory: !privateProject ? [isRequired] : []
  }

  React.useEffect(() => {
    deleteCookie('simulation.new')
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.delete('new')
    setSearchParams(newSearchParams, { replace: true })
  }, [searchParams])

  React.useEffect(() => {
    if (!isGettingProject && !hasGettingProjectFailed && !isNil(project) && !isLoadingProjectPreferences) {
      setProjectName(project.projectName)

      setProjectDescription(project.description)
      if (!isEmpty(project.categories)) {
        setProjectCategory(project.categories?.[0])
      }
      setTags(project.tags)

      const isPrivateProject = Object.values(project.publicPermission).every((value) => value === false)

      const canCreateBothPublicAndPrivateProjects = allowPublicProjects && allowPrivateProjects
      const shouldBePrivateProject = !allowPublicProjects && allowPrivateProjects

      if (!canCreateBothPublicAndPrivateProjects && shouldBePrivateProject !== isPrivateProject) {
        if (isPrivateProject && !allowPrivateProjects) {
          setPrivateProjectMessage(t('project.privateProjectAlerts.publicProject'))
        }
        if (!isPrivateProject && !allowPublicProjects) {
          setPrivateProjectMessage(t('project.privateProjectAlerts.privateProject'))
        }

        setPrivateProject(shouldBePrivateProject)
        setProjectPermissions({
          readPreview: !shouldBePrivateProject,
          read: !shouldBePrivateProject,
          write: false,
          billableAction: false,
          getCopy: !shouldBePrivateProject
        })
      } else {
        setPrivateProjectMessage(null)
        setPrivateProject(isPrivateProject)
        setProjectPermissions(project.publicPermission)
      }

      setPublicProjectName(project.publicProjectName)
      setProjectOwnerName(project.ownerName)
    }
  }, [
    project,
    isGettingProject,
    hasGettingProjectFailed,
    allowPublicProjects,
    allowPrivateProjects,
    isLoadingProjectPreferences
  ])

  React.useEffect(() => {
    if (!isNil(fetchedProjectPermissions) && !isLoadingProjectPermissions && !isFetchingProjectPermissions) {
      const sharedWithSupport = fetchedProjectPermissions.filter(
        ({ scope: { scopeType, groupName } }) => scopeType === ScopeType.GROUP && groupName === GroupName.SUPPORT
      )
      if (!isEmpty(sharedWithSupport)) {
        // value of initialIsSharedWithSupport it's not mutated by UI
        initialIsSharedWithSupport.current = true
        setIsSharedWithSupport(true)
        const expiresAt = sharedWithSupport[0]?.expiresAt
        if (expiresAt) {
          setSharingPermissionDuration(new Date(expiresAt))
        }
      }
      setIsSharingPermissionLoaded(true)
    }
  }, [fetchedProjectPermissions, isFetchingProjectPermissions, isLoadingProjectPermissions])

  React.useEffect(() => {
    if (isCreateProjectError) {
      handleApiHookErrors(createProjectError)
    }
  }, [isCreateProjectError, createProjectError])

  React.useEffect(() => {
    if (isUpdateProjectError) {
      handleApiHookErrors(updateProjectError)
    }
  }, [isUpdateProjectError, updateProjectError])

  React.useEffect(() => {
    if (isErrorShareWithSupport) {
      handleApiHookErrors(errorShareWithSupport)
    }
  }, [isErrorShareWithSupport, errorShareWithSupport])

  React.useEffect(() => {
    if (isGettingProject || isLoadingProjectPreferences) {
      return
    }

    if (!isEditModal) {
      // setting default value for private project after fetching user capabilities
      setPrivateProject(allowPrivateProjects)
      setProjectPermissions({
        readPreview: !allowPrivateProjects,
        read: !allowPrivateProjects,
        write: false,
        billableAction: false,
        getCopy: !allowPrivateProjects
      })
    }
  }, [allowPrivateProjects, isEditModal, isGettingProject, isLoadingProjectPreferences])

  React.useEffect(() => {
    const isSuccessful =
      isCreateProjectSuccess && (!isSharedWithSupport || isSuccessShareWithSupport) && !isGettingNewProject

    if (isSuccessful && !isNil(newProjectIdExt)) {
      dispatch(closeModal())
      navigate(`/projects/${newProjectIdExt}`)
      openProjectInWorkbench(newProjectIdExt)
    }
  }, [isCreateProjectSuccess, isSharedWithSupport, isSuccessShareWithSupport, newProjectIdExt, isGettingNewProject])

  const handleShareWithSupport = React.useCallback(
    ({ ownerName, publicProjectName, sharingPermission, sharingPermissionDuration }: ShareWithSupportType) => {
      if (!isNil(sharingPermission)) {
        postProjectPermissions({
          ownerName,
          publicProjectName,
          permissions: {
            permissions: [
              {
                scope: {
                  groupName: GroupName.SUPPORT,
                  scopeType: ScopeType.GROUP
                },
                permission: sharingPermission,
                expiresAt: sharingPermissionDuration
              }
            ]
          }
        })
      }
    },
    []
  )

  const handleCreateProjectButton = (): void => {
    if (validateForm()) {
      createProject({
        userName: getUsername(),
        payload: {
          spaceId: isPathAnInvalidSpace(targetFolderPath) ? personalSpaceId : first(targetFolderPath),
          parentFolderId: targetFolderPath.length > 1 ? last(targetFolderPath) : null,
          projectName,
          description: projectDescription,
          categories: !isEmpty(projectCategory) ? [projectCategory] : [],
          publicPermission: projectPermissions,
          measurementSystem: defaultUnitSystem,
          tags: tags
        }
      })
    }
  }

  React.useEffect(() => {
    if (isCreateProjectSuccess) {
      setIsGettingNewProject(true)
      get<{ projectIdExt: string; publicProjectName: string }>(locationUrl)
        .then(async (response) => {
          setNewProjectIdExt(response.data.projectIdExt)
          setIsGettingNewProject(false)

          if (isSharedWithSupport) {
            handleShareWithSupport({
              ownerName: getUsername(),
              publicProjectName: response.data.publicProjectName,
              sharingPermission: PermissionType.COPY,
              sharingPermissionDuration
            })
          }
        })
        .catch(() => {
          setIsGettingNewProject(false)
          dispatch(closeModal())
          dispatch(addSnackbarToState({ severity: 'error', message: t('project.notFound') }))
          return
        })
    }
  }, [isCreateProjectSuccess, locationUrl, sharingPermissionDuration])

  React.useEffect(() => {
    if (isUpdateProjectSuccess && !isLoadingShareWithSupport && !isErrorShareWithSupport) {
      dispatch(closeModal())
      const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
      navigateNoReset(`/projects/${projectIdExt}${filteredParams}`)
    }
  }, [isUpdateProjectSuccess, isLoadingShareWithSupport, isErrorShareWithSupport, searchParams])

  const getSharingPermission = React.useCallback(
    (initialIsSharedWithSupport: boolean, isSharedWithSupport: boolean): PermissionType | undefined => {
      if (isSharedWithSupport && !initialIsSharedWithSupport) {
        return PermissionType.COPY
      }

      if (!isSharedWithSupport && initialIsSharedWithSupport) {
        return PermissionType.REMOVE
      }

      if (isSharedWithSupport && initialIsSharedWithSupport) {
        return PermissionType.COPY
      }

      return undefined
    },
    []
  )

  const handleUpdateProjectButton = async (): Promise<void> => {
    if (validateForm()) {
      handleShareWithSupport({
        ownerName: projectOwnerName,
        publicProjectName,
        sharingPermission: getSharingPermission(initialIsSharedWithSupport.current, isSharedWithSupport),
        sharingPermissionDuration
      })

      updateProject({
        projectIdExt,
        payload: {
          projectName,
          description: projectDescription,
          categories: !isEmpty(projectCategory) ? [projectCategory] : [],
          publicPermission: projectPermissions,
          tags: tags
        }
      })
    }
  }

  const validateForm = (): boolean => {
    const localValidationErrors = { ...validationErrors }

    Object.keys(validationRules).forEach((field) => {
      let value = ''

      // TODO: refactor this, it's ugly, we need to find a better way to get the value of the field, maybe store it in a state for the whole form and get value by field name, or use refs, or react-hook-form
      switch (field) {
        case 'projectName':
          value = projectName
          break
        case 'description':
          value = projectDescription
          break
        case 'projectCategory':
          value = projectCategory
          break
      }

      localValidationErrors[field] = validateField(value, validationRules[field])
    })

    setValidationErrors(localValidationErrors)

    const isFormValid = Object.values(localValidationErrors).every(({ valid }) => valid)

    return isFormValid
  }

  React.useEffect(() => {
    if (isFormDirty.current) {
      validateForm()
    }
  }, [privateProject, projectCategory])

  const handleChange = React.useCallback((event: any, component: string) => {
    isFormDirty.current = true
    const { value, checked } = event.target
    switch (component) {
      case 'projectName':
        setProjectName(value)
        break
      case 'description':
        setProjectDescription(value)
        break
      case 'projectCategory':
        setProjectCategory(value)
        break
      case 'shareWithSupport':
        setIsSharedWithSupport(checked)
        break
      case 'defaultUnitSystem':
        setDefaultUnitSystem(value)
        break
      case 'privateProject':
        const changePermission = (): void => {
          setPrivateProject(checked)
          setProjectPermissions((prevState) => ({
            ...prevState,
            readPreview: !checked,
            read: !checked,
            getCopy: !checked
          }))
        }
        if (!checked) {
          dispatch(
            openModal({
              template: ModalTemplate.CONFIRM_PUBLIC_PROJECT_PERMISSION,
              showCloseButton: true,
              modalProps: {
                title: t('enablePublicProjectPermission.modalTitle', { ns: 'modals' }),
                onConfirm: () => changePermission()
              }
            })
          )
        } else {
          changePermission()
        }
        setIsSharedWithSupport(false)
        break
    }
  }, [])

  const handleValidation = React.useCallback(
    (value: any, component: string) => {
      isFormDirty.current = true
      let validation: ValidationResultProps
      switch (component) {
        case 'projectName':
          validation = validateField(value, validationRules.projectName)
          break
        case 'description':
          validation = validateField(value, validationRules.description)
          break
        case 'projectCategory':
          validation = validateField(value, validationRules.projectCategory)
          break
      }
      if (!isNil(validation)) {
        setValidationErrors((prevState) => ({ ...prevState, [component]: validation }))
      }
    },
    [validationRules]
  )

  const projectCategories = React.useMemo(
    () => Object.entries(categories).map(([value, label]) => ({ value, label })),
    [categories]
  )

  const onTagChange = React.useCallback((tags: string[]): void => {
    const validTags = tags.filter(
      (tag) => validateField(tag, [isNotEmpty, isValidRequiredNumberOfChars(3), isValidTagInput]).valid
    )
    setTags(validTags)
  }, [])

  return (
    <React.Fragment>
      <ModalTitle onClose={onClose}>{title}</ModalTitle>

      <ModalContent
        onSubmit={isEditModal ? handleUpdateProjectButton : handleCreateProjectButton}
        submitDisabled={disabledSubmit ?? true}
      >
        <ModalInputText
          onChange={(event): void => {
            handleChange(event, 'projectName')
          }}
          error={!validationErrors['projectName']?.valid}
          helperText={validationErrors['projectName']?.message}
          label={t('project.fields.projectName')}
          placeholder={t('project.placeholders.projectName')}
          required
          value={projectName}
          autoFocus={true}
          tooltip={<ProjectNameTooltip />}
          tooltipProps={{ disableFocusListener: true }}
          disabled={isLoading}
          onBlur={(): void => handleValidation(projectName, 'projectName')}
        />
        <ModalInputTextArea
          label={t('project.fields.projectDescription')}
          placeholder={t('project.placeholders.projectDescription')}
          required={!privateProject}
          value={projectDescription}
          onChange={(event): void => {
            handleChange(event, 'description')
          }}
          error={!validationErrors['description']?.valid}
          helperText={validationErrors['description']?.message}
          tooltip={<ProjectDescriptionTooltip />}
          disabled={isLoading}
          onBlur={(): void => handleValidation(projectDescription, 'description')}
        />
        <ModalInputSelect
          value={projectCategory}
          placeholder={t('project.placeholders.projectCategory')}
          required={!privateProject}
          label={t('project.fields.projectCategory')}
          onChange={(event): void => {
            handleChange(event, 'projectCategory')
          }}
          options={projectCategories}
          error={!validationErrors['projectCategory']?.valid}
          helperText={validationErrors['projectCategory']?.message}
          disabled={isLoading}
          onBlur={(): void => handleValidation(projectCategory, 'projectCategory')}
        />
        <ProjectTagsAutocomplete tags={tags} onChange={onTagChange} isDisabled={isLoading} />
        {!isEditModal && (
          <AdvancedSettings defaultUnitSystem={defaultUnitSystem} handleChange={handleChange} isDisabled={isLoading} />
        )}
        <PrivacySelector
          isChecked={privateProject}
          isLoading={isLoadingProjectPreferences}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            handleChange(event, 'privateProject')
          }}
          isPrivateProjectDisabled={!allowPrivateProjects}
          isPublicProjectDisabled={!allowPublicProjects}
          isDisabled={isLoading}
        />
        {privateProjectMessage && <PrivateProjectAlert>{privateProjectMessage}</PrivateProjectAlert>}
        {privateProject && (isSharingPermissionLoaded || isNil(projectIdExt)) && (
          <ShareWithSupportBox
            sharingPermissionDuration={sharingPermissionDuration}
            setSharingPermissionDuration={setSharingPermissionDuration}
            isSharedWithSupport={isSharedWithSupport}
            handleChange={handleChange}
            isLoading={isEditModal && (isLoadingProjectPermissions || isFetchingProjectPermissions)}
            isDisabled={isLoading}
          />
        )}
      </ModalContent>

      <ModalActions>
        {isEditModal && (
          <Button onClick={onCancel} color="secondary" variant="outlined" disabled={isLoading}>
            {t('project.actions.cancel')}
          </Button>
        )}
        <Button
          onClick={isEditModal ? handleUpdateProjectButton : handleCreateProjectButton}
          disabled={disabledSubmit}
          color="primary"
          variant="contained"
          isLoading={isLoading}
        >
          {isEditModal ? t('project.actions.editProject') : t('project.actions.createProject')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

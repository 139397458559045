import { MoveElementPayload } from '../../../../../components/modal/templates/moveProject/helpers'
import { ResourceType } from '../../../../../containers/detailsPanel/components/DetailsList'
import { apiTags } from '../../apiTags'
import { ApplicationApi } from '../../ApplicationApi'
import { Folder } from '../folder/interfaces'
import { isNil } from 'lodash'

const genericItemSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    moveItem: builder.mutation<
      void,
      Pick<Folder, 'spaceId'> & Pick<Folder, 'folderId'> & { payload: MoveElementPayload }
    >({
      query: ({ spaceId, folderId, payload }) => ({
        url:
          spaceId === folderId || isNil(folderId)
            ? `api/v1/spaces/${spaceId}/move`
            : `api/v1/spaces/${spaceId}/folders/${folderId}/move`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: (_result, _error, { spaceId, folderId, payload }) => {
        let tags = []

        if (spaceId === folderId || isNil(folderId)) {
          tags = [
            { type: apiTags.SPACE_DETAILS, spaceId },
            { type: apiTags.PROJECTS, spaceId },
            { type: apiTags.FOLDERS_FROM_SPACE },
            { type: apiTags.FOLDERS_FROM_FOLDER }
          ]
        } else {
          tags = [
            // Parent folder
            { type: apiTags.FOLDER_DETAILS, spaceId, folderId },
            { type: apiTags.PROJECTS, spaceId, folderId },
            { type: apiTags.FOLDERS_FROM_SPACE },
            { type: apiTags.FOLDERS_FROM_FOLDER }
          ]
        }
        tags = [
          ...tags,
          ...payload.entries
            .filter((item) => item.type === ResourceType.FOLDER)
            .map((item) => ({ type: apiTags.FOLDER_DETAILS, spaceId, folderId: item.folderId })),

          ...payload.entries
            .filter((item) => item.type === ResourceType.PROJECT)
            .map((item) => ({ type: apiTags.PROJECT_DETAILS, projectIdExt: item.projectIdExt } as any))
        ]

        if (isNil(payload.to.parentFolderId) || payload.to.parentFolderId === payload.to.spaceId) {
          tags = [...tags, { type: apiTags.PROJECTS, spaceId: payload.to.spaceId }]
        } else {
          tags = [...tags, { type: apiTags.PROJECTS, spaceId: payload.to.spaceId, folderId: payload.to.parentFolderId }]
        }

        return tags
      }
    })
  })
})

export const { useMoveItemMutation } = genericItemSlice

export const FORUM_URL = 'https://www.simscale.com/forum/'
export const PRICING_URL = `https://www.simscale.com/product/pricing/`
export const COMMENTS_PER_PAGE = 18

export enum PagePath {
  MY_PROJECTS = 'my-projects',
  TEAM_SPACES = 'team-spaces',
  SHARED_WITH_ME = 'shared-with-me',
  SEARCH = 'search',
  // Organization Pages
  MANAGE_SPACES = 'manage-spaces',
  MANAGE_USERS = 'manage-users',
  MANAGE_INVITATIONS = 'manage-invitations',
  ORGANIZATION_SETTINGS = 'organization-settings',
  // Profile Pages
  PROFILE = 'profile',
  USAGE_OVERVIEW = 'usage_overview',
  API_KEYS = 'api_keys',
  SECURITY = 'security',
  MY_INVITATIONS = 'my_invitations',
  AI_MODELS = 'models'
}

export const AiSectionPaths = {
  aiModelTraining: { label: 'aiBreadcrumbs.aiModelTraining', value: 'models' },
  dataSelection: { label: 'aiBreadcrumbs.dataSelection', value: 'data-selection' },
  dataSettings: { label: 'aiBreadcrumbs.dataSettings', value: 'data-settings' }
}

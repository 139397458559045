declare global {
  interface Window {
    Intercom: any
    intercomSettings: any
  }
}

if (typeof window !== 'undefined') {
  window.Intercom = window.Intercom || ((): void => {})
}

export const intercomUpdate = (data: any): void => {
  if (typeof window !== 'undefined') {
    window.intercomSettings = { ...(window.intercomSettings || {}), ...data }
    window.Intercom('update', data)
  }
}

import { Box, styled } from '@mui/material'

export const RowDate = styled(Box)({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '.8125rem',
  width: '95px',
  textAlign: 'right',
  flexGrow: 0,
  flexShrink: 0
})

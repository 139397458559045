import { LegacyProjectPermissions, ProjectPermissions } from '../store/features/apis/slices/project/interfaces'

interface Operation {
  onClick: (payload?: unknown) => void
  isAllowed: boolean
  isLoading?: boolean
}

export enum OperationId {
  OPEN,
  CREATE,
  EDIT,
  COPY,
  SHARE,
  DELETE
}

export type OperationsList = Partial<Record<OperationId, Operation>>

export type OperationWithPermission<T> = [(payload: T) => void, boolean, boolean?]

export interface ShareOperationPayload {
  ownerName: string
  publicProjectName: string
  projectName: string
  requesterPermissions: ProjectPermissions
  publicPermission: LegacyProjectPermissions
}

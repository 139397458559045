import theme from '../../theme/defaultTheme'
import { getCurrentProjectPage } from '../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../store/hooks'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

export const NoSharedProjectWarning = (): React.ReactElement => {
  const { t } = useTranslation(['detailsPanel', 'translation'])
  const currentProjectPage = useAppSelector(getCurrentProjectPage)
  const isInFirstPage = currentProjectPage === 1
  const { currentPath, isLoading } = useCurrentPath()

  if (isLoading || isEmpty(currentPath)) {
    return null
  }

  return (
    <Alert icon={false} severity="info">
      <Typography variant="text" color={theme.palette.text.primary}>
        {isInFirstPage
          ? t('noSharedProjects', { ns: 'detailsPanel' })
          : t('noProjectsOnThisPage', { ns: 'translation' })}
      </Typography>
    </Alert>
  )
}

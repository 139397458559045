import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { SidebarCard } from '@/components/aiModels/cards/SidebarCard'
import { Button } from '@/components/button/Button'
import { VersionsHistory, VersionsHistorySkeleton } from '@/components/aiModels/versionsHistory/VersionsHistory'
import { useGetFilteredModelVersionsQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { ModelStage } from '@/store/features/apis/slices/ai/interfaces'
import { openModal } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'

const Actions = ({ modelId }: { modelId: string }): React.ReactElement => {
  const { t } = useTranslation(['aiTraining', 'modals'])
  const dispatch = useAppDispatch()

  const handleFullHistoryClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.RELEASE_HISTORY,
        showCloseButton: true,
        modalProps: {
          title: t('viewAiModelReleaseHistory.title', { ns: 'modals' }),
          modelId: modelId,
          size: 'xl'
        }
      })
    )
  }, [dispatch, modelId])

  return (
    <Button variant="outlined" color="primary" onClick={handleFullHistoryClick}>
      {t('modelDetails.actions.seeFullHistory')}
    </Button>
  )
}

export const ModelHistorySkeleton = (): React.ReactElement => {
  return (
    <SidebarCard isLoading>
      <VersionsHistorySkeleton />
    </SidebarCard>
  )
}

export const ModelHistory = ({ modelId }: { modelId: string }): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])

  const { data: modelVersionReleases, isLoading } = useGetFilteredModelVersionsQuery({
    modelUuid: modelId,
    stages: [ModelStage.ARCHIVED, ModelStage.PRODUCTION],
    limit: 5
  })

  if (isLoading) {
    return <ModelHistorySkeleton />
  }
  if (isNil(modelVersionReleases) || isEmpty(modelVersionReleases)) {
    return null
  }

  return (
    <SidebarCard
      title={t('modelDetails.releaseHistory')}
      actions={<Actions modelId={modelId} />}
      isLoading={isLoading}
      qa-attribute="aiModel--section--history"
    >
      <VersionsHistory versions={modelVersionReleases} />
    </SidebarCard>
  )
}

import { NavigationItemType } from './AiModelSelectionNavigation'
import { NavigationItemTitle } from '../../navigation/NavigationItemTitle'
import { t } from 'i18next'
import * as React from 'react'

export const SharedWithMeNavigationItem = ({ isSelected, handleOnClick }: NavigationItemType): React.ReactElement => {
  return (
    <NavigationItemTitle
      spriteId="share-nodes"
      label={t('sharedWithMe')}
      onClick={handleOnClick}
      isSelected={isSelected}
    />
  )
}

import { AiModelTraining } from '../../containers/ai/AiModelTraining'
import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { useIsAiAreaEnabled, useRedirectIfPermissionDenied } from '../../permissions/areas'
import * as React from 'react'
import { Stack } from '@mui/material'
import { AiModelTrainingBreadcrumbs } from '@/containers/ai/breadcrumbs/AiModelTrainingBreadcrumbs'

const AiModelTrainingPage = (): React.ReactElement => {
  const permissionAccess = useIsAiAreaEnabled()
  const [isLoading, hasAccess] = permissionAccess
  useRedirectIfPermissionDenied([permissionAccess])
  if (isLoading || !hasAccess) {
    return
  }

  return (
    <MainDisplay>
      <Stack direction="column" gap={8} pt={4}>
        <AiModelTrainingBreadcrumbs />
        <AiModelTraining />
      </Stack>
    </MainDisplay>
  )
}

export default AiModelTrainingPage

import * as React from 'react'
import { isNil } from 'lodash'
import { useGetModelQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { getCookie } from '@/helpers/cookies'
import { useErrorHandler } from '@/hooks/useErrorHandler'

export const useCanCreateNewModelVersion = (modelUuid: string): boolean => {
  const { handleApiHookErrors } = useErrorHandler()
  const { uid } = getCookie()
  const { data: model, isLoading, isError, error } = useGetModelQuery({ modelUuid }, { skip: isNil(modelUuid) })

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  const canCreateNewVersion = uid === model?.creator_user_id

  if (isLoading || isError) {
    return false
  }

  return canCreateNewVersion
}

import { StyledCircularProgress } from '../components/progress/CenteredCircularProgress'
import { PagePath } from '../constants'
import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const LandingPage = (): React.ReactElement => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    navigate(`/${PagePath.MY_PROJECTS}?${searchParams.toString()}`, { replace: true })
  }, [navigate, searchParams])

  return <StyledCircularProgress />
}

export default LandingPage

import { FormRow } from './FormRow'
import { StyledTooltip } from '../../tooltip/StyledTooltip'
import { getQaSelectorText } from '../../../helpers/qaSelectors'
import { BaseTextFieldProps, CircularProgress, Stack, TextField } from '@mui/material'
import * as React from 'react'

interface ModalInputTextProps extends BaseTextFieldProps {
  label?: string
  placeholder?: string
  value: string | number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  helperText?: string
  required?: boolean
  tooltip?: string | React.ReactElement
  tooltipProps?: {
    disableFocusListener?: boolean
  }
  qaSelectorText?: string
  isLoading?: boolean
}

export const ModalInputText = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  required,
  tooltip,
  isLoading,
  tooltipProps,
  qaSelectorText = getQaSelectorText(label),
  ...props
}: ModalInputTextProps): React.ReactElement => {
  return (
    <FormRow label={label} required={required} helperText={helperText} error={error}>
      {isLoading ? (
        <Stack alignItems="center">
          <CircularProgress size="28px" />
        </Stack>
      ) : (
        <StyledTooltip title={tooltip} placement="right" {...tooltipProps}>
          <TextField
            autoComplete="off"
            fullWidth
            required={required}
            placeholder={placeholder}
            size="small"
            variant="standard"
            value={value}
            onChange={onChange}
            error={error}
            qa-attribute={`${qaSelectorText}--input`}
            {...props}
          />
        </StyledTooltip>
      )}
    </FormRow>
  )
}

import { ResourceType } from '../../containers/detailsPanel/components/DetailsList'
import { ViewMode } from '../../store/features/uiSlice/interfaces'
import * as React from 'react'
import { styled } from '@mui/system'
import { Box, Grid, Typography } from '@mui/material'

interface CreateComponentPlaceholderProps {
  onClick: () => void
  displayType: ViewMode
  label: string
  resourceType: ResourceType
}

const getMinimalHeight = (resourceType: ResourceType, displayType: ViewMode): string => {
  if (displayType === ViewMode.GRID) {
    return resourceType === ResourceType.FOLDER ? '58px' : '263px'
  } else {
    return '48px'
  }
}

const CreateComponentPlaceholderStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'resourceType' && prop !== 'displayType'
})<Partial<CreateComponentPlaceholderProps>>(({ theme, resourceType, displayType }) => ({
  minHeight: getMinimalHeight(resourceType, displayType),
  border: `1px dashed ${theme.palette.primary['300']}`,
  borderRadius: '4px',
  backgroundColor: theme.palette.background.default,
  ':hover': {
    backgroundColor: theme.palette.action.hover
  },
  marginBlock: displayType === ViewMode.LIST ? theme.spacing(2) : '0',
  cursor: 'pointer',
  transition: '.3s background'
}))

export const PlaceholderBox = ({
  displayType,
  onClick,
  label,
  resourceType
}: CreateComponentPlaceholderProps): React.ReactElement => {
  const isListMode = displayType === ViewMode.LIST

  return (
    <Grid item {...(isListMode && { xs: 12, justifyContent: 'center' })}>
      <CreateComponentPlaceholderStyled
        resourceType={resourceType}
        displayType={displayType}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
        qa-attribute={`create-component-placeholder--${resourceType.toLocaleLowerCase()}-${ViewMode[
          displayType
        ].toLocaleLowerCase()}`}
      >
        <Typography
          variant="h2"
          component="a"
          color="primary.700"
          align="center"
          sx={{ ':hover': { textDecoration: 'none' } }}
        >
          {label}
        </Typography>
      </CreateComponentPlaceholderStyled>
    </Grid>
  )
}

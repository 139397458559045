import { getUsername } from '../helpers/cookies'
import { OrganizationAttributes } from '../store/features/apis/slices/organization/interfaces'
import {
  useGetOrganizationV2AttributesQuery,
  useGetOrganizationV2Query
} from '../store/features/apis/slices/organization/organizationSlice'
import { useGetUserQuery } from '../store/features/apis/slices/user/userSlice'
import { isNil } from 'lodash'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'

type UseOrganizationIdProps = {
  skipCondition?: boolean
}

// new world organization id
export const useOrganizationId = (props?: UseOrganizationIdProps): { data: string; isLoading: boolean } => {
  const username = getUsername()
  const { data: user, isLoading } = useGetUserQuery({ username }, { skip: isNil(username) && props?.skipCondition })

  return { data: user?.organizationInfo.organizationId, isLoading: isLoading }
}

export const useOrganizationName = (props?: { skipCondition: boolean }): { data: string; isLoading: boolean } => {
  const skipCondition = props?.skipCondition ?? false

  const { data: organizationId, isLoading: isLoadingOrganization } = useOrganizationId({ skipCondition })
  const { data: organization, isLoading } = useGetOrganizationV2Query(
    { organizationId },
    { skip: isNil(organizationId) || skipCondition || isLoadingOrganization }
  )

  return { data: organization?.name, isLoading: isLoading || isLoadingOrganization }
}

export const useAttributes = (): {
  data: OrganizationAttributes
  isLoading: boolean
  isError: boolean
  error: FetchBaseQueryError | SerializedError
} => {
  const username = getUsername()
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery({ username })
  const {
    data: attributes,
    isLoading: isLoadingAttributes,
    isError: isErrorAttributes,
    error: errorAttributes
  } = useGetOrganizationV2AttributesQuery({ user: user?.uidExt }, { skip: isNil(user?.uidExt) })

  return {
    data: attributes,
    isLoading: isLoadingUser || isLoadingAttributes,
    isError: isErrorAttributes,
    error: errorAttributes
  }
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { OperationWithPermission } from '@/operations/interfaces'
import { ModelStage, ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { useAppDispatch } from '@/store/hooks'
import { openModal } from '@/store/features/uiSlice/uiSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'

export const useReleaseModelVersionOperation = (version: ModelVersion): OperationWithPermission<void> => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()

  const canReleaseModelVersion = version?.stage !== ModelStage.PRODUCTION

  const onClick = React.useCallback(() => {
    // TODO: Here should be a check if the version has documentation, if not, show a warning message asking if the user wants to proceed without it, but documentation is still in discussion, if it will be in the MVP. https://simscale.atlassian.net/browse/ENG-69189
    // So we will just open the release dialog for now
    dispatch(
      openModal({
        template: ModalTemplate.RELEASE_MODEL_VERSION,
        showCloseButton: true,
        modalProps: {
          title: t('releaseModelVersion.title'),
          version
        }
      })
    )
  }, [version])

  return [onClick, canReleaseModelVersion]
}

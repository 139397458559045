import { PermissionType, ScopeType, SpacePermission } from '../modal/templates/share/interfaces'
import { StyledTooltip } from '../tooltip/StyledTooltip'
import { FullProject } from '../../store/features/apis/slices/project/interfaces'
import { UserAvatarPicture } from '../../containers/user/UserAvatarPicture'
import { useGetProjectPermissionsQuery } from '../../store/features/apis/slices/project/projectSlice'
import * as React from 'react'
import { styled } from '@mui/system'
import { isEmpty, isNil } from 'lodash'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const iconEarth = require('../../assets/svg/earth.svg')

const PublicProjectIconStyled = styled('div')(({ theme }) => ({
  flexShrink: '0',
  width: '28px',
  height: '28px',
  border: `1px solid ${theme.palette.grey['200']}`,
  borderRadius: '50%',
  backgroundColor: theme.palette.grey['700'],
  backgroundImage: `url(${iconEarth})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
}))

const PublicProjectIcon = (): React.ReactElement => {
  const { t } = useTranslation(['tooltips'])

  return (
    <StyledTooltip title={t('public', { ns: 'tooltips' })}>
      <PublicProjectIconStyled />
    </StyledTooltip>
  )
}

const VisibleUsers = ({ visibleUsers }: { visibleUsers: string[] }): React.ReactElement => {
  return (
    <Stack direction="row-reverse">
      {visibleUsers
        .map((username: string) => (
          <UserAvatarPicture key={username} userName={username} addMarginLeft showUsernameTooltip />
        ))
        .reverse()}
    </Stack>
  )
}

interface AdditionalUsersProps {
  restOfTheUsernames: string[]
}

export const AdditionalUsers = ({ restOfTheUsernames }: AdditionalUsersProps): React.ReactElement => {
  const MAX_VISIBLE_USERS = 5

  const visibleUsers = restOfTheUsernames.slice(0, MAX_VISIBLE_USERS)
  const remainingUsersCount = restOfTheUsernames.length - MAX_VISIBLE_USERS

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <VisibleUsers visibleUsers={visibleUsers} />
      {remainingUsersCount > 0 && (
        <Typography variant="title" color="grey.700">
          +{remainingUsersCount}
        </Typography>
      )}
    </Stack>
  )
}

interface SharedWithUsersProps {
  permissions: SpacePermission[]
  publicProject: boolean
}

const SharedWithUsers = ({ permissions, publicProject }: SharedWithUsersProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')
  let ownerUsername: string
  const restOfTheUsernames: string[] = []
  permissions.forEach((permission) => {
    if (permission.scope.scopeType === ScopeType.USER && !isNil(permission.scope?.username)) {
      const username = permission.scope.username
      if (permission.permission === PermissionType.FULL_ACCESS) {
        ownerUsername = username
      } else {
        restOfTheUsernames.push(username)
      }
    }
  })
  return isEmpty(restOfTheUsernames) ? (
    <React.Fragment>
      {!publicProject && (
        <Typography variant="subtitle" color="grey.700">
          {t('notShared')}
        </Typography>
      )}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <UserAvatarPicture userName={ownerUsername} showUsernameTooltip />
      {!isEmpty(restOfTheUsernames) && <AdditionalUsers restOfTheUsernames={restOfTheUsernames} />}
    </React.Fragment>
  )
}

interface SharedWithProps {
  projectData?: FullProject
}

export const SharedWith = ({ projectData }: SharedWithProps): React.ReactElement => {
  const { t } = useTranslation('detailsPanel')

  const {
    data: projectPermissions,
    isLoading,
    isFetching,
    isUninitialized
  } = useGetProjectPermissionsQuery(
    { ownerName: projectData.ownerName, publicProjectName: projectData.publicProjectName },
    { skip: isNil(projectData) || !projectData?.requesterPermissions?.canWriteProject }
  )

  if (isLoading || isFetching) {
    return (
      <Stack alignItems="center">
        <CircularProgress size="28px" />
      </Stack>
    )
  }

  if (isNil(projectData) || isNil(projectPermissions) || isUninitialized) {
    return null
  }

  return (
    <Box sx={{ margin: '20px 0px 0px 0' }} qa-attribute="share-with">
      <Typography variant="title" color="text.primary" paragraph sx={{ mb: 3 }}>
        {t('sharedWith')}
      </Typography>
      <Stack direction="row" spacing={2} alignItems="center">
        <SharedWithUsers permissions={projectPermissions} publicProject={projectData?.publicPermission?.read} />
        {projectData?.publicPermission?.read && <PublicProjectIcon />}
      </Stack>
    </Box>
  )
}

import { useTranslation } from 'react-i18next'
import { TableHeaderCell } from '@/components/table/enhancedTable/interfaces'

export const useSelectModelTrainingDataHeader = (): TableHeaderCell[] => {
  const { t } = useTranslation(['adminArea', 'solvers'])
  let tableHeaderData: TableHeaderCell[] = [
    // {
    //   id: 'expandSimulations',
    //   label: '',
    //   numeric: false,
    //   disablePadding: false,
    //   sx: { width: '10%' },
    //   disableSorting: true
    // },
    {
      id: 'name', // important for sorting
      label: t('ai.tableHeader.name'),
      numeric: false,
      disablePadding: false,
      sx: { width: '33%', paddingLeft: '8px' }
    },
    // commented out due to https://simscale.atlassian.net/browse/ENG-69340
    // {
    //   id: 'simulationRuns',
    //   label: t('ai.tableHeader.simulationRuns'),
    //   numeric: false,
    //   disablePadding: false,
    //   sx: { width: '33%' }
    // },
    {
      id: 'lastModifiedAt',
      label: t('ai.tableHeader.lastModified'),
      numeric: false,
      disablePadding: false,
      sx: { width: '33%' }
    }
  ]
  return tableHeaderData
}

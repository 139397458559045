import { useErrorHandler } from '../../hooks/useErrorHandler'
import { useAppDispatch } from '../../store/hooks'
import { addSnackbarToState } from '../../store/features/uiSlice/uiSlice'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Box, BoxProps, Typography } from '@mui/material'
import { usePatchModelMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { Model, ModelState } from '@/store/features/apis/slices/ai/interfaces'
import { InternalDistributionSelect } from '@/components/aiModels/internalDistributionSelect/InternalDistributionSelect'
import { useInternalDistributionOperation } from '@/operations/ai/models/updateInternalDistributionOperation'

type InternalDistributionProps = {
  model: Model
} & BoxProps

export const InternalDistribution = ({ model, ...boxProps }: InternalDistributionProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('adminArea')
  const { handleApiHookErrors } = useErrorHandler()

  const [patchModel] = usePatchModelMutation()
  const sharedWithOrganization = model.shared_with_organization
  const [sharedWithOrganizationState, setSharedWithOrganizationState] = React.useState(sharedWithOrganization)
  const canUpdateInternalDistribution = useInternalDistributionOperation(model)

  const handleChange = React.useCallback(
    (updatedSharedWithOrganization: boolean) => {
      setSharedWithOrganizationState(updatedSharedWithOrganization)
      patchModel({ modelUuid: model.uuid, payload: { shared_with_organization: updatedSharedWithOrganization } })
        .unwrap()
        .then(() => {
          dispatch(
            addSnackbarToState({
              severity: 'info',
              message: t('ai.snackbar.updatedSuccessfully')
            })
          )
        })
        .catch((error) => {
          handleApiHookErrors(error)
        })
    },
    [model?.uuid]
  )

  return (
    <Box sx={{ height: '2rem', display: 'flex', alignItems: 'center' }} {...boxProps}>
      {model?.state === ModelState.RELEASED ? (
        <>
          {canUpdateInternalDistribution ? (
            <InternalDistributionSelect sharedWithOrganization={sharedWithOrganizationState} onChange={handleChange} />
          ) : (
            <Typography variant="textItalicWithEllipsis" fontSize="0.875rem">
              {t(`ai.internalDistribution.options.${sharedWithOrganizationState ? 'myOrganization' : 'onlyMe'}`)}
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="textItalicWithEllipsis" fontSize="0.875rem">
          {t('ai.internalDistribution.options.notReleased')}
        </Typography>
      )}
    </Box>
  )
}

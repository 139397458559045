import { SelfUpdatableSettingsRow } from '../../../../components/settingsForm/SelfUpdatableSettingsRow'
import { usePutUserMutation } from '../../../../store/features/apis/slices/user/userSlice'
import {
  isRequired,
  isValidMaximumNumberOfChars,
  itDoesNotStartWithEmptySpace
} from '../../../../helpers/validationHelpers'
import { useValidation } from '../../../../hooks/useValidation'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { isNil } from 'lodash'

export const FirstName = ({ firstName, username }: { firstName: string; username: string }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['profileArea', 'modals'])
  const { validateField } = useValidation()
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [putUser, { isLoading: isPutUserLoading }] = usePutUserMutation()
  const [newFirstName, setNewFirstName] = React.useState<string>(firstName)

  const handleFirstNameChange = useCallback((): void => {
    if (!error) {
      putUser({ username: username, firstName: newFirstName })
        .unwrap()
        .then(() => {
          dispatch(
            addSnackbarToState({
              severity: 'info',
              message: t(`userDataChange.firstNameChangeSuccessful`, { ns: 'modals' })
            })
          )
        })
        .catch(() => {
          dispatch(
            addSnackbarToState({
              severity: 'error',
              message: t(`userDataChange.firstNameChangeFailed`, { ns: 'modals' })
            })
          )
        })
    }
  }, [newFirstName, username, error])

  const handleFirstNameOnChange = useCallback((value: string) => {
    setNewFirstName(value)
    const validation = validateField(value, [isRequired, itDoesNotStartWithEmptySpace, isValidMaximumNumberOfChars(40)])
    if (!isNil(validation)) {
      setError(!validation.valid)
      setErrorMessage(validation.message)
    }
  }, [])

  const handleFirstNameOnKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === 'Enter' && firstName !== newFirstName) {
        handleFirstNameChange()
      }
    },
    [firstName, newFirstName]
  )

  return (
    <SelfUpdatableSettingsRow
      label={t('profile.labels.firstName')}
      value={newFirstName}
      originalValue={firstName}
      required
      disabled={isPutUserLoading}
      onChange={handleFirstNameOnChange}
      onKeyDown={handleFirstNameOnKeyDown}
      error={error}
      helperText={errorMessage}
      isLoading={isPutUserLoading}
      onSubmit={handleFirstNameChange}
      qaAttributePrefix="personal-details"
    />
  )
}

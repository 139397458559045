import { CoreHoursOption, CoreHoursOptions } from './interfaces'
import { UserListDataType } from '../../../../containers/list/interfaces'
import {
  BudgetType,
  QuotaBudget,
  QuotaBudgetsPayload
} from '../../../../store/features/apis/slices/organization/interfaces'

const getQuotaBudgetFromCoreHours = (coreHours: string): QuotaBudget => {
  switch (coreHours) {
    case '0':
    case BudgetType.ZERO:
      return {
        budgetType: BudgetType.ZERO
      }
    case BudgetType.UNLIMITED:
      return {
        budgetType: BudgetType.UNLIMITED
      }
    default:
      return {
        budgetType: BudgetType.LIMITED,
        quantity: Number(coreHours) * 3600 // convert hours to seconds
      }
  }
}

export const generateQuotaBudgetsPayload = (
  users: UserListDataType[],
  cpuHoursString: string,
  gpuHoursString: string
): QuotaBudgetsPayload => {
  return {
    budgets: users.map((user: UserListDataType) => {
      const cpuSeconds = getQuotaBudgetFromCoreHours(cpuHoursString)
      const gpuSeconds = getQuotaBudgetFromCoreHours(gpuHoursString)

      return {
        uidExt: Number(user.id),
        cpuSeconds,
        gpuSeconds
      }
    })
  }
}

export const getCoreHoursOptionAndValue = (budget: QuotaBudget): CoreHoursOption => {
  switch (budget.budgetType) {
    case BudgetType.ZERO:
      return CoreHoursOptions.ZERO
    case BudgetType.UNLIMITED:
      return CoreHoursOptions.UNLIMITED
    default:
      return String(budget.quantity / 3600) // convert seconds to hours
  }
}

import { ViewMode } from '../../../../store/features/uiSlice/interfaces'
import { getViewingMode } from '../../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../../store/hooks'
import { NoSpacesWarning } from '../NoSpacesWarning'
import { SpaceDataGridComponents } from '../SpaceDataGrid'
import { useCenteredCircularProgress } from '../../../../components/progress/CenteredCircularProgress'
import { useGetUserSpacesQuery } from '../../../../store/features/apis/slices/space/spacesSlice'
import * as React from 'react'
import { isEmpty } from 'lodash'
import Grid from '@mui/material/Grid'

export const SpacesView = (): React.ReactElement => {
  const displayType = useAppSelector(getViewingMode)
  const { data, isLoading, isFetching } = useGetUserSpacesQuery()
  const spaces = data?.filter((space) => space.spaceType === 'TEAM')

  useCenteredCircularProgress(isFetching)

  if (isEmpty(spaces) && !isLoading && !isFetching) {
    return <NoSpacesWarning />
  }

  return (
    <Grid
      container
      sx={{
        display: `${displayType === ViewMode.GRID ? 'grid' : 'block'}`,
        gridTemplateColumns: 'repeat(auto-fill,minmax(230px, 1fr))',
        gridGap: '12px',
        mb: displayType === ViewMode.GRID ? 3 : 0
      }}
    >
      <SpaceDataGridComponents spaces={spaces} />
    </Grid>
  )
}

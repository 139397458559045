import { MoveItem } from './interfaces'
import { PathSelector } from '../../../../containers/spaces/interfaces'
import { ResourceType } from '../../../../containers/detailsPanel/components/DetailsList'
import { first, last } from 'lodash'

export interface MoveElementPayload {
  entries: Array<Record<string, string>>
  to: {
    spaceId: string
    parentFolderId: string
  }
}

export const getMoveElementPayload = (items: MoveItem[], selectedPath: PathSelector): MoveElementPayload => {
  const entries = items.map(({ id, type }) => ({
    [type === ResourceType.FOLDER ? 'folderId' : 'projectIdExt']: id,
    type
  }))

  return {
    entries,
    to: {
      spaceId: first(selectedPath),
      parentFolderId: selectedPath.length > 1 ? last(selectedPath) : undefined
    }
  }
}

import { useProjectById } from '../../components/modal/templates/moveProject/hooks'
import { OperationWithPermission } from '../interfaces'
import { FullProject } from '../../store/features/apis/slices/project/interfaces'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { filterSearchParams } from '../../helpers/navigation'
import { useAttributes } from '../../hooks/newWorld'
import { OrganizationAttributes } from '../../store/features/apis/slices/organization/interfaces'
import { useSearchParams } from 'react-router-dom'

export const canCopyProject = (project: FullProject, attributes: OrganizationAttributes): boolean => {
  const isPublicProject = project?.publicPermission.read
  return (isPublicProject || attributes?.isProfessional) && project?.requesterPermissions?.canCopyProject
}

export const useCopyProjectOperation = (projectId: string): OperationWithPermission<void> => {
  const { navigateNoReset } = useDashboardNavigate()
  const { project } = useProjectById(projectId)
  const { data: attributes } = useAttributes()
  const [searchParams] = useSearchParams()

  return [
    (): void => {
      const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
      navigateNoReset(`/projects/${projectId}/copy${filteredParams}`)
    },
    canCopyProject(project, attributes)
  ]
}

import { getQaSelectorText } from '../../helpers/qaSelectors'
import { Box, ListItemIcon, MenuItem, Typography, styled } from '@mui/material'
import * as React from 'react'

type ContextMenuItemIconStyledProps = {
  imageUrl: string
}

const ContextMenuItemIconStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'imageUrl'
})<ContextMenuItemIconStyledProps>(({ imageUrl }) => ({
  width: 20,
  height: 20,
  backgroundImage: `url(${imageUrl})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center'
}))

type ContextMenuItemProps = {
  imageUrl?: string
  label: string
  disabled?: boolean
  onClick?: () => void
}

export const ContextMenuItem = ({ imageUrl, label, onClick, disabled }: ContextMenuItemProps): React.ReactElement => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={onClick}
      qa-attribute={`${getQaSelectorText(label)}--context-menu-item`}
      component="button"
      sx={{ width: '100%' }}
    >
      {imageUrl && (
        <ListItemIcon>
          <ContextMenuItemIconStyled imageUrl={imageUrl} />
        </ListItemIcon>
      )}
      <Typography variant="text">{label}</Typography>
    </MenuItem>
  )
}

import { Button } from '../../../../components/button/Button'
import { Stack, Typography } from '@mui/material'
import * as React from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'

export const UsageOverviewHeader = ({
  csvData,
  totalCPUHours,
  totalGPUHours
}: {
  csvData: string
  totalCPUHours: number
  totalGPUHours: number
}): React.ReactElement => {
  const { t } = useTranslation(['profileArea'])

  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={2}>
      <Typography variant="tableTitle">{t('profile.usageTable.title')}</Typography>
      <Stack direction="row" alignItems="center" gap={6}>
        <Typography variant="body1" color="text.secondary" qa-attribute="usage-overview--summary">
          {t('profile.usageTable.sumOfCPUHours')}: {totalCPUHours?.toFixed(2)} | {t('profile.usageTable.sumOfGPUHours')}
          : {totalGPUHours?.toFixed(2)}
        </Typography>
        <CSVLink data={csvData} filename="usage-overview.csv">
          <Button variant="contained" color="primary">
            {t('profile.usageTable.downloadCSV')}
          </Button>
        </CSVLink>
      </Stack>
    </Stack>
  )
}

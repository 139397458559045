import { UserListDataType } from './interfaces'
import { sortUsers } from './helpers'
import { UserListItem } from './UserListItem'
import { CheckboxList, OrderBy } from '../../components/list/checkboxList/CheckboxList'
import { useAppDispatch } from '../../store/hooks'
import { setCheckboxListItems } from '../../store/features/uiSlice/uiSlice'
import { PermissionType } from '../../components/modal/templates/share/interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { CircularProgress, Stack } from '@mui/material'

interface TeamMembersListProps {
  userList: UserListDataType[]
  searchString?: string
  disabled?: boolean
  isLoading?: boolean
  showPermissionsDropdown?: boolean
  appendUserListItemComponent?: (user: UserListDataType) => React.ReactNode
}

export const TeamMembersList = ({
  userList,
  searchString,
  disabled,
  isLoading,
  showPermissionsDropdown,
  appendUserListItemComponent
}: TeamMembersListProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const [orderBy, setOrderBy] = React.useState<OrderBy>('asc')
  const [userListData, setUserListData] = React.useState<UserListDataType[]>([])
  const shouldUsePermissions = showPermissionsDropdown

  React.useEffect(() => {
    if (!isLoading) {
      setUserListData(sortUsers([...userList], orderBy))
    }
  }, [isLoading])

  React.useEffect(() => {
    if (!isEmpty(userListData)) {
      dispatch(setCheckboxListItems(userListData))
    }
  }, [userListData])

  const handlePermissionChange = React.useCallback((permission: PermissionType, id: string): void => {
    setUserListData((prevState) => prevState.map((item) => (item.id === id ? { ...item, permission } : item)))
  }, [])

  const handleCheckboxClick = React.useCallback(
    (id: string | number): void => {
      setUserListData((prevState) => {
        return prevState.map((listUser) => {
          if (!shouldUsePermissions) {
            if (listUser.id === id) {
              return { ...listUser, checked: !listUser.checked }
            }
          } else {
            if (listUser.id === id) {
              if (!listUser.checked && (isNil(listUser.permission) || listUser.permission === PermissionType.REMOVE)) {
                return { ...listUser, permission: PermissionType.VIEW, checked: true }
              }
              if (listUser.checked && !isNil(listUser.permission)) {
                return { ...listUser, permission: PermissionType.REMOVE, checked: false }
              }
            }
          }
          return listUser
        })
      })
    },
    [shouldUsePermissions]
  )

  const handleCheckAllClick = React.useCallback(
    (allItemsChecked: boolean): void => {
      setUserListData((prevState) =>
        prevState.map((listUser) => {
          if (!shouldUsePermissions) {
            if (!listUser.disabled) {
              return { ...listUser, checked: !allItemsChecked }
            }
          } else {
            if (listUser.permission === PermissionType.FULL_ACCESS) {
              return listUser
            }
            if (!listUser.disabled) {
              return {
                ...listUser,
                permission: !allItemsChecked ? PermissionType.VIEW : PermissionType.REMOVE,
                checked: !allItemsChecked
              }
            }
          }
          return listUser
        })
      )
    },
    [shouldUsePermissions]
  )

  const handleOrderByClick = React.useCallback(
    (orderBy: OrderBy): void => {
      setOrderBy(orderBy)
      setUserListData(sortUsers([...userListData], orderBy))
    },
    [userListData]
  )

  const filteredItems = React.useMemo(() => {
    if (!isEmpty(userListData)) {
      return userListData.filter(
        (user) =>
          user.email.toLowerCase().includes(searchString) || user.userFullName?.toLowerCase().includes(searchString)
      )
    }
    return null
  }, [searchString, userListData])

  if (isLoading || isNil(filteredItems)) {
    return (
      <Stack alignItems="center">
        <CircularProgress size="28px" />
      </Stack>
    )
  }

  return (
    <CheckboxList
      headerTitle={t('teamSpace.selectAll')}
      items={filteredItems}
      orderBy={orderBy}
      handleCheckboxClick={handleCheckboxClick}
      handleCheckAllClick={handleCheckAllClick}
      handleOrderByClick={handleOrderByClick}
      checkboxListItemComponent={(item: UserListDataType): React.ReactElement => (
        <UserListItem
          userListData={item}
          showUserActions={showPermissionsDropdown}
          handlePermissionChange={handlePermissionChange}
          appendComponent={appendUserListItemComponent}
          disabled={disabled}
        />
      )}
      disabled={disabled}
    />
  )
}

import { useGetAiModelTrainingTableBodyData, useGetAiModelTrainingTableHeaderData } from './hooks'
import { useGetModelsQuery } from '../../store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '../../hooks/useErrorHandler'
import { EnhancedTable } from '../../components/table/enhancedTable/EnhancedTable'
import { useAppDispatch } from '../../store/hooks'
import * as React from 'react'
import { setAiSectionPath } from '@/store/features/uiSlice/uiSlice'
import { AiSectionPaths } from '@/constants'

export const AiModelTraining = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const { data: models, isLoading, isError, error } = useGetModelsQuery()
  const isTableBodyLoading = isLoading

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  React.useEffect(() => {
    dispatch(setAiSectionPath([AiSectionPaths.aiModelTraining]))
  }, [])

  const tableHeaderData = useGetAiModelTrainingTableHeaderData()
  const tableBodyData = useGetAiModelTrainingTableBodyData(models)

  return (
    <EnhancedTable
      isLoading={isTableBodyLoading}
      tableHeaderData={tableHeaderData}
      tableBodyData={tableBodyData}
      displayPagination={true}
      rowsPerPageOptions={[10, 20, 50]}
      defaultRowsPerPage={20}
    />
  )
}

import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useAcceptInvitationMutation } from '../../../../store/features/apis/slices/invitation/inviteSlice'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { navigateToUrl } from '@/utils/windowUtils'

interface AcceptInvitationModalTemplateProps extends ModalTemplateProps {
  title: string
  inviteId: string
  organizationName: string
}

export const AcceptInvitationModalTemplate = ({
  title,
  onClose,
  showCloseButton,
  inviteId,
  organizationName
}: AcceptInvitationModalTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const [approveInvitation, { isLoading }] = useAcceptInvitationMutation()

  const { handleApiHookErrors } = useErrorHandler({})

  const onClick = React.useCallback(async () => {
    await approveInvitation({ inviteId })
      .unwrap()
      .then((_) => {
        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t('acceptInvitation.acceptedSuccessfully')
          })
        )
        navigateToUrl('/dashboard/')
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [inviteId])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={onClick} submitDisabled={isLoading}>
        <Typography>{t('acceptInvitation.message', { organizationName })}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('acceptInvitation.cancel')}
        </Button>
        <Button onClick={onClick} color="primary" variant="contained" disabled={isLoading}>
          {t('acceptInvitation.accept')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import theme from '../../../theme/defaultTheme'
import { Alert, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const NoSpacesWarning = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <Alert icon={false} severity="info">
      <Typography variant="text" color={theme.palette.text.primary}>
        {t('notMemberOfTeamSpace')}
      </Typography>
    </Alert>
  )
}

import { DetailsPanelStyled, DetailsPanelTypes } from './GenericDetailsPanel'
import { SimulationCards } from './components/SimulationCard'
import { MultiSelectionDetails } from './MultiSelectionDetails'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

const SearchDetailsInfoMessageStyled = styled(Box)({
  width: '100%',
  marginTop: '20px',
  textAlign: 'center'
})

const SearchDetailsPanel = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Box qa-attribute="search-details-panel">
      <Typography sx={{ mt: 2 }} variant="title" color="text.primary" paragraph>
        {t('search.title')}
      </Typography>
      <SimulationCards panelType={DetailsPanelTypes.SEARCH_DETAILS} />
      <SearchDetailsInfoMessageStyled>
        <Typography
          variant="subtitle"
          color="grey.700"
          sx={{ whiteSpace: 'normal', wordBreak: 'break-word', hyphens: 'auto' }}
        >
          {t('search.message')}
        </Typography>
      </SearchDetailsInfoMessageStyled>
    </Box>
  )
}

const SearchDetailsPanelDelayed = (): React.ReactElement => {
  const { selectedItems } = useMultiSelect()
  const showMultiSelectionDetails =
    selectedItems.projects?.length + selectedItems.folders?.length + selectedItems.spaces?.length > 0

  return (
    <DetailsPanelStyled>
      {showMultiSelectionDetails ? <MultiSelectionDetails /> : <SearchDetailsPanel />}
    </DetailsPanelStyled>
  )
}

export default SearchDetailsPanelDelayed

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ViewMode } from '../../store/features/uiSlice/interfaces'
import { ActionButton } from '../../components/button/ActionButton'
import { getViewingMode, setViewMode } from '../../store/features/uiSlice/uiSlice'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import ButtonGroup from '@mui/material/ButtonGroup'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const ViewModeButtons = (): React.ReactElement => {
  const { t } = useTranslation(['tooltips'])
  const dispatch = useAppDispatch()
  const selectedViewMode = useAppSelector(getViewingMode)

  const handleOnClick = (viewMode: ViewMode): void => {
    dispatch(setViewMode(viewMode))
  }

  return (
    <ButtonGroup disableRipple size="small">
      <ActionButton
        selected={selectedViewMode === ViewMode.GRID}
        onClick={(): void => {
          handleOnClick(ViewMode.GRID)
        }}
        title={t('gridViewMode')}
        variant="outlined"
        tabIndex={TAB_INDEXES.VIEW_MODE_GRID}
      >
        <SpriteIcon spriteId="grid-view" width={14} />
      </ActionButton>
      <ActionButton
        selected={selectedViewMode === ViewMode.LIST}
        onClick={(): void => {
          handleOnClick(ViewMode.LIST)
        }}
        title={t('listViewMode')}
        variant="outlined"
        tabIndex={TAB_INDEXES.VIEW_MODE_LIST}
      >
        <SpriteIcon spriteId="list-view" width={14} />
      </ActionButton>
    </ButtonGroup>
  )
}

import { ShareModalTemplateProps } from './ShareModalTemplate'
import { PermissionType } from './share/interfaces'
import { Button } from '../../button/Button'
import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import * as React from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  Divider,
  FormControl,
  MenuItem,
  MenuItemProps,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { CustomChip } from '@/components/chips/CustomChip'
import { isValidInput, isValidNullableInput } from '@/helpers/validationHelpers'
import { useAvailableShareProjectPermissions } from '@/hooks/useAvailableShareProjectPermissions'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { usePermissionsPayload } from '@/hooks/usePermissionsPayload'
import { useValidation } from '@/hooks/useValidation'
import { usePostProjectPermissionsMutation } from '@/store/features/apis/slices/project/projectSlice'
import { addSnackbarToState } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import theme from '@/theme/defaultTheme'

interface MenuItemStyledProps extends MenuItemProps {
  addDivider?: boolean
}

export const MenuItemStyled = (menuItemProps: MenuItemStyledProps): React.ReactElement => {
  const { addDivider, ...props } = menuItemProps

  return (
    <React.Fragment>
      {addDivider && <Divider variant="middle" />}
      <MenuItem {...props}>
        <Typography variant="fontAwesomeIcon" color={props.selected ? 'primary.main' : 'text'} width="22px">
          {props.selected && props.children !== 'Remove access' && (
            <SpriteIcon spriteId="check" height={17} width={17} />
          )}
          {props.children === 'Remove access' && <SpriteIcon spriteId="times" height={17} width={17} />}
        </Typography>
        <Typography color={props.selected ? 'primary.main' : 'text'}>{props.children}</Typography>
      </MenuItem>
    </React.Fragment>
  )
}

interface ShareWithUsersModalTemplateProps extends ShareModalTemplateProps {
  sharedModalUsernamesOrEmails: string[]
  projectIdExt: string
}

export const ShareWithUsersModalTemplate = ({
  onClose,
  sharedModalUsernamesOrEmails,
  ownerName,
  publicProjectName
}: ShareWithUsersModalTemplateProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { validateField } = useValidation()
  const { getPermissions } = usePermissionsPayload()
  const [usernameOrEmail, setUsernameOrEmail] = React.useState('')
  const [usernamesOrEmails, setUsernamesOrEmails] = React.useState<string[]>(sharedModalUsernamesOrEmails)
  const [selectedPermission, setSelectedPermission] = React.useState<PermissionType>(PermissionType.VIEW)
  const [shareMessage, setShareMessage] = React.useState('')
  const [error, setError] = React.useState({})
  const [errorMessage, setErrorMessage] = React.useState({})
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false)
  const [postProjectPermissions] = usePostProjectPermissionsMutation()
  const { handleApiHookErrors } = useErrorHandler()

  const { availableSharePermissions } = useAvailableShareProjectPermissions()

  const isShareWithUsersErroneous: boolean = Object.values(error).some((value) => value === true)

  const onError = React.useCallback((error) => {
    setIsLoadingRequest(false)
    handleApiHookErrors(error)
    onClose()
  }, [])

  const onSuccessfulPermissionsUpdate = React.useCallback(() => {
    setIsLoadingRequest(false)
    dispatch(
      addSnackbarToState({
        severity: 'info',
        message: t(`share.successfulPermissionsUpdate`)
      })
    )
    onClose()
  }, [])

  const handleEmailsOnchange = React.useCallback(
    (onChangeValues: string[]) => {
      if (!error['usernameOrEmailInput']) {
        setUsernamesOrEmails(onChangeValues)
        if (isEmpty(onChangeValues)) {
          onClose()
        }
      }
      setUsernameOrEmail('')
      setError((prevState) => ({ ...prevState, ['usernameOrEmailInput']: false }))
      setErrorMessage((prevState) => ({ ...prevState, ['usernameOrEmailInput']: '' }))
    },
    [error]
  )

  const handleEmailInputOnChange = React.useCallback((value: string) => {
    const inputValueTrimmed = value.trim()

    setUsernameOrEmail(inputValueTrimmed)
    const validation = validateField(inputValueTrimmed, [isValidNullableInput])
    setError((prevState) => ({ ...prevState, ['usernameOrEmailInput']: !validation.valid }))
    setErrorMessage((prevState) => ({ ...prevState, ['usernameOrEmailInput']: validation.message }))
  }, [])

  const handleEmailInputOnBlur = React.useCallback(() => {
    if (!error['usernameOrEmailInput'] && !isEmpty(usernameOrEmail)) {
      setUsernamesOrEmails((previousState) => [...previousState, usernameOrEmail])
    }
    setError((prevState) => ({ ...prevState, ['usernameOrEmailInput']: false }))
    setErrorMessage((prevState) => ({ ...prevState, ['usernameOrEmailInput']: '' }))
  }, [usernameOrEmail, error])

  const handlePermissionOnChange = React.useCallback((event: SelectChangeEvent<string>) => {
    setSelectedPermission(event.target.value as PermissionType)
  }, [])

  const handleShareMessageOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const validation = validateField(event.target.value, [isValidNullableInput, isValidInput])
      setShareMessage(event.target.value)
      setError((prevState) => ({ ...prevState, ['shareMessage']: !validation.valid }))
      setErrorMessage((prevState) => ({ ...prevState, ['shareMessage']: validation.message }))
    },
    []
  )

  const handleShareOnClick = (): void => {
    setIsLoadingRequest(true)
    if (!isEmpty(selectedPermission)) {
      const payload = getPermissions(usernamesOrEmails, selectedPermission, shareMessage, true)
      postProjectPermissions({
        ownerName,
        publicProjectName,
        permissions: payload
      })
        .unwrap()
        .then(() => {
          onSuccessfulPermissionsUpdate()
        })
        .catch(onError)
    }
  }

  const submitDisabled =
    isShareWithUsersErroneous || isEmpty(usernamesOrEmails) || isEmpty(selectedPermission) || isLoadingRequest

  return (
    <React.Fragment>
      <ModalTitle onClose={onClose} onGoBack={onClose}>
        {t('share.labels.shareWith')}
      </ModalTitle>
      <ModalContent onSubmit={handleShareOnClick} submitDisabled={submitDisabled}>
        <Stack direction="column" gap={2}>
          <Stack direction="row" alignItems="flex-start">
            <Autocomplete
              multiple
              id="share-emails"
              freeSolo
              options={[]}
              value={usernamesOrEmails}
              clearOnBlur
              onChange={(_, value: string[]): void => {
                handleEmailsOnchange(value)
              }}
              onInputChange={(_, value): void => {
                handleEmailInputOnChange(value)
              }}
              inputValue={usernameOrEmail}
              onBlur={handleEmailInputOnBlur}
              renderTags={(values: string[], getTagProps): React.ReactElement[] =>
                values.map((option: string, index: number) => (
                  <CustomChip size="small" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params): React.ReactElement => (
                <TextField
                  {...params}
                  placeholder={t('share.fields.userNameOrEmail')}
                  error={error['usernameOrEmailInput']}
                  helperText={errorMessage['usernameOrEmailInput']}
                  variant="standard"
                />
              )}
              sx={{ flexGrow: 1, height: 'fit-content' }}
            />
            {!isEmpty(availableSharePermissions) ? (
              <FormControl size="small" sx={{ marginLeft: theme.spacing(3), width: '120px', flexShrink: 0 }}>
                <Select
                  labelId="permission-select"
                  id="permission-select"
                  variant="standard"
                  value={selectedPermission}
                  onChange={handlePermissionOnChange}
                  qa-attribute={`share-permission--select`}
                >
                  {availableSharePermissions.map((item) => (
                    <MenuItemStyled key={item.key} value={item.value} addDivider={item.value === 'NONE'}>
                      {t(item.key)}
                    </MenuItemStyled>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Stack>
          <TextField
            id="share-message"
            placeholder={t('share.fields.shareMessage')}
            size="small"
            multiline={true}
            minRows={2}
            fullWidth
            value={shareMessage}
            onChange={handleShareMessageOnChange}
            error={error['shareMessage']}
            helperText={errorMessage['shareMessage']}
            variant="standard"
          />
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('share.labels.cancel')}
        </Button>
        <Button color="primary" variant="contained" disabled={submitDisabled} onClick={handleShareOnClick}>
          {t('share.labels.share')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

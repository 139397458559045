import { PermissionType, SharePermission } from './interfaces'

export const NEW_USER_SHARE_PERMISSIONS: SharePermission[] = [
  { key: 'permissions.view', value: PermissionType.VIEW },
  { key: 'permissions.copy', value: PermissionType.COPY },
  { key: 'permissions.edit', value: PermissionType.EDIT }
]

export const EXISTENT_USER_SHARE_PERMISSIONS: SharePermission[] = [
  ...NEW_USER_SHARE_PERMISSIONS,
  { key: 'permissions.removeAccess', value: PermissionType.REMOVE }
]

import { ResourcesState } from './interfaces'
import { getUserPlanThunk } from './actions'
import { RequestLoadingStatus } from '../../interfaces'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ResourcesState = {
  userPlan: {
    loading: RequestLoadingStatus.IDLE,
    content: undefined
  }
}

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserPlanThunk.fulfilled, (state, action) => {
      state.userPlan.content = action.payload
      state.userPlan.loading = RequestLoadingStatus.FULFILLED
    }),
      builder.addCase(getUserPlanThunk.pending, (state) => {
        state.userPlan.loading = RequestLoadingStatus.PENDING
      }),
      builder.addCase(getUserPlanThunk.rejected, (state) => {
        state.userPlan.loading = RequestLoadingStatus.REJECTED
      })
  }
})

export const resourcesReducer = resourcesSlice.reducer

import { ModelPreviewImage } from './previewImage/ModelPreviewImage'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import Box from '@mui/material/Box'
import format from 'date-fns/format'
import Typography from '@mui/material/Typography'
import { Model, ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { formatVersion, getHumanReadableText } from '@/containers/ai/helpers'

type ModelVersionDetailsProps = {
  version: ModelVersion
  model: Model
}

export const ModelVersionDetails = ({ version, model }: ModelVersionDetailsProps): React.ReactElement => {
  return (
    <Stack
      direction="row"
      spacing={5}
      borderRadius={1}
      paddingX={4}
      paddingY={3}
      sx={{ backgroundColor: 'grey.100' }}
      alignItems="center"
      qa-attribute="aiModel--modelVersion--details"
    >
      <ModelPreviewImage width={95} height={95} url={model.thumbnail} flexShrink={0} />
      <Stack spacing={4}>
        <Box>
          <Typography variant="textV2" display="block">
            {formatVersion(version.version)} - {version.name}
          </Typography>
          <Typography variant="textSmallV2" color="grey.700">
            {format(new Date(version.created_at), 'MMM d, yyyy')}
          </Typography>
        </Box>
        <Box>
          <Typography variant="textV2" display="block" fontWeight="bold">
            {model.name}
          </Typography>
          <Typography variant="textSmallV2" color="grey.700">
            {getHumanReadableText(model.analysis_type)}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  )
}

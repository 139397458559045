import * as React from 'react'
import { styled } from '@mui/system'
import { InputAdornment, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

const TextFieldStyled = styled(TextField)({
  '& .MuiInput-root': {
    minWidth: '200px'
  }
})

type GeneralSearchFieldType = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  handleSearch: (value: string) => void
  qaAttribute: string
  isDisabled?: boolean
}

export const SearchFieldUi = ({
  value,
  setValue,
  handleSearch,
  qaAttribute,
  isDisabled
}: GeneralSearchFieldType): React.ReactElement => {
  const { t } = useTranslation('toolbar')

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => setValue(event.target.value)

  const handleOnClick = (): void => {
    handleSearch(value)
  }

  const handleResetValue = (): void => {
    handleSearch('')
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleSearch(value)
    }
  }

  return (
    <TextFieldStyled
      placeholder={t('searchInputLabel')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ p: 1 }} qa-attribute={`${qaAttribute}-input-adornment`}>
            {isEmpty(value) ? (
              <SpriteIcon
                spriteId="magnifying"
                width=".8125rem"
                onClick={handleOnClick}
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <SpriteIcon spriteId="times" width=".8125rem" onClick={handleResetValue} style={{ cursor: 'pointer' }} />
            )}
          </InputAdornment>
        )
      }}
      value={value}
      variant="standard"
      onKeyDown={handleOnKeyDown}
      onChange={handleOnChange}
      qa-attribute={qaAttribute}
      disabled={isDisabled}
      inputProps={{
        tabIndex: TAB_INDEXES.SEARCH_FIELD
      }}
    />
  )
}

import { styled } from '@mui/system'
import Avatar from '@mui/material/Avatar'

export enum AvatarImageSize {
  SMALL = 28,
  MEDIUM = 32,
  LARGE = 66,
  BADGE = 20
}
export const AVATAR_IMAGE_DEFAULT_SIZE = AvatarImageSize.SMALL
export const AVATAR_IMAGE_DEFAULT_SOURCE_SIZE = 66

interface AvatarStyledProps {
  addMarginLeft?: boolean
  imageSize?: AvatarImageSize
}

export const AvatarStyled = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'addMarginLeft' && prop !== 'imageSize'
})<AvatarStyledProps>(({ theme, addMarginLeft, imageSize }) => ({
  width: imageSize ?? AVATAR_IMAGE_DEFAULT_SIZE,
  height: imageSize ?? AVATAR_IMAGE_DEFAULT_SIZE,
  border: `1px solid ${theme.palette.grey['200']}`,
  '&:not(:last-child)': {
    marginLeft: addMarginLeft && '-8px'
  }
}))

export const BadgeAvatarStyled = styled(Avatar)(({ theme }) => ({
  width: AvatarImageSize.BADGE,
  height: AvatarImageSize.BADGE,
  border: `1px solid ${theme.palette.grey['200']}`
}))

import { CustomChip } from '../../components/chips/CustomChip'
import { ChipsList } from '../../components/chips/ChipsList'
import { PagePath } from '../../constants'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchType } from '@/components/searchbar/SearchField'

interface SearchTab {
  key: string
  label: string
  onClick: () => void
  isActive: boolean
}

export const SearchTabs = ({ searchType }: { searchType: SearchType }): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const goToSearchPath = (section: PagePath): void => {
    navigate(`/${PagePath.SEARCH}?q=${searchParams.get('q')}&section=${section}`)
  }

  const tabs: SearchTab[] = [
    {
      key: 'all',
      label: 'All',
      onClick: () =>
        searchType === SearchType.GENERAL
          ? goToSearchPath(PagePath.SEARCH)
          : setSearchParams({ ...Object.fromEntries(searchParams), section: PagePath.SEARCH }),
      isActive: searchParams.get('section') === PagePath.SEARCH || !searchParams.has('section')
    },
    {
      key: 'my-projects',
      label: 'My projects',
      onClick: () =>
        searchType === SearchType.GENERAL
          ? goToSearchPath(PagePath.MY_PROJECTS)
          : setSearchParams({ ...Object.fromEntries(searchParams), section: PagePath.MY_PROJECTS }),
      isActive: searchParams.get('section') === PagePath.MY_PROJECTS
    },
    {
      key: 'shared-with-me',
      label: 'Shared with me',
      onClick: () =>
        searchType === SearchType.GENERAL
          ? goToSearchPath(PagePath.SHARED_WITH_ME)
          : setSearchParams({ ...Object.fromEntries(searchParams), section: PagePath.SHARED_WITH_ME }),
      isActive: searchParams.get('section') === PagePath.SHARED_WITH_ME
    }
  ]

  return (
    <ChipsList>
      {tabs.map((tab) => (
        <CustomChip
          key={tab.key}
          label={tab.label}
          onClick={tab.onClick}
          qa-attribute={`${tab.key}-search-results--chip`}
          variant={tab.isActive ? 'filled' : 'outlined'}
          sx={{ textTransform: 'uppercase' }}
        />
      ))}
    </ChipsList>
  )
}

import * as React from 'react'

export const useDebounce = (value: any, delay: number): typeof value => {
  const [debouncedValue, setDebouncedValue] = React.useState<typeof value>(value)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => clearTimeout(timer)
  }, [value])

  return [debouncedValue, setDebouncedValue]
}

import { PathSelector } from './../containers/spaces/interfaces'
import { PagePath } from '../constants'
import { identity, isEmpty, isNil, last } from 'lodash'

interface QueryParams {
  [key: string]: string | number | boolean | undefined
}

export const queryParamsToString = (params: QueryParams): string => {
  if (!isNil(params) && Object.keys(params).length > 0) {
    const paramsString = Object.keys(params)
      .filter((key) => !isNil(params[key]))
      .map((key) => `${key}=${encodeURIComponent(params[key].toString())}`)
      .join('&')

    return paramsString ? `?${paramsString}` : ''
  }
  return ''
}

export const pathToRoute = (path: PathSelector, queryParams?: QueryParams, hash?: string): string => {
  let route = ''

  const filteredPath = path.filter(identity)

  if (filteredPath.length === 0 || isEmpty(filteredPath)) {
    route = PagePath.MY_PROJECTS
  } else if (filteredPath.length === 1) {
    const [spaceId] = filteredPath
    route = `spaces/${spaceId}`

    switch (spaceId) {
      case PagePath.SEARCH:
      case PagePath.TEAM_SPACES:
      case PagePath.SHARED_WITH_ME:
      case PagePath.MANAGE_SPACES:
      case PagePath.MANAGE_USERS:
      case PagePath.MANAGE_INVITATIONS:
      case PagePath.ORGANIZATION_SETTINGS:
      case PagePath.PROFILE:
      case PagePath.USAGE_OVERVIEW:
      case PagePath.API_KEYS:
      case PagePath.MY_INVITATIONS:
        route = spaceId
        break
    }
  } else {
    route = `spaces/${filteredPath[0]}/folders/${last(filteredPath)}`
  }

  return `${route}${queryParamsToString(queryParams)}${hash ? `#${hash}` : ''}`
}

import { TOOLTIP_DELAY } from './constants'
import { BreadcrumbsItemProps } from './interfaces'
import { StyledTooltip } from '../tooltip/StyledTooltip'
import * as React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export const BreadcrumbsItem = ({
  label,
  path,
  isLastElement,
  onElementSelect,
  nonClickable = false
}: BreadcrumbsItemProps): React.ReactElement => {
  const onClick = React.useCallback((): void => {
    onElementSelect(path)
  }, [path])

  if (isLastElement || nonClickable) {
    return (
      <StyledTooltip title={label} enterDelay={TOOLTIP_DELAY}>
        <Typography variant="text" noWrap sx={{ display: 'block' }}>
          {label}
        </Typography>
      </StyledTooltip>
    )
  }

  return (
    <StyledTooltip title={label} enterDelay={TOOLTIP_DELAY}>
      <Link underline="hover" noWrap onClick={onClick} variant="textBold" sx={{ cursor: 'pointer', display: 'block' }}>
        {label}
      </Link>
    </StyledTooltip>
  )
}

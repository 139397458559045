import { useAppSelector } from '../../../store/hooks'
import { getCurrentFolderPage, setCurrentFolderPage } from '../../../store/features/uiSlice/uiSlice'
import { getNumberOfPages, useGetFolders } from '../../../store/features/apis/slices/folder/helpers'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import { Pagination } from '@mui/material'
import { Stack } from '@mui/system'
import * as React from 'react'
import { first, last } from 'lodash'
import { useDispatch } from 'react-redux'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const FolderPagination = (): React.ReactElement => {
  const currentFolderPage = useAppSelector(getCurrentFolderPage)
  const { currentPath } = useCurrentPath()
  const dispatch = useDispatch()

  const { data: folders, isLoading } = useGetFolders({ spaceId: first(currentPath), folderId: last(currentPath) })
  const numberOfPages = getNumberOfPages(folders)

  React.useEffect(() => {
    if (numberOfPages > 0) {
      // When a folder is deleted, check if the current page still exists
      dispatch(setCurrentFolderPage(Math.min(numberOfPages, currentFolderPage)))
    }
  }, [numberOfPages])

  if (isLoading || numberOfPages <= 1) {
    return null
  }

  return (
    <Stack alignItems="center" marginBottom={5} marginTop={3}>
      <Pagination
        count={numberOfPages}
        page={currentFolderPage}
        onChange={(_e, nextPage): void => {
          dispatch(setCurrentFolderPage(nextPage))
        }}
        shape="rounded"
        tabIndex={TAB_INDEXES.FOLDER_PAGINATION}
      />
    </Stack>
  )
}

import { SettingsRowStyled } from './SettingsRow'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import { Button } from '../button/Button'
import * as React from 'react'
import { Box, CircularProgress, Stack, Switch, TextField, Typography } from '@mui/material'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const SelfUpdatableSettingsRow = ({
  label,
  value,
  originalValue,
  disabled,
  required,
  reverse,
  onChange,
  onKeyDown,
  error,
  helperText,
  isLoading,
  onSubmit,
  qaAttributePrefix
}: {
  value: string | boolean
  originalValue?: string | boolean
  label: string
  disabled?: boolean
  required?: boolean
  reverse?: boolean
  onChange?: (newValue: string | boolean) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  error?: boolean
  helperText?: string
  isLoading?: boolean
  onSubmit?: () => void
  qaAttributePrefix?: string
}): React.ReactElement => {
  const qaAttributeRow = `${qaAttributePrefix}--row`
  const [isTextFieldFocused, setIsTextFieldFocused] = React.useState(false)

  const handleFocus = (): void => {
    setIsTextFieldFocused(true)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (
      event.relatedTarget?.getAttribute('qa-attribute') === 'confirm--button' ||
      event.relatedTarget?.getAttribute('qa-attribute') === 'cancel--button'
    ) {
      return
    }
    onChange(originalValue)
    setIsTextFieldFocused(false)
  }

  const handleTextFieldButtonCancelClick = React.useCallback((): void => {
    onChange(originalValue)
    setIsTextFieldFocused(false)
  }, [originalValue])

  const handleTextFieldButtonConfirmClick = React.useCallback((): void => {
    if (originalValue !== value) {
      onSubmit()
      setIsTextFieldFocused(false)
    }
  }, [originalValue, value])

  return (
    <SettingsRowStyled flexDirection={reverse ? 'row-reverse' : 'row'} qa-attribute={qaAttributeRow}>
      <Box sx={{ width: '50%', flexGrow: 1, textAlign: reverse ? 'right' : 'left' }}>
        <Typography color={disabled ? 'GrayText' : 'text.primary'}>
          {label}
          {required ? '*' : ''}
        </Typography>
      </Box>
      <Box sx={{ width: '50%', flexGrow: 1 }}>
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress size={20} />
          </Stack>
        ) : (
          <React.Fragment>
            {typeof value === 'boolean' ? (
              <Switch
                size="small"
                disabled={disabled}
                checked={value}
                onChange={(event): void => onChange(event.target.checked)}
                qa-attribute={`${qaAttributePrefix}--${getQaSelectorText(label)}--switch`}
              />
            ) : (
              <Stack direction="row" gap={1} onBlur={handleBlur} rowGap={1}>
                <TextField
                  size="small"
                  sx={{ flexGrow: 1 }}
                  disabled={disabled}
                  value={value}
                  onChange={(event): void => onChange(event.target.value)}
                  onKeyDown={onKeyDown}
                  onFocus={handleFocus}
                  qa-attribute={`${qaAttributePrefix}--${getQaSelectorText(label)}--input`}
                  variant="standard"
                  error={error}
                  helperText={helperText}
                />
                {isTextFieldFocused ? (
                  <React.Fragment>
                    <Button
                      onClick={(): void => {
                        handleTextFieldButtonCancelClick()
                      }}
                      color="info"
                      variant="outlined"
                      qa-attribute="cancel--button"
                      sx={{ minWidth: 32, flexShrink: 0, padding: 0 }}
                    >
                      <SpriteIcon
                        spriteId="times" // times => light // regular
                        color={originalValue === value ? 'text.disabled' : 'grey.600'}
                        width={13}
                        height={13}
                      />
                    </Button>
                    <Button
                      onClick={(): void => {
                        handleTextFieldButtonConfirmClick()
                      }}
                      color="primary"
                      variant="contained"
                      qa-attribute="confirm--button"
                      disabled={error}
                      sx={{ minWidth: 32, flexShrink: 0, padding: 0 }}
                    >
                      <SpriteIcon spriteId="check" color="primary.negative" width={13} height={13} />
                    </Button>
                  </React.Fragment>
                ) : null}
              </Stack>
            )}
          </React.Fragment>
        )}
      </Box>
    </SettingsRowStyled>
  )
}

import { PathIDs } from './slices/folder/interfaces'
import { createRequestHeaders } from '../../../helpers/network'
import { getUserId } from '../../../helpers/cookies'
import {
  BaseQueryFn,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery,
  retry
} from '@reduxjs/toolkit/query/react'
import { isNil } from 'lodash'
import type { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'

const prepareHeaders = (headers: Headers): Headers => {
  const requestHeaders = createRequestHeaders()
  headers.set('Authorization', requestHeaders.Authorization)
  headers.set('Content-type', requestHeaders['Content-type'])
  return headers
}

export enum ApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3'
}

type CustomQueryReturnValue<D, E, M> = {
  data?: D
  error?: E
  meta?: M
}

const reportError = (result: CustomQueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>): void => {
  const headers = result.meta?.response?.headers
  const consoleMethod = (result.error.status as number) <= 499 ? 'warn' : 'error'

  // eslint-disable-next-line no-console
  console[consoleMethod]({
    TRACE: headers?.get('Trace-Id'),
    SPAN: headers?.get('Span-Id'),
    USER: `${getUserId()}`,
    USER_AGENT: navigator.userAgent,
    message: `${result.error.status}: service ${result.meta?.request?.url ?? ''} -
    ${result.error.data['message'] ?? ''}`,
    client_url: window.location.href
  })
}

export const staggeredBaseQueryWithBailOut = (
  baseUrl: string
): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const baseQuery = fetchBaseQuery({ baseUrl, prepareHeaders })
      // args can be string for simple get requests, or can be objects with more detailed params specified
      const method = typeof args === 'string' ? 'GET' : args.method?.toUpperCase() || 'GET'
      const result = await baseQuery(args, api, extraOptions)

      if (method !== 'GET') {
        // Skip retry for non-GET requests by immediately failing
        if (result.error) {
          reportError(result)
          retry.fail(result.error) // Mark this as a failure without retrying
        }
        return result
      }

      if (result.error) {
        reportError(result)
      }
      return result
    },
    {
      maxRetries: 5
    }
  )

export const isSpace = ({ spaceId, folderId }: PathIDs): boolean => isNil(folderId) || spaceId === folderId

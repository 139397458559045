import { useGetUserProductPlan } from './planHooks'
import { getCookie, getUsername } from '../helpers/cookies'
import { intercomUpdate } from '../helpers/intercom'
import { useGetUserQuery } from '../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import { isNil } from 'lodash'

export const useIntercom = (): void => {
  const username = getUsername()
  const productPlan = useGetUserProductPlan()
  const { data: userData, isLoading: isUserDataLoading } = useGetUserQuery({ username }, { skip: !username })

  React.useEffect(() => {
    if (isNil(userData) || isNil(productPlan) || isUserDataLoading) {
      return
    }

    const cookie = getCookie()

    const insideCypress = cookie.insideCypress
    const disableIntercom = cookie.disableIntercom
    if (insideCypress && disableIntercom) {
      return
    }

    intercomUpdate({
      name: `${userData.firstname} ${userData.lastname}`,
      email: userData.emailAddress,
      user_hash: userData.intercom.emailAddressHash,
      user: userData.user,
      productPlan,
      position: userData.position
    })
  }, [productPlan, isUserDataLoading, username, userData])
}

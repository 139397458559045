import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { first } from 'lodash'
import { useParams } from 'react-router-dom'
import { OperationWithPermission } from '@/operations/interfaces'
import { useAppDispatch } from '@/store/hooks'
import { openModal } from '@/store/features/uiSlice/uiSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { ModelVersion, TrainingPipeline } from '@/store/features/apis/slices/ai/interfaces'

export const useViewModelTrainingRunLogsOperation = ({
  version,
  trainingPipeline
}: {
  version?: ModelVersion
  trainingPipeline?: TrainingPipeline
}): OperationWithPermission<void> => {
  const { t } = useTranslation(['modals'])
  const dispatch = useAppDispatch()
  const { modelUuid } = useParams<{ modelUuid: string }>()

  const onClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.MODEL_VERSION_LOGS,
        showCloseButton: true,
        modalProps: {
          title: t('viewLogs.title'),
          modelUuid,
          trainingRunId: version?.training_run_id || first(trainingPipeline?.training_run_ids),
          size: 'xl'
        }
      })
    )
  }, [version, trainingPipeline, modelUuid])

  return [onClick, true]
}

import { useGetAdminUserManageUsersTableBodyData, useGetAdminUserManageUsersTableHeaderData } from './adminUserHooks'
import { SearchUsers } from './SearchUsers'
import { EnhancedTable } from '../../../components/table/enhancedTable/EnhancedTable'
import { CounterCard } from '../../../components/counterCard/CounterCard'
import {
  useIsAiInManageUsersEnabled,
  useIsManageUsersAreaEnabled,
  useRedirectIfPermissionDenied
} from '../../../permissions/areas'
import { useAppSelector } from '../../../store/hooks'
import { useCenteredCircularProgress } from '../../../components/progress/CenteredCircularProgress'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import { useAttributes } from '@/hooks/newWorld'

export const AdminManageUsersDisplay = (): React.ReactElement => {
  const { t } = useTranslation()

  const isManageUsersAreaEnabled = useIsManageUsersAreaEnabled({ allowNonAdmins: true })
  const [isLoadingManageUsersAreaPermission, isManageUsersAreaAllowedToVisit] = isManageUsersAreaEnabled

  const isAiAreaEnabledResult = useIsAiInManageUsersEnabled()
  const [isAiAreaLoadingPermission, isAiAreaAllowedToVisit] = isAiAreaEnabledResult

  const { data: attributes } = useAttributes()
  const isCommunityPlan = !attributes.isProfessional

  useRedirectIfPermissionDenied([isManageUsersAreaEnabled])

  const adminUserTableHeaderData = useGetAdminUserManageUsersTableHeaderData(isCommunityPlan, isAiAreaAllowedToVisit)
  const searchString = useAppSelector<string>((state) => state.ui.searchUserString)

  const {
    total: totalMembers,
    tableData: adminUserTableBodyData,
    isLoading: isTableBodyLoading
  } = useGetAdminUserManageUsersTableBodyData(searchString, isCommunityPlan, isAiAreaAllowedToVisit)

  useCenteredCircularProgress(isTableBodyLoading || isLoadingManageUsersAreaPermission || isAiAreaLoadingPermission)

  if (isTableBodyLoading || !isManageUsersAreaAllowedToVisit) {
    return null
  }

  return (
    <Stack direction="column" gap={5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" height={48}>
        <CounterCard currentAmount={totalMembers} description={t('manageUsers.cards.members', { ns: 'adminArea' })} />
        <SearchUsers />
      </Stack>
      <EnhancedTable
        isLoading={isTableBodyLoading}
        tableHeaderData={adminUserTableHeaderData}
        tableBodyData={adminUserTableBodyData}
        displayPagination={true}
        rowsPerPageOptions={[10, 20, 50]}
        defaultRowsPerPage={20}
      />
    </Stack>
  )
}

import { StyledTooltip } from '../tooltip/StyledTooltip'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import { styled } from '@mui/system'
import * as React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import { isNil } from 'lodash'
import { Typography } from '@mui/material'

export interface ActionButtonProps extends ButtonProps {
  selected?: boolean
  title?: string
  'qa-attribute'?: string
}

interface ButtonStyledProps extends ButtonProps {
  selected?: boolean
}

export const ActionButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'outlined'
})<ButtonStyledProps>(({ theme, selected, variant, size }) => {
  const defaultBackgroundColor = variant === 'outlined' ? theme.palette.background.paper : 'transparent'
  const defaultBorderColor = variant === 'outlined' ? theme.palette.grey['200'] : 'transparent'
  const defaultWidth = size === 'small' ? theme.spacing(6) : theme.spacing(8)
  const defaultHeight = size === 'small' ? theme.spacing(6) : theme.spacing(8)

  const styles = {
    backgroundColor: selected ? theme.palette.action.selected : defaultBackgroundColor,
    border: `1px solid ${selected ? theme.palette.border.primary : defaultBorderColor}`
  }
  if (variant === 'outlined' && (isNil(size) || size === 'medium')) {
    styles['padding'] = theme.spacing(1.5, 2)
  }
  const hoverStyles = {
    border: `1px solid ${selected ? theme.palette.border.primary : theme.palette.grey['200']}`,
    backgroundColor: selected ? theme.palette.action.selected : theme.palette.action.hover
  }

  return {
    ...styles,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: defaultWidth,
    height: defaultHeight,
    borderRadius: '4px',
    minWidth: '0',
    transition: '.3s color, .3s background, .3s border, .3s box-shadow',
    ':hover:not(.Mui-disabled)': {
      ...hoverStyles
    },
    '&:active:not(.Mui-disabled)': {
      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1) inset'
    }
  }
})

const getActionButtonColor = (selected: boolean, disabled: boolean, primaryColor?: string): string => {
  if (selected) {
    return 'primary.main'
  }
  if (disabled) {
    return 'grey.400'
  }
  return primaryColor ?? 'grey.700'
}

export const ActionButton = ({
  onClick,
  selected,
  disabled,
  title,
  children,
  'qa-attribute': qaAttribute,
  ...buttonProps
}: ActionButtonProps): React.ReactElement => {
  const useTooltip = title && !disabled
  const ButtonWrapper = (useTooltip ? StyledTooltip : React.Fragment) as any
  const buttonWrapperProps = useTooltip ? { title } : {}

  return (
    <ButtonWrapper {...buttonWrapperProps}>
      <ActionButtonStyled
        onClick={onClick}
        selected={selected}
        disabled={disabled}
        style={{ backgroundColor: getActionButtonColor(selected, disabled) }}
        qa-attribute={!isNil(qaAttribute) ? qaAttribute : `${getQaSelectorText(title)}--button`}
        {...buttonProps}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {children}
        </Typography>
      </ActionButtonStyled>
    </ButtonWrapper>
  )
}

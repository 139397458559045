import { useGetSettingsMapQuery } from '../store/features/apis/slices/user/userSlice'
import { isNil } from 'lodash'
import * as React from 'react'

export const useEnforceOnboarding = (): void => {
  const { data: userSettings, isLoading } = useGetSettingsMapQuery()

  React.useEffect(() => {
    if (
      !isLoading &&
      !isNil(userSettings) &&
      userSettings.onboarding_finished !== 'true' &&
      !isNil(userSettings.social_signin_provider)
    ) {
      window.location.href = '/onboarding/'
    }
  }, [isLoading, userSettings])
}

import { Button } from '../../../button/Button'
import { ActionButton } from '../../../button/ActionButton'
import theme from '../../../../theme/defaultTheme'
import * as React from 'react'
import { t } from 'i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const CancelButton = ({ onClick, disabled, label }): React.ReactElement => (
  <Button onClick={onClick} disabled={disabled} color="secondary" variant="outlined">
    {label}
  </Button>
)

export const ConfirmButton = ({ onClick, disabled, label }): React.ReactElement => (
  <Button onClick={onClick} disabled={disabled} color="primary" variant="contained">
    {label}
  </Button>
)

export const CreateFolder = ({ onClick, disabled }): React.ReactElement => (
  <ActionButton
    onClick={onClick}
    variant="outlined"
    sx={{ marginRight: 'auto', minWidth: theme.spacing(8), padding: '0' }}
    disabled={disabled}
    title={t('createFolder', { ns: 'tooltips' })}
  >
    <SpriteIcon spriteId="folder-plus" width={14} height={14} color="grey.550" />
  </ActionButton>
)

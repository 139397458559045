import { ContextMenuType } from '../../../components/contextMenu/ContextMenu'
import { Project } from '../apis/slices/project/interfaces'
import { Space } from '../apis/slices/space/interfaces'
import { PathSelector } from '../../../containers/spaces/interfaces'
import { UserListDataType } from '../../../containers/list/interfaces'
import { ApiKey } from '../../../containers/dashboard/personalArea/apiKeys/interfaces'
import { Folder } from '../apis/slices/folder/interfaces'
import { OrganizationV2User } from '../apis/slices/organization/interfaces'
import { UserV2 } from '../apis/slices/user/interfaces'
import { Invite } from '../apis/slices/invitation/interfaces'
import { ModelVersion, TrainModelUserOptionsType, TrainingPipeline } from '../apis/slices/ai/interfaces'
import { AlertColor, SnackbarCloseReason } from '@mui/material'

export interface UiState {
  // "sortingModeV2" because "sortingMode" was used previously with different values,
  // and was set persistently on localStorage
  sortingModeV2: SortingModeV2
  sortingDirection: SortingDirection
  viewingMode: ViewMode
  selectedNavigationTreeNode: PathSelector
  fullPath: PathSelector
  selectedSpaces: string[]
  selectedFolders: string[]
  selectedProjects: string[]
  modalsQueue: ModalQueueItem[]
  searchUserString: string
  searchSpaceString: string
  snackbarState: SnackbarStateWithId[]
  contextMenu: {
    isOpen: boolean
    type?: ContextMenuType
    x?: number
    y?: number
    folder?: Folder
    project?: Project
    space?: Space
    user?: UserV2
    apiKey?: ApiKey
    invitation?: Invite
    organizationUser?: OrganizationV2User
    version?: ModelVersion
    trainingPipeline?: TrainingPipeline
  }
  showRightPanel: boolean
  currentProjectPage: number
  currentFolderPage: number
  checkboxListItems: UserListDataType[]
  latestCopiedProject: { thumbnailUrl: string; projectIdExt: string }
  isLoading: boolean
  mobileSidebarOpen?: boolean
  aiSectionPath?: AiSectionPathValue[]
  aiModelTrainingSettings?: AiModelTrainingSettingsType
}

export type CustomSnackbarCloseReason = SnackbarCloseReason | 'undo'

export interface SnackbarState {
  severity: AlertColor
  message: string | React.ReactElement
  autoHideDuration?: number
  showUndoButton?: boolean
  onClose?: (snackbarId?: string, closeReason?: CustomSnackbarCloseReason) => void
}

export interface SnackbarStateWithId extends SnackbarState {
  id: string
}

export enum SortingModeV2 {
  LAST_MODIFIED_AT = 'lastModifiedAt',
  CREATED_AT = 'createdAt',
  NAME = 'name'
}

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SortingDirectionForRequest {
  ASC = '+',
  DESC = '-'
}

export enum ViewMode {
  GRID,
  LIST
}

export enum ModalTemplate {
  CREATE_FOLDER,
  EDIT_FOLDER,
  CREATE_PROJECT,
  EDIT_PROJECT,
  COPY_PROJECT,
  SHARE,
  SHARE_WITH_USERS,
  TRASH_PROJECT,
  TRASH_FOLDER,
  TRASH_SPACE,
  MOVE_ITEM,
  SPACE_MEMBERS,
  REMOVE_ORGANIZATION_USER,
  INVITE_COLLEAGUES,
  ASSIGN_ORGANIZATION_ADMIN,
  UNASSIGN_ORGANIZATION_ADMIN,
  SEATS_ARE_FULL,
  TRASH_USER_API_KEY,
  ASSIGN_CORE_HOURS,
  APPROVE_INVITATION,
  REJECT_INVITATION,
  CONFIRM_PUBLIC_PROJECT_PERMISSION,
  CREATE_ORGANIZATION,
  ACCEPT_INVITATION,
  GENERATE_KEY,
  DOMAIN_VERIFICATION,
  DOMAIN_DECLINED,
  ORGANIZATION_WELCOME,
  CONFIRMATION,
  CREATE_AI_MODEL,
  SELECT_AI_TRAINING_ANALYSIS_TYPE,
  DELETE_MODEL_VERSION,
  UNRELEASE_MODEL_VERSION,
  RELEASE_MODEL_VERSION,
  RELEASE_HISTORY,
  MODEL_VERSION_LOGS
}

interface ModalProps {
  [key: string]: any
}

export interface ModalQueueItem {
  template: ModalTemplate
  showCloseButton?: boolean
  modalProps?: ModalProps
}

export interface SelectedItem {
  id: string
  type: SelectedItemType
}

export interface SelectItems {
  items: string[]
  type?: SelectedItemType
}

export type SelectedItemType = 'folder' | 'project' | 'space'

export interface SelectedItems {
  spaces: string[]
  folders: string[]
  projects: string[]
}

export interface ContextMenu {
  type?: ContextMenuType
  x: number
  y: number
  folder?: Folder
  project?: Project
  space?: Space
  user?: UserV2
  apiKey?: ApiKey
  invitation?: Invite
  organizationUser?: OrganizationV2User
  version?: ModelVersion
  trainingPipeline?: TrainingPipeline
}

export type AiSectionPathValue = {
  label: string
  value: string
}

export enum AiModelParameter {
  DEFAULT = 'Default',
  HYPERPARAMETER_TUNING = 'Hyperparameter tuning',
  CUSTOM = 'Custom'
}

export enum MaxRuntimeRange {
  HOURS = 'Hours',
  DAYS = 'Days'
}

export type AiModelTrainingSettingsType = {
  formData: {
    versionName: string
    maxRuntime: number
    maxRuntimeRange: MaxRuntimeRange
    aiModelParameter: AiModelParameter
    userOptions: TrainModelUserOptionsType
    isDirty?: boolean
  }
  isValid?: boolean
}

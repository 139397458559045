// Will be deprecated in favour of SpacePermissions

export enum SharingControl {
  NO_SHARING = 'NO_SHARING',
  WITHIN_SPACE = 'WITHIN_SPACE',
  WITHIN_ORGANIZATION = 'WITHIN_ORGANIZATION',
  WITH_ANYONE = 'WITH_ANYONE'
}

export const DEFAULT_SHARING_CONTROL_PERMISSION = SharingControl.WITHIN_SPACE

export interface Space {
  spaceId: string
  organizationId: string
  spaceType: 'TEAM' | 'PERSONAL'
  name: string
  createdAt: string
  lastModifiedAt: string
  ownerUsername: string
  spaceSettings: SpaceSettings
  // These attributes are not present in spaces list is fetched from the organization.
  requesterPermission?: SpacePermissionsFlag
  requesterPermissions?: SpacePermissionsFlags
}

// TODO: are these attributes still used?
interface SpacePermissionsFlag {
  canPreview: boolean
  canRead: boolean
  canCopy: boolean
  canWrite: boolean
  canShare: boolean
  canManage: boolean
}

interface SpacePermissionsFlags {
  canListContentInRoot: boolean
  canCreateContentInRoot: boolean
  canMoveContentToRoot: boolean
  canDeleteSpace: boolean
  canEditSpaceMetadata: boolean
  canEditSpacePermissions: boolean
  canEditSpaceSettings: boolean
  canMoveContentOutOfRoot: boolean
}

export interface SpaceContent {
  personalSpaces: Space[]
  teamSpaces: Space[]
}

export interface TeamSpaceProps {
  name: string
  spaceSettings: SpaceSettings
}

interface SpaceSettings {
  sharingControl: SharingControl
}

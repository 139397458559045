import Stack from '@mui/material/Stack'
import { styled } from '@mui/system'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { isNil } from 'lodash'
import Box from '@mui/material/Box'
import { UserAvatarPicture } from '@/containers/user/UserAvatarPicture'
import { useOrganizationName } from '@/hooks/newWorld'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { useIsProfileSectionPage } from '@/hooks/useIsProfileSectionPage'
import { useUserNames } from '@/hooks/useUserNames'
import { setMobileSidebarOpen } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { SpriteIcon } from '@/assets/SpriteIcon'

const UserProfileWidgetStyled = styled(Stack, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean
}>(({ theme, isSelected }) => ({
  cursor: 'pointer',
  marginTop: '-1px',
  backgroundColor: isSelected ? theme.palette.primary[100] : 'none',
  borderLeft: `2px solid ${isSelected ? theme.palette.primary[200] : 'transparent'}`,
  borderTop: `1px solid ${isSelected ? theme.palette.primary[200] : 'transparent'}`,
  ':hover': {
    backgroundColor: theme.palette.primary[100],
    borderLeft: `2px solid ${theme.palette.primary[200]}`,
    borderTop: `1px solid ${theme.palette.primary[200]}`
  }
}))

export const UserProfileWidget = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { navigate } = useDashboardNavigate()
  const { username, userFullName } = useUserNames()
  const { data: organizationName } = useOrganizationName()
  const isProfileSectionPage = useIsProfileSectionPage()
  const isMobileSidebarOpen = useAppSelector((state) => state.ui.mobileSidebarOpen)

  const onWidgetClick = React.useCallback((): void => {
    if (isMobileSidebarOpen) {
      dispatch(setMobileSidebarOpen(!isMobileSidebarOpen))
    }
    navigate('/profile')
  }, [navigate, isMobileSidebarOpen])

  return (
    <UserProfileWidgetStyled
      direction="row"
      p={4}
      gap={2.5}
      alignItems="center"
      isSelected={isProfileSectionPage}
      onClick={onWidgetClick}
      qa-attribute="user-profile-widget"
    >
      <UserAvatarPicture
        userName={username}
        sx={{ width: 43, height: 43 }}
        qa-attribute="user-profile-widget--avatar"
      />
      <Stack direction="column" gap={1}>
        {!isNil(organizationName) && (
          <Typography
            variant="textBold"
            color={isProfileSectionPage ? 'text.selected' : 'text.primary'}
            qa-attribute="user-profile-widget--organizationName"
            sx={{
              maxWidth: '150px'
            }}
          >
            {organizationName}
          </Typography>
        )}
        <Typography
          variant="textBold"
          color={isProfileSectionPage ? 'text.selected' : 'grey.700'}
          qa-attribute="user-profile-widget--username"
          sx={{
            maxWidth: '150px'
          }}
        >
          {userFullName}
        </Typography>
      </Stack>
      <Box ml="auto">
        <SpriteIcon spriteId="gear" />
      </Box>
    </UserProfileWidgetStyled>
  )
}

import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { isNotEmpty, isValidEmail, isValidInput } from '../../../../helpers/validationHelpers'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import { useValidation } from '../../../../hooks/useValidation'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useIsAdmin } from '../../../../permissions/areas'
import { usePostInviteMutation } from '../../../../store/features/apis/slices/invitation/inviteSlice'
import { useGetOrganizationId } from '../../../../store/features/apis/slices/organization/hooks'
import { CustomChip } from '../../../chips/CustomChip'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { useDispatch } from 'react-redux'
import { Autocomplete, Stack, TextField, Typography } from '@mui/material'

interface InviteColleaguesProps extends ModalTemplateProps {
  title: string
}

type EmailType = {
  email: string
  valid: boolean
}

type ServerResponseInvalidEmail = {
  email: string
}

export const InviteColleaguesModalTemplate = ({
  title,
  onClose,
  showCloseButton
}: InviteColleaguesProps): React.ReactElement => {
  const dispatch = useDispatch()
  const { t } = useTranslation('modals')
  const [email, setEmail] = React.useState('')
  const [emailsWithValidation, setEmailsWithValidation] = React.useState<EmailType[]>([])
  const [serverResponseInvalidEmails, setServerResponseInvalidEmails] = React.useState<ServerResponseInvalidEmail[]>([])
  const { validateField } = useValidation()
  const { handleApiHookErrors } = useErrorHandler()

  const organizationId = useGetOrganizationId()
  const { data: isAdmin, isLoading: isLoadingIsAdmin } = useIsAdmin()
  const [sendInvite, { isLoading: isLoadingPostInvite }] = usePostInviteMutation()

  const allEmailsValid = React.useMemo(() => emailsWithValidation.every((email) => email.valid), [emailsWithValidation])
  const isLoading = isLoadingIsAdmin || isLoadingPostInvite
  const inviteButtonDisabled = isEmpty(emailsWithValidation) || !allEmailsValid || isLoading

  React.useEffect(() => {
    if (!isEmpty(serverResponseInvalidEmails)) {
      setEmailsWithValidation((prevState) => {
        return prevState.map((emailWithValidation) => {
          const isEmailInvalid = serverResponseInvalidEmails.some(
            (serverResponseInvalidEmail) => serverResponseInvalidEmail.email === emailWithValidation.email
          )
          if (isEmailInvalid) {
            emailWithValidation.valid = !isEmailInvalid
          }
          return emailWithValidation
        })
      })
    }
  }, [serverResponseInvalidEmails, emailsWithValidation])

  const handleOnSubmit = React.useCallback(async () => {
    const validEmails = emailsWithValidation.map((validatedEmail) => validatedEmail.email)
    sendInvite({ organizationId, emails: validEmails })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: isAdmin ? t('inviteColleagues.adminSuccess') : t('inviteColleagues.userSuccess')
          })
        )
        onClose()
      })
      .catch((error) => {
        handleApiHookErrors(error)
        const invalidEmails = error.data.errorDetails?.invalidEmails
        setServerResponseInvalidEmails((prevState) => [...prevState, ...(invalidEmails ?? [])])
      })
  }, [organizationId, emailsWithValidation])

  const convertEmailsWithValidationToStrings = React.useCallback((emailsWithValidation: EmailType[]): string[] => {
    return emailsWithValidation.map(({ email }) => email)
  }, [])

  const createEmailsWithValidation = React.useCallback(
    ({ email, emails }: { email?: string; emails?: string[] }): void => {
      if (!isNil(email)) {
        setEmailsWithValidation((prevState) => [
          ...prevState,
          { email: email, valid: validateField(email, [isNotEmpty, isValidEmail, isValidInput]).valid }
        ])
      }
      if (!isNil(emails)) {
        const emailsWithValidation = emails.map((value) => ({
          email: value,
          valid: validateField(value, [isNotEmpty, isValidEmail, isValidInput]).valid
        }))
        setEmailsWithValidation(emailsWithValidation)
      }
      setEmail('')
    },
    []
  )

  const handleInputOnChange = React.useCallback(
    (inputValue: string): void => {
      const inputValueTrimmed = inputValue.replace(/\s/g, '')
      // add email function on "," char
      if (inputValueTrimmed === ',') {
        return
      }
      // for inputValue that may contain multiple emails already
      if (inputValueTrimmed.includes(',')) {
        const inputValueSplit = inputValueTrimmed.split(',')
        const previousEmails = convertEmailsWithValidationToStrings(emailsWithValidation)
        createEmailsWithValidation({ emails: [...previousEmails, ...inputValueSplit] })
        return
      }
      setEmail(inputValueTrimmed)
    },
    [emailsWithValidation]
  )

  const handleOnchange = React.useCallback((emails: string[]): void => {
    createEmailsWithValidation({ emails })
  }, [])

  const handleOnBlur = React.useCallback((): void => {
    if (!isEmpty(email)) {
      createEmailsWithValidation({ email })
    }
  }, [email])

  const handleOnKeyDown = React.useCallback(
    (key: string): void => {
      if (key === ',' && !isEmpty(email)) {
        createEmailsWithValidation({ email })
      }
    },
    [email]
  )

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>

      <ModalContent onSubmit={handleOnSubmit} submitDisabled={inviteButtonDisabled}>
        <Stack direction="column" gap={8}>
          <Typography>{t('inviteColleagues.subtitle')}</Typography>
          <Autocomplete
            multiple
            id="invite-emails"
            freeSolo
            options={[]}
            value={convertEmailsWithValidationToStrings(emailsWithValidation)}
            onChange={(_, value: string[]): void => {
              handleOnchange(value)
            }}
            onBlur={handleOnBlur}
            onKeyDown={(event): void => handleOnKeyDown(event.key)}
            onInputChange={(_, value): void => {
              handleInputOnChange(value)
            }}
            inputValue={email}
            renderTags={(_, getTagProps): React.ReactElement[] => {
              return emailsWithValidation.map(({ email, valid }, index: number) => (
                <CustomChip
                  color={!valid ? 'error' : 'default'}
                  size="small"
                  label={email}
                  {...getTagProps({ index })}
                />
              ))
            }}
            renderInput={(params): React.ReactElement => (
              <TextField {...params} helperText={t('inviteColleagues.emailHelperText')} variant="standard" />
            )}
            sx={{ height: 'fit-content' }}
            qa-attribute="invite-emails--input"
          />
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('inviteColleagues.cancel')}
        </Button>
        <Button onClick={handleOnSubmit} disabled={inviteButtonDisabled} color="primary" variant="contained">
          {t('inviteColleagues.invite')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

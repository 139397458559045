import { getDefaultAvatarPicture } from './helpers'
import { CommentDataProps } from '../../../../../containers/detailsPanel/components/comments/Comments'
import { ForumResponse } from '../../../../../containers/detailsPanel/interfaces'
import { get } from '../../../../../helpers/network'
import { ForumData } from '../../../../../components/modal/templates/share/interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { isNil } from 'lodash'

const forumSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getComments: builder.query<
      { comments: CommentDataProps[]; totalNumberOfComments: number },
      { topicId: number; page: number }
    >({
      query: ({ topicId, page = 1 }) => `forum/t/${topicId}.json?page=${page}`,
      transformResponse: (response: ForumResponse, _meta, arg) => {
        const { page } = arg
        // removing the first comment on first page from the creator
        const totalNumberOfComments = response?.posts_count - 1
        const projectComments = response?.post_stream?.posts.slice(page === 1 ? 1 : 0)
        const comments = projectComments.map((comment) => ({
          userName: comment.username,
          modifiedAt: comment.updated_at,
          textContent: comment.cooked
        }))
        return { comments, totalNumberOfComments }
      }
    }),
    getAvatarImageUrl: builder.query<string, { userName: string; size: number }>({
      queryFn: async ({ userName, size }) => {
        if (isNil(userName)) {
          return { data: getDefaultAvatarPicture() }
        }

        try {
          const response = await get<ForumData>(`/forum/users/${userName}.json`)
          const forumAvatar = response.data?.user?.avatar_template.replace('{size}', `${size}`)
          return { data: forumAvatar ?? getDefaultAvatarPicture() }
        } catch (error) {
          return { data: getDefaultAvatarPicture() }
        }
      }
    })
  })
})

export const { useGetCommentsQuery, useGetAvatarImageUrlQuery } = forumSlice

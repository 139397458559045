import { InputFilterDialog } from './InputFilterDialog'
import { DateFilterDialog } from './DateFilterDialog'
import { TableFilter } from '../interfaces'
import * as React from 'react'

interface FilterSelectorProps {
  filter: TableFilter
  id: string
  setFilters: React.Dispatch<React.SetStateAction<TableFilter<any>[]>>
}

export const FilterSelector = ({ id, filter, setFilters }: FilterSelectorProps): React.ReactElement => {
  switch (filter.type) {
    case 'string':
    case 'number':
      return <InputFilterDialog filter={filter} id={id} setFilters={setFilters} />
    case 'date':
      return <DateFilterDialog filter={filter} id={id} setFilters={setFilters} />
    default:
      return null
  }
}

import { SearchTabs } from './SearchTabs'
import { Sort } from './Sort'
import { CategoryBreadcrumbs, SpaceCategory } from '../Breadcrumbs/CategoryBreadcrumbs'
import { ToggleInfoPanelButton } from '../SmartButtons/ToggleInfoPanelButton'
import { ViewModeButtons } from '../SmartButtons/ViewModeButtons'
import { FolderBreadcrumbs } from '../Breadcrumbs/Breadcrumbs'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { PagePath } from '../../constants'
import { Grid, Stack } from '@mui/material'
import * as React from 'react'
import { has, last } from 'lodash'
import { SearchType } from '@/components/searchbar/SearchField'

export const OperationToolbar = (): React.ReactElement => {
  const { currentPath: selectedPath } = useCurrentPath()
  const isSearchMode = useIsSearchMode()
  const [scrolled, setScrolled] = React.useState(false)
  const inTeamSpaces = last(selectedPath) === PagePath.TEAM_SPACES
  const widthOfToolbarButtons = '266px'

  const handleScroll = (): void => {
    const mainDisplay = document.querySelector('[qa-attribute="main-display"]')
    setScrolled(mainDisplay?.scrollTop > 0)
  }

  React.useEffect(() => {
    const mainDisplay = document.querySelector('[qa-attribute="main-display"]')
    mainDisplay.addEventListener('scroll', handleScroll)
    return () => {
      mainDisplay.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      sx={{
        background: 'common.white',
        flexShrink: '0',
        padding: 5,
        borderBottom: '1px',
        borderBottomStyle: 'solid',
        borderColor: scrolled ? 'grey.200' : 'common.white',
        transition: '.3s border'
      }}
      qa-attribute="operation-toolbar"
    >
      <Grid
        item
        maxWidth={`calc(100% - ${widthOfToolbarButtons})`}
        flexGrow={1}
        qa-attribute="operation-toolbar-breadcrumbs"
      >
        <React.Fragment>
          {isSearchMode || has(SpaceCategory, last(selectedPath)) ? <CategoryBreadcrumbs /> : <FolderBreadcrumbs />}
          {isSearchMode && <SearchTabs searchType={SearchType.GENERAL} />}
        </React.Fragment>
      </Grid>
      <Grid item ml="auto" qa-attribute="operation-toolbar-action-buttons">
        <Stack direction="row" spacing={1} sx={{ height: 32 }}>
          {!inTeamSpaces && <Sort />}
          <ViewModeButtons />
          <ToggleInfoPanelButton />
        </Stack>
      </Grid>
    </Grid>
  )
}

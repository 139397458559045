import { ContextMenuType } from '../../../components/contextMenu/ContextMenu'
import { useContextMenu } from '../../../hooks/useContextMenu'
import { useMultiSelect } from '../../../hooks/useMultiSelect'
import { ViewMode } from '../../../store/features/uiSlice/interfaces'
import { ProjectCard } from '../../../components/projects/ProjectCard'
import { getViewingMode } from '../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../store/hooks'
import { ProjectRow } from '../../../components/projects/ProjectRow'
import { Project } from '../../../store/features/apis/slices/project/interfaces'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { openProjectInWorkbench } from '../../../helpers/navigation'
import * as React from 'react'

export const ProjectDataGridComponents = ({ projects }: { projects: Project[] }): React.ReactElement => {
  const { isSelectedItem, handleMultiSelection } = useMultiSelect()
  const displayType = useAppSelector(getViewingMode)
  const isMobile = useIsMobile()
  const { show } = useContextMenu()

  const Component = displayType === ViewMode.GRID ? ProjectCard : ProjectRow

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, projectIdExt: string): void => {
    const isDoubleClick = event.detail === 2
    if (isDoubleClick || isMobile) {
      openProjectInWorkbench(projectIdExt)
    }
    handleMultiSelection(event, projectIdExt, 'project')
  }

  return (
    <>
      {projects.map((project) => (
        <Component
          key={project.projectIdExt}
          projectCardData={{
            ...project,
            thumbnailUrl: project.thumbnailUrl,
            public: project.publicPermission?.readPreview ?? false,
            shared: project.isShared,
            selected: isSelectedItem(project.projectIdExt.toString(), 'project')
          }}
          onContextMenu={(event): void => show({ event, type: ContextMenuType.PROJECT, project })}
          onClick={handleOnClick}
          className="selectable-card"
        />
      ))}
    </>
  )
}

import { PagePath } from '../../constants'
import { useIsAdmin } from '../../permissions/areas'
import { useSpaceById } from '../../store/features/apis/slices/space/hooks'
import { useIsSharedWithMePage } from '../../hooks/useIsSharedWithMePage'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useIsProfileSectionPage } from '../../hooks/useIsProfileSectionPage'
import { useIsMyProjectsPage } from '../../hooks/useIsMyProjectsPage'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { camelCase, first, isNil } from 'lodash'

export const ToolbarTitle = (): React.ReactElement => {
  const { t } = useTranslation(['toolbar'], { useSuspense: true })
  const { data: isAdmin, isLoading: isLoadingAdmin } = useIsAdmin()
  const { currentPath, isLoading: isLoadingCurrentPath } = useCurrentPath()
  const firstPath = first(currentPath) as PagePath
  const { space, isLoading: isSpaceLoading } = useSpaceById(firstPath)
  const isSearchMode = useIsSearchMode()
  const isSharedWithMePage = useIsSharedWithMePage()
  const isUsersPage = firstPath === PagePath.MANAGE_USERS
  const isProfilePage = useIsProfileSectionPage()
  const isMyProjectsPage = useIsMyProjectsPage()
  const isLoading = isLoadingCurrentPath || isSpaceLoading || isLoadingAdmin

  const previousTitle = React.useRef<string | null>(null)
  const title = React.useMemo(() => {
    if (isLoading) {
      return previousTitle.current
    }
    let newTitle = t(camelCase(firstPath), '')
    if (isSearchMode) {
      newTitle = t('search')
    } else if (isSharedWithMePage) {
      newTitle = t('sharedWithMe')
    } else if (isUsersPage) {
      newTitle = isAdmin ? t('manageUsers.title') : t('users')
    } else if (isProfilePage) {
      newTitle = t('userPreferencesAndDetails')
    } else if (isMyProjectsPage) {
      newTitle = t('myProjects')
    } else if (!isNil(space) && !isSpaceLoading) {
      newTitle = space?.name
    }
    previousTitle.current = newTitle
    return newTitle
  }, [
    space,
    isSpaceLoading,
    isLoading,
    isAdmin,
    isSharedWithMePage,
    isSearchMode,
    isUsersPage,
    isProfilePage,
    isMyProjectsPage,
    firstPath,
    t
  ])

  return (
    <Typography variant="toolbarTitle" flexGrow={1}>
      {title}
    </Typography>
  )
}

import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { SelectedUserBoxStyled, User } from '../../../../components/user/User'
import { useGetOrganizationId } from '../../../../store/features/apis/slices/organization/hooks'
import { UserOrganizationStatus } from '../../../../store/features/apis/slices/organization/interfaces'
import { useUpdateOrganizationV2UserStatusMutation } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { UserV2 } from '../../../../store/features/apis/slices/user/interfaces'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { isError } from 'lodash'

interface UnassignOrganizationProps extends ModalTemplateProps {
  title: string
  selectedUser?: UserV2
}

export const UnassignOrganizationAdmin = ({
  title,
  onClose,
  showCloseButton,
  selectedUser
}: UnassignOrganizationProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const organizationId = useGetOrganizationId()
  const [updateOrganizationV2UserStatus, { isLoading }] = useUpdateOrganizationV2UserStatusMutation()

  const onClick = React.useCallback(async () => {
    const response = await updateOrganizationV2UserStatus({
      userId: selectedUser.uidExt,
      organizationId,
      userRole: UserOrganizationStatus.MEMBER
    })

    if (!isError(response)) {
      dispatch(
        addSnackbarToState({
          severity: 'info',
          message: (
            <Trans
              i18nKey="unassignOrganizationAdmin.unassignedSuccessfully"
              t={t}
              values={{ name: selectedUser.email }}
            />
          )
        })
      )
    } else {
      dispatch(
        addSnackbarToState({
          severity: 'info',
          message: (
            <Trans
              i18nKey="unassignOrganizationAdmin.unassignedError"
              t={t}
              values={{ name: `${selectedUser.firstName} ${selectedUser.lastName}` }}
            />
          )
        })
      )
    }

    onClose()
  }, [selectedUser.firstName, selectedUser.lastName])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={onClick} submitDisabled={isLoading} noPadding>
        <SelectedUserBoxStyled direction="row" alignItems="center" gap={3} sx={{ marginBottom: 0 }}>
          <User
            username={selectedUser.username}
            firstName={selectedUser.firstName}
            lastName={selectedUser.lastName}
            email={selectedUser.email}
          />
        </SelectedUserBoxStyled>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        <Button onClick={onClick} color="primary" variant="contained" disabled={isLoading}>
          {t('unassignOrganizationAdmin.remove')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import { PathSelector } from '../containers/spaces/interfaces'
import { FullProject } from '../store/features/apis/slices/project/interfaces'
import { isEmpty, isNil } from 'lodash'

export const getHumanReadableDate = (date: string): string =>
  new Date(date).toDateString().split(' ').slice(1).join(' ')

export const encodeQueryParams = (queryParams: object): string =>
  Object.keys(queryParams)
    .map((key) => [key, queryParams[key]].map(encodeURIComponent).join('='))
    .join('&')

const URL_LONG_DEV = 'https://ws-long-dev.simscale.com'
const URL_LONG_STAGING = 'https://ws-long-staging.simscale.com'
const URL_LONG_PRODUCTION = 'https://ws-long.simscale.com'

const getModifiedOrigin = (): string => {
  const origin = window.location.origin
  switch (origin) {
    case 'https://staging.simscale.com':
      return URL_LONG_STAGING
    case 'https://simscale.com':
    case 'https://www.simscale.com':
      return URL_LONG_PRODUCTION
    default:
      return URL_LONG_DEV
  }
}

export const getResolvedThumbnailFullServicePath = (path: string | null | undefined): string => {
  if (isNil(path)) {
    return path
  }

  const [basePath, queryPart] = path.split('?')
  const modifiedOrigin = getModifiedOrigin()
  if (/^\/api\/v1\/projects\/.*\/thumbnail(?:\/.*)?$/.test(basePath)) {
    return `${modifiedOrigin}${basePath}${queryPart ? `?${queryPart}` : ''}`
  }

  return path
}

export const getProjectPath = (project: FullProject, personalSpaceId?: string): PathSelector => {
  const projectPath: string[] = []
  if (!isNil(project?.spaceId)) {
    projectPath.push(project.spaceId)
  }
  if (!isNil(project.parentFolderId)) {
    projectPath.push(project.parentFolderId)
  }
  return isEmpty(projectPath) ? [personalSpaceId] : projectPath
}

import { PathSelector } from './interfaces'
import { NavigationItemTitle } from '../navigation/NavigationItemTitle'
import { Space } from '../../store/features/apis/slices/space/interfaces'
import { FolderSelectorNavigationTree } from '../../components/modal/templates/moveProject/NavigationTreePathSelector'
import { pathToRoute } from '../../helpers/router'
import { PagePath } from '../../constants'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { first, isNil } from 'lodash'

export const TeamsSpaces = ({ spaces }: { spaces: Space[] }): React.ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useDashboardNavigate()
  const isSearchMode = useIsSearchMode()
  const { currentPath: selectedPath } = useCurrentPath()
  const selectedSpaceId = first(selectedPath)
  const isSelected =
    spaces.map(({ spaceId }) => spaceId).includes(selectedSpaceId) || selectedSpaceId === PagePath.TEAM_SPACES
  const displayItems = !isSearchMode && isSelected

  const onSelected = (path: PathSelector): void => {
    if (!isNil(path)) {
      navigate(`/${pathToRoute(path)}`)
    }
  }

  return (
    <>
      <NavigationItemTitle
        spriteId="user-group"
        label={t('spaces')}
        onClick={(): void => navigate(PagePath.TEAM_SPACES)}
        isSelected={isSelected}
      />
      {displayItems &&
        spaces.map((space) => (
          <FolderSelectorNavigationTree
            key={space.spaceId}
            path={[space.spaceId]}
            label={space.name}
            setSelectedPath={onSelected}
            selectedPath={selectedPath}
          />
        ))}
    </>
  )
}

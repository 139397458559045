import { UserListDataType } from './interfaces'
import { UserAvatarPicture } from '../user/UserAvatarPicture'
import { PermissionType } from '../../components/modal/templates/share/interfaces'
import { MenuItemStyled } from '../../components/modal/templates/ShareWithUsersModalTemplate'
import { TEAM_SPACE_PERMISSIONS_MAP } from '../../components/modal/templates/teamSpace/helpers'
import { StyledTooltip } from '../../components/tooltip/StyledTooltip'
import * as React from 'react'
import { Box, ListItemAvatar, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

interface UserListItemProps {
  userListData: UserListDataType
  showUserActions?: boolean
  handlePermissionChange: (permission: PermissionType, id: string) => void
  disabled?: boolean
  appendComponent?: (user: UserListDataType) => React.ReactNode
}

export const UserListItem = ({
  userListData,
  showUserActions,
  handlePermissionChange,
  disabled,
  appendComponent
}: UserListItemProps): React.ReactElement => {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, overflowX: 'hidden' }}>
        <ListItemAvatar sx={{ minWidth: 'auto' }}>
          <UserAvatarPicture userName={userListData.username} />
        </ListItemAvatar>
        <StyledTooltip title={userListData.email} enterDelay={500}>
          <Stack direction="column" sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }} pr={2}>
            <Typography variant="text" color="text.primary" component="span">
              {userListData.userFullName}
            </Typography>
            <Typography variant="subtitle" color="grey.700">
              {userListData.email}
            </Typography>
          </Stack>
        </StyledTooltip>
      </Box>
      {!isNil(appendComponent) && appendComponent(userListData)}
      {showUserActions && (
        <UserActions userListData={userListData} handlePermissionChange={handlePermissionChange} disabled={disabled} />
      )}
    </React.Fragment>
  )
}

const UserActions = ({ userListData, handlePermissionChange, disabled }): React.ReactElement => {
  const { t } = useTranslation('modals')

  if (!userListData.checked) {
    return (
      <Typography variant="text" sx={{ color: 'text.disabled', flexShrink: 0, marginLeft: 'auto', marginRight: 2 }}>
        {t('permissions.noAccess')}
      </Typography>
    )
  }

  return (
    <Select
      value={userListData.permission ?? PermissionType.VIEW}
      variant="standard"
      onChange={(event: SelectChangeEvent<string>): void => {
        handlePermissionChange(event.target.value, userListData.id)
      }}
      sx={{ minWidth: '60px', flexShrink: 0, marginLeft: 'auto', marginRight: 2 }}
      disabled={disabled ?? userListData.disabled}
    >
      {Object.entries(TEAM_SPACE_PERMISSIONS_MAP).map(([key, value]) => (
        <MenuItemStyled key={key} value={key} addDivider={key === 'NONE'}>
          {t(value)}
        </MenuItemStyled>
      ))}
    </Select>
  )
}

import { ActionButton } from '../button/ActionButton'
import theme from '../../theme/defaultTheme'
import { useModalEvents } from '../../hooks/useModalEvents'
import * as React from 'react'
import { styled } from '@mui/system'
import {
  Box,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogTitleProps,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  Dialog as MuiModal,
  DialogProps as MuiModalProps,
  Stack,
  Typography
} from '@mui/material'
import { SpriteIcon } from '@/assets/SpriteIcon'

type ModalProps = {
  isLastInQueue?: boolean
  size?: 'md' | 'xl'
} & MuiModalProps

export const Modal = styled(MuiModal, {
  shouldForwardProp: (prop) => prop !== 'isLastInQueue' && prop !== 'size'
})<ModalProps>(({ isLastInQueue, size }) => {
  let dialogWidth: number | string = 480
  if (size === 'xl') {
    dialogWidth = '100%'
  }

  return {
    '&.MuiDialog-root': !isLastInQueue
      ? {
          opacity: 0,
          visibility: 'hidden',
          pointerEvents: 'none'
        }
      : {},
    '.MuiDialog-paper': {
      width: dialogWidth,
      maxWidth: '100%',
      boxShadow: '2px 2px 20px 0 rgba(0, 0, 0, 0.05)'
    }
  }
})

const ModalActionsStyled = styled(MuiDialogActions)(() => ({
  padding: `${theme.spacing(5)} ${theme.spacing(6)}`
}))

export const ModalActions = ({ children, ...props }: DialogActionsProps): React.ReactElement => (
  <ModalActionsStyled {...props}>{children}</ModalActionsStyled>
)

type ModalContentProps = {
  noPadding?: boolean
  onSubmit?: () => void
  submitDisabled?: boolean
}

export const ModalContent = ({
  children,
  noPadding,
  onSubmit,
  submitDisabled
}: DialogContentProps & ModalContentProps): React.ReactElement => {
  useModalEvents(onSubmit, submitDisabled)

  return (
    <DialogContent className="u-overflowOverlay" sx={{ padding: noPadding ? 0 : `0 ${theme.spacing(6)}` }}>
      {children}
    </DialogContent>
  )
}

const ModalTitleStyled = styled(MuiDialogTitle)(() => ({
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(6)}`
}))

export const ModalTitle = ({
  children,
  onClose,
  onGoBack,
  ...props
}: DialogTitleProps & { onClose?: () => void; onGoBack?: () => void }): React.ReactElement => (
  <ModalTitleStyled {...props}>
    <Stack direction="row" alignItems="center">
      {onGoBack && (
        <ActionButton onClick={onGoBack} style={{ marginRight: 4, marginLeft: -12 }}>
          <SpriteIcon spriteId="arrow-down" height={12} width={12} rotatingAngle={90} />
        </ActionButton>
      )}
      <Typography noWrap variant="title" color="text.primary">
        {children}
      </Typography>
      {onClose && (
        <Box sx={{ ml: 'auto' }}>
          <ActionButton onClick={onClose}>
            <SpriteIcon spriteId="times" height={13} width={13} data-icon="xmark" />
          </ActionButton>
        </Box>
      )}
    </Stack>
  </ModalTitleStyled>
)

import { getQaSelectorText } from '../../../helpers/qaSelectors'
import { CustomChip } from '../../chips/CustomChip'
import { Autocomplete, TextField } from '@mui/material'
import { isNil } from 'lodash'
import * as React from 'react'

interface ModalInputAutocompleteProps {
  values: any[]
  onChange: (value: any[]) => void
  onInputChange: (inputValue: string) => void
  options: any[]
  label: string
  isDisabled?: boolean
}

export const ModalInputAutocomplete = ({
  values,
  onChange,
  onInputChange,
  options,
  label,
  isDisabled
}: ModalInputAutocompleteProps): React.ReactElement => {
  const qaSelectorText = getQaSelectorText(label)
  return (
    <Autocomplete
      disableClearable
      multiple
      size="small"
      freeSolo
      autoSelect
      autoComplete
      loading={isNil(values) || isNil(options)}
      value={values}
      onChange={(_, value): void => {
        onChange(value)
      }}
      options={options}
      getOptionLabel={(option): string => option}
      qa-attribute={`${qaSelectorText}--autocomplete`}
      renderTags={(values: string[], getTagProps): React.ReactElement[] =>
        values.map((option: string, index: number) => (
          <CustomChip size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params): React.ReactElement => (
        <TextField
          variant="standard"
          {...params}
          placeholder={label}
          onChange={(event): void => {
            onInputChange(event.target.value)
          }}
        />
      )}
      disabled={isDisabled}
      sx={{ maxWidth: 210, height: '100%' }}
    />
  )
}

import { uiReducer } from './features/uiSlice/uiSlice'
import { resourcesReducer } from './features/resourcesSlice/resourcesSlice'
import { ApplicationApi } from './features/apis/ApplicationApi'
import { environmentHelper } from '../helpers/environmentHelper'
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['viewingMode', 'sortingModeV2', 'sortingDirection']
  },
  uiReducer
)

export const store = configureStore({
  devTools: environmentHelper.isDevelopment(),
  reducer: {
    ui: persistedReducer,
    resources: resourcesReducer,
    [ApplicationApi.reducerPath]: ApplicationApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([ApplicationApi.middleware])
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

import { FORUM_URL } from '../../constants'
import { Alert, Link } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const GeneralBlockingError = (): React.ReactElement => {
  const { t } = useTranslation(['error'])

  return (
    <Alert severity="error" icon={false} sx={{ margin: 4 }}>
      <p>{t('generalBlockingError.one', { ns: 'error' })}</p>
      <p>
        {t('generalBlockingError.two', { ns: 'error' })}{' '}
        <Link
          href="#"
          onClick={(): void => {
            window['Intercom']?.('show')
          }}
        >
          {t('support')}
        </Link>{' '}
        {t('generalBlockingError.three', { ns: 'error' })} <Link href={FORUM_URL}> {t('forum')}</Link>.
      </p>
    </Alert>
  )
}

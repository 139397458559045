import * as React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  tabIndex: number
  value: number
}

export const TabPanel = ({ children, value, tabIndex, ...other }: TabPanelProps): React.ReactElement => {
  if (value !== tabIndex) {
    return null
  }

  return (
    <div role="tabpanel" hidden={value !== tabIndex} id={`comments-tabpanel-${tabIndex}`} {...other}>
      {children}
    </div>
  )
}

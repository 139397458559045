import { ProjectPreferencesToggles } from './ProjectPreferencesToggles'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'

export const ProjectPreferences = (): React.ReactElement => {
  const { t } = useTranslation(['adminArea'])

  return (
    <Stack direction="column" gap={4} pb={4}>
      <Typography variant="textBold">{t('organizationSettings.projectPreferences')}</Typography>

      <ProjectPreferencesToggles />
    </Stack>
  )
}

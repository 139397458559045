import { Space, SpaceContent } from './interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { apiTags } from '../../apiTags'
import { t } from 'i18next'

const spacesSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    // This method is returning all the spaces for a user, including personal and team spaces.
    // This method is also including the permissions flag to define the user's operations on the space.
    getUserSpaces: builder.query<Space[], void>({
      query: () => 'api/v1/spaces',
      transformResponse: (response: SpaceContent) => {
        response.personalSpaces[0].name = t('myProjects')
        return [...response.personalSpaces, ...response.teamSpaces]
      },
      providesTags: [{ type: apiTags.SPACES }]
    }),

    // This method is only returning the team spaces for an organization.
    getOrganizationSpaces: builder.query<Space[], { organizationId: string }>({
      query: ({ organizationId }) => {
        return `api/v1/organizations/${organizationId}/spaces`
      },
      transformResponse: (response: SpaceContent) => {
        return response.teamSpaces
      },
      providesTags: [{ type: apiTags.SPACES }]
    })
  })
})

export const { useGetUserSpacesQuery, useGetOrganizationSpacesQuery } = spacesSlice

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setIsLoading } from '../../store/features/uiSlice/uiSlice'
import { CircularProgress } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/system'

export const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  left: 'calc(50% - 30px)',
  top: 'calc(50% - 30px)',
  height: '60px',
  width: '60px',
  margin: '0px auto',
  zIndex: 100
}))

export const CenteredCircularProgress = (): React.ReactElement => {
  const isLoading = useAppSelector((state) => state.ui.isLoading)
  return isLoading && <StyledCircularProgress />
}

export const useCenteredCircularProgress = (enabled: boolean): void => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (enabled) {
      dispatch(setIsLoading(true))
    }
    return () => {
      dispatch(setIsLoading(false))
    }
  }, [enabled])
}

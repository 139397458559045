import { StyledTooltip } from '../../tooltip/StyledTooltip'
import theme from '../../../theme/defaultTheme'
import { getQaSelectorText } from '../../../helpers/qaSelectors'
import { Box, InputLabel } from '@mui/material'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import * as React from 'react'

interface ModalInputCheckboxProps extends CheckboxProps {
  label?: string
  tooltip?: string | React.ReactElement
}

export const ModalInputCheckbox = ({ label, tooltip, ...props }: ModalInputCheckboxProps): React.ReactElement => {
  const qaSelectorText = getQaSelectorText(label)
  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
        gridGap: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '&:last-child': { marginBottom: 0 }
      }}
      mb={2}
    >
      <StyledTooltip title={tooltip} placement="right">
        <Checkbox
          sx={{ color: 'text.primary' }}
          color="primary"
          {...props}
          disableRipple
          qa-attribute={`${qaSelectorText}--checkbox`}
        />
      </StyledTooltip>
      <InputLabel sx={{ color: 'text.primary' }} qa-attribute={`${qaSelectorText}--label`}>
        {label}
      </InputLabel>
    </Box>
  )
}

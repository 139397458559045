import { PagePath } from '../constants'
import { PathSelector } from '../containers/spaces/interfaces'
import { useGetProjectV3Details } from '../store/features/apis/slices/project/helpers'
import { usePersonalSpaceId } from '../store/features/apis/slices/space/hooks'
import { getProjectPath } from '../utils/helpers'
import { isNil, last } from 'lodash'
import { useLocation, useParams } from 'react-router-dom'
import * as React from 'react'

type UseCurrentPath = () => { currentPath: PathSelector | null; currentPathNode: string | null; isLoading: boolean }

export const useCurrentPath: UseCurrentPath = () => {
  const { pathname } = useLocation()
  const path: string = pathname.replace(/^\/|\/$/g, '')
  const { projectId, spaceId, folderId } = useParams<{ projectId?: string; spaceId?: string; folderId?: string }>()
  const personalSpaceId = usePersonalSpaceId()
  const { data: project, isLoading } = useGetProjectV3Details({ projectId }, { skip: isNil(projectId) })

  const previousPath = React.useRef<PathSelector>([])
  const currentPath = React.useMemo(() => {
    if (!isLoading) {
      let newPath = []
      if (path === PagePath.MY_PROJECTS) {
        newPath = [personalSpaceId]
      } else if (!isNil(spaceId) && !isNil(folderId)) {
        newPath = [spaceId, folderId]
      } else if (!isNil(spaceId)) {
        newPath = [spaceId]
      } else if (!isNil(projectId) && !isNil(project)) {
        newPath = getProjectPath(project, project.isSharedWithRequester ? PagePath.SHARED_WITH_ME : personalSpaceId)
      } else if (!isNil(path)) {
        newPath = path.split('/')
      }
      newPath = newPath.filter((node) => !isNil(node))
      previousPath.current = newPath
      return newPath
    } else if (previousPath.current) {
      return previousPath.current
    }
    return []
  }, [path, personalSpaceId, spaceId, folderId, projectId, project, isLoading])

  return {
    currentPath,
    currentPathNode: last(currentPath),
    isLoading
  }
}

import { getUserFlowTokenByEnv } from './helpers'
import { isNil } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import userflow from 'userflow.js'
import { UserData } from '@/components/modal/templates/share/interfaces'
import { getCookie, getUsername } from '@/helpers/cookies'
import { getUserPlanLabelKey } from '@/hooks/planHooks'
import { OrganizationAttributes } from '@/store/features/apis/slices/organization/interfaces'
import { useGetUserQuery } from '@/store/features/apis/slices/user/userSlice'
import { useAttributes } from '@/hooks/newWorld'

export const useSetupUserflow = (): void => {
  const { t } = useTranslation('header')
  const username = getUsername()
  const userflowToken = getUserFlowTokenByEnv()
  const { data: user } = useGetUserQuery({ username }, { skip: isNil(username) || isNil(userflowToken) })
  const { osanoConsent } = getCookie()
  const { data: attributes } = useAttributes()

  React.useEffect(() => {
    const initializeAndIdentify = (userflowToken: string, user: UserData, attributes: OrganizationAttributes): void => {
      const userPlanLabelKey = getUserPlanLabelKey(attributes)

      if (!isNil(userflowToken)) {
        userflow.init(userflowToken)
        userflow.identify(user.uidExt.toString(), {
          username: user.username,
          email: user.emailAddress,
          name: `${user.firstname} ${user.lastname}`,
          organization_id: user.organizationInfo?.organizationId,
          organization_status: user.organizationInfo?.organizationStatus,
          userPlan: t(userPlanLabelKey),
          isSimScaleUser: !isNil(attributes?.isSimscale) && attributes.isSimscale
        })
      }
    }

    if (osanoConsent && !isNil(userflowToken) && !isNil(user) && !isNil(attributes)) {
      initializeAndIdentify(userflowToken, user, attributes)
    }
  }, [osanoConsent, userflowToken, user, attributes])
}

import { ToolbarButton } from './ToolbarButton'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { last } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateFolderOperation } from '@/operations/folder/createFolderOperation'
import { PagePath } from '@/constants'
import { useIsSearchMode } from '@/hooks/useIsSearchMode'
import { useIsSharedWithMePage } from '@/hooks/useIsSharedWithMePage'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const CreateFolderButton = (): React.ReactElement => {
  const { currentPath: selectedPath } = useCurrentPath()
  const inTeamSpaces = last(selectedPath) === PagePath.TEAM_SPACES
  const { t } = useTranslation(['toolbar', 'tooltips'])
  const isSearchMode = useIsSearchMode()
  const isSharedWithMePage = useIsSharedWithMePage()

  const [createFolder, canCreateFolder, isLoading] = useCreateFolderOperation()

  const isDisabled = !canCreateFolder || isSearchMode || inTeamSpaces || isSharedWithMePage || isLoading

  if (isDisabled) {
    return null
  }

  return (
    <ToolbarButton
      title={t('createFolder', { ns: 'tooltips' })}
      icon={<SpriteIcon spriteId="plus" height=".8125rem" width=".8125rem" color="primary.main" />}
      onClick={(): void => {
        createFolder()
      }}
      label={t('newFolderButtonLabel', { ns: 'toolbar' })}
      variant="outlined"
      tabIndex={TAB_INDEXES.CREATE_FOLDER_BUTTON}
    />
  )
}

import { ContextMenuType } from './ContextMenu'
import { ContextMenuItem } from './ContextMenuItem'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { closeContextMenu, openModal, selectSingleItem } from '../../store/features/uiSlice/uiSlice'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { ResourceType } from '../../containers/detailsPanel/components/DetailsList'
import { OperationId } from '../../operations/interfaces'
import { useProjectOperation } from '../../operations/project/operations'
import { MoveItem } from '../modal/templates/moveProject/interfaces'
import { useMoveOperation } from '../../operations/commons/moveOperation'
import { useDeleteFolderOperation } from '../../operations/folder/deleteFolderOperation'
import { useEditFolderOperation } from '../../operations/folder/editFolderOperation'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { useDeleteProjectOperation } from '../../operations/project/deleteProjectOperation'
import { useEditProjectOperation } from '../../operations/project/editProjectOperation'
import { useCopyProjectOperation } from '../../operations/project/copyProjectOperation'
import { useDeleteSpaceOperation } from '../../operations/space/deleteSpaceOperation'
import { InviteStatus } from '../../store/features/apis/slices/invitation/interfaces'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useIsSharedWithMePage } from '../../hooks/useIsSharedWithMePage'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { filterSearchParams } from '../../helpers/navigation'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useUnreleaseModelVersionOperation } from '@/operations/ai/models/versions/unreleaseModelVersionOperation'
import { useDeleteModelVersionOperation } from '@/operations/ai/models/versions/deleteModelVersionOperation'
import { useOpenFolderOperation } from '@/operations/folder/openFolderOperation'
import { useViewModelTrainingRunLogsOperation } from '@/operations/ai/models/versions/viewModelTrainingRunLogsOperation'
import { useCanCreateNewModelVersion } from '@/operations/ai/models/versions/useCanCreateNewModelVersion'

const iconPlusButton = require('../../assets/svg/plus-button.svg')
const iconOpenButton = require('../../assets/svg/open-button.svg')
const iconShareButton = require('../../assets/svg/share-button.svg')
const iconCopyButton = require('../../assets/svg/copy-button.svg')
const iconMoveButton = require('../../assets/svg/move-button.svg')
const iconEditButton = require('../../assets/svg/edit-button.svg')
const iconTrashButton = require('../../assets/svg/trash-button.svg')

interface DisplayButtonsType {
  [key: string]: string[]
}

export const useContextMenuItems = (): React.ReactElement[] => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['contextMenu', 'modals'])

  const { navigate, navigateNoReset } = useDashboardNavigate()
  const [searchParams] = useSearchParams()

  const { currentPath } = useCurrentPath()

  const { type, folder, project, space, invitation, version, trainingPipeline } = useAppSelector(
    (state) => state.ui.contextMenu
  )

  const [openFolder, canOpenFolder] = useOpenFolderOperation()
  const [editFolder, canEditFolder] = useEditFolderOperation(folder?.folderId)
  const [deleteFolder, canDeleteFolder] = useDeleteFolderOperation(folder?.folderId)
  const [moveItems, canMoveItems] = useMoveOperation()

  const [openProject, canOpenProject] = useProjectOperation(OperationId.OPEN, { projectId: project?.projectIdExt })
  const [editProject, canEditProject] = useEditProjectOperation(project?.projectIdExt)
  const [shareProject, canShareProject] = useProjectOperation(OperationId.SHARE, { projectId: project?.projectIdExt })
  const [copyProject, canCopyProject] = useCopyProjectOperation(project?.projectIdExt)
  const [deleteProject, canDeleteProject] = useDeleteProjectOperation(project?.projectIdExt)

  const [deleteSpace, canDeleteSpace] = useDeleteSpaceOperation(space)

  const [unreleaseModelVersion, canUnreleaseModelVersion] = useUnreleaseModelVersionOperation(version)
  const [deleteModelVersion, canDeleteModelVersion] = useDeleteModelVersionOperation(version)
  const [viewModelTrainingRunLogs] = useViewModelTrainingRunLogsOperation({ version, trainingPipeline })
  const canCreateNewVersion = useCanCreateNewModelVersion(version?.model_uuid)

  const canApproveInvitation = React.useMemo(
    () => invitation?.status === InviteStatus.PENDING_ADMIN_APPROVAL,
    [invitation]
  )

  const canRejectInvitation = React.useMemo(
    () =>
      invitation?.status === InviteStatus.PENDING_ADMIN_APPROVAL ||
      invitation?.status === InviteStatus.PENDING_USER_CONFIRMATION,
    [invitation]
  )

  const { selectedItems, isSelectedItem } = useMultiSelect()

  const isSearchMode = useIsSearchMode()
  const isSharedWithMePage = useIsSharedWithMePage()

  const [actualType, setActualType] = React.useState<ContextMenuType>(undefined)

  const displayButtons: DisplayButtonsType = {
    [ContextMenuType.FOLDER]: ['open', 'move', 'edit', 'trash'],
    [ContextMenuType.PROJECT]: ['open', 'share', 'copy', 'move', 'edit', 'trash'],
    [ContextMenuType.MULTI_SELECTION]: [...(!isSearchMode ? ['move'] : [])],
    [ContextMenuType.SPACE]: ['edit', ...(canDeleteSpace ? ['trash'] : [])],
    [ContextMenuType.INVITATION]: ['approveInvitation', 'rejectInvitation'],
    [ContextMenuType.MODEL_VERSION]: ['newVersion', 'viewLogs', 'deleteVersion', 'unreleaseVersion'],
    [ContextMenuType.MODEL_TRAINING_PIPELINE]: ['viewLogs']
  }

  const items: React.ReactElement[] = []

  React.useEffect(() => {
    let newType = type
    if ([ContextMenuType.FOLDER, ContextMenuType.PROJECT].includes(type)) {
      const haveMultipleItemsSelected = selectedItems.folders.length + selectedItems.projects.length > 1
      const selectionType = type === ContextMenuType.FOLDER ? 'folder' : 'project'
      const selectionId = type === ContextMenuType.FOLDER ? folder?.folderId : project?.projectIdExt

      if (haveMultipleItemsSelected && isSelectedItem(selectionId, selectionType)) {
        newType = ContextMenuType.MULTI_SELECTION
      } else if (!isSelectedItem(selectionId, selectionType)) {
        dispatch(selectSingleItem({ id: selectionId, type: selectionType }))
        if (selectionType === 'project') {
          const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
          const projectUrl = `/projects/${selectionId}`
          navigateNoReset(`${projectUrl}${filteredParams}`)
        }
      }
    }
    setActualType(newType)
  }, [type])

  const close = (): void => {
    dispatch(closeContextMenu())
  }

  const handleOpenClick = React.useCallback((): void => {
    if (actualType === ContextMenuType.PROJECT) {
      openProject(project.projectIdExt)
    } else if (actualType === ContextMenuType.FOLDER) {
      openFolder([...currentPath, folder.folderId])
    }
    close()
  }, [actualType, project, folder, currentPath, openProject, openFolder])

  const handleNewModelVersionClick = React.useCallback((): void => {
    navigate(`/models/${version?.model_uuid}/create-version?page=data-selection`)
    close()
  }, [navigate, version?.model_uuid])

  const handleShareClick = React.useCallback((): void => {
    shareProject()
    close()
  }, [shareProject, project])

  const handleCopyClick = React.useCallback((): void => {
    copyProject()
    close()
  }, [copyProject, project])

  const handleMoveClick = React.useCallback((): void => {
    const { folders, projects } = selectedItems

    const items: MoveItem[] = [
      ...folders.map((folderId) => ({ id: folderId, type: ResourceType.FOLDER })),
      ...projects.map((projectId) => ({ id: projectId, type: ResourceType.PROJECT }))
    ]

    if (folder && !items.some(({ id }) => id === folder.folderId)) {
      items.push({ id: folder.folderId, type: ResourceType.FOLDER })
    }

    if (project && !items.some(({ id }) => id === project.projectIdExt)) {
      items.push({ id: project.projectIdExt, type: ResourceType.PROJECT })
    }

    moveItems(items)
    close()
  }, [selectedItems, folder, project])

  const handleEditClick = React.useCallback((): void => {
    if (actualType === ContextMenuType.PROJECT) {
      editProject()
    } else if (actualType === ContextMenuType.FOLDER) {
      editFolder()
    } else if (actualType === ContextMenuType.SPACE) {
      dispatch(
        openModal({
          template: ModalTemplate.SPACE_MEMBERS,
          showCloseButton: true,
          modalProps: {
            title: t('teamSpace.editSpace', { ns: 'modals', spaceName: space.name }),
            selectedTeamSpace: space
          }
        })
      )
    }
    close()
  }, [actualType, space, editProject, editFolder])

  const handleTrashClick = React.useCallback((): void => {
    if (actualType === ContextMenuType.PROJECT) {
      deleteProject()
    } else if (actualType === ContextMenuType.FOLDER) {
      deleteFolder()
    } else if (actualType === ContextMenuType.SPACE) {
      deleteSpace()
    } else if (actualType === ContextMenuType.MODEL_VERSION) {
      deleteModelVersion()
    }
    close()
  }, [actualType, deleteProject, deleteFolder, deleteSpace, deleteModelVersion])

  const handleInvitationApproval = React.useCallback((): void => {
    dispatch(
      openModal({
        template: ModalTemplate.APPROVE_INVITATION,
        showCloseButton: true,
        modalProps: {
          title: t('approveInvitation.title', { ns: 'modals' }),
          inviteId: invitation.inviteId
        }
      })
    )
  }, [invitation])

  const handleInvitationRejection = React.useCallback((): void => {
    dispatch(
      openModal({
        template: ModalTemplate.REJECT_INVITATION,
        showCloseButton: true,
        modalProps: {
          title: t('rejectInvitation.title', { ns: 'modals' }),
          inviteId: invitation.inviteId
        }
      })
    )
  }, [invitation])

  const handleUnreleaseModelVersion = React.useCallback((): void => {
    unreleaseModelVersion()
    close()
  }, [unreleaseModelVersion])

  const handleViewModelTrainingRunLogsClick = React.useCallback((): void => {
    viewModelTrainingRunLogs()
    close()
  }, [viewModelTrainingRunLogs])

  if (!isNil(actualType) && !isEmpty(displayButtons[actualType])) {
    if (displayButtons[actualType].includes('open')) {
      items.push(
        <ContextMenuItem
          key="open"
          label={t('open')}
          imageUrl={iconOpenButton}
          onClick={handleOpenClick}
          disabled={
            (actualType === ContextMenuType.PROJECT && !canOpenProject) ||
            (actualType === ContextMenuType.FOLDER && !canOpenFolder)
          }
        />
      )
    }
    if (displayButtons[actualType].includes('share')) {
      items.push(
        <ContextMenuItem
          key="share"
          label={t('share')}
          imageUrl={iconShareButton}
          onClick={handleShareClick}
          disabled={!canShareProject}
        />
      )
    }
    if (displayButtons[actualType].includes('copy')) {
      items.push(
        <ContextMenuItem
          key="copy"
          label={t('makeACopy')}
          imageUrl={iconCopyButton}
          onClick={handleCopyClick}
          disabled={actualType === ContextMenuType.PROJECT && !canCopyProject}
        />
      )
    }
    if (displayButtons[actualType].includes('move')) {
      items.push(
        <ContextMenuItem
          key="move"
          label={t('move')}
          imageUrl={iconMoveButton}
          onClick={handleMoveClick}
          disabled={isSharedWithMePage || !canMoveItems}
        />
      )
    }
    if (displayButtons[actualType].includes('edit')) {
      items.push(
        <ContextMenuItem
          key="edit"
          label={t('edit')}
          imageUrl={iconEditButton}
          onClick={handleEditClick}
          disabled={
            (actualType === ContextMenuType.PROJECT && !canEditProject) ||
            (actualType === ContextMenuType.FOLDER && !canEditFolder)
          }
        />
      )
    }
    if (displayButtons[actualType].includes('trash')) {
      items.push(
        <ContextMenuItem
          key="trash"
          label={t('trash')}
          imageUrl={iconTrashButton}
          onClick={handleTrashClick}
          disabled={
            (actualType === ContextMenuType.PROJECT && !canDeleteProject) ||
            (actualType === ContextMenuType.FOLDER && !canDeleteFolder) ||
            (actualType === ContextMenuType.SPACE && !canDeleteSpace)
          }
        />
      )
    }
    if (displayButtons[actualType].includes('approveInvitation') && canApproveInvitation) {
      items.push(
        <ContextMenuItem
          key="approveInvitation"
          label={t('approveInvitation')}
          onClick={handleInvitationApproval}
          disabled={false}
        />
      )
    }
    if (displayButtons[actualType].includes('rejectInvitation') && canRejectInvitation) {
      items.push(
        <ContextMenuItem
          key="rejectInvitation"
          label={t('rejectInvitation')}
          onClick={handleInvitationRejection}
          disabled={false}
        />
      )
    }
    if (displayButtons[actualType].includes('newVersion') && canCreateNewVersion) {
      items.push(
        <ContextMenuItem
          key="newVersion"
          label={t('newVersion')}
          imageUrl={iconPlusButton}
          onClick={handleNewModelVersionClick}
        />
      )
    }
    if (displayButtons[actualType].includes('viewLogs')) {
      items.push(<ContextMenuItem key="viewLogs" label={t('viewLogs')} onClick={handleViewModelTrainingRunLogsClick} />)
    }
    if (displayButtons[actualType].includes('deleteVersion') && canDeleteModelVersion) {
      items.push(<ContextMenuItem key="deleteVersion" label={t('deleteVersion')} onClick={handleTrashClick} />)
    }
    if (displayButtons[actualType].includes('unreleaseVersion') && canUnreleaseModelVersion) {
      items.push(
        <ContextMenuItem key="unreleaseVersion" label={t('unreleaseVersion')} onClick={handleUnreleaseModelVersion} />
      )
    }
  }

  return items
}

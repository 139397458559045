import { PlaceholderBox } from '../../components/placeholders/PlaceholderBox'
import { getViewingMode } from '../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../store/hooks'
import { ResourceType } from '../detailsPanel/components/DetailsList'
import { useCreateProjectOperation } from '../../operations/project/createProjectOperation'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const CreateProjectPlaceholder = (): React.ReactElement => {
  const displayType = useAppSelector(getViewingMode)
  const { currentPath } = useCurrentPath()
  const { t } = useTranslation(['placeholders', 'modals'])

  const [createProject, hasPermission, isLoading] = useCreateProjectOperation(currentPath)
  if (!hasPermission || isLoading) {
    return null
  }

  return (
    <PlaceholderBox
      resourceType={ResourceType.PROJECT}
      displayType={displayType}
      onClick={(): void => {
        createProject()
      }}
      label={t('createProjectLabel')}
    />
  )
}

import { Button } from '../button/Button'
import { StyledTooltip } from '../tooltip/StyledTooltip'
import { ButtonProps, Typography } from '@mui/material'
import * as React from 'react'

type ToolbarButtonProps = ButtonProps & {
  title: string
  icon: React.ReactNode
  label: string
  onClick: () => void
}

export const ToolbarButton = ({ title, icon, label, onClick, ...rest }: ToolbarButtonProps): React.ReactElement => {
  return (
    <StyledTooltip title={title} placeholder="bottom">
      {/* Span is required to avoid a warning about disabled tooltip */}
      <span>
        <Button startIcon={icon} onClick={onClick} size="large" sx={{ minWidth: 120 }} {...rest}>
          <Typography variant="text" flexShrink={0}>
            {label}
          </Typography>
        </Button>
      </span>
    </StyledTooltip>
  )
}

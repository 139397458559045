import { SearchFieldUi } from './SearchFieldUi'
import { SearchType } from './SearchField'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

type AiSearchFieldType = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
}

export const AiSearchField = ({ value, setValue }: AiSearchFieldType): React.ReactElement => {
  const [_, setSearchParams] = useSearchParams()

  const handleSearch = React.useCallback((value: string): void => {
    const isValueValid = value.trim() !== ''
    if (isValueValid) {
      setSearchParams({ page: 'data-selection', q: value, section: 'search', searchType: SearchType.AI })
    } else {
      setSearchParams({ page: 'data-selection' })
    }
  }, [])

  return <SearchFieldUi value={value} setValue={setValue} handleSearch={handleSearch} qaAttribute="ai-search-field" />
}

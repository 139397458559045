import { PermissionType, SpacePermission } from './share/interfaces'
import { SharedWithUsers } from './share/SharedWithUsers'
import { ModalTemplateProps } from '../interfaces'
import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { Button } from '../../button/Button'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isValidNullableInput } from '@/helpers/validationHelpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { usePermissionsPayload } from '@/hooks/usePermissionsPayload'
import { useValidation } from '@/hooks/useValidation'
import { ShareOperationPayload } from '@/operations/interfaces'
import {
  useGetProjectPermissionsQuery,
  usePostProjectPermissionsMutation
} from '@/store/features/apis/slices/project/projectSlice'
import { ModalTemplate } from '@/store/features/uiSlice/interfaces'
import { addSnackbarToState, openModal } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import theme from '@/theme/defaultTheme'
import { SpriteIcon } from '@/assets/SpriteIcon'

const lockSolid = require('../../../assets/svg/lock-solid.svg')

const PrivateProjectStackStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderLeft: `${theme.spacing(1)} solid ${theme.palette.grey['400']}`,
  borderTop: `1px solid ${theme.palette.grey['200']}`,
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
  padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(5)}`,
  marginBottom: theme.spacing(6)
}))

const PrivateProjectIconStyled = styled('div')(({ theme }) => ({
  flexShrink: '0',
  width: '32px',
  height: '32px',
  border: `1px solid ${theme.palette.grey['200']}`,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `url(${lockSolid})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '12px'
}))

export interface ShareModalTemplateProps extends ModalTemplateProps, ShareOperationPayload {
  projectIdExt: string
}

export const ShareModalTemplate = ({
  onClose,
  onCancel,
  projectName,
  ownerName,
  publicProjectName,
  projectIdExt,
  requesterPermissions,
  publicPermission
}: ShareModalTemplateProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { validateField } = useValidation()
  const { getPermission } = usePermissionsPayload()
  const [usernameOrEmail, setUsernameOrEmail] = React.useState<string>('')
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [updatedPermissions, setUpdatedPermissions] = React.useState<SpacePermission[]>([])
  const [postProjectPermissions] = usePostProjectPermissionsMutation()
  const { handleApiHookErrors } = useErrorHandler()

  const { data: projectPermissions, isFetching } = useGetProjectPermissionsQuery(
    { ownerName, publicProjectName },
    { skip: isNil(requesterPermissions) || !requesterPermissions?.canWriteProject }
  )

  const onError = React.useCallback((error) => {
    setIsLoading(false)
    handleApiHookErrors(error)
    onClose()
  }, [])

  const onSuccessfulPermissionsUpdate = React.useCallback(() => {
    setIsLoading(false)
    dispatch(
      addSnackbarToState({
        severity: 'info',
        message: t(`share.successfulPermissionsUpdate`)
      })
    )
    onClose()
  }, [])

  const handleOnChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const inputValueTrimmed = event.target.value.trim()
    const validation = validateField(inputValueTrimmed, [isValidNullableInput])
    setUsernameOrEmail(inputValueTrimmed)
    setError(!validation.valid)
    setErrorMessage(validation.message)
  }, [])

  const onUserPermissionChange = React.useCallback((email: string, permission: PermissionType): void => {
    const payload = getPermission(email, permission)
    setUpdatedPermissions((prevState) => {
      prevState = prevState.filter((item) => item?.scope?.email !== email)
      return [...prevState, payload]
    })
  }, [])

  const handleSaveOnClick = React.useCallback(
    (updatedPermissions: SpacePermission[]) => {
      setIsLoading(true)
      postProjectPermissions({
        ownerName,
        publicProjectName,
        permissions: { permissions: updatedPermissions }
      })
        .unwrap()
        .then(() => {
          setUpdatedPermissions([])
          onSuccessfulPermissionsUpdate()
        })
        .catch(onError)
    },
    [ownerName, publicProjectName]
  )

  const handleOnSubmit = React.useCallback(() => {
    if (!error && !isEmpty(usernameOrEmail)) {
      dispatch(
        openModal({
          template: ModalTemplate.SHARE_WITH_USERS,
          modalProps: {
            sharedModalUsernamesOrEmails: !isEmpty(usernameOrEmail) ? [usernameOrEmail] : [],
            ownerName,
            publicProjectName,
            projectIdExt
          }
        })
      )
    }
  }, [error, usernameOrEmail, ownerName, publicProjectName, projectIdExt])

  const submitDisabled = isEmpty(updatedPermissions) || error || isLoading

  return (
    <React.Fragment>
      <ModalTitle onClose={onClose}>
        {t('share.labels.share')} - {projectName}
      </ModalTitle>
      {!publicPermission.read && (
        <PrivateProjectStackStyled direction="row" alignItems="center">
          <PrivateProjectIconStyled />
          <Stack direction="column" flexGrow={1} marginLeft="15px">
            <Typography variant="text" color="text.primary">
              {t('share.labels.private')}
            </Typography>
            <Typography variant="subtitle" color="grey.700">
              {t('share.labels.onlyWithShared')}
            </Typography>
          </Stack>
          <Typography variant="text" color="grey.700">
            {t('share.labels.private')}
          </Typography>
        </PrivateProjectStackStyled>
      )}
      <ModalContent onSubmit={(): void => handleSaveOnClick(updatedPermissions)} submitDisabled={submitDisabled}>
        <Typography
          variant="text"
          color="text.primary"
          paragraph
          sx={{ fontWeight: 'bold', marginBottom: theme.spacing(2) }}
        >
          {t('share.labels.shareWith')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            direction: 'row',
            alignItems: 'center',
            gridGap: theme.spacing(2),
            '&:last-child': { marginBottom: 0 }
          }}
          my={2}
        >
          <TextField
            id="share-project"
            placeholder={t('share.fields.userNameOrEmail')}
            required
            size="small"
            fullWidth
            variant="standard"
            value={usernameOrEmail}
            onChange={handleOnChange}
            onKeyDown={(event): void => {
              event.stopPropagation()
              if (event.key === 'Enter') {
                handleOnSubmit()
              }
            }}
            error={error}
            helperText={errorMessage}
            qa-attribute={`share-with--textfield`}
          />
          <Button
            disabled={isEmpty(usernameOrEmail)}
            onClick={handleOnSubmit}
            color="secondary"
            variant="outlined"
            sx={{ marginRight: 'auto', minWidth: '32px', padding: '0' }}
            qa-attribute={`share-with--button`}
          >
            <SpriteIcon spriteId="user-plus" width={17} height={32} />
          </Button>
        </Box>

        {!isNil(projectPermissions) ? (
          isFetching ? (
            <Stack alignItems="center" style={{ marginTop: 50 }}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box py={2}>
              <Typography
                variant="text"
                color="text.primary"
                paragraph
                sx={{ fontWeight: 'bold', marginBottom: theme.spacing(2) }}
              >
                {t('share.labels.usersWithAccess')}
              </Typography>
              <SharedWithUsers projectPermissions={projectPermissions} onPermissionChange={onUserPermissionChange} />
            </Box>
          )
        ) : null}
      </ModalContent>
      <ModalActions>
        <Button onClick={onCancel} color="secondary" variant="outlined">
          {t('share.labels.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={(): void => {
            handleSaveOnClick(updatedPermissions)
          }}
          disabled={submitDisabled}
        >
          {t('share.labels.save')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

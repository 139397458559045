import { PagePath } from '../constants'
import { useLocation } from 'react-router-dom'

export const ProfilePages: {
  [key: string]: PagePath
} = {
  PROFILE: PagePath.PROFILE,
  MY_INVITATIONS: PagePath.MY_INVITATIONS,
  USAGE_OVERVIEW: PagePath.USAGE_OVERVIEW,
  API_KEYS: PagePath.API_KEYS,
  SECURITY: PagePath.SECURITY
}
export type ProfilePagesType = keyof typeof ProfilePages

export const useIsProfileSectionPage = (): boolean => {
  const { pathname } = useLocation()
  const path: string = pathname.replace(/^\/|\/$/g, '')
  return Object.values(ProfilePages).includes(path as PagePath)
}

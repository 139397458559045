import Chip, { ChipProps } from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import styled from '@mui/system/styled'
import { isNil } from 'lodash'
import * as React from 'react'
import { CircularProgress, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ModelStage, ModelVersion, TrainingRunStatus } from '@/store/features/apis/slices/ai/interfaces'
import { useGetTrainingRunQuery } from '@/store/features/apis/slices/ai/aiSlice'

type TrainingRunDetailsProps = {
  version?: ModelVersion
  trainingRunId: string
}

const StateChip = styled(Chip)(({ theme }) => ({
  fontWeight: 700,
  textTransform: 'uppercase',
  height: theme.spacing(4.5),
  '&.MuiChip-colorDefault': {
    backgroundColor: theme.palette.grey[150],
    color: theme.palette.text.primary
  },
  '&.MuiChip-colorSuccess': {
    backgroundColor: theme.palette.success[200],
    color: theme.palette.text.primary
  },
  '&.MuiChip-colorError': {
    backgroundColor: theme.palette.error[200],
    color: theme.palette.error[800]
  },
  '&.MuiChip-colorWarning': {
    backgroundColor: theme.palette.warning[200],
    color: theme.palette.text.primary
  }
}))

const TrainingRunProgress = ({ percentage }: { percentage: number }): React.ReactElement => {
  return (
    <Stack spacing={2} width="100%">
      <Stack direction="row" spacing={1} alignItems="center">
        <CircularProgress size={12} />
        <Typography variant="textV2" color="primary" fontWeight="bold">
          Started {percentage}%
        </Typography>
      </Stack>
      <LinearProgress variant="determinate" value={percentage} color="primary" sx={{ height: 4 }} />
    </Stack>
  )
}

export const TrainingRunDetails = ({ version, trainingRunId }: TrainingRunDetailsProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const {
    data: trainingRun,
    refetch,
    isLoading
  } = useGetTrainingRunQuery({ trainingRunId }, { skip: isNil(trainingRunId) })

  let interval: NodeJS.Timeout
  React.useEffect(() => {
    if (trainingRun?.status !== TrainingRunStatus.FINISHED && trainingRun?.status !== TrainingRunStatus.FAILED) {
      interval = setInterval(() => {
        refetch()
      }, 30000)
    }
    return () => {
      clearInterval(interval)
    }
  }, [trainingRun?.status])

  const stateLabel = React.useMemo(() => {
    if (version?.stage === ModelStage.PRODUCTION) {
      return t('trainingRun.status.RELEASED')
    }
    if (!isNil(version) && trainingRun?.status === TrainingRunStatus.FINISHED) {
      return t(`trainingRun.status.READY`)
    }

    return t(`trainingRun.status.${trainingRun?.status}`)
  }, [version?.stage, trainingRun?.status])

  const stateColor: ChipProps['color'] = React.useMemo(() => {
    if (version?.stage === ModelStage.PRODUCTION) {
      return 'success'
    }

    switch (trainingRun?.status) {
      case TrainingRunStatus.FINISHED:
        return 'primary'
      case TrainingRunStatus.TRAINING:
        return 'warning'
      case TrainingRunStatus.FAILED:
        return 'error'
      default:
        return 'default'
    }
  }, [version?.stage, trainingRun?.status])

  const showProgress = !isNil(trainingRun?.progress) && trainingRun?.status === TrainingRunStatus.TRAINING

  if (isLoading) {
    return null
  }

  return (
    <Stack
      direction="row"
      spacing={6}
      alignItems="center"
      width={400}
      flexShrink={1}
      flexGrow={1}
      qa-attribute="aiModel--trainingRun--details"
    >
      <StateChip label={stateLabel} color={stateColor} size="small" />
      {showProgress && <TrainingRunProgress percentage={trainingRun?.progress * 100} />}
    </Stack>
  )
}

import { useOrganizationId } from '../../../../hooks/newWorld'
import { NavbarItem } from '../../../navigation/NavbarItem'
import { useGetOrganizationSettingsQuery } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { ProfilePages } from '../../../../hooks/useIsProfileSectionPage'
import { useCurrentProfilePage } from '../../../../hooks/useCurrentProfilePage'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { isNil } from 'lodash'

export const ProfileTabBar = (): React.ReactElement => {
  const { t } = useTranslation()
  const activeTab = useCurrentProfilePage()
  const { data: organizationId } = useOrganizationId()
  const { data: settings } = useGetOrganizationSettingsQuery({ organizationId }, { skip: isNil(organizationId) })
  const showMyInvitations = isNil(organizationId)
  const showSecurity = isNil(organizationId) || settings?.enablePasswordLogin

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2} mt={5} mx={0}>
      <NavbarItem
        label={t('personalDetails')}
        route={ProfilePages.PROFILE}
        isSelected={activeTab === ProfilePages.PROFILE}
      />
      {showMyInvitations && (
        <NavbarItem
          label={t('myInvitations')}
          route={ProfilePages.MY_INVITATIONS}
          isSelected={activeTab === ProfilePages.MY_INVITATIONS}
        />
      )}
      <NavbarItem
        label={t('usageOverview')}
        route={ProfilePages.USAGE_OVERVIEW}
        isSelected={activeTab === ProfilePages.USAGE_OVERVIEW}
      />
      {showSecurity && (
        <NavbarItem
          label={t('security')}
          route={ProfilePages.SECURITY}
          isSelected={activeTab === ProfilePages.SECURITY}
        />
      )}
      <NavbarItem label={t('apiKeys')} route={ProfilePages.API_KEYS} isSelected={activeTab === ProfilePages.API_KEYS} />
    </Stack>
  )
}

import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { Button } from '../../button/Button'
import { ModalTemplateProps } from '../interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

type ConfirmPublicProjectPermissionType = ModalTemplateProps & {
  title: string
  onConfirm: () => void
}

export const ConfirmPublicProjectPermission = ({
  title,
  onClose,
  showCloseButton,
  onConfirm
}: ConfirmPublicProjectPermissionType): React.ReactElement => {
  const { t } = useTranslation('modals')

  const handleConfirmOnClick = React.useCallback(() => {
    onConfirm()
    onClose()
  }, [])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        <Typography component="span">
          {t('enablePublicProjectPermission.modalDescription', { ns: 'modals' })}
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('enablePublicProjectPermission.cancel')}
        </Button>

        <Button onClick={handleConfirmOnClick} color="primary" variant="contained" autoFocus>
          {t('enablePublicProjectPermission.enable')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { Button } from '../../button/Button'
import { ModalTemplateProps } from '../interfaces'
import { useAppDispatch } from '../../../store/hooks'
import { addSnackbarToState, deselectAllItems } from '../../../store/features/uiSlice/uiSlice'
import { useGetFolderDetails } from '../../../store/features/apis/slices/folder/helpers'
import { pathToRoute } from '../../../helpers/router'
import { useDeleteFolderMutation } from '../../../store/features/apis/slices/folder/folderSlice'
import { getUsername } from '../../../helpers/cookies'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import { useDashboardNavigate } from '../../../hooks/useDashboardNavigate'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { first, isEmpty, last } from 'lodash'

interface TrashFolderModalTemplateProps extends ModalTemplateProps {
  title: string
  folderId: string
}

export const TrashFolderModalTemplate = ({
  title,
  onClose,
  showCloseButton,
  folderId
}: TrashFolderModalTemplateProps): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { currentPath } = useCurrentPath()
  const spaceId = first(currentPath)

  const [deleteFolder, { isLoading, isSuccess, isError, error }] = useDeleteFolderMutation()

  const { data: folder, isLoading: areItemDetailsLoading } = useGetFolderDetails({ spaceId, folderId })

  const disabledButtons = isLoading || areItemDetailsLoading
  const parentPath = folder?.parentFolderId ?? folder?.spaceId

  React.useEffect(() => {
    if (isError) {
      dispatch(
        addSnackbarToState({
          severity: 'error',
          message: t(`trash.folder.failed`)
        })
      )
    }
  }, [isError, error])

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(
        addSnackbarToState({
          severity: 'info',
          message: t('trash.movedToTrash', { item: folder.name })
        })
      )

      if (folder.folderId === last(currentPath) && !isEmpty(parentPath)) {
        const parentPath = isEmpty('parentFolderId' in folder) ? [spaceId, folder.parentFolderId] : [spaceId]
        navigate(`/${pathToRoute(parentPath)}`)
      } else {
        dispatch(deselectAllItems())
      }

      onClose()
    }
  }, [isSuccess, areItemDetailsLoading, parentPath, spaceId, folder.parentFolderId])

  const handleFolderDeletion = React.useCallback(async (): Promise<void> => {
    // eslint-disable-next-line no-console
    console.info(
      `User ${getUsername()} clicked on the trash button to delete folder ${folder.name} with id ${
        folder.folderId
      } from space ${folder.spaceId}.`
    )

    await deleteFolder({
      spaceId: folder.spaceId,
      folderId: folder.folderId,
      parentItemId: parentPath
    })
  }, [folder.spaceId, folder.folderId, parentPath])

  if (isLoading) {
    return null
  }

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleFolderDeletion} submitDisabled={disabledButtons}>
        <Typography>{t('trash.folder.willBeDeleted', { folderName: folder.name })}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined" disabled={disabledButtons}>
          {t('cancel')}
        </Button>
        <Button onClick={handleFolderDeletion} color="primary" variant="contained" disabled={disabledButtons}>
          {t('trash.trash')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import Stack from '@mui/material/Stack'
import * as React from 'react'

interface ChipsListProps {
  children: React.ReactElement[]
}

export const ChipsList = ({ children }: ChipsListProps): React.ReactElement => {
  return (
    <Stack direction="row" gap={1}>
      {children}
    </Stack>
  )
}

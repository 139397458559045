import { EnhancedTableHead } from './EnhancedTableHead'
import { EnhancedTableRows } from './EnhancedTableRows'
import { applyFilters, getComparator } from './helpers'
import { EnhancedTableProps, Order, TableFilter } from './interfaces'
import { useCenteredCircularProgress } from '../../progress/CenteredCircularProgress'
import { useIsMobile } from '../../../hooks/useIsMobile'
import * as React from 'react'
import { Box, Table, TableBody, TableContainer, TablePagination } from '@mui/material'
import { first, isEmpty } from 'lodash'

export const EnhancedTable = ({
  tableHeaderData,
  tableBodyData,
  useRowSelectionCheckbox,
  displayPagination,
  isLoading,
  filtersConfig,
  defaultSortByColumn = 0,
  defaultOrder = 'asc',
  fixedSorting,
  rowsPerPageOptions = [10, 25, 50],
  defaultRowsPerPage,
  onFiltersChange,
  rowComponent
}: EnhancedTableProps): React.ReactElement => {
  const isMobile = useIsMobile({ breakpoint: 'md' })
  const [order, setOrder] = React.useState<Order>(fixedSorting?.order ?? defaultOrder)
  const [orderBy, setOrderBy] = React.useState<string>(fixedSorting?.column ?? tableHeaderData[defaultSortByColumn]?.id)
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage ?? first(rowsPerPageOptions))
  const [filters, setFilters] = React.useState<TableFilter[]>(filtersConfig ?? [])

  useCenteredCircularProgress(isLoading)

  React.useEffect(() => {
    setPage(0)
  }, [tableBodyData?.length])

  const handleOnRequestSort = React.useCallback((order: Order, orderBy: string): void => {
    setOrder(order)
    setOrderBy(orderBy)
  }, [])

  const handleOnSelectAllClick = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const newSelected = tableBodyData.map((tableRow) => tableRow.tableRowId)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }, [])

  const handleCheckboxOnClick = React.useCallback((tableRowId: string): void => {
    setSelected((prevSate) => {
      if (prevSate.includes(tableRowId)) {
        return prevSate.filter((item) => item !== tableRowId)
      } else {
        return [...prevSate, tableRowId]
      }
    })
  }, [])

  const handleOnChangePage = React.useCallback((_event: unknown, newPage: number): void => {
    setPage(newPage)
  }, [])

  const handleOnRowsPerPageChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }, [])

  const filteredData = React.useMemo(() => {
    if (!isEmpty(filters)) {
      return tableBodyData.filter((row) => applyFilters(row, filters))
    }
    return tableBodyData
  }, [tableBodyData, filters])

  React.useEffect(() => {
    if (onFiltersChange) {
      onFiltersChange(filteredData)
    }
  }, [filteredData, onFiltersChange])

  const sortedData = React.useMemo(() => {
    const data = [...filteredData]
    // If fixedSorting is defined, sort the data by the fixedSorting column and order
    if (fixedSorting) {
      return data.sort(getComparator(fixedSorting.order, fixedSorting.column, tableHeaderData))
    }
    return data.sort(getComparator(order, orderBy, tableHeaderData))
  }, [filteredData, order, orderBy, fixedSorting])

  const paginatedData = React.useMemo(() => {
    return displayPagination ? sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedData
  }, [displayPagination, page, rowsPerPage, sortedData])

  if (isLoading) {
    return null
  }

  return (
    <Box>
      <TableContainer>
        <Table aria-labelledby="table" size={'medium'}>
          {/**
           * TODO: When design specs are available, implement correct enhanced table styles.
           * Currently, it's not clear how it should look, and it's breaking our mobile tests for simba. */}
          {!isMobile && (
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleOnSelectAllClick}
              onRequestSort={handleOnRequestSort}
              rowCount={tableBodyData.length}
              tableHeaderData={tableHeaderData}
              useRowSelectionCheckbox={useRowSelectionCheckbox}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          <TableBody>
            <EnhancedTableRows
              tableRowsData={displayPagination ? paginatedData : sortedData}
              selected={selected}
              useRowSelectionCheckbox={useRowSelectionCheckbox}
              handleCheckboxOnClick={handleCheckboxOnClick}
              rowComponent={rowComponent}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {displayPagination && (
        <Box display="flex" justifyContent="center">
          <TablePagination
            component={'div'}
            rowsPerPageOptions={rowsPerPageOptions}
            count={tableBodyData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleOnChangePage}
            onRowsPerPageChange={handleOnRowsPerPageChange}
          />
        </Box>
      )}
    </Box>
  )
}

import { getSharingWithSupportExpirationDate, isPeriodType } from './helpers'
import { usePeriodOptions } from './hooks'
import { PeriodType } from './interfaces'
import { ActionButton } from '../../../button/ActionButton'
import { Alert, AlertTitle, Box, Checkbox, CircularProgress, MenuItem, Select, Stack, useTheme } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import addYears from 'date-fns/addYears'
import format from 'date-fns/format'
import { isNil } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface ShareWithSupportBoxProps {
  isSharedWithSupport: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void
  sharingPermissionDuration: Date
  setSharingPermissionDuration: React.Dispatch<React.SetStateAction<Date>>
  isLoading: boolean
  isDisabled?: boolean
}

export const ShareWithSupportBox = ({
  isSharedWithSupport,
  handleChange,
  sharingPermissionDuration,
  setSharingPermissionDuration,
  isLoading,
  isDisabled
}: ShareWithSupportBoxProps): React.ReactElement => {
  const { t } = useTranslation(['modals'])
  const theme = useTheme()

  const periodOptions = usePeriodOptions()

  const [isDataSet, setIsDataSet] = React.useState(!isNil(sharingPermissionDuration))

  const [period, setPeriod] = React.useState<PeriodType>(PeriodType.unlimited)

  React.useEffect(() => {
    if (isSharedWithSupport && !isDataSet) {
      const proposedDate = getSharingWithSupportExpirationDate(period)
      setSharingPermissionDuration(proposedDate)
    }
  }, [isSharedWithSupport, isDataSet, period])

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Alert icon={false} severity="info">
      <AlertTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: `${theme.spacing(1)}`,
          marginRight: `${theme.spacing(1)}`,
          marginBottom: `${theme.spacing(2)}`
        }}
      >
        <b>{t('project.fields.shareWithSupport')}</b>
        <Checkbox
          qa-attribute="share-with-support-checkbox"
          checked={isSharedWithSupport}
          onChange={(event): void => {
            handleChange(event, 'shareWithSupport')
          }}
          size="small"
          disabled={isDisabled}
          disableRipple
          sx={{ background: 'white', borderColor: theme.palette.grey[300] }}
        />
      </AlertTitle>

      <Box style={{ marginBottom: 8 }}>
        <em style={{ fontSize: 12 }}>{t('project.shareWithSupportDisclaimer')}</em>
      </Box>

      {isSharedWithSupport && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 4,
              margin: `0 0 ${theme.spacing(1)} 0`
            }}
          >
            <Box sx={{ flex: 1 }}>
              <b>{t('project.sharingDuration')}</b>
            </Box>

            {isDataSet ? (
              <Box sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}>
                <ExpirationDateLabel expirationDate={sharingPermissionDuration} />
                <ActionButton
                  onClick={(): void => {
                    setSharingPermissionDuration(addYears(new Date(), 99))
                    setIsDataSet(false)
                  }}
                  title={t('edit', { ns: 'tooltips' })}
                  disabled={isDisabled}
                >
                  <SpriteIcon spriteId="pencil" height={12} width={12} />
                </ActionButton>
              </Box>
            ) : (
              <Select
                qa-attribute="sharing-duration-select"
                sx={{ flex: 1, minWidth: 0 }}
                value={period}
                onChange={(e): void => {
                  const newPeriod = e.target.value
                  if (isPeriodType(newPeriod)) {
                    setPeriod(newPeriod)
                  }
                }}
                variant="standard"
                IconComponent={(): React.ReactElement => (
                  <SpriteIcon spriteId="angle-right" rotatingAngle={90} width={12} height={12} />
                )}
                disabled={isDisabled}
              >
                {periodOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Box>
          {!isDataSet && (
            <Box sx={{ display: 'flex' }}>
              <div style={{ flex: 1 }} />
              <ExpirationDateLabel expirationDate={sharingPermissionDuration} />
            </Box>
          )}
        </>
      )}
    </Alert>
  )
}

const ExpirationDateLabel = ({ expirationDate }: { expirationDate: Date }): React.ReactElement => {
  const { t } = useTranslation(['modals'])

  if (isNil(expirationDate) || expirationDate > new Date('2100-01-01')) {
    return <small>{t('project.sharingDurationActions.unlimitedTooltip')}</small>
  }

  return (
    <small>
      {t('project.sharingDurationActions.expiresAt')} {format(expirationDate, 'MMM d, yyyy')}
    </small>
  )
}

import { Button } from '../../../components/button/Button'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Stack, Typography } from '@mui/material'

export const RetryLoadingEssentialResources = (): React.ReactElement => {
  const { t } = useTranslation(['error'])

  return (
    <Stack direction="column" gap={2} justifyContent="center" alignItems="center" minHeight="100vh">
      <Typography>{t('failedRequest')}</Typography>
      <Button color="primary" variant="contained" onClick={(): void => window.location.reload()}>
        {t('retry')}
      </Button>
    </Stack>
  )
}

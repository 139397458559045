import * as React from 'react'
import { Typography } from '@mui/material'
import { isNil } from 'lodash'
import format from 'date-fns/format'
import { TableRowProps } from '@/components/table/enhancedTable/interfaces'
import { Project } from '@/store/features/apis/slices/project/interfaces'
import { ProjectDetails } from '@/components/projects/ProjectDetails'

export const useSelectModelTrainingData = (projects: Project[]): TableRowProps[] => {
  // const [showSimulations, setShowSimulations] = React.useState({})

  // const handleSimulationDisplayChange = React.useCallback((projectIdExt: string) => {
  //   setShowSimulations((prevValue) => ({ ...prevValue, [projectIdExt]: !prevValue?.[projectIdExt] }))
  // }, [])

  const tableData = React.useMemo(() => {
    if (isNil(projects)) {
      return []
    }

    return projects.map((project: Project) => {
      return {
        tableRowId: `${project.projectIdExt}`,
        tableRowCells: [
          // {
          //   id: `expandSimulations`,
          //   value: 'expandSimulations',
          //   component: (
          //     <Stack direction="row" justifyContent="center">
          //       <SpriteIcon
          //         spriteId="angle-right"
          //         onClick={(): void => {
          //           handleSimulationDisplayChange(project.projectIdExt)
          //         }}
          //       />
          //     </Stack>
          //   )
          // },
          {
            id: `${project.projectName}-projectName`,
            value: project.projectName,
            component: (
              <ProjectDetails
                projectName={project.name}
                thumbnailUrl={project.thumbnailUrl}
                ownerName={project.ownerName}
              />
            ),
            sx: { paddingX: '8px', width: '80%' }
          },
          // commented out due to https://simscale.atlassian.net/browse/ENG-69340
          // {
          //   id: `${project.projectName}-simulationRuns`,
          //   value: project.projectName,
          //   component: <Typography variant="text">{project.projectName}</Typography>,
          //   sx: { paddingRight: '8px' }
          // },
          {
            id: `${project.lastModifiedAt}-lastModifiedAt`,
            value: project.lastModifiedAt,
            component: (
              <Typography variant="text">{format(new Date(project.lastModifiedAt), 'MMM d, yyyy')}</Typography>
            ),
            sx: { paddingRight: '8px' }
          }
        ]
        // ...(showSimulations[project.projectIdExt] && {
        //   expandedRowComponent: <SimulationRunsSelection projectIdExt={project.projectIdExt} />
        // })
      }
    })
  }, [projects])

  return tableData
}

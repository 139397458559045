import { Chip as MuiChip, ChipProps as MuiChipProps, styled } from '@mui/material'
import * as React from 'react'

interface CustomChipProps extends MuiChipProps {
  icon?: React.ReactElement
  isDisabled?: boolean
}

const CustomChipStyled = styled(MuiChip)(({ theme }) => ({
  '&.MuiChip-root': {
    height: '1.375rem',
    fontSize: '0.75rem',
    lineHeight: '0.875rem'
  },

  '&.MuiChip-outlinedDefault': {
    backgroundColor: theme.palette.background,
    color: theme.palette.text.primary,
    borderColor: theme.palette.grey['300'],
    '.MuiChip-deleteIcon': {
      color: theme.palette.grey['600'],
      '&:hover': {
        color: theme.palette.grey['800']
      }
    }
  },
  '&.MuiChip-filledDefault': {
    backgroundColor: theme.palette.grey['150'],
    color: theme.palette.text.primary,
    '.MuiChip-deleteIcon': {
      color: theme.palette.grey['600'],
      '&:hover': {
        color: theme.palette.grey['800']
      }
    }
  },

  '&.MuiChip-filledInfo': {
    backgroundColor: theme.palette.primary['100'],
    color: theme.palette.primary['800'],
    '.MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary['800']
      }
    }
  },

  '&.MuiChip-filledError': {
    backgroundColor: theme.palette.error['100'],
    color: theme.palette.error['800'],
    '.MuiChip-deleteIcon': {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error['800']
      }
    }
  },

  '&.MuiChip-filledSuccess': {
    backgroundColor: theme.palette.success['100'],
    color: theme.palette.success['800'],
    '.MuiChip-deleteIcon': {
      color: theme.palette.success.main,
      '&:hover': {
        color: theme.palette.success['800']
      }
    }
  },

  '&.MuiChip-filledWarning': {
    backgroundColor: theme.palette.warning['100'],
    color: theme.palette.warning['800'],
    '.MuiChip-deleteIcon': {
      color: theme.palette.warning.main,
      '&:hover': {
        color: theme.palette.warning['800']
      }
    }
  }
}))

export const CustomChip = ({ label, icon, isDisabled, onClick, ...props }: CustomChipProps): React.ReactElement => {
  return <CustomChipStyled label={label} avatar={icon} onClick={onClick} {...props} disabled={isDisabled} />
}

import * as React from 'react'
import { isEmpty, isNil } from 'lodash'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { ModalTemplateProps } from '@/components/modal/interfaces'
import { DistributionType, ModelStage, ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { ModalActions, ModalContent, ModalTitle } from '@/components/modal/Modal'
import { Button } from '@/components/button/Button'
import {
  useGetModelQuery,
  usePatchModelMutation,
  usePatchModelVersionDescriptionMutation,
  usePatchModelVersionStageMutation
} from '@/store/features/apis/slices/ai/aiSlice'
import { useAppDispatch } from '@/store/hooks'
import { addSnackbarToState } from '@/store/features/uiSlice/uiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { ModelVersionDetails } from '@/components/aiModels/ModelVersionDetails'
import { ModalInputTextArea } from '@/components/modal/inputComponents/ModalInputTextArea'
import { ModalInputSelect, ModalInputSelectProps } from '@/components/modal/inputComponents/ModalInputSelect'
import { useValidation } from '@/hooks/useValidation'
import {
  isValidMaximumNumberOfChars,
  isValidRequiredNumberOfChars,
  itDoesNotStartWithEmptySpace
} from '@/helpers/validationHelpers'
import { useInternalDistributionOperation } from '@/operations/ai/models/updateInternalDistributionOperation'

interface ReleaseModelVersionTemplateProps extends ModalTemplateProps {
  title: string
  version: ModelVersion
}

export const ReleaseModelVersionTemplate = ({
  title,
  onClose,
  showCloseButton,
  version
}: ReleaseModelVersionTemplateProps): React.ReactElement => {
  const { t } = useTranslation(['modals', 'adminArea'])
  const dispatch = useAppDispatch()
  const { validateField } = useValidation()
  const { data: model, isLoading: isLoadingModel } = useGetModelQuery(
    { modelUuid: version?.model_uuid },
    { skip: isNil(version?.model_uuid) }
  )
  const [patchModelVersionStage, { isLoading: isLoadingPatchModelVersionStage }] = usePatchModelVersionStageMutation()
  const [patchModelVersionDescription, { isLoading: isLoadingPatchModelVersionDescription }] =
    usePatchModelVersionDescriptionMutation()
  const [patchModel, { isLoading: isLoadingPatchModel }] = usePatchModelMutation()
  const isLoading =
    isLoadingModel || isLoadingPatchModelVersionStage || isLoadingPatchModelVersionDescription || isLoadingPatchModel

  const canUpdateInternalDistribution = useInternalDistributionOperation(model)

  const [description, setDescription] = React.useState<string>('')
  const [sharedWithOrganization, setSharedWithOrganization] = React.useState<boolean>(false)

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    description: { valid: true, message: '' }
  })

  const internalDistributionOptions: ModalInputSelectProps['options'] = React.useMemo(
    () => [
      {
        value: DistributionType.MY_ORGANIZATION,
        label: t('ai.internalDistribution.options.myOrganization', { ns: 'adminArea' })
      },
      { value: DistributionType.ONLY_ME, label: t('ai.internalDistribution.options.onlyMe', { ns: 'adminArea' }) }
    ],
    [t]
  )

  React.useEffect(() => {
    if (!isLoadingModel) {
      setSharedWithOrganization(model?.shared_with_organization)
    }
  }, [model, isLoadingModel])

  const handleDescriptionChange = React.useCallback((updatedDescription: string) => {
    setDescription(updatedDescription)
    setValidationErrors((prevState) => ({
      ...prevState,
      description: validateField(updatedDescription, [
        itDoesNotStartWithEmptySpace,
        isValidRequiredNumberOfChars(1),
        isValidMaximumNumberOfChars(2000)
      ])
    }))
  }, [])

  const handleSharedWithOrganizationChange = React.useCallback(
    (updatedSharedWithOrganization: boolean) => {
      setSharedWithOrganization(updatedSharedWithOrganization)
    },
    [setSharedWithOrganization]
  )

  const handleReleaseOnClick = React.useCallback(async (): Promise<void> => {
    const isDescriptionValid = validationErrors['description']?.valid
    if (!isDescriptionValid) {
      return
    }

    if (!isEmpty(description)) {
      const descriptionUpdateResult = await patchModelVersionDescription({
        modelUuid: version.model_uuid,
        version: version.version,
        description
      })
      if ('error' in descriptionUpdateResult) {
        handleApiHookErrors(descriptionUpdateResult.error)
        return
      }
    }

    const updateModelResult = await patchModel({
      modelUuid: version.model_uuid,
      payload: { shared_with_organization: sharedWithOrganization }
    })
    if ('error' in updateModelResult) {
      handleApiHookErrors(updateModelResult.error)
      return
    }

    await patchModelVersionStage({
      modelUuid: version.model_uuid,
      version: version.version,
      stage: ModelStage.PRODUCTION
    })
      .unwrap()
      .then((_) => {
        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t('releaseModelVersion.alerts.success', { version: version.version })
          })
        )

        onClose()
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [patchModelVersionStage, version, description, sharedWithOrganization, t])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent>
        {!isLoadingModel && (
          <Stack spacing={4}>
            <ModelVersionDetails version={version} model={model} />
            <ModalInputTextArea
              label={t('releaseModelVersion.fields.description.label')}
              placeholder={t('releaseModelVersion.fields.description.placeholder')}
              value={description}
              onChange={(event): void => {
                handleDescriptionChange(event.target.value)
              }}
              error={!validationErrors['description']?.valid}
              helperText={validationErrors['description']?.message}
              disabled={isLoading}
            />
            {canUpdateInternalDistribution && (
              <ModalInputSelect
                value={sharedWithOrganization ? DistributionType.MY_ORGANIZATION : DistributionType.ONLY_ME}
                label={t('releaseModelVersion.fields.internalDistribution.label')}
                placeholder={t('releaseModelVersion.fields.internalDistribution.placeholder')}
                onChange={(event): void => {
                  handleSharedWithOrganizationChange(event.target.value === DistributionType.MY_ORGANIZATION)
                }}
                options={internalDistributionOptions}
                helperText={t('releaseModelVersion.fields.internalDistribution.helperText')}
                disabled={isLoading}
              />
            )}
          </Stack>
        )}
      </ModalContent>
      <ModalActions>
        <Button
          onClick={handleReleaseOnClick}
          color="primary"
          variant="contained"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t('releaseModelVersion.actions.release')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

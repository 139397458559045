import { DetailsPanelStyled, DetailsPanelTypes } from './GenericDetailsPanel'
import { SimulationCards } from './components/SimulationCard'
import { MultiSelectionDetails } from './MultiSelectionDetails'
import { usePersonalSpaceId, useSpaceById } from '../../store/features/apis/slices/space/hooks'
import { useErrorHandler } from '../../hooks/useErrorHandler'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { useIsMyProjectsPage } from '../../hooks/useIsMyProjectsPage'
import * as React from 'react'
import { styled } from '@mui/system'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { useParams, useSearchParams } from 'react-router-dom'

const SpaceDetailsPanelContentStyled = styled(Box)({
  width: '100%',
  marginTop: '20px',
  textAlign: 'center'
})

export const SpaceDetailsPanel = ({ spaceId: activeSpaceId }: { spaceId?: string }): React.ReactElement => {
  let { spaceId } = useParams<{ spaceId: string }>()
  if (!isNil(activeSpaceId)) {
    spaceId = activeSpaceId
  }
  const personalSpaceId = usePersonalSpaceId()
  const { space, isError, isLoading } = useSpaceById(spaceId ?? personalSpaceId)
  const { t } = useTranslation()
  const { handleNotFound } = useErrorHandler()
  const isMyProjectsPage = useIsMyProjectsPage()
  const [searchParams] = useSearchParams()

  React.useEffect(() => {
    if (!isLoading && (isError || isNil(space))) {
      handleNotFound()
    }
  }, [space, isLoading, isError, searchParams])

  if (isLoading || isNil(space)) {
    return null
  }

  return (
    <Box qa-attribute="location-details-panel">
      <Typography sx={{ mt: 2 }} variant="title" color="text.primary" paragraph>
        {isMyProjectsPage ? t('myProjects') : space.name}
      </Typography>
      <SimulationCards panelType={DetailsPanelTypes.LOCATION_DETAILS} />
      <SpaceDetailsPanelContentStyled>
        <Typography variant="subtitle" color="grey.700">
          {t('location.message')}
        </Typography>
      </SpaceDetailsPanelContentStyled>
    </Box>
  )
}

const SpaceDetailsPanelDelayed = (): React.ReactElement => {
  const { selectedItems } = useMultiSelect()
  const showMultiSelectionDetails =
    selectedItems.projects?.length + selectedItems.folders?.length + selectedItems.spaces?.length > 0

  return (
    <DetailsPanelStyled>
      {showMultiSelectionDetails ? <MultiSelectionDetails /> : <SpaceDetailsPanel />}
    </DetailsPanelStyled>
  )
}

export default SpaceDetailsPanelDelayed

import { ProjectMiniature } from './ProjectMiniature'
import { OwnerName } from './OwnerName'
import { getResolvedThumbnailFullServicePath } from '../../utils/helpers'
import { RowDetails } from '../rows/RowDetails'
import * as React from 'react'
import { Stack, Typography } from '@mui/material'

const imgSimulationDemo = require('@/assets/png/no-preview.png')

type ProjectDetailsType = {
  projectName: string
  thumbnailUrl: string
  ownerName: string
}

export const ProjectDetails = ({ projectName, thumbnailUrl, ownerName }: ProjectDetailsType): React.ReactElement => {
  return (
    <RowDetails>
      <ProjectMiniature thumbnailUrl={getResolvedThumbnailFullServicePath(thumbnailUrl) ?? imgSimulationDemo} />
      <Stack direction="column" width={300}>
        <Typography variant="text" component="p">
          {projectName}
        </Typography>
        <OwnerName ownerName={ownerName} />
      </Stack>
    </RowDetails>
  )
}

import { useGetAvatarImageUrlQuery } from '../../store/features/apis/slices/forum/forumSlice'
import { StyledTooltip } from '../../components/tooltip/StyledTooltip'
import * as React from 'react'
import { AvatarProps, Badge, Skeleton } from '@mui/material'
import { isNil } from 'lodash'
import {
  AVATAR_IMAGE_DEFAULT_SIZE,
  AVATAR_IMAGE_DEFAULT_SOURCE_SIZE,
  AvatarImageSize,
  AvatarStyled,
  BadgeAvatarStyled
} from '@/components/avatar/Avatar'
import { navigateToUrl } from '@/utils/windowUtils'
import { useGetUserByIdQuery } from '@/store/features/apis/slices/user/userSlice'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface UserAvatarProps extends AvatarProps {
  userName?: string
  userId?: string
  addMarginLeft?: boolean
  showUsernameTooltip?: boolean
  editProfile?: boolean
  avatarImageSize?: AvatarImageSize
}

export const UserAvatarPicture = ({
  userName,
  userId,
  addMarginLeft,
  showUsernameTooltip,
  editProfile,
  avatarImageSize,
  ...props
}: UserAvatarProps): React.ReactElement => {
  const { data: user, isLoading: isLoadingUser } = useGetUserByIdQuery({ uidExt: userId }, { skip: isNil(userId) })
  const username = userName ?? user?.username
  const { data: userImageUrl, isFetching } = useGetAvatarImageUrlQuery(
    {
      userName: username,
      size: AVATAR_IMAGE_DEFAULT_SOURCE_SIZE
    },
    { skip: isLoadingUser }
  )

  if (isFetching) {
    return (
      <Skeleton
        animation="wave"
        variant="circular"
        width={avatarImageSize ?? AVATAR_IMAGE_DEFAULT_SIZE}
        height={avatarImageSize ?? AVATAR_IMAGE_DEFAULT_SIZE}
        style={{ marginLeft: addMarginLeft ? -8 : undefined }}
      />
    )
  }

  if (showUsernameTooltip) {
    return (
      <StyledTooltip title={username}>
        <AvatarStyled src={userImageUrl} addMarginLeft={addMarginLeft} imageSize={avatarImageSize} {...props} />
      </StyledTooltip>
    )
  }

  if (editProfile) {
    return (
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        badgeContent={
          <BadgeAvatarStyled
            alt="Change profile"
            onClick={(): void => {
              navigateToUrl(`/forum/u/${username}/preferences/`)
            }}
            qa-attribute="edit-profile--button"
          >
            <SpriteIcon spriteId="pencil" width=".625rem" height=".625rem" style={{ cursor: 'pointer' }} />
          </BadgeAvatarStyled>
        }
      >
        <AvatarStyled
          src={userImageUrl}
          addMarginLeft={addMarginLeft}
          imageSize={avatarImageSize ?? AvatarImageSize.LARGE}
          {...props}
        />
      </Badge>
    )
  }

  return <AvatarStyled src={userImageUrl} addMarginLeft={addMarginLeft} imageSize={avatarImageSize} {...props} />
}

import { TableFilter } from '../interfaces'
import { Input } from '@mui/material'
import * as React from 'react'

interface TextFilterDialogProps {
  filter: TableFilter
  id: string
  setFilters: React.Dispatch<React.SetStateAction<TableFilter<any>[]>>
}

export const InputFilterDialog = ({ filter, id, setFilters }: TextFilterDialogProps): React.ReactElement => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value // necessary to avoid event nullification in the map
    setFilters((filters: TableFilter[]): TableFilter[] =>
      filters.map((filter) => (filter.id === id ? { type: filter.type, id, value: newValue } : filter))
    )
  }

  return <Input type={filter.type} onChange={onChange} value={filter.value} autoFocus />
}

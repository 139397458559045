import { SpaceBreadcrumbItem } from './SpaceBreadcrumbItem'
import { FolderBreadcrumbItem } from './BreadcrumbItem'
import { PathSelector } from '../spaces/interfaces'
import { useAppDispatch } from '../../store/hooks'
import { setFullPath } from '../../store/features/uiSlice/uiSlice'
import { BreadcrumbsRow } from '../../components/breadcrumb/BreadcrumbsRow'
import { CollapsedBreadcrumbs } from '../../components/breadcrumb/CollapsedBreadcrumbs'
import { CollapsedBreadcrumbsLabel } from '../../components/breadcrumb/CollapsedBreadcrumbsLabel'
import { pathToRoute } from '../../helpers/router'
import { useGetFolderDetails } from '../../store/features/apis/slices/folder/helpers'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'
import { first, isEmpty, isNil, last } from 'lodash'

export const FolderBreadcrumbs = (): React.ReactElement => {
  const { currentPath } = useCurrentPath()
  if (isEmpty(currentPath)) {
    return null
  }

  return <FullPathLoader currentPath={currentPath} />
}

const FolderBreadcrumbsLabel = ({ path }: { path: PathSelector }): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const { data: folder, isLoading } = useGetFolderDetails({ spaceId: first(path), folderId: last(path) })
  if (isLoading) {
    return null
  }

  const onClick = (): void => {
    navigate(`/${pathToRoute(path)}`)
  }

  return <CollapsedBreadcrumbsLabel label={folder.name} onClick={onClick} />
}

const FullPathLoader = ({ currentPath }: { currentPath: PathSelector }): React.ReactElement => {
  const spaceId = first(currentPath)
  const folderId = last(currentPath)

  const isSpace = spaceId === folderId

  const firstFolder = currentPath[1]

  const { data: folder, isLoading: isFolderLoading } = useGetFolderDetails(
    { spaceId, folderId: firstFolder },
    { skip: isSpace }
  )

  if (isSpace) {
    return <BreadcrumbWidget fullPath={currentPath} />
  }

  if (isFolderLoading) {
    return null
  }

  const parentFolderId = folder?.parentFolderId
  if (isNil(parentFolderId)) {
    return <BreadcrumbWidget fullPath={currentPath} />
  }

  return <FullPathLoader currentPath={[spaceId, parentFolderId, ...currentPath.slice(1)]} />
}

const BreadcrumbWidget = ({ fullPath }): React.ReactElement => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(setFullPath(fullPath))
  }, [fullPath, dispatch])

  return (
    <BreadcrumbsRow>
      <SpaceBreadcrumbItem spaceId={first(fullPath)} isLastElement={fullPath.length === 1} />
      {fullPath.length > 3 && (
        <CollapsedBreadcrumbs>
          {fullPath.slice(1, -2).map((id: string) => {
            const currentElementIndex = fullPath.indexOf(id)
            return <FolderBreadcrumbsLabel key={last(fullPath)} path={fullPath.slice(0, currentElementIndex + 1)} />
          })}
        </CollapsedBreadcrumbs>
      )}
      {fullPath.length > 2 && <FolderBreadcrumbItem partialPath={fullPath.slice(0, -1)} />}
      {fullPath.length > 1 && <FolderBreadcrumbItem partialPath={fullPath} isLastElement />}
    </BreadcrumbsRow>
  )
}

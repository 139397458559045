import { SearchField, SearchType } from './SearchField'
import { useIsMobile } from '../../hooks/useIsMobile'
import * as React from 'react'
import Box from '@mui/material/Box'

export const Searchbar = (): React.ReactElement => {
  const isMobile = useIsMobile()

  if (isMobile) {
    // TODO: When design specs are available, implement mobile search, currently, it's not clear how it should look, and it's breaking our mobile tests for simba. (https://simscale.atlassian.net/browse/ENG-58023)
    return null
  }

  return (
    <Box qa-attribute="searchbar">
      <SearchField searchType={SearchType.GENERAL} />
    </Box>
  )
}

import { PathSelector } from '../spaces/interfaces'
import { pathToRoute } from '../../helpers/router'
import { BreadcrumbsItem } from '../../components/breadcrumb/BreadcrumbsItem'
import { useGetFolderDetails } from '../../store/features/apis/slices/folder/helpers'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'
import { first, last } from 'lodash'

interface FolderBreadcrumbItemProps {
  partialPath: PathSelector
  isLastElement?: boolean
}

export const FolderBreadcrumbItem = ({ partialPath, isLastElement }: FolderBreadcrumbItemProps): React.ReactElement => {
  const { navigate } = useDashboardNavigate()

  const { data: folder, isLoading } = useGetFolderDetails({
    spaceId: first(partialPath),
    folderId: last(partialPath)
  })

  const onElementSelect = React.useCallback((path: PathSelector) => {
    navigate(`/${pathToRoute(path)}`)
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <BreadcrumbsItem
      isLastElement={isLastElement}
      path={partialPath}
      label={folder?.name}
      onElementSelect={onElementSelect}
    />
  )
}

import { removeLightboxMeta } from './utils'
import { UserAvatarPicture } from '../../../user/UserAvatarPicture'
import { COMMENTS_PER_PAGE } from '../../../../constants'
import * as React from 'react'
import { styled } from '@mui/system'
import intlFormatDistance from 'date-fns/intlFormatDistance'
import Typography, { TypographyTypeMap } from '@mui/material/Typography'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, Pagination, Stack } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import './onebox'

const CommentHeaderStyled = styled(Box)({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  height: '32px'
})

const CommentTextStyled: OverridableComponent<TypographyTypeMap<{}, 'div'>> = styled(Typography)({
  whiteSpace: 'initial',
  overflowWrap: 'break-word',
  marginTop: '5px',
  '& .emoji': {
    display: 'inline-block',
    maxWidth: '1.2em',
    width: 'auto',
    height: '1.2em',
    margin: '0 .05em',
    verticalAlign: 'text-bottom'
  }
})

export interface CommentDataProps {
  userName: string
  modifiedAt: string
  textContent: string
}

const Comment = ({ userName, modifiedAt, textContent }: CommentDataProps): React.ReactElement => {
  const timeAgo = intlFormatDistance(new Date(modifiedAt), new Date())

  return (
    <Box mt={4} qa-attribute="comment">
      <CommentHeaderStyled>
        <UserAvatarPicture userName={userName} />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="textBoldWithEllipsis" color="text.primary">
            {userName}
          </Typography>
          <Typography variant="textLight" color="grey.700">
            {timeAgo}
          </Typography>
        </Box>
      </CommentHeaderStyled>
      <CommentTextStyled
        variant="text"
        color="text.primary"
        dangerouslySetInnerHTML={{ __html: textContent }}
        component="div"
      />
    </Box>
  )
}

const NoComments = (): React.ReactElement => {
  const { t } = useTranslation()
  return <Typography variant="text">{t('noComments')}</Typography>
}

interface CommentsProps {
  comments: CommentDataProps[]
  totalNumberOfComments: number
  isLoading: boolean
  page: number
  handlePageChange: (page: number) => void
}

export const Comments = ({
  comments,
  totalNumberOfComments,
  isLoading,
  page,
  handlePageChange
}: CommentsProps): React.ReactElement => {
  if (isLoading) {
    return null
  }

  if (isEmpty(comments)) {
    return <NoComments />
  }

  const totalPages = Math.ceil(totalNumberOfComments / COMMENTS_PER_PAGE)

  return (
    <Stack direction="column" gap={4} paddingBottom={4}>
      <Box>
        {comments?.map(({ userName, modifiedAt, textContent }) => {
          if (textContent.includes('lightbox')) {
            textContent = removeLightboxMeta(textContent)
          }
          return (
            <Comment
              key={`${userName}-${modifiedAt}`}
              userName={userName}
              modifiedAt={modifiedAt}
              textContent={textContent}
            />
          )
        })}
      </Box>
      <Stack alignItems="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={(_e, val): void => handlePageChange(val)}
          shape="rounded"
        />
      </Stack>
    </Stack>
  )
}

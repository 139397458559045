import { DetailsPanelTypes } from '../GenericDetailsPanel'
import * as React from 'react'
import { styled } from '@mui/system'
import { isEmpty, isNil } from 'lodash'
import { nanoid } from 'nanoid'
import Box from '@mui/material/Box'
import { ActionButton } from '@/components/button/ActionButton'
import { SpriteIcon } from '@/assets/SpriteIcon'

const imgFolderSimulationCard = require('../../../assets/svg/folder-simulation-card.svg')
const imgProjectsSimulationCard = require('../../../assets/svg/projects-simulation-card.svg')
const imgSearchSimulationCard = require('../../../assets/svg/search-simulation-card.svg')
const imgNoPreview = require('../../../assets/png/no-preview.png')

interface SimulationCardStyledProps {
  imageUrl: string
  addMargin?: boolean
  addBorder?: boolean
  isIcon?: boolean
  isBottomBorderFlat?: boolean
  isSelected?: boolean
}

export const SimulationCardStyled = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'imageUrl' &&
    prop !== 'addMargin' &&
    prop !== 'addBorder' &&
    prop !== 'isIcon' &&
    prop !== 'isBottomBorderFlat'
})<SimulationCardStyledProps>(({ theme, imageUrl, addBorder, isIcon, isBottomBorderFlat }) => ({
  position: 'relative',
  width: '100%',
  height: 'auto',
  aspectRatio: '4 / 3',
  backgroundColor: theme.palette.grey['250'],
  backgroundImage: `url(${imageUrl}), linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.action.hover} 100%)`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: isIcon ? 'initial' : 'cover',
  border: addBorder && `1px solid ${theme.palette.grey['200']}`,
  borderRadius: 4,
  borderBottomLeftRadius: isBottomBorderFlat ? 0 : 4,
  borderBottomRightRadius: isBottomBorderFlat ? 0 : 4,
  '& > .MuiButton-root': {
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 1
  }
}))

const SmallSimulationCardStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'imageUrl' && prop !== 'isSelected'
})<SimulationCardStyledProps>(({ theme, imageUrl, isSelected }) => ({
  display: 'inline-block',
  width: 39,
  height: 39,
  marginRight: 5,
  backgroundImage: `url(${imageUrl})`,
  backgroundColor: theme.palette.grey['350'],
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '100%',
  border: `1px solid ${isSelected ? theme.palette.primary['300'] : theme.palette.grey['200']}`,
  borderRadius: 4,
  cursor: 'pointer',
  '&:hover': {
    border: `1px solid ${theme.palette.primary['300']}`
  }
}))

const SmallSimulationCardsContainerStyled = styled('div')(({ theme }) => ({
  display: 'block',
  maxWidth: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  '&::-webkit-scrollbar': {
    height: 4
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.grey['200']
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey['400']
  },
  marginTop: 5
}))

const ZoomedCardStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > img': {
    width: 'auto',
    height: 'auto',
    maxWidth: '90%',
    maxHeight: '90%',
    objectFit: 'contain',
    backgroundColor: theme.palette.grey['250'],
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey['200']}`
  }
}))

export interface ThumbnailProps {
  imageUrl: string
  panelType?: DetailsPanelTypes
}

interface SimulationCardsProps {
  panelType: DetailsPanelTypes
  projectThumbnail?: ThumbnailProps
  resultThumbnails?: ThumbnailProps[]
}

const getSimulationCardImageUrl = (panelType: DetailsPanelTypes): string => {
  switch (panelType) {
    case DetailsPanelTypes.FOLDER_DETAILS:
      return imgFolderSimulationCard
    case DetailsPanelTypes.SEARCH_DETAILS:
      return imgSearchSimulationCard
    case DetailsPanelTypes.PROJECT_DETAILS:
      return imgNoPreview
    default:
      return imgProjectsSimulationCard
  }
}

const SimulationCard = ({ imageUrl, panelType }: ThumbnailProps): React.ReactElement => {
  const [isZoomed, setIsZoomed] = React.useState(false)

  if (isNil(imageUrl)) {
    imageUrl = getSimulationCardImageUrl(panelType)
    return (
      <SimulationCardStyled
        imageUrl={imageUrl}
        addMargin
        addBorder
        isIcon={panelType === DetailsPanelTypes.LOCATION_DETAILS}
        qa-attribute="simulation-card"
      />
    )
  }

  return (
    <>
      <SimulationCardStyled imageUrl={imageUrl} addMargin addBorder qa-attribute="simulation-card">
        <ActionButton
          onClick={(): void => {
            setIsZoomed(true)
          }}
        >
          <SpriteIcon spriteId="maximize" />
        </ActionButton>
      </SimulationCardStyled>
      {isZoomed && (
        <ZoomedCardStyled onClick={(): void => setIsZoomed(false)}>
          <img
            src={imageUrl}
            onClick={(e): void => {
              e.stopPropagation()
            }}
          />
        </ZoomedCardStyled>
      )}
    </>
  )
}

export const SimulationCards = ({
  panelType,
  projectThumbnail,
  resultThumbnails
}: SimulationCardsProps): React.ReactElement => {
  const [selectedCard, setSelectedCard] = React.useState(projectThumbnail)

  React.useEffect(() => {
    setSelectedCard(projectThumbnail)
  }, [projectThumbnail])

  return (
    <React.Fragment>
      <SimulationCard imageUrl={selectedCard?.imageUrl} panelType={panelType} />
      {!isEmpty(resultThumbnails) && (
        <SmallSimulationCardsContainerStyled>
          {resultThumbnails.map(
            (item, index) =>
              !isNil(item.imageUrl) && (
                <SmallSimulationCardStyled
                  key={nanoid()}
                  imageUrl={item.imageUrl}
                  onClick={(): void => setSelectedCard(item)}
                  isSelected={(selectedCard === projectThumbnail && index === 0) || selectedCard === item}
                />
              )
          )}
        </SmallSimulationCardsContainerStyled>
      )}
    </React.Fragment>
  )
}

import * as React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ModalInputText } from '@/components/modal/inputComponents/ModalInputText'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { setAiModelTrainingSettingsFormData } from '@/store/features/uiSlice/uiSlice'
import { ValidationErrors } from '@/hooks/useErrorHandler'

export const GeneralInformation = ({
  validationErrors
}: {
  validationErrors?: ValidationErrors
}): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('aiTraining')
  const aiModelTrainingSettings = useAppSelector((store) => store.ui.aiModelTrainingSettings)

  const handleOnChange = (value: string): void => {
    dispatch(
      setAiModelTrainingSettingsFormData({ ...aiModelTrainingSettings?.formData, versionName: value, isDirty: true })
    )
  }

  return (
    <Stack direction="column" gap={5}>
      <Typography variant="title">{t('modelSettings.generalInformation')}</Typography>
      <ModalInputText
        onChange={(event): void => handleOnChange(event.target.value)}
        label={`${t('modelSettings.versionName')}:`}
        value={aiModelTrainingSettings?.formData?.versionName ?? ''}
        autoFocus={true}
        required
        error={!validationErrors['versionName']?.valid}
        helperText={validationErrors['versionName']?.message}
      />
    </Stack>
  )
}

import { VersionsHistoryItem } from './VersionsHistoryItem'
import { VersionsFullHistoryItem } from './VersionsFullHistoryItem'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import styled from '@mui/system/styled'
import Skeleton from '@mui/material/Skeleton'
import { ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { ZINDEXES } from '@/constants/zindexes'

const VersionsHistoryWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  zIndex: ZINDEXES.CONTENT_LAYER,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '12px',
    left: '25px',
    width: '1px',
    height: 'calc(100% - 24px)',
    backgroundColor: theme.palette.grey[200],
    zIndex: ZINDEXES.BEHIND_EVERYTHING
  }
}))

export const VersionsHistorySkeleton = (): React.ReactElement => {
  return (
    <Stack direction="column" spacing={5}>
      <Skeleton variant="rounded" width="100%" height={32} animation="wave" />
      <Skeleton variant="rounded" width="100%" height={32} animation="wave" />
      <Skeleton variant="rounded" width="100%" height={32} animation="wave" />
      <Skeleton variant="rounded" width="100%" height={32} animation="wave" />
    </Stack>
  )
}

export const VersionsHistory = ({
  versions,
  fullHistory
}: {
  versions: ModelVersion[]
  fullHistory?: boolean
}): React.ReactElement => {
  return (
    <VersionsHistoryWrapper direction="column" spacing={5} qa-attribute="aiModel--modelVersions--history">
      {versions?.map((version, index) => {
        const versionItemProps = { key: version.version, version, isLatestVersion: index === 0 }
        return fullHistory ? (
          <VersionsFullHistoryItem {...versionItemProps} />
        ) : (
          <VersionsHistoryItem {...versionItemProps} />
        )
      })}
    </VersionsHistoryWrapper>
  )
}

import { FolderCardData } from './interfaces'
import { Card } from '../card/Card'
import * as React from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

const FolderDetailsStyled = styled(Box)(({ theme }) => ({
  fontSize: '.6875rem',
  fontWeight: 400,
  lineHeight: '1',
  color: theme.palette.grey['700']
}))

export const FolderCard = ({
  folder,
  selected,
  onClick,
  onContextMenu,
  className
}: FolderCardData): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Card
      title={folder.name}
      selected={selected}
      onClick={(event): void => {
        onClick(event, folder.folderId)
      }}
      onContextMenu={onContextMenu}
      className={className}
    >
      <FolderDetailsStyled>
        <span>{t('items', { count: folder.numberOfItems })}</span>
      </FolderDetailsStyled>
    </Card>
  )
}

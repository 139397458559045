import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalInputText } from '../../inputComponents/ModalInputText'
import { ModalTemplateProps } from '../../interfaces'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import {
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidInput,
  isValidRequiredNumberOfChars
} from '@/helpers/validationHelpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useValidation } from '@/hooks/useValidation'
import { usePostUserApiKeyMutation } from '@/store/features/apis/slices/user/userSlice'
import { addSnackbarToState } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface GenerateKeyTemplateProps extends ModalTemplateProps {}

enum Steps {
  GENERATE,
  DONE
}

export const GenerateKeyTemplate = ({ onClose, showCloseButton }: GenerateKeyTemplateProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const [step, setStep] = React.useState<Steps>(Steps.GENERATE)
  const [name, setName] = React.useState<string>('My API key')
  const [generatedKey, setGeneratedKey] = React.useState<string>('')
  const { validateField } = useValidation()
  const [postUserApiKey] = usePostUserApiKeyMutation()

  const validationRules: ValidationRules = {
    name: [isRequired, isNotEmpty, isValidRequiredNumberOfChars(1), isValidInput]
  }

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    name: { valid: true, message: '' }
  })

  const handleNameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value)
      const validation = validateField(event.target.value, validationRules.name)
      if (!isNil(validation)) {
        setValidationErrors({ ...validationErrors, name: validation })
      }
    },
    [validationRules]
  )

  const disableGenerateButton = React.useMemo(() => {
    return !validationErrors['name'].valid
  }, [validationErrors])

  const handleOnGenerateClick = React.useCallback(() => {
    const validation = validateField(name, validationRules.name)
    if (!isNil(validation) && !validation.valid) {
      setValidationErrors({ ...validationErrors, name: validation })
      return
    }

    postUserApiKey({ description: name })
      .unwrap()
      .then((key) => {
        setGeneratedKey(key.apiKey)
        setName('')
        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t('generateKey.alerts.apiKeyGenerationSuccess')
          })
        )
        setStep(Steps.DONE)
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
  }, [name])

  const handleCopyIconOnClick = React.useCallback(
    (_: any): void => {
      navigator.clipboard.writeText(generatedKey)
      dispatch(
        addSnackbarToState({
          severity: 'info',
          message: t('generateKey.alerts.copyKeySuccess')
        })
      )
    },
    [generatedKey]
  )

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{t('generateKey.title')}</ModalTitle>
      <ModalContent onSubmit={handleOnGenerateClick} submitDisabled={disableGenerateButton}>
        {step === Steps.GENERATE && (
          <ModalInputText
            helperText={validationErrors['name'].message}
            error={!validationErrors['name'].valid}
            onChange={handleNameChange}
            label={t('generateKey.fields.name')}
            value={name}
            autoFocus={true}
            required={true}
          />
        )}
        {step === Steps.DONE && (
          <React.Fragment>
            <Typography variant="text" color="grey.700">
              {t('generateKey.alerts.keySuccessfullyGeneratedDisclaimer')}
            </Typography>
            <TextField
              id="generated-api-key"
              fullWidth
              variant="standard"
              value={generatedKey}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      onClick={handleCopyIconOnClick}
                      color="primary"
                      variant="contained"
                      sx={{ padding: 0, minWidth: 0, height: '30px', width: '30px' }}
                      qa-attribute="copy-key--button"
                    >
                      <SpriteIcon spriteId="copy" color="common.white" />
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </React.Fragment>
        )}
      </ModalContent>
      <ModalActions>
        {step === Steps.GENERATE && (
          <React.Fragment>
            <Button onClick={onClose} color="secondary" variant="outlined">
              {t('generateKey.cancel')}
            </Button>
            <Button
              onClick={handleOnGenerateClick}
              color="primary"
              variant="contained"
              disabled={disableGenerateButton}
            >
              {t('generateKey.generate')}
            </Button>
          </React.Fragment>
        )}
        {step === Steps.DONE && (
          <Button onClick={onClose} color="primary" variant="contained">
            {t('generateKey.done')}
          </Button>
        )}
      </ModalActions>
    </React.Fragment>
  )
}

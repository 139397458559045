import { DataSelectionHeading } from './DataSelectionHeading'
import * as React from 'react'
import { Stack } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { AiModelSelectionNavigation } from '@/containers/ai/navigation/AiModelSelectionNavigation'
import { ProjectsSelection } from '@/containers/ai/dataSelection/ProjectsSelection'
import { setAiSectionPath } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'
import { AiSectionPaths } from '@/constants'
import { Model } from '@/store/features/apis/slices/ai/interfaces'
import { SearchField, SearchType } from '@/components/searchbar/SearchField'
import { SearchTabs } from '@/containers/OperationToolbar/SearchTabs'

export const AiModelDataSelection = ({ model }: { model: Model }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const inSearchMode = searchParams.has('q')

  React.useEffect(() => {
    dispatch(
      setAiSectionPath([
        AiSectionPaths.aiModelTraining,
        { label: model.name, value: model.uuid },
        AiSectionPaths.dataSelection
      ])
    )
  }, [])

  return (
    <Stack direction="column" spacing={5} flexGrow={1}>
      <DataSelectionHeading />
      <Stack
        direction="column"
        gap={2}
        flexGrow={1}
        sx={{
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'divider',
          borderRadius: 1,
          padding: 5,
          overflowX: 'scroll'
        }}
      >
        <Stack
          direction="row"
          justifyContent={!inSearchMode ? 'flex-end' : 'space-between'}
          alignItems="center"
          gap={4}
        >
          {inSearchMode && <SearchTabs searchType={SearchType.AI} />}
          <SearchField searchType={SearchType.AI} />
        </Stack>

        <Stack direction="row" gap={2} flexGrow={1}>
          {!inSearchMode && <AiModelSelectionNavigation />}
          <ProjectsSelection />
        </Stack>
      </Stack>
    </Stack>
  )
}

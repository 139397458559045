import { SearchFieldUi } from './SearchFieldUi'
import { SearchType } from './SearchField'
import { pathToRoute } from '../../helpers/router'
import { useGeneralBlockingError } from '../../hooks/useGeneralBlockingError'
import { PagePath } from '../../constants'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'

type GeneralSearchFieldType = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
}

export const GeneralSearchField = ({ value, setValue }: GeneralSearchFieldType): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const isBlockingError = useGeneralBlockingError()

  const handleSearch = React.useCallback((value: string): void => {
    const isValueValid = value.trim() !== ''
    if (isValueValid) {
      navigate(`/${pathToRoute([PagePath.SEARCH], { q: value, section: 'search', searchType: SearchType.GENERAL })}`)
    } else {
      navigate(PagePath.MY_PROJECTS)
    }
  }, [])

  return (
    <SearchFieldUi
      value={value}
      setValue={setValue}
      handleSearch={handleSearch}
      qaAttribute="search-field"
      isDisabled={isBlockingError}
    />
  )
}

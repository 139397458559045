import { UserListDataType } from './interfaces'
import { UserAvatarPicture } from '../user/UserAvatarPicture'
import { TEAM_SPACE_PERMISSIONS_MAP } from '../../components/modal/templates/teamSpace/helpers'
import { StyledTooltip } from '../../components/tooltip/StyledTooltip'
import { CustomChip } from '../../components/chips/CustomChip'
import * as React from 'react'
import { Avatar, ListItemAvatar, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { PermissionType } from '@/components/modal/templates/share/interfaces'
import { MenuItemStyled } from '@/components/modal/templates/ShareWithUsersModalTemplate'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface TeamSpaceUserListItemProps {
  userListData: UserListDataType
  showPermissionsDropdown?: boolean
  handlePermissionChange?: (permission: PermissionType, id: string) => void
  handleRemoveButtonClick?: (id: string | number) => void
  handleItemClick?: (id: string | number) => void
  disabled?: boolean
  isMember?: boolean
  isInvited?: boolean
  appendComponent?: (user: UserListDataType) => React.ReactNode
  style?: React.CSSProperties
}

export const TeamSpaceUserListItem = ({
  userListData,
  showPermissionsDropdown,
  handlePermissionChange,
  handleRemoveButtonClick,
  handleItemClick,
  disabled,
  isMember,
  isInvited,
  appendComponent,
  style
}: TeamSpaceUserListItemProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{ overflowX: 'hidden' }}
      style={style}
      paddingRight={2}
      onClick={(): void => !isMember && !isNil(handleItemClick) && handleItemClick(userListData.id)}
      height={48}
      width={'100%'}
      qa-attribute="team-space-member-list-item"
    >
      <ListItemAvatar sx={{ minWidth: 'auto' }}>
        {isInvited ? (
          <Avatar sx={{ backgroundColor: 'grey.100' }}>
            <SpriteIcon spriteId="envelope" color={'grey.600'} fontSize={15} />
          </Avatar>
        ) : (
          <UserAvatarPicture userName={userListData.username} />
        )}
      </ListItemAvatar>
      <StyledTooltip title={userListData.email} enterDelay={500}>
        <Stack direction="column" sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography variant="subtitle" color={disabled ? 'grey.400' : 'text.primary'}>
            {userListData.email}
          </Typography>
        </Stack>
      </StyledTooltip>
      {isMember && (
        <CustomChip
          label={t('teamSpace.member')}
          color={'default'}
          sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        />
      )}
      {isInvited && (
        <CustomChip
          label={t('teamSpace.invited')}
          color={'default'}
          sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        />
      )}
      {!isNil(appendComponent) && appendComponent(userListData)}
      {showPermissionsDropdown && (
        <React.Fragment>
          <UserActions
            userListData={userListData}
            handlePermissionChange={handlePermissionChange}
            disabled={disabled}
          />
          <SpriteIcon
            spriteId="times"
            color="grey.600"
            onClick={(): void => {
              handleRemoveButtonClick(userListData.id)
            }}
            cursor="pointer"
            qa-attribute="team-space-member-list-item-remove-icon"
          />
        </React.Fragment>
      )}
    </Stack>
  )
}

const UserActions = ({ userListData, handlePermissionChange, disabled }): React.ReactElement => {
  const { t } = useTranslation('modals')

  if (!userListData.checked) {
    return (
      <Typography variant="text" sx={{ color: 'text.disabled', flexShrink: 0, marginLeft: 'auto', marginRight: 2 }}>
        {t('permissions.noAccess')}
      </Typography>
    )
  }

  return (
    <Select
      value={userListData.permission ?? PermissionType.VIEW}
      variant="standard"
      onChange={(event: SelectChangeEvent<string>): void => {
        handlePermissionChange(event.target.value, userListData.id)
      }}
      sx={{ minWidth: '60px', flexShrink: 0, marginLeft: 'auto', marginRight: 2 }}
      disabled={disabled ?? userListData.disabled}
    >
      {Object.entries(TEAM_SPACE_PERMISSIONS_MAP).map(([key, value]) => (
        <MenuItemStyled key={key} value={key}>
          {t(value)}
        </MenuItemStyled>
      ))}
    </Select>
  )
}

import { ActionButton, ActionButtonProps } from '../../components/button/ActionButton'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { openProjectInWorkbench } from '../../helpers/navigation'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface OpenProjectButtonProps extends ActionButtonProps {
  projectIdExt?: string
}

export const OpenProjectButton = ({ projectIdExt, ...buttonProps }: OpenProjectButtonProps): React.ReactElement => {
  const { t } = useTranslation(['tooltips'])
  const { getFirstSelectedProject } = useMultiSelect()
  const projectId = projectIdExt || getFirstSelectedProject()

  return (
    <ActionButton
      onClick={(e): void => {
        e.stopPropagation()
        openProjectInWorkbench(projectId)
      }}
      title={t('openProjectInWorkbench', { ns: 'tooltips' })}
      {...buttonProps}
    >
      <SpriteIcon spriteId="open-in-new-page" width={14} height={14} color="grey.550" />
    </ActionButton>
  )
}

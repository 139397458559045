import { User } from '../../../user/User'
import { useGetOrganizationId } from '../../../../store/features/apis/slices/organization/hooks'
import { UserOrganizationStatus } from '../../../../store/features/apis/slices/organization/interfaces'
import { useGetOrganizationV2UsersQuery } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { UserV2 } from '../../../../store/features/apis/slices/user/interfaces'
import { useGetUsersV2Query } from '../../../../store/features/apis/slices/user/userSlice'
import { MenuItem, Select, Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/system'
import { isEmpty, isNil } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const AdminSelectorItem = styled(MenuItem)(({ theme }) => ({
  height: '48px',
  padding: `${theme.spacing(2)}`
}))

interface AdminSelectorProps {
  assignedUser: UserV2
  setAssignedUser: (newAssignedUser: UserV2) => void
  selectedUser: UserV2
}

export const AdminSelector = ({
  assignedUser,
  setAssignedUser,
  selectedUser
}: AdminSelectorProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  const organizationId = useGetOrganizationId()
  // Data are already cached and don't need to load the data again
  const { data: users } = useGetUsersV2Query({ organizationId }, { skip: isNil(organizationId) })
  const { data: organizationUsers } = useGetOrganizationV2UsersQuery(
    { organizationId },
    { skip: isNil(organizationId) }
  )

  // const organizationId = useGetOrganizationId()

  React.useEffect(() => {
    if (!isEmpty(users) && !isEmpty(organizationUsers)) {
      const firstAdminAvailable = organizationUsers.find(({ status }) => status === UserOrganizationStatus.ADMIN)
      const firstAdminAvailableUidExt = firstAdminAvailable?.uidExt
      const assignedUser = users.find(({ uidExt }) => uidExt === firstAdminAvailableUidExt)
      setAssignedUser(assignedUser)
    }
  }, [organizationId, users, organizationUsers])

  const handleOnChange = React.useCallback(
    (value: number): void => {
      setAssignedUser(users.find(({ uidExt }) => uidExt === value))
    },
    [users]
  )

  if (isEmpty(users)) {
    return null
  }

  return (
    <Stack direction="row" gap={3} sx={{ padding: '0 24px' }} alignItems="center">
      <Typography width="150px" whiteSpace="nowrap">
        {t('removeOrganizationUser.assignProjectsTo')}
      </Typography>
      <Select
        id="projects-assign-select"
        value={assignedUser.uidExt}
        onChange={(event): void => handleOnChange(event.target.value as number)}
        variant="standard"
        fullWidth
        sx={{ height: '50px', overflowX: 'hidden' }}
      >
        {users
          .filter((user) => user.uidExt !== selectedUser.uidExt)
          .map((user) => (
            <AdminSelectorItem key={user.uidExt} value={user.uidExt}>
              <User username={user.username} firstName={user.firstName} lastName={user.lastName} email={user.email} />
            </AdminSelectorItem>
          ))}
      </Select>
    </Stack>
  )
}

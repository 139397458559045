import { ContextMenuType } from '../components/contextMenu/ContextMenu'
import { Project } from '../store/features/apis/slices/project/interfaces'
import { Space } from '../store/features/apis/slices/space/interfaces'
import { openContextMenu } from '../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../store/hooks'
import { ApiKey } from '../containers/dashboard/personalArea/apiKeys/interfaces'
import { Folder } from '../store/features/apis/slices/folder/interfaces'
import { OrganizationV2User } from '../store/features/apis/slices/organization/interfaces'
import { UserV2 } from '../store/features/apis/slices/user/interfaces'
import { Invite } from '../store/features/apis/slices/invitation/interfaces'
import { ModelVersion, TrainingPipeline } from '@/store/features/apis/slices/ai/interfaces'

type ShowFunctionArgs = {
  event: React.MouseEvent
  type?: ContextMenuType
  folder?: Folder
  project?: Project
  space?: Space
  user?: UserV2
  apiKey?: ApiKey
  invitation?: Invite
  organizationUser?: OrganizationV2User
  version?: ModelVersion
  trainingPipeline?: TrainingPipeline
}

type ShowFunction = (args: ShowFunctionArgs) => void

export const useContextMenu = (): {
  show: ShowFunction
} => {
  const dispatch = useAppDispatch()

  return {
    show: ({
      event,
      type,
      folder,
      project,
      space,
      user,
      apiKey,
      invitation,
      organizationUser,
      version,
      trainingPipeline
    }): void => {
      event.preventDefault()
      event.stopPropagation()

      const { pageX, pageY } = event
      dispatch(
        openContextMenu({
          x: pageX,
          y: pageY,
          type,
          folder,
          project,
          space,
          user,
          apiKey,
          invitation,
          organizationUser,
          version,
          trainingPipeline
        })
      )
    }
  }
}

import { styled } from '@mui/material/styles'
import { Switch } from '@mui/material'

interface SwitchStyledProps {
  icon?: string
}

export const SwitchStyled = styled(Switch, {
  shouldForwardProp: (prop) => prop !== 'icon'
})<SwitchStyledProps>(({ theme, icon }) => ({
  width: theme.spacing(7),
  height: theme.spacing(2),
  padding: 0,
  overflow: 'visible',
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.grey['100'],
    border: `1px solid ${theme.palette.grey['200']}`,
    borderRadius: theme.spacing(2)
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    top: '50%',
    transform: 'translate(0,-50%)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translate(50%,-50%)',
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary['300']
      }
    },
    '&.Mui-disabled': {
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.grey['200'],
        border: `1px solid ${theme.palette.grey['300']}`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.grey['300']
      }
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.common.white,
      width: theme.spacing(5),
      height: theme.spacing(5),
      border: `1px solid ${theme.palette.grey['300']}`,
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      transition: '.3s background-color, .3s border',
      '&:before': icon && {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: '9px 8px',
        backgroundImage: `url(${icon})`
      }
    }
  }
}))

import { CreateFolderButton } from './CreateFolderButton'
import { ToolbarButton } from './ToolbarButton'
import { ToolbarTitle } from './ToolbarTitle'
import { useAppDispatch } from '../../store/hooks'
import { openModal } from '../../store/features/uiSlice/uiSlice'
import { useCreateProjectOperation } from '../../operations/project/createProjectOperation'
import { AiSectionPaths, PagePath } from '../../constants'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { useIsAdmin } from '../../permissions/areas'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useIsProfileSectionPage } from '../../hooks/useIsProfileSectionPage'
import { ProfileTabBar } from '../../containers/dashboard/personalArea/profile/ProfileTabBar'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { first } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { useIsModelDetailsPage } from '@/hooks/useIsModelDetailsPage'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const Toolbar = (): React.ReactElement => {
  const { t } = useTranslation(['toolbar', 'modals', 'tooltips'])
  const { currentPath } = useCurrentPath()
  const [searchParams] = useSearchParams()

  const [createProject, canCreateProject] = useCreateProjectOperation(currentPath)

  const spaceId = first(currentPath)
  const dispatch = useAppDispatch()
  const { data: isAdmin } = useIsAdmin()
  const isSearchMode = useIsSearchMode()
  const isProfilePage = useIsProfileSectionPage()
  const isTrainingDataPage = [AiSectionPaths.dataSettings.value, AiSectionPaths.dataSelection.value].includes(
    searchParams.get('page') as string
  )
  const isModelDetailsPage = useIsModelDetailsPage()

  const showToolbar = !isModelDetailsPage

  const showInviteButton = spaceId === PagePath.MANAGE_USERS || spaceId === PagePath.MANAGE_INVITATIONS
  const showAddSpaceButton = spaceId === PagePath.MANAGE_SPACES && isAdmin
  const showNewProjectButton = canCreateProject && !isSearchMode
  const showNewAiModelButton = spaceId === PagePath.AI_MODELS && !isTrainingDataPage
  const showProfileTabBar = isProfilePage

  if (!showToolbar) {
    return null
  }

  return (
    <Stack
      sx={{
        borderBottom: `1px`,
        borderBottomStyle: 'solid',
        borderBottomColor: 'grey.200',
        px: 5,
        py: 3
      }}
      direction="column"
      justifyContent="center"
      qa-attribute="toolbar"
      flexShrink={0}
    >
      <Stack width="100%" direction="row" alignItems="center" spacing={2} flexShrink={0}>
        <ToolbarTitle />
        <CreateFolderButton />
        {showNewProjectButton && (
          <ToolbarButton
            title={t('createProject', { ns: 'tooltips' })}
            icon={<SpriteIcon spriteId="plus" height=".8125rem" width=".8125rem" color="common.white" />}
            onClick={(): void => {
              createProject()
            }}
            label={t('newProjectButtonLabel', { ns: 'toolbar' })}
            variant="contained"
            tabIndex={TAB_INDEXES.CREATE_PROJECT_BUTTON}
          />
        )}
        {showInviteButton && (
          <ToolbarButton
            title={t('inviteColleagues', { ns: 'tooltips' })}
            icon={<SpriteIcon spriteId="plus" height=".8125rem" width=".8125rem" color="common.white" />}
            onClick={(): void => {
              dispatch(
                openModal({
                  template: ModalTemplate.INVITE_COLLEAGUES,
                  showCloseButton: true,
                  modalProps: {
                    title: t('inviteColleagues.title', { ns: 'modals' })
                  }
                })
              )
            }}
            label={t('manageUsers.inviteColleaguesButton', { ns: 'toolbar' })}
            variant="contained"
          />
        )}
        {showAddSpaceButton && (
          <ToolbarButton
            title={t('addSpace', { ns: 'tooltips' })}
            icon={<SpriteIcon spriteId="plus" height=".8125rem" width=".8125rem" color="common.white" />}
            onClick={(): void => {
              dispatch(
                openModal({
                  template: ModalTemplate.SPACE_MEMBERS,
                  showCloseButton: true,
                  modalProps: {
                    title: t('teamSpace.addNewTeam', { ns: 'modals' })
                  }
                })
              )
            }}
            label={t('addSpace', { ns: 'tooltips' })}
            variant="contained"
          />
        )}
        {showNewAiModelButton && (
          <ToolbarButton
            title={t('newModel', { ns: 'tooltips' })}
            icon={<SpriteIcon spriteId="plus" height=".8125rem" width=".8125rem" color="common.white" />}
            onClick={(): void => {
              dispatch(
                openModal({
                  template: ModalTemplate.CREATE_AI_MODEL,
                  showCloseButton: true,
                  modalProps: {
                    title: t('createAiModel.title', { ns: 'modals' })
                  }
                })
              )
            }}
            label={t('newModel', { ns: 'tooltips' })}
            variant="contained"
          />
        )}
      </Stack>
      {showProfileTabBar && <ProfileTabBar />}
    </Stack>
  )
}

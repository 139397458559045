import { Breakpoint, Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type UseIsMobileProps = {
  breakpoint?: number | Breakpoint
}

export const useIsMobile = (props?: UseIsMobileProps): boolean => {
  const { breakpoint = 'lg' } = props || {}
  const isMobileUsingMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down(breakpoint))
  const isMobileUsingUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
  return isMobileUsingMediaQuery || isMobileUsingUserAgent
}

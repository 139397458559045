import { CoreHoursOptions } from './interfaces'
import { useAppSelector } from '../../../../store/hooks'
import { UserListDataType } from '../../../../containers/list/interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const useSelectedUsersIds = (): UserListDataType[] => {
  const checkboxListItems = useAppSelector<UserListDataType[]>((state) => state.ui.checkboxListItems)
  const usersIds = React.useMemo(() => checkboxListItems.filter((item) => item.checked), [checkboxListItems])
  return usersIds
}

export const useCoreHoursOptions = (type: string): { value: string; label: string }[] => {
  const { t } = useTranslation('modals')

  const options: { value: string; label: string }[] = React.useMemo(() => {
    return [
      {
        value: CoreHoursOptions.ZERO,
        label: t(`assignCoreHours.${type.toLowerCase()}HoursOptions.zero${type.toUpperCase()}`)
      },
      {
        value: CoreHoursOptions.FIVE_THOUSAND,
        label: t(`assignCoreHours.${type.toLowerCase()}HoursOptions.fiveThousand${type.toUpperCase()}`)
      },
      {
        value: CoreHoursOptions.TEN_THOUSAND,
        label: t(`assignCoreHours.${type.toLowerCase()}HoursOptions.tenThousand${type.toUpperCase()}`)
      },
      {
        value: CoreHoursOptions.UNLIMITED,
        label: t(`assignCoreHours.${type.toLowerCase()}HoursOptions.unlimited${type.toUpperCase()}`)
      },
      { value: CoreHoursOptions.CUSTOM, label: t(`assignCoreHours.${type.toLowerCase()}HoursOptions.custom`) }
    ]
  }, [type])

  return options
}

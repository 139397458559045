import { TrainingRunMetricsChart } from './TrainingRunMetricsChart'
import { TrainingRunMetricsSummary } from './TrainingRunMetricsSummary'
import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useGetTrainingRunQuery } from '@/store/features/apis/slices/ai/aiSlice'

type TrainingRunListItemDetailsProps = {
  trainingRunId: string
}

export const TrainingRunListItemDetails = ({ trainingRunId }: TrainingRunListItemDetailsProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const { data: trainingRun } = useGetTrainingRunQuery({ trainingRunId }, { skip: isNil(trainingRunId) })

  const summaryMetrics = trainingRun?.metrics.filter((metric) => metric.hasHistory === false)
  const chartMetrics = trainingRun?.metrics.filter((metric) => metric.hasHistory === true)

  return (
    <Grid container spacing={4} padding={4} qa-attribute="aiModel--versionsList--item--details">
      <Grid item xs={6}>
        <Typography variant="textV2" display="block" fontWeight="bold" color="grey.700" mb={2}>
          {t('trainingRun.modelAccuracy')}
        </Typography>
        <TrainingRunMetricsSummary metrics={summaryMetrics} />
      </Grid>
      {chartMetrics?.map((metric) => (
        <Grid item xs={6} key={metric.name}>
          <Typography variant="textV2" display="block" fontWeight="bold" color="grey.700" mb={2}>
            {t(`trainingRun.metrics.${metric.translationKey}`, metric.translationKey)}
          </Typography>
          <TrainingRunMetricsChart trainingRunId={trainingRunId} metricName={metric.name} />
        </Grid>
      ))}
    </Grid>
  )
}

import { getQaSelectorText } from '../../../helpers/qaSelectors'
import { FormControl, FormHelperText, InputLabel, Stack, Typography } from '@mui/material'
import { isNil } from 'lodash'
import * as React from 'react'

interface FormRowProps {
  label: string
  subLabel?: string
  required?: boolean
  error?: boolean
  helperText?: string
  alignItems?: string
  fontSize?: string
  children: React.ReactNode
}

export const FormRow = ({
  label,
  subLabel,
  required,
  error,
  helperText,
  alignItems,
  fontSize,
  children
}: FormRowProps): React.ReactElement => {
  const qaSelectorText = getQaSelectorText(label)

  return (
    <Stack direction="row" alignItems={alignItems ?? 'baseline'} gap={2} my={2} width="100%">
      {!isNil(label) && (
        <Stack direction="column" sx={{ flexBasis: '50%' }}>
          <InputLabel
            required={required}
            sx={{ color: 'text.primary', ...(!isNil(fontSize) && { fontSize }) }}
            qa-attribute={`${qaSelectorText}--label`}
          >
            {label}
          </InputLabel>
          {!isNil(subLabel) && (
            <Typography variant="subtitle" color="grey.700" qa-attribute={`${qaSelectorText}--subLabel`}>
              {subLabel}
            </Typography>
          )}
        </Stack>
      )}

      <Stack direction="column" sx={{ flexBasis: '50%' }}>
        <FormControl error={error} qa-attribute={`${qaSelectorText}-wrapper`}>
          {children}
          <FormHelperText error={error} qa-attribute={`${qaSelectorText}-validation-message`}>
            {helperText}
          </FormHelperText>
        </FormControl>
      </Stack>
    </Stack>
  )
}

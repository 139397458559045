import { useUsageOverviewHeaders } from './header'
import { useUsageOverviewData } from './bodyData'
import { UsageOverviewHeader } from './UsageOverviewHeader'
import { EnhancedTable } from '../../../../components/table/enhancedTable/EnhancedTable'
import { useCenteredCircularProgress } from '../../../../components/progress/CenteredCircularProgress'
import { useErrorHandler } from '../../../../../src/hooks/useErrorHandler'
import { TableFilterType, TableRowProps } from '../../../../components/table/enhancedTable/interfaces'
import { getTotalCoreHours } from '../../../../helpers/coreHours'
import { useGetJobsQuery } from '../../../../store/features/apis/slices/user/userSlice'
import { Alert, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

export const UsageOverviewDisplay = (): React.ReactElement => {
  const { t } = useTranslation()
  const { handleApiHookErrors } = useErrorHandler()

  const { isLoading, isFetching, data: jobs, isError, error } = useGetJobsQuery()
  useCenteredCircularProgress(isLoading || isFetching)

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError])

  const headers = useUsageOverviewHeaders()
  const { data: bodyData } = useUsageOverviewData(jobs)

  const [totalCPUHours, setTotalCPUHours] = React.useState<number>(0)
  const [totalGPUHours, setTotalGPUHours] = React.useState<number>(0)

  const filtersConfig = [
    { id: 'projectName', type: TableFilterType.STRING },
    { id: 'type', type: TableFilterType.STRING },
    { id: 'startTime', type: TableFilterType.DATE, value: { fromDate: null, toDate: null } },
    { id: 'duration', type: TableFilterType.NUMBER, value: '' },
    { id: 'processors', type: TableFilterType.NUMBER, value: '' },
    { id: 'coreHours', type: TableFilterType.NUMBER, value: '' },
    { id: 'status', type: TableFilterType.STRING, value: '' }
  ]

  const csvData: string = React.useMemo(() => {
    let csv = ''
    csv += headers.map((header) => header.label).join(',') + '\n'
    bodyData.forEach((row) => {
      csv += row.tableRowCells.map((cell) => cell.formattedValue).join(',') + '\n'
    })
    return csv
  }, [bodyData, headers])

  const handleFiltersChange = React.useCallback((data: TableRowProps[]): void => {
    const totalCPUHours = getTotalCoreHours(data, 'CPU')
    setTotalCPUHours(totalCPUHours)

    const totalGPUHours = getTotalCoreHours(data, 'GPU')
    setTotalGPUHours(totalGPUHours)
  }, [])

  return (
    <Stack direction="column" gap={4} mt={2.5} mb={4}>
      {isEmpty(jobs) && !isLoading && !isFetching && !isError && (
        <Alert icon={false} severity="info">
          <Typography variant="text">{t('noJobsFound')}</Typography>
        </Alert>
      )}
      {!isEmpty(jobs) && (
        <Stack
          direction="column"
          padding={6}
          border={1}
          borderRadius={1}
          borderColor="grey.200"
          qa-attribute="usage-overview--table"
        >
          <UsageOverviewHeader csvData={csvData} totalCPUHours={totalCPUHours} totalGPUHours={totalGPUHours} />
          <EnhancedTable
            isLoading={isLoading || isFetching}
            tableHeaderData={headers}
            tableBodyData={bodyData}
            filtersConfig={filtersConfig}
            onFiltersChange={handleFiltersChange}
          />
        </Stack>
      )}
    </Stack>
  )
}

import { CollapseWrapper } from '../../../elements/Collapse'
import { ModalInputSelect } from '../../inputComponents/ModalInputSelect'
import { useTranslation } from 'react-i18next'
import * as React from 'react'

interface AdvancedSettingsProps {
  defaultUnitSystem: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void
  isDisabled?: boolean
}

export const AdvancedSettings = ({
  defaultUnitSystem,
  handleChange,
  isDisabled
}: AdvancedSettingsProps): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <CollapseWrapper
      collapseText={t('project.advancedSettings', { ns: 'modals' })}
      collapsedIconRotationValue={90}
      unmountOnExit
      noMarginLeft
    >
      <ModalInputSelect
        label={t('project.fields.defaultUnitSystem', { ns: 'modals' })}
        value={defaultUnitSystem}
        onChange={(event): void => {
          handleChange(event, 'defaultUnitSystem')
        }}
        options={[
          { label: t('metric', { ns: 'modals' }), value: 'SI' },
          { label: t('imperial', { ns: 'modals' }), value: 'US_CUSTOMARY' }
        ]}
        disabled={isDisabled}
      />
    </CollapseWrapper>
  )
}

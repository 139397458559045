import { URLConstants, isLocalhost, navigateToUrl } from '../utils/windowUtils'
import JSCookie from 'js-cookie'
import { isEmpty, isNil } from 'lodash'

interface Cookie {
  uid: string
  sid: string
  username: string
  insideCypress: boolean
  disableIntercom: boolean
  osanoConsent?: boolean
  welcome?: boolean
}

export const getCookie = (): Cookie => {
  if (isLocalhost()) {
    return {
      username: 'ssdfsdf',
      sid: 'a_session_id',
      uid: '54635416352',
      insideCypress: false,
      disableIntercom: false
    }
  }

  const cookie = JSCookie.get()
  if (isEmpty(cookie?.sid) || isEmpty(cookie?.uid) || isEmpty(cookie?.username)) {
    navigateToUrl(URLConstants.LOGOUT_URL)
  }

  return {
    uid: cookie.uid,
    sid: cookie.sid,
    username: cookie.username,
    insideCypress: cookie.inside_cypress === 'true',
    disableIntercom: cookie.disable_intercom === 'true',
    osanoConsent: !isNil(cookie.osano_consentmanager),
    welcome: cookie.welcome === 'true'
  }
}

export const setCookie = (cookieName: string, cookieValue: string, options?: JSCookie.CookieAttributes): void => {
  JSCookie.set(cookieName, cookieValue, options)
}

export const deleteCookie = (cookieName: string): void => {
  JSCookie.remove(cookieName)
}

export const getUsername = (): string => {
  return getCookie().username
}
export const getUserId = (): number => Number(getCookie().uid)
export const isInsideCypress = (): boolean => getCookie().insideCypress

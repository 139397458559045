import { NavigationItem } from './styledComponents'
import { HelpLinks } from './HelpLinks'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const HelpMenu = (): ReactElement => {
  const { t } = useTranslation('header')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)

  const handleOnMouseEnter = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleOnClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <NavigationItem
      style={{ height: '100%', position: 'relative', display: 'flex', gap: 4 }}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnClose}
      key="navigationItemHelp"
      tabIndex={TAB_INDEXES.HELP_MENU}
      onKeyDown={handleOnKeyDown}
      qa-attribute="qa-helpMenuButton"
    >
      {t('help')}
      <SpriteIcon spriteId="angle-right" width={11} height={11} color="grey.550" rotatingAngle={90} />
      <HelpLinks isOpen={isOpen} anchorEl={anchorEl} handleClose={handleOnClose} />
    </NavigationItem>
  )
}

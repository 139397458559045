import { useAppSelector } from '../../../store/hooks'
import { getShowRightPanel } from '../../../store/features/uiSlice/uiSlice'
import { PageCategory } from '../../Breadcrumbs/CategoryBreadcrumbs'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import Grid from '@mui/material/Grid'
import * as React from 'react'
import { has } from 'lodash'

export const RightSidebar = ({ children }: { children?: React.ReactNode }): React.ReactElement => {
  const { currentPathNode } = useCurrentPath()
  const showRightPanel = useAppSelector(getShowRightPanel)

  return (
    !has(PageCategory, currentPathNode) && (
      <Grid
        qa-attribute="right-sidebar"
        className="u-overflowOverlay right-sidebar"
        item
        xs="auto"
        sm="auto"
        md="auto"
        lg="auto"
        xl="auto"
        sx={{ maxHeight: '100%', overflowX: 'hidden' }}
      >
        {showRightPanel && children}
      </Grid>
    )
  )
}

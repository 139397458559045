import { PermissionType } from './interfaces'
import { MenuItemStyled } from '../ShareWithUsersModalTemplate'
import theme from '../../../../theme/defaultTheme'
import { UserAvatarPicture } from '../../../../containers/user/UserAvatarPicture'
import { useAvailableShareProjectPermissions } from '../../../../hooks/useAvailableShareProjectPermissions'
import { useGetUserQuery } from '../../../../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import { CircularProgress, Input, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

interface SharedWithUserProps {
  username: string
  permissionType: PermissionType
  onSelectChange: (event: SelectChangeEvent<string>, email: string) => void
}

export const SharedWithUser = ({
  username,
  permissionType,
  onSelectChange
}: SharedWithUserProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const { data: user, isFetching } = useGetUserQuery({ username }, { skip: isNil(username) })
  const { availableSharePermissions } = useAvailableShareProjectPermissions({
    includeRemoveAccess: true
  })

  if (isFetching) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ marginBottom: theme.spacing(2), '&:last-child': { marginBottom: 0 } }}
    >
      <UserAvatarPicture userName={username} />
      <Typography pl={3} variant="text" color={'text.primary'}>
        {username || user.emailAddress}
      </Typography>
      {permissionType === PermissionType.FULL_ACCESS ? (
        <Input size="small" sx={{ width: '120px', flexShrink: 0, marginLeft: 'auto' }} disabled defaultValue="Owner" />
      ) : (
        <Select
          labelId="permission-select"
          id="permission-select"
          variant="standard"
          size="small"
          sx={{ width: '120px', flexShrink: 0, marginLeft: 'auto' }}
          value={permissionType}
          onChange={(event): void => {
            onSelectChange(event, username)
          }}
          qa-attribute={`share-permission--select`}
        >
          {availableSharePermissions.map((item) => (
            <MenuItemStyled key={item.key} value={item.value} addDivider={item.value === 'NONE'}>
              {t(item.key)}
            </MenuItemStyled>
          ))}
        </Select>
      )}
    </Stack>
  )
}

import { UserListDataType } from './interfaces'
import { ScopeType } from '../../components/modal/templates/share/interfaces'
import { Space } from '../../store/features/apis/slices/space/interfaces'
import { useGetSpaceUsersPermissionsQuery } from '../../store/features/apis/slices/space/spaceSlice'
import { useGetOrganizationUsersQuery } from '../../store/features/apis/slices/organization/organizationSlice'
import { useGetOrganizationId } from '../../store/features/apis/slices/organization/hooks'
import { useGetUsersV2Query } from '../../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'

export const useGetTeamMemberListData = (
  selectedTeamSpace?: Space
): { userList: UserListDataType[]; isLoading: boolean; isFetching: boolean } => {
  const organizationId = useGetOrganizationId()

  const {
    data: organizationUsers,
    isLoading: isOrganizationLoading,
    isFetching: isOrganizationFetching
  } = useGetOrganizationUsersQuery({ organizationId }, { skip: isNil(organizationId) })

  const {
    data: users,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching
  } = useGetUsersV2Query({ organizationId }, { skip: isNil(organizationId) })

  const {
    data: spaceUsersPermissions,
    isLoading: isSpacePermissionLoading,
    isFetching: isSpacePermissionFetching
  } = useGetSpaceUsersPermissionsQuery(
    { spaceId: selectedTeamSpace?.spaceId },
    { skip: isNil(selectedTeamSpace?.spaceId) }
  )

  const userList = React.useMemo(() => {
    if (!isNil(organizationUsers) && !isNil(users)) {
      let organizationUsersData = organizationUsers.users
      let permissionsData = {}
      if (!isNil(spaceUsersPermissions)) {
        permissionsData = spaceUsersPermissions?.permissions.reduce((acc, { scope, permission }) => {
          return scope.scopeType === ScopeType.USER && !isNil(scope.username)
            ? { ...acc, [scope.username]: permission }
            : { ...acc }
        }, {})
      }

      const userList = organizationUsersData.map((user) => {
        const userFromUsers = users.find((u) => u.username === user.username)
        return {
          id: user.userId,
          email: user.email,
          userFullName: !isNil(userFromUsers) ? `${userFromUsers?.firstName} ${userFromUsers?.lastName}` : undefined,
          username: user.username,
          checked: !isEmpty(permissionsData) ? !isNil(permissionsData[user.username]) : false,
          permission: !isEmpty(permissionsData) ? permissionsData[user.username] : null
        }
      })
      return userList
    }
    return []
  }, [organizationUsers, users, spaceUsersPermissions])

  return {
    userList,
    isLoading: isOrganizationLoading || isSpacePermissionLoading || isUsersLoading,
    isFetching: isOrganizationFetching || isSpacePermissionFetching || isUsersFetching
  }
}

export const useGetTeamMemberListV2Data = (): { userList: UserListDataType[]; isLoading: boolean } => {
  const organizationId = useGetOrganizationId()

  const {
    data: organizationUsers,
    isLoading: isOrganizationLoading,
    isFetching: isOrganizationFetching
  } = useGetUsersV2Query({ organizationId }, { skip: isNil(organizationId) })

  const userList = React.useMemo(() => {
    if (!isNil(organizationUsers)) {
      let organizationUsersData = organizationUsers

      const userList = organizationUsersData.map((user) => ({
        id: user.uidExt,
        email: user.email,
        userFullName: `${user.firstName} ${user.lastName}`,
        permission: undefined,
        username: user.username,
        checked: false,
        disabled: false
      }))
      return userList
    }
    return []
  }, [organizationUsers])

  return {
    userList,
    isLoading: isOrganizationFetching || isOrganizationLoading
  }
}

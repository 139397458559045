import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { EnhancedTableWithExternalState } from '@/components/table/enhancedTable/EnhancedTableWithExternalState'
import { useSelectModelTrainingData } from '@/containers/ai/dataSelection/tableHooks/useSelectModelTrainingData'
import { useSelectModelTrainingDataHeader } from '@/containers/ai/dataSelection/tableHooks/useSelectModelTrainingDataHeader'
import { SortingDirection, SortingModeV2 } from '@/store/features/uiSlice/interfaces'
import { getUsername } from '@/helpers/cookies'
import { useGetSearchProjectsQuery } from '@/store/features/apis/slices/project/projectsSlice'
import { useGetProjectScope } from '@/containers/dashboard/mainDisplay/hooks'

export const SearchedProjectsSelection = ({ onSelect, onSelectAll, selectedProjects }): React.ReactElement => {
  const { t } = useTranslation('modals')
  const [projectsPerPage, setProjectsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(1)
  const [order, setOrder] = React.useState<SortingDirection>(SortingDirection.DESC)
  const [orderBy, setOrderBy] = React.useState<SortingModeV2>(SortingModeV2.LAST_MODIFIED_AT)
  const username = getUsername()
  const [searchParams] = useSearchParams()

  const query = searchParams.get('q')
  const scope = useGetProjectScope()

  const { data, isLoading, isFetching } = useGetSearchProjectsQuery(
    {
      sortingModeV2: orderBy,
      sortingDirection: order,
      targetPage: page,
      username,
      projectsPerPage: projectsPerPage + 1,
      offset: projectsPerPage,
      filterby: 'project_text',
      query,
      scope
    },
    { skip: isEmpty(query) }
  )

  const projects = data?.projects.slice(0, projectsPerPage)
  const projectIds = React.useMemo(() => projects?.map((project) => project.projectIdExt), [projects])

  const selectedOnPage = React.useMemo(() => {
    if (isNil(projectIds)) {
      return
    }
    return selectedProjects.filter((selectedProjectId: string) => projectIds.includes(selectedProjectId))
  }, [selectedProjects, projectIds])

  const tableHeaderData = useSelectModelTrainingDataHeader()
  const tableBodyData = useSelectModelTrainingData(projects)

  const onSelectAllCheckboxClick = (): void => {
    if (isNil(projects)) {
      return
    }
    onSelectAll(projectIds)
  }

  if (isEmpty(tableBodyData) && (!isLoading || !isFetching)) {
    return (
      <Stack width="100%" direction="row" justifyContent="center" alignItems="center">
        <Typography>{t('teamSpace.noItemsFound')}</Typography>
      </Stack>
    )
  }

  return (
    <EnhancedTableWithExternalState
      isLoading={isLoading || isFetching}
      tableHeaderData={tableHeaderData}
      tableBodyData={tableBodyData}
      page={page}
      setPage={setPage}
      hasMorePages={data?.projects.length > projects?.length}
      rowsPerPage={projectsPerPage}
      setRowsPerPage={setProjectsPerPage}
      order={order}
      setOrder={setOrder}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      onSelect={onSelect}
      onSelectAll={onSelectAllCheckboxClick}
      selected={selectedOnPage}
      flexGrow={1}
      qa-attribute="searched-projects-selection--table"
    />
  )
}

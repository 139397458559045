import { OperationWithPermission } from '../interfaces'
import { first, last } from 'lodash'
import { pathToRoute } from '@/helpers/router'
import { useCurrentPath } from '@/hooks/useCurrentPath'
import { PathSelector } from '@/containers/spaces/interfaces'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { useGetFolderDetails, useGetSpaceDetails } from '@/store/features/apis/slices/folder/helpers'

export const useOpenFolderOperation = (): OperationWithPermission<PathSelector> => {
  const { navigate } = useDashboardNavigate()

  const { currentPath } = useCurrentPath()

  const spaceId = first(currentPath)
  const folderLocationId = last(currentPath)
  const isSpace = spaceId === folderLocationId

  const { data: space, isLoading: isSpaceLoading } = useGetSpaceDetails({ spaceId: first(currentPath) })
  const { data: folderLocation, isLoading: isFolderLocationLoading } = useGetFolderDetails({
    spaceId,
    folderId: folderLocationId
  })

  const onClick = (folderPath: PathSelector): void => {
    navigate(`/${pathToRoute(folderPath)}`)
  }

  const isAllowed = isSpace
    ? space?.requesterPermissions?.canListContentInRoot
    : folderLocation?.requesterPermissions?.canListContentInFolder

  return [onClick, isAllowed, isSpaceLoading || isFolderLocationLoading]
}

import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { AdminManageUsersDisplay } from '../../containers/dashboard/manageUsers/AdminManageUsersDisplay'
import * as React from 'react'

const ManageUsersPage = (): React.ReactElement => {
  return (
    <MainDisplay>
      <AdminManageUsersDisplay />
    </MainDisplay>
  )
}

export default ManageUsersPage

import { PathSelector } from '../../../../containers/spaces/interfaces'
import { closeModal } from '../../../../store/features/uiSlice/uiSlice'
import { Button } from '../../../button/Button'
import { useGetFolderDetails } from '../../../../store/features/apis/slices/folder/helpers'
import { useUpdateFolderMutation } from '../../../../store/features/apis/slices/folder/folderSlice'
import { useModalEvents } from '../../../../hooks/useModalEvents'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { first } from 'lodash'

interface UpdateFolderSubmitProps {
  isButtonDisabled: boolean
  selectedPath: PathSelector
  folderName: string
  folderId: string
  validateForm: () => boolean
  handleApiHookErrors: (error: FetchBaseQueryError | SerializedError) => void
}

export const UpdateFolderSubmit = ({
  isButtonDisabled,
  selectedPath,
  folderName,
  validateForm,
  folderId,
  handleApiHookErrors
}: UpdateFolderSubmitProps): React.ReactElement => {
  const { t } = useTranslation(['modals'])
  const dispatch = useDispatch()
  const { data: folder, isLoading: areFolderDetailsLoading } = useGetFolderDetails({
    spaceId: first(selectedPath),
    folderId
  })
  const [updateFolder, { isLoading, isError, isSuccess, error }] = useUpdateFolderMutation()

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(closeModal())
    }
  }, [isSuccess])

  const onClick = (): void => {
    if (validateForm()) {
      updateFolder({
        spaceId: selectedPath[0],
        folderId,
        folderName,
        parentItemId: folder.parentFolderId ?? folder.spaceId
      })
    }
  }

  const isSubmitDisabled = isButtonDisabled || isLoading || areFolderDetailsLoading

  useModalEvents(onClick, isSubmitDisabled)

  return (
    <Button onClick={onClick} disabled={isSubmitDisabled} color="primary" variant="contained">
      {t('folder.actions.editFolder')}
    </Button>
  )
}

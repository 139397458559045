import { NavigationTreeProps } from './interfaces'
import { CardIcon } from '../card/CardIcon'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import { Grid, Typography } from '@mui/material'
import * as React from 'react'
import Stack from '@mui/system/Stack'
import { styled } from '@mui/system'
import { SpriteIcon } from '@/assets/SpriteIcon'

const StyledNavigationTree = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isLeaf'
})<{ isSelected: boolean; level: number; isLeaf?: boolean }>(({ isSelected, level, theme }) => ({
  height: theme.spacing(7),
  paddingRight: theme.spacing(3),
  paddingLeft: 24 * level + 8,
  boxSizing: 'border-box',
  userSelect: 'none',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: `${isSelected ? theme.palette.action.selected : theme.palette.grey['100']}`,
    border: `1px solid ${isSelected ? theme.palette.border.primary : theme.palette.grey['200']}`
  },
  border: `1px solid ${isSelected ? theme.palette.border.primary : 'transparent'}`,
  backgroundColor: isSelected ? theme.palette.action.selected : 'transparent',
  color: isSelected ? theme.palette.text.selected : theme.palette.text.primary,
  transition: '.3s background, .3s border'
}))

export const NavigationTree = ({
  label,
  isCurrentLocation,
  isExpanded,
  isSelected,
  level = 0,
  isLeaf,
  onSelectedCallback,
  onCollapsedCallback,
  isTeamSpace,
  isDisabled,
  hasFolders
}: NavigationTreeProps): React.ReactElement => (
  <StyledNavigationTree
    direction="row"
    alignItems="center"
    spacing={1}
    borderRadius={1}
    level={level}
    isSelected={!isDisabled && isSelected}
    onClick={(e): void => {
      e.stopPropagation()
      if (isDisabled) {
        onCollapsedCallback?.()
        return
      }
      onSelectedCallback?.()
    }}
    isLeaf={isLeaf}
    qa-attribute={`${getQaSelectorText(label)}--folder-tree-item`}
  >
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '20px', width: '20px', flexShrink: '0' }}
      onClick={(e): void => {
        e.stopPropagation()
        onCollapsedCallback?.()
      }}
    >
      {hasFolders && (
        <SpriteIcon
          spriteId="angle-right"
          width={11}
          height={11}
          rotatingAngle={isExpanded ? 90 : 0}
          color="grey.550"
        />
      )}
    </Grid>
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '20px', width: '20px', flexShrink: '0' }}>
      <CardIcon size={20} useTeamIcon={isTeamSpace} />
    </Grid>
    <Typography
      sx={{ whiteSpace: 'nowrap' }}
      variant="text"
      color={!isDisabled && !isCurrentLocation ? 'inherit' : 'text.disabled'}
    >
      {label} {isCurrentLocation && '(current location)'}
    </Typography>
  </StyledNavigationTree>
)

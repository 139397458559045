import { getChipColorFromModelState, getHumanReadableText } from './helpers'
import { InternalDistribution } from './InternalDistribution'
import { TableHeaderCell, TableRowProps } from '../../components/table/enhancedTable/interfaces'
import { CustomChip } from '../../components/chips/CustomChip'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { isNil } from 'lodash'
import { Model, ModelState } from '@/store/features/apis/slices/ai/interfaces'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { getCookie } from '@/helpers/cookies'

export const useGetAiModelTrainingTableHeaderData = (): TableHeaderCell[] => {
  const { t } = useTranslation(['adminArea', 'solvers'])
  let tableHeaderData: TableHeaderCell[] = [
    {
      id: 'modelName',
      label: t('ai.tableHeader.modelName'),
      numeric: false,
      disablePadding: false,
      sx: { width: '35%' }
    },
    {
      id: 'analysisType',
      label: t('ai.tableHeader.analysisType'),
      numeric: false,
      disablePadding: false,
      sx: { width: '35%' }
    },
    {
      id: 'state',
      label: t('ai.tableHeader.state'),
      numeric: false,
      disablePadding: false,
      sx: { width: '15%' }
    },
    // Hiding column as no functionality is available yet
    // {
    //   id: 'editRights',
    //   label: t('ai.tableHeader.editRights'),
    //   numeric: false,
    //   disablePadding: false,
    //   sx: { width: '15%' }
    // },
    {
      id: 'internalDistribution',
      label: t('ai.tableHeader.internalDistribution'),
      numeric: false,
      disablePadding: false,
      sx: { width: '15%' }
    }
  ]
  return tableHeaderData
}

enum WeightedSorting {
  MY_ORGANIZATION,
  ONLY_ME,
  NOT_RELEASED
}

const useGetInternalDistributionSort = (): ((state: ModelState, sharedWithOrganization: boolean) => number) => {
  const getInternalDistributionSortValue = (state: ModelState, sharedWithOrganization: boolean): number => {
    if (state === ModelState.RELEASED) {
      return sharedWithOrganization ? WeightedSorting.MY_ORGANIZATION : WeightedSorting.ONLY_ME
    }
    return WeightedSorting.NOT_RELEASED
  }

  return getInternalDistributionSortValue
}

export const useGetAiModelTrainingTableBodyData = (models: Model[]): TableRowProps[] => {
  const { t } = useTranslation(['aiTraining'])
  const id = React.useId()
  const getInternalDistributionSortValue = useGetInternalDistributionSort()
  const { navigate } = useDashboardNavigate()
  const { uid } = getCookie()

  const tableData = React.useMemo(() => {
    if (isNil(models)) {
      return []
    }

    return models
      .map((model: Model) => {
        if (!model.shared_with_organization && uid !== model.creator_user_id) {
          return
        }
        return {
          tableRowId: `${model.uuid}`,
          tableRowCells: [
            {
              id: `${model.name}-${id}-modelName`,
              value: model.name,
              component: <Typography variant="text">{model.name}</Typography>
            },
            {
              id: `${model.analysis_type}-${id}-modelAnalysisType`,
              value: model.analysis_type,
              component: !isNil(model.analysis_type) && (
                <Typography variant="text">
                  {t(`analysisTypes.${model.analysis_type}`, getHumanReadableText(model.analysis_type))}
                </Typography>
              )
            },
            {
              id: `${model.state}-${id}-modelState`,
              value: model.state,
              component: (
                <CustomChip
                  label={model.state}
                  color={getChipColorFromModelState(model.state)}
                  sx={{ textTransform: 'uppercase', fontWeight: 700 }}
                />
              )
            },
            {
              id: `${model.shared_with_organization}-${id}-internalDistribution`,
              value: getInternalDistributionSortValue(model.state, model.shared_with_organization),
              component: (
                <InternalDistribution
                  model={model}
                  onClick={(event: React.MouseEvent): void => event.stopPropagation()}
                />
              ),
              onCellClick: (event: React.MouseEvent): void => event.stopPropagation()
            }
          ],
          onRowClick: (_: React.MouseEvent): void => {
            navigate(`/models/${model.uuid}`)
          }
        }
      })
      .filter(Boolean)
  }, [models])

  return tableData
}

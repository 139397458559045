import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import { SidebarCard } from '@/components/aiModels/cards/SidebarCard'
import { SidebarCardSection } from '@/components/aiModels/cards/SidebarCardSection'
import { UsersAvatarRow } from '@/components/user/UsersAvatarRow'
import { Model } from '@/store/features/apis/slices/ai/interfaces'

const SectionSkeleton = (): React.ReactElement => (
  <Skeleton variant="rounded" width="100%" height={54} animation="wave" />
)

type ModelUsageProps = {
  model?: Model
  isLoading?: boolean
}

export const ModelUsage = ({ model, isLoading }: ModelUsageProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const successRate =
    model?.usage_metrics?.tries > 0
      ? Math.ceil(model.usage_metrics.successful_tries / model.usage_metrics.tries) * 100
      : 0

  const isUsageNotAvailable = isNil(model?.usage_metrics) || model?.usage_metrics.tries === 0

  if (!isLoading && isUsageNotAvailable) {
    return null
  }

  return (
    <SidebarCard title={t('modelDetails.usage')} isLoading={isLoading} qa-attribute="aiModel--section--usage">
      <Stack spacing={4}>
        {isLoading ? (
          <>
            <SectionSkeleton />
            <SectionSkeleton />
            <SectionSkeleton />
          </>
        ) : (
          <>
            <SidebarCardSection title={t('modelDetails.usage')}>
              <UsersAvatarRow users={model.usage_metrics.users} />
            </SidebarCardSection>
            <SidebarCardSection title={t('modelDetails.totalUses')}>
              <Typography variant="textV2" qa-attribute="aiModel--section--usage--totalUses">
                {model.usage_metrics.tries}
              </Typography>
            </SidebarCardSection>
            <SidebarCardSection title={t('modelDetails.successRate')}>
              <Typography variant="textV2" qa-attribute="aiModel--section--usage--successRate">
                {successRate}%
              </Typography>
            </SidebarCardSection>
          </>
        )}
      </Stack>
    </SidebarCard>
  )
}

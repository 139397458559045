import { useAppDispatch } from '../../store/hooks'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { openModal } from '../../store/features/uiSlice/uiSlice'
import { OperationWithPermission } from '../interfaces'
import { useGetFolderDetails } from '../../store/features/apis/slices/folder/helpers'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { t } from 'i18next'
import { first } from 'lodash'

export const useEditFolderOperation = (folderId?: string): OperationWithPermission<void> => {
  const dispatch = useAppDispatch()

  const { currentPath } = useCurrentPath()

  const { data: targetFolder } = useGetFolderDetails({
    spaceId: first(currentPath),
    folderId
  })

  const onClick = (): void => {
    dispatch(
      openModal({
        template: ModalTemplate.EDIT_FOLDER,
        showCloseButton: true,
        modalProps: {
          title: t('folder.editTitle', { ns: 'modals' }),
          folderId
        }
      })
    )
  }

  const isAllowed = targetFolder?.requesterPermissions?.canEditFolderMetadata

  return [onClick, isAllowed]
}

import { AiSearchField } from './AiSearchField'
import { GeneralSearchField } from './GeneralSearchField'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

export enum SearchType {
  GENERAL = 'general',
  AI = 'ai'
}

export const SearchField = ({ searchType }: { searchType: SearchType }): React.ReactElement => {
  const [searchParams] = useSearchParams()
  const [value, setValue] = React.useState(
    searchParams.get('searchType') === searchType ? searchParams.get('q') || '' : ''
  )

  React.useEffect(() => {
    if (!searchParams.has('q') || searchParams.get('q') === '') {
      setValue('')
    }
  }, [searchParams])

  if (searchType === SearchType.GENERAL) {
    return <GeneralSearchField value={value} setValue={setValue} />
  }

  if (searchType === SearchType.AI) {
    return <AiSearchField value={value} setValue={setValue} />
  }

  return null
}

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, styled, tooltipClasses } from '@mui/material'
import { isNil } from 'lodash'
import * as React from 'react'

const HtmlTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    maxWidth: 260,
    border: `1px solid ${theme.palette.grey['200']}`,
    padding: 0
  }
}))

export const StyledTooltip = ({ children, title, ...tooltipProps }: MuiTooltipProps): React.ReactElement => {
  if (isNil(title)) {
    return children
  }

  if (typeof title === 'string') {
    return (
      <MuiTooltip title={title} {...tooltipProps}>
        {children}
      </MuiTooltip>
    )
  }

  return (
    <HtmlTooltip arrow title={title} {...tooltipProps}>
      {children}
    </HtmlTooltip>
  )
}

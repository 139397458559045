import { useAppDispatch } from '../store/hooks'
import { closeAllActionModals, deselectAllItems, resetPagination } from '../store/features/uiSlice/uiSlice'
import { NavigateFunction, NavigateOptions, To, useNavigate as useReactRouterNavigate } from 'react-router-dom'

type useNavigateFn = () => {
  navigate: NavigateFunction
  navigateNoReset: NavigateFunction
}

export const useDashboardNavigate: useNavigateFn = () => {
  const reactRouterNavigate = useReactRouterNavigate()
  const dispatch = useAppDispatch()

  const navigateNoReset = (toOrDelta: To | number, options?: NavigateOptions): void => {
    dispatch(closeAllActionModals())
    if (typeof toOrDelta === 'number') {
      reactRouterNavigate(toOrDelta)
    } else if (typeof toOrDelta === 'string') {
      const target = toOrDelta.startsWith('/') ? toOrDelta : `/${toOrDelta}`
      reactRouterNavigate(target, options)
    } else {
      reactRouterNavigate(toOrDelta, options)
    }
  }

  const navigate = (toOrDelta: To | number, options?: NavigateOptions): void => {
    dispatch(deselectAllItems())
    dispatch(resetPagination())
    navigateNoReset(toOrDelta, options)
  }

  return {
    navigate,
    navigateNoReset
  }
}

import { NavigationContainer, NavigationItem } from './styledComponents'
import { UserMenu } from './UserMenu'
import { HelpMenu } from './HelpMenu'
import { getUrl } from '../../helpers/navigation'
import { useIsMobile } from '../../hooks/useIsMobile'
import { ReactElement } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import Link from '@mui/material/Link'
import * as React from 'react'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

const NavigationMenu = (): ReactElement => {
  const isMobile = useIsMobile()
  const { t } = useTranslation('header')

  const handleForumOnKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      window.location.href = '/forum/'
    }
  }

  return (
    <NavigationContainer component="nav" height="100%" qa-attribute="mainMenu">
      <Box display="flex" height="100%" alignItems="center">
        {!isMobile && (
          <>
            <NavigationItem
              key="navigationItemForum"
              tabIndex={TAB_INDEXES.FORUM_LINK}
              onKeyDown={handleForumOnKeyDown}
            >
              <Link href={getUrl('/forum/')}>
                {t('forum')}
                <SpriteIcon
                  spriteId="arrow-up-right-from-square"
                  width={12}
                  height={12}
                  style={{ marginLeft: '10px' }}
                />
              </Link>
            </NavigationItem>
            <HelpMenu />
          </>
        )}
        <UserMenu />
      </Box>
    </NavigationContainer>
  )
}

export default NavigationMenu

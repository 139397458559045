import { CollapsedBreadcrumbsPlaceholder } from './CollapsedBreadcrumbsPlaceholder'
import * as React from 'react'
import Menu from '@mui/material/Menu'

export const CollapsedBreadcrumbs = ({ children }): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<Element>(null)

  const setAnchorElCallback = React.useCallback(
    (event: React.MouseEvent): void => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget)
      }
    },
    [anchorEl]
  )

  const handleClose = React.useCallback((): void => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <CollapsedBreadcrumbsPlaceholder
        className="breadcrumb-collapsible-placeholder"
        onClick={setAnchorElCallback}
        onMouseOver={setAnchorElCallback}
      >
        …
      </CollapsedBreadcrumbsPlaceholder>
      <Menu
        autoFocus={false}
        sx={{ width: 320 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        {children}
      </Menu>
    </>
  )
}

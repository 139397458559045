import { formatMetricValue, transformMetricValue } from './helpers'
import { METRICS_TYPES } from './interfaces'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { TrainingRunMetric } from '@/store/features/apis/slices/ai/interfaces'
import { Button } from '@/components/button/Button'
import { StyledTooltip } from '@/components/tooltip/StyledTooltip'
import { SpriteIcon } from '@/assets/SpriteIcon'

type TrainingRunMetricsSummaryProps = {
  metrics: TrainingRunMetric[]
}

type DisplayableMetrics = TrainingRunMetric & { formattedValue: string }

type TrainingRunMetricsSummaryItemProps = {
  label: string
  description: DisplayableMetrics['description']
  value: DisplayableMetrics['value']
  formattedValue?: DisplayableMetrics['formattedValue']
}

const TrainingRunMetricsSummaryItem = ({
  label,
  description,
  value,
  formattedValue
}: TrainingRunMetricsSummaryItemProps): React.ReactElement => {
  return (
    <Stack direction="column" spacing={2} qa-attribute="aiModel--trainingRun--metricsSummary">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="textV2">{label}</Typography>
          <StyledTooltip title={description}>
            <SpriteIcon spriteId="circle-info" color="grey.500" width={16} height={16} />
          </StyledTooltip>
        </Stack>
        <Typography variant="textV2" color="grey.700">
          {formattedValue || value}
        </Typography>
      </Stack>
      <LinearProgress variant="determinate" value={value} color="primary" sx={{ height: 8 }} />
    </Stack>
  )
}

export const TrainingRunMetricsSummary = ({ metrics }: TrainingRunMetricsSummaryProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const showMetrics = !isNil(metrics) && !isEmpty(metrics)
  const [seeAll, setSeeAll] = React.useState(false)

  const handleSeeAllClick = (): void => {
    setSeeAll((prev) => !prev)
  }

  const filteredMetrics = metrics
    ?.map((metric) => ({
      ...metric,
      value: transformMetricValue(metric.name, metric.value),
      formattedValue: formatMetricValue(metric.name, metric.value)
    }))
    .sort((a, b) => {
      if (a.name.includes(METRICS_TYPES.APE) && !b.name.includes(METRICS_TYPES.APE)) {
        return -1
      } else if (!a.name.includes(METRICS_TYPES.APE) && b.name.includes(METRICS_TYPES.APE)) {
        return 1
      } else {
        return a.translationKey.localeCompare(b.translationKey)
      }
    })
    .slice(0, seeAll ? metrics.length : 3)

  return (
    <>
      <Stack
        spacing={4}
        padding={7}
        border={1}
        borderColor="border.default"
        borderRadius={1}
        sx={{ backgroundColor: 'white' }}
      >
        {showMetrics ? (
          <>
            {filteredMetrics.map((metric) => (
              <TrainingRunMetricsSummaryItem
                key={metric.name}
                label={t(`trainingRun.metrics.${metric.translationKey}`, metric.translationKey)}
                description={metric.description}
                value={metric.value}
                formattedValue={metric.formattedValue}
              />
            ))}
            <Button variant="outlined" color="primary" size="small" onClick={handleSeeAllClick}>
              {t(`trainingRun.metrics.${seeAll ? 'seeLess' : 'seeAll'}`)}
            </Button>
          </>
        ) : (
          <Typography variant="textV2" color="grey.700">
            {t('trainingRun.metrics.noMetrics')}
          </Typography>
        )}
      </Stack>
    </>
  )
}

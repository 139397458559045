import * as React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'

type SidebarCardSectionProps = {
  title?: string
  children: React.ReactNode
  actions?: React.ReactNode
  isLoading?: boolean
  'qa-attribute'?: string
} & BoxProps

export const SidebarCard = ({
  title,
  actions,
  children,
  isLoading,
  ...props
}: SidebarCardSectionProps): React.ReactElement => {
  return (
    <Box padding={4} border={1} borderRadius={1} borderColor="border.default" width="100%" {...props}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
        {isLoading ? (
          <Skeleton width={100} height={20} variant="rounded" animation="wave" />
        ) : (
          <>
            <Typography variant="h4" fontWeight="bold">
              {title}
            </Typography>
            {actions}
          </>
        )}
      </Stack>
      {children}
    </Box>
  )
}

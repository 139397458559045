import { useAppDispatch, useAppSelector } from '../store/hooks'
import { deselectAllItems, getSelectedItems } from '../store/features/uiSlice/uiSlice'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'
import { useCurrentPath } from '@/hooks/useCurrentPath'
import { PagePath } from '@/constants'

const getAllParentClassNamesUntil = (selector: string, target: HTMLElement | SVGElement): string[] => {
  const classNames = []
  selector = selector.replace('.', '')
  let parent = target
  while (!isNil(parent)) {
    if (
      (typeof parent.className === 'string' && parent.className.includes(selector)) ||
      (typeof parent.className === 'object' && parent.className.baseVal?.includes(selector))
    ) {
      break
    }
    classNames.push(parent.className)
    parent = parent.parentElement
  }
  return classNames.join(' ').split(' ')
}

export const useUnselectOnOutsideClick = (selector: string, ignoreSelectors?: string[]): void => {
  const dispatch = useAppDispatch()
  const selectedItems = useAppSelector(getSelectedItems)
  const { projects, folders } = selectedItems
  const { currentPath } = useCurrentPath()
  const isOnAiTrainingPage = currentPath[0]?.includes(PagePath.AI_MODELS)

  const onClick = React.useCallback(
    ({ target }) => {
      const allClassNames = getAllParentClassNamesUntil(selector, target)
      const ignoreHasClass = ignoreSelectors?.some((className) => allClassNames.includes(className))
      if (!ignoreHasClass && !isOnAiTrainingPage) {
        dispatch(deselectAllItems())
      }
    },
    [selector, ignoreSelectors?.join('-'), isOnAiTrainingPage]
  )

  React.useEffect(() => {
    if (!isEmpty(projects) || !isEmpty(folders)) {
      document.querySelectorAll(selector).forEach((element) => element.addEventListener('click', onClick))
      return () =>
        document.querySelectorAll(selector).forEach((element) => element.removeEventListener('click', onClick))
    }
  }, [projects, folders, selector])
}

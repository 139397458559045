export const TAB_INDEXES = {
  SIMSCALE_LOGO: 1,
  SEARCH_FIELD: 2,
  FORUM_LINK: 3,
  HELP_MENU: 4,
  USER_MENU: 5,
  PERSONAL_SPACE_SECTION: 6,
  AI_MODEL_TRAINING_SECTION: 7,
  ADMIN_SECTION: 8,
  CREATE_FOLDER_BUTTON: 9,
  CREATE_PROJECT_BUTTON: 10,
  SORT_DIRECTION: 11,
  SORT_MODE: 12,
  VIEW_MODE_GRID: 13,
  VIEW_MODE_LIST: 14,
  INFO_PANEL_BUTTON: 15,
  MAIN_DISPLAY: 16,
  FOLDER_PAGINATION: 17,
  PROJECT_PAGINATION: 18
}

import { Button } from '../button/Button'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface InfinitePaginationProps {
  currentPage: number
  currentNumberOfItems: number
  pageSize: number
  hideNextPage?: boolean
  isLoading?: boolean
  setPageCallback: (newPage: number) => void
}

export const InfinitePagination = ({
  currentPage,
  currentNumberOfItems,
  pageSize,
  hideNextPage,
  isLoading,
  setPageCallback
}: InfinitePaginationProps): React.ReactElement => {
  const { t } = useTranslation()

  const goToPreviousPage = (): void => {
    setPageCallback(currentPage - 1)
  }
  const goToNextPage = (): void => {
    setPageCallback(currentPage + 1)
  }

  const showPagination = !(currentPage === 1 && currentNumberOfItems < pageSize)
  const showPreviousPage = currentPage !== 1
  const showNextPage = currentNumberOfItems === pageSize

  return (
    showPagination && (
      <Stack direction="row" alignItems="center" textAlign="center">
        <Button
          onClick={goToPreviousPage}
          startIcon={<SpriteIcon spriteId="angle-right" width=".8125rem" color="grey.600" rotatingAngle={180} />}
          variant="text"
          size="medium"
          color="secondary"
          disabled={!showPreviousPage || isLoading}
        >
          <Typography variant="text">{t('previous')}</Typography>
        </Button>
        <Button
          onClick={goToNextPage}
          endIcon={<SpriteIcon spriteId="angle-right" width=".8125rem" color="grey.600" />}
          variant="text"
          size="medium"
          color="secondary"
          disabled={!showNextPage || hideNextPage || isLoading}
        >
          <Typography variant="text">{t('next')}</Typography>
        </Button>
      </Stack>
    )
  )
}

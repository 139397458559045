import { TableHeaderCell, TableRowProps } from '../../../components/table/enhancedTable/interfaces'
import { useAppSelector } from '../../../store/hooks'
import { User } from '../../../components/user/User'
import { useIsAdmin } from '../../../permissions/areas'
import { useContextMenu } from '../../../hooks/useContextMenu'
import { ContextMenuType } from '../../../components/contextMenu/ContextMenu'
import { useGetOrganizationId } from '../../../store/features/apis/slices/organization/hooks'
import { Invite, InviteStatus } from '../../../store/features/apis/slices/invitation/interfaces'
import { useGetInvitesQuery } from '../../../store/features/apis/slices/invitation/inviteSlice'
import { camelCase, isNil } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

export const useGetManageInvitationsTableHeaderData = (): TableHeaderCell[] => {
  const { t } = useTranslation(['adminArea'])
  return [
    {
      id: 'email',
      label: t('manageInvitations.tableHeader.email'),
      numeric: false,
      disablePadding: true,
      sx: { width: '40%' }
    },
    {
      id: 'invitedAt',
      label: t('manageInvitations.tableHeader.invitedAt'),
      numeric: false,
      disablePadding: true,
      sx: { width: '20%' }
    },
    {
      id: 'approvedAt',
      label: t('manageInvitations.tableHeader.approvedAt'),
      numeric: false,
      disablePadding: true,
      sx: { width: '20%' }
    },
    {
      id: 'status',
      label: t('manageInvitations.tableHeader.status'),
      numeric: false,
      disablePadding: true,
      disableSorting: false,
      sx: { width: '10%' }
    }
  ]
}

export const useGetManageInvitationsTableBodyData = (): {
  total: number
  tableData: TableRowProps[]
  isLoading: boolean
} => {
  const { data: isAdmin } = useIsAdmin()
  const { t, i18n } = useTranslation(['translation', 'modals'])
  const organizationId = useGetOrganizationId()
  const { show } = useContextMenu()

  const {
    data: invitations,
    isFetching,
    isLoading
  } = useGetInvitesQuery({ organizationId }, { skip: isNil(organizationId) })

  const searchString = useAppSelector<string>((state) => state.ui.searchUserString)

  const getFormattedInvitationStatus = React.useCallback((invitationStatus: InviteStatus) => {
    return t(camelCase(invitationStatus))
  }, [])

  const getInvitationStatusColor = React.useCallback((invitationStatus: InviteStatus) => {
    if (invitationStatus === InviteStatus.PENDING_USER_CONFIRMATION) return 'grey'
    return 'secondary'
  }, [])

  const tableData = React.useMemo(() => {
    const filteredInvitations =
      invitations?.filter((invitation: Invite) =>
        [invitation.email.toLowerCase()].some((invitationValue) => invitationValue.includes(searchString))
      ) ?? []

    return filteredInvitations.map((invitation: Invite): TableRowProps => {
      const invitationStatus = getFormattedInvitationStatus(invitation.status)
      const invitationStatusColor = getInvitationStatusColor(invitation.status)
      const showActionButton =
        isAdmin &&
        (invitation.status === InviteStatus.PENDING_USER_CONFIRMATION ||
          invitation.status === InviteStatus.PENDING_ADMIN_APPROVAL)

      return {
        tableRowId: invitation.inviteId,
        tableRowCells: [
          {
            id: invitation.email,
            title: invitation.email,
            component: <User email={invitation.email} />
          },
          {
            id: `${invitation.email}-invitedAt`,
            title: invitation.invitedAt,
            component: (
              <span>
                {!isNil(invitation?.invitedAt)
                  ? new Date(invitation.invitedAt).toLocaleDateString(i18n?.language, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })
                  : ''}
              </span>
            )
          },
          {
            id: `${invitation.email}-approvedAt`,
            title: invitation.approvedAt,
            component: (
              <span>
                {!isNil(invitation?.approvedAt)
                  ? new Date(invitation.approvedAt).toLocaleDateString(i18n?.language, {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric'
                    })
                  : ''}
              </span>
            )
          },
          {
            id: invitationStatus,
            title: invitationStatus,
            component: <Typography color={invitationStatusColor}>{invitationStatus}</Typography>
          }
        ],
        actionButton: showActionButton
          ? (event: React.MouseEvent): void => {
              show({
                event,
                type: ContextMenuType.INVITATION,
                invitation
              })
            }
          : undefined
      }
    })
  }, [invitations, searchString, isAdmin])

  return {
    total: isLoading || isFetching ? 0 : invitations?.length,
    tableData: isLoading || isFetching ? [] : tableData,
    isLoading: isLoading || isFetching
  }
}

import { BreadcrumbsItem } from '../../components/breadcrumb/BreadcrumbsItem'
import { BreadcrumbsRow } from '../../components/breadcrumb/BreadcrumbsRow'
import { pathToRoute } from '../../helpers/router'
import { PagePath } from '../../constants'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

// todo check why it's not using internationalization
export const SpaceCategory = {
  [PagePath.TEAM_SPACES]: 'Spaces',
  personal_space: 'My projects',
  [PagePath.SHARED_WITH_ME]: 'Shared with me',
  [PagePath.MANAGE_SPACES]: 'Manage spaces',
  [PagePath.MANAGE_USERS]: 'Manage users',
  [PagePath.MANAGE_INVITATIONS]: 'Manage invitations',
  [PagePath.PROFILE]: 'Profile',
  [PagePath.MY_INVITATIONS]: 'My invitations',
  [PagePath.USAGE_OVERVIEW]: 'Usage overview',
  [PagePath.SECURITY]: 'Security',
  [PagePath.API_KEYS]: 'Api keys'
}

// todo check why it's not using internationalization
export const PageCategory = {
  [PagePath.MANAGE_SPACES]: 'Manage spaces',
  [PagePath.MANAGE_USERS]: 'Manage users',
  [PagePath.MANAGE_INVITATIONS]: 'Manage invitations',
  [PagePath.PROFILE]: 'Profile',
  [PagePath.MY_INVITATIONS]: 'My invitations',
  [PagePath.USAGE_OVERVIEW]: 'Usage overview',
  [PagePath.SECURITY]: 'Security',
  [PagePath.API_KEYS]: 'Api keys',
  [PagePath.ORGANIZATION_SETTINGS]: 'Organization settings',
  [PagePath.AI_MODELS]: 'AI model training'
}

export const CategoryBreadcrumbs = (): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const { t } = useTranslation()
  const { currentPathNode: categoryId } = useCurrentPath()
  const [searchParams] = useSearchParams()
  const isSearchMode = useIsSearchMode()
  const onElementSelect = (): void => {
    navigate(`/${pathToRoute([categoryId])}`)
  }

  const label = isSearchMode ? `${t('searchBreadcrumbLabel')} ${searchParams.get('q')}` : SpaceCategory[categoryId]
  const path = isSearchMode ? [] : [categoryId]
  return (
    <BreadcrumbsRow>
      <BreadcrumbsItem
        isLastElement
        path={path}
        label={label}
        onElementSelect={onElementSelect}
        nonClickable={isSearchMode}
      />
    </BreadcrumbsRow>
  )
}

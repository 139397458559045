import { ApiKey } from './interfaces'
import { usePutUserApiKeyMutation } from '../../../../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import TextField from '@mui/material/TextField'

interface DescriptionInputProps {
  apiKey: ApiKey
  disabled?: boolean
}

export const DescriptionInput = ({ apiKey, disabled }: DescriptionInputProps): React.ReactElement => {
  const [putUserApiKey] = usePutUserApiKeyMutation()
  const [descriptionInput, setDescriptionInput] = React.useState(apiKey.description ?? '')

  const handleDescriptionOnKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>, apiKey: ApiKey, description: string) => {
      if (event.key === 'Enter') {
        putUserApiKey({ apiKeySuffix: apiKey.suffix, description, enabled: apiKey.enabled })
      }
    },
    []
  )

  const handleDescriptionOnBlur = React.useCallback((apiKey: ApiKey, description: string) => {
    if (apiKey.description !== description) {
      putUserApiKey({ apiKeySuffix: apiKey.suffix, description, enabled: apiKey.enabled })
    }
  }, [])

  return (
    <TextField
      id="table-api-key-description"
      variant="standard"
      fullWidth
      value={descriptionInput}
      onKeyDown={(event): void => handleDescriptionOnKeyDown(event, apiKey, descriptionInput)}
      onBlur={(): void => handleDescriptionOnBlur(apiKey, descriptionInput)}
      onChange={(event): void => setDescriptionInput(event.target.value)}
      disabled={disabled}
      sx={{
        '.MuiInputBase-root:before': { display: 'none' },
        '&:hover': { '.MuiInputBase-root:before': { display: 'block' } }
      }}
    />
  )
}

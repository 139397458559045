import { useCoreHoursOptions, useSelectedUsersIds } from './hooks'
import { CoreHoursOption, CoreHoursOptions } from './interfaces'
import { generateQuotaBudgetsPayload, getCoreHoursOptionAndValue } from './helpers'
import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { ModalInputSelect } from '../../inputComponents/ModalInputSelect'
import { ModalInputText } from '../../inputComponents/ModalInputText'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import {
  ValidationResultProps,
  ValidationRules,
  isNotEmpty,
  isRequired,
  isValidInput
} from '../../../../helpers/validationHelpers'
import { useValidation } from '../../../../hooks/useValidation'
import { TeamMembersList } from '../../../../containers/list/TeamMembersList'
import { useGetTeamMemberListV2Data } from '../../../../containers/list/hooks'
import { useIsAdmin } from '../../../../permissions/areas'
import { ModalInputCheckbox } from '../../inputComponents/ModalInputCheckbox'
import { UserListDataType } from '../../../../containers/list/interfaces'
import { useGetOrganizationId } from '../../../../store/features/apis/slices/organization/hooks'
import {
  useGetOrganizationV2UserQuotaBudgetsQuery,
  useUpdateOrganizationV2UserQuotaBudgetsMutation
} from '../../../../store/features/apis/slices/organization/organizationSlice'
import { UserV2 } from '../../../../store/features/apis/slices/user/interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { isEmpty, isNil } from 'lodash'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import { SpriteIcon } from '@/assets/SpriteIcon'

interface AssignCoreHoursProps extends ModalTemplateProps {
  title: string
  selectedUser?: UserV2
}

export const AssignCoreHours = ({
  title,
  onClose,
  showCloseButton,
  selectedUser
}: AssignCoreHoursProps): React.ReactElement => {
  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const organizationId = useGetOrganizationId()
  const { validateField } = useValidation()
  const { userList, isLoading: isUserListLoading } = useGetTeamMemberListV2Data()
  const [updateOrganizationV2UserQuotaBudgets, { isLoading: isLoadingUserQuotaBudgetsUpdate }] =
    useUpdateOrganizationV2UserQuotaBudgetsMutation()
  const selectedUsersIds = useSelectedUsersIds()

  const { data: quotaBudgets, isLoading: isUserQuotaBudgetsLoading } = useGetOrganizationV2UserQuotaBudgetsQuery(
    { organizationId, uidExt: selectedUser?.uidExt },
    { skip: isNil(organizationId) || isNil(selectedUser?.uidExt) }
  )

  const [searchString, setSearchString] = React.useState<string>('')

  const [cpuHoursValue, setCPUHoursValue] = React.useState<CoreHoursOption>(CoreHoursOptions.UNLIMITED)
  const cpuHoursOptions = useCoreHoursOptions('CPU')
  const [distributeCPUHours, setDistributeCPUHours] = React.useState<boolean>(false)

  const [gpuHoursValue, setGPUHoursValue] = React.useState<CoreHoursOption>(CoreHoursOptions.UNLIMITED)
  const gpuHoursOptions = useCoreHoursOptions('GPU')
  const [distributeGPUHours, setDistributeGPUHours] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (!isNil(quotaBudgets)) {
      const { cpuSeconds, gpuSeconds } = quotaBudgets
      if (!isNil(cpuSeconds)) {
        const value = getCoreHoursOptionAndValue(cpuSeconds)
        setCPUHoursValue(value)
      }
      if (!isNil(gpuSeconds)) {
        const value = getCoreHoursOptionAndValue(gpuSeconds)
        setGPUHoursValue(value)
      }
    }
  }, [quotaBudgets])

  const individualCPUHoursAssigned = React.useMemo(() => {
    if (distributeCPUHours) {
      const individualCoreHours = Math.floor(Number(cpuHoursValue) / selectedUsersIds.length)
      if (!isNaN(individualCoreHours)) {
        return individualCoreHours.toString()
      }
    }
    return cpuHoursValue
  }, [distributeCPUHours, selectedUsersIds, cpuHoursValue])

  const isCPUHoursCustom = React.useMemo(
    () => cpuHoursValue !== CoreHoursOptions.UNLIMITED && cpuHoursValue !== CoreHoursOptions.ZERO,
    [cpuHoursValue]
  )

  const individualGPUHoursAssigned = React.useMemo(() => {
    if (distributeGPUHours) {
      const individualCoreHours = Math.floor(Number(gpuHoursValue) / selectedUsersIds.length)
      if (!isNaN(individualCoreHours)) {
        return individualCoreHours.toString()
      }
    }
    return gpuHoursValue
  }, [distributeGPUHours, selectedUsersIds, gpuHoursValue])

  const isGPUHoursCustom = React.useMemo(
    () => gpuHoursValue !== CoreHoursOptions.UNLIMITED && gpuHoursValue !== CoreHoursOptions.ZERO,
    [gpuHoursValue]
  )

  const showDistributeCoreHours = React.useMemo(() => selectedUsersIds.length > 1, [selectedUsersIds])

  const userListWithSelectedUser = React.useMemo(() => {
    if (!isUserListLoading && !isNil(selectedUser)) {
      return userList.map((user) => {
        if (user.id === selectedUser.uidExt) {
          return { ...user, checked: true, alwaysOnTop: true }
        }
        return user
      })
    }
    return userList
  }, [isUserListLoading, userList, selectedUser])

  const validationRules: ValidationRules = {
    cpuHoursValue: [isRequired, isValidInput],
    gpuHoursValue: [isRequired, isValidInput],
    selectedUsersIds: [isRequired, isNotEmpty, isValidInput]
  }

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    cpuHoursValue: { valid: true, message: '' },
    gpuHoursValue: { valid: true, message: '' },
    selectedUsersIds: { valid: true, message: '' }
  })

  React.useEffect(() => {
    const validation = validateField(
      selectedUsersIds.map((user: UserListDataType) => user.username).join(','),
      validationRules.selectedUsersIds
    )
    if (!isNil(validation)) {
      setValidationErrors({ ...validationErrors, selectedUsersIds: validation })
    }
  }, [selectedUsersIds])

  const handleValidation = React.useCallback(
    (value: any, component: string) => {
      let validation: ValidationResultProps
      switch (component) {
        case 'cpuHoursValue':
          validation = validateField(value, validationRules.cpuHoursValue)
          break
        case 'gpuHoursValue':
          validation = validateField(value, validationRules.gpuHoursValue)
          break
      }
      if (!isNil(validation)) {
        setValidationErrors((prevState) => ({ ...prevState, [component]: validation }))
      }
    },
    [validationRules]
  )

  const handleOnClick = (): void => {
    setSearchString(searchString)
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setSearchString(searchString)
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchString(event.target.value)
  }

  const isLoading = isUserListLoading || isUserQuotaBudgetsLoading || isLoadingUserQuotaBudgetsUpdate
  const isInputDisabled = isLoading || isAdminLoading || !isAdmin
  const isSubmitDisabled = Object.values(validationErrors).some(({ valid }) => !valid) || isInputDisabled

  const onClick = React.useCallback(async () => {
    const freshValidation: Record<string, ValidationResultProps> = {
      ...validationErrors,
      selectedUsersIds: validateField(selectedUsersIds?.map((user: UserListDataType) => user.id).join(','), [
        isRequired,
        isNotEmpty,
        isValidInput
      ])
    }
    setValidationErrors(freshValidation)
    const isValid = Object.keys(freshValidation).every((key) => freshValidation[key].valid)

    if (isValid) {
      const payload = generateQuotaBudgetsPayload(
        selectedUsersIds,
        individualCPUHoursAssigned,
        individualGPUHoursAssigned
      )
      updateOrganizationV2UserQuotaBudgets({
        organizationId,
        payload
      })
        .unwrap()
        .then((_) => {
          dispatch(
            addSnackbarToState({
              severity: 'info',
              message: t('assignCoreHours.assignedSuccessfully')
            })
          )

          onClose()
        })
        .catch((error) => {
          handleApiHookErrors(error)
        })
    }
  }, [selectedUsersIds, individualCPUHoursAssigned, individualGPUHoursAssigned])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={onClick} submitDisabled={isSubmitDisabled}>
        <ModalInputSelect
          value={!isCPUHoursCustom ? cpuHoursValue : CoreHoursOptions.CUSTOM}
          placeholder={t('assignCoreHours.cpuHours')}
          label={t('assignCoreHours.cpuHours')}
          onChange={(event): void => setCPUHoursValue(event.target.value)}
          options={cpuHoursOptions}
          required
          onBlur={(): void =>
            handleValidation(!isCPUHoursCustom ? cpuHoursValue : CoreHoursOptions.CUSTOM, 'cpuHoursValue')
          }
        />
        {isCPUHoursCustom && (
          <ModalInputText
            helperText={validationErrors['cpuHoursValue'].message}
            error={!validationErrors['cpuHoursValue'].valid}
            onChange={(event): void => setCPUHoursValue(event.target.value)}
            placeholder={t('assignCoreHours.customCPUHours')}
            label={t('assignCoreHours.customCPUHours')}
            value={cpuHoursValue}
            required
            onBlur={(): void => handleValidation(cpuHoursValue, 'cpuHoursValue')}
          />
        )}
        {showDistributeCoreHours && (
          <ModalInputCheckbox
            label={t('assignCoreHours.distributeCPUHours')}
            checked={distributeCPUHours}
            onChange={(_, checked): void => setDistributeCPUHours(checked)}
          />
        )}
        <ModalInputSelect
          value={!isGPUHoursCustom ? gpuHoursValue : CoreHoursOptions.CUSTOM}
          placeholder={t('assignCoreHours.gpuHours')}
          label={t('assignCoreHours.gpuHours')}
          onChange={(event): void => setGPUHoursValue(event.target.value)}
          options={gpuHoursOptions}
          required
          onBlur={(): void =>
            handleValidation(!isGPUHoursCustom ? gpuHoursValue : CoreHoursOptions.CUSTOM, 'gpuHoursValue')
          }
        />
        {isGPUHoursCustom && (
          <ModalInputText
            helperText={validationErrors['gpuHoursValue'].message}
            error={!validationErrors['gpuHoursValue'].valid}
            onChange={(event): void => setGPUHoursValue(event.target.value)}
            placeholder={t('assignCoreHours.customGPUHours')}
            label={t('assignCoreHours.customGPUHours')}
            value={gpuHoursValue}
            required
            onBlur={(): void => handleValidation(gpuHoursValue, 'gpuHoursValue')}
          />
        )}
        {showDistributeCoreHours && (
          <ModalInputCheckbox
            label={t('assignCoreHours.distributeGPUHours')}
            checked={distributeGPUHours}
            onChange={(_, checked): void => setDistributeGPUHours(checked)}
          />
        )}
        <Typography variant="textBoldWithEllipsis" paragraph pt={2} mb={2}>
          {t('assignCoreHours.chooseOrganizationMembers')}
        </Typography>
        <TextField
          fullWidth
          placeholder={t('assignCoreHours.searchOrganizationMembers')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ p: 1 }}>
                <SpriteIcon
                  spriteId="magnifying"
                  width=".8125rem"
                  onClick={handleOnClick}
                  style={{ cursor: 'pointer' }}
                />
              </InputAdornment>
            )
          }}
          value={searchString}
          variant="standard"
          onKeyDown={handleOnKeyDown}
          onChange={handleOnChange}
          sx={{ paddingBottom: '24px' }}
          disabled={isLoading}
          qa-attribute="search-team-members--textfield"
        />
        {isLoading ? (
          <Stack alignItems="center">
            <CircularProgress size="28px" />
          </Stack>
        ) : (
          <>
            <TeamMembersList
              userList={userListWithSelectedUser}
              searchString={searchString}
              disabled={isInputDisabled}
              isLoading={isUserListLoading}
              appendUserListItemComponent={(user: UserListDataType): React.ReactNode => (
                <IndividualCoreHoursAssigned
                  cpuHours={individualCPUHoursAssigned}
                  gpuHours={individualGPUHoursAssigned}
                  user={user}
                />
              )}
            />
            {!validationErrors['selectedUsersIds']?.valid && (
              <Typography variant="text" color="error" pt={2}>
                {validationErrors['selectedUsersIds']?.message}
              </Typography>
            )}
          </>
        )}
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        <Button onClick={onClick} color="primary" variant="contained" disabled={isSubmitDisabled}>
          {t('assignCoreHours.save')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

const IndividualCoreHoursAssigned = ({
  cpuHours,
  gpuHours,
  user
}: {
  cpuHours: string
  gpuHours: string
  user: UserListDataType
}): React.ReactElement => {
  if (!user.checked) {
    return null
  }

  const showCPU = React.useMemo(() => !isEmpty(cpuHours) && Number(cpuHours) > 0, [cpuHours])
  const showGPU = React.useMemo(() => !isEmpty(gpuHours) && Number(gpuHours) > 0, [gpuHours])

  return (
    <Stack direction="column" gap={0} flexShrink={0} flexGrow={1} alignItems={'end'}>
      {showCPU && (
        <Typography variant="text" noWrap>
          {cpuHours} CPU
        </Typography>
      )}
      {showGPU && (
        <Typography variant="text" noWrap>
          {gpuHours} GPU
        </Typography>
      )}
    </Stack>
  )
}

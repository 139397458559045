import { FaviconThumbnail } from '../../../../../components/FaviconThumbnail'
import { Button } from '../../../../../components/button/Button'
import { ModalTemplate } from '../../../../../store/features/uiSlice/interfaces'
import { useAppDispatch } from '../../../../../store/hooks'
import { addSnackbarToState, openModal } from '../../../../../store/features/uiSlice/uiSlice'
import {
  OrganizationDomain,
  OrganizationDomainStatus
} from '../../../../../store/features/apis/slices/organization/interfaces'
import { useDeleteOrganizationDomainMutation } from '../../../../../store/features/apis/slices/organization/organizationSlice'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { useBlockPageLeave } from '../../../../../hooks/useBlockPageLeave'
import { CustomChip } from '../../../../../components/chips/CustomChip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type DomainsListItemProps = {
  domain: OrganizationDomain
}

export const DomainsListItem = ({ domain }: DomainsListItemProps): React.ReactElement => {
  const { t } = useTranslation('domains')
  const dispatch = useAppDispatch()
  const [preventivelyRemoved, setPreventivelyRemoved] = React.useState(false)
  const [deleteOrganizationDomain] = useDeleteOrganizationDomainMutation()
  const { handleApiHookErrors } = useErrorHandler()
  const { blockPageLeave, unblockPageLeave } = useBlockPageLeave()

  const statusLabel = t(`status.${domain.status}`)
  const statusColor = domain.status === OrganizationDomainStatus.DECLINED ? 'error' : 'default'
  const showStatusChip = domain.status !== OrganizationDomainStatus.VERIFIED

  const handleRemove = (): void => {
    blockPageLeave()
    setPreventivelyRemoved(true)
    dispatch(
      addSnackbarToState({
        severity: 'info',
        message: t('alerts.removeDomainSuccess', { domain: domain.domain }),
        showUndoButton: true,
        onClose: (_, reason) => {
          if (reason !== 'undo') {
            deleteOrganizationDomain({ domain: domain.domain })
              .unwrap()
              .catch(handleApiHookErrors)
              .finally(() => {
                unblockPageLeave()
              })
          } else {
            setPreventivelyRemoved(false)
            unblockPageLeave()
          }
        }
      })
    )
  }

  const handleStatusOnClick = (): void => {
    let modalTemplate = ModalTemplate.DOMAIN_VERIFICATION
    if (domain.status === OrganizationDomainStatus.DECLINED) {
      modalTemplate = ModalTemplate.DOMAIN_DECLINED
    }

    dispatch(openModal({ template: modalTemplate, showCloseButton: true, modalProps: { domain: domain.domain } }))
  }

  if (preventivelyRemoved) {
    return null
  }

  return (
    <Stack
      direction="row"
      gap={3}
      alignItems="center"
      paddingY={2}
      borderBottom={1}
      borderColor="grey.150"
      qa-attribute="domains-management--listItem"
    >
      <FaviconThumbnail url={domain.domain} />
      <Typography variant="text">{domain.domain}</Typography>
      {showStatusChip && (
        <CustomChip
          onClick={handleStatusOnClick}
          label={statusLabel}
          size="small"
          color={statusColor}
          clickable
          qa-attribute="domains-management--listItem--status"
          sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
        />
      )}
      <Stack direction="row" gap={2} alignItems="center" marginLeft="auto">
        <Button
          onClick={handleRemove}
          color="primary"
          variant="outlined"
          qa-attribute="domains-management--listItem--removeButton"
        >
          {t('list.buttons.remove')}
        </Button>
      </Stack>
    </Stack>
  )
}

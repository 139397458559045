import { useAppDispatch } from '../../store/hooks'
import { OperationWithPermission } from '../interfaces'
import { Space } from '../../store/features/apis/slices/space/interfaces'
import { openModal } from '../../store/features/uiSlice/uiSlice'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { useIsAdmin } from '../../permissions/areas'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const useDeleteSpaceOperation = (space: Space): OperationWithPermission<void> => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { data: isAdmin } = useIsAdmin()

  const canDeleteSpace = isAdmin || space?.requesterPermissions?.canDeleteSpace

  const onClick = React.useCallback(() => {
    dispatch(
      openModal({
        template: ModalTemplate.TRASH_SPACE,
        showCloseButton: true,
        modalProps: {
          title: t('trash.space.title', { ns: 'modals' }),
          selectedTeamSpace: space
        }
      })
    )
  }, [space])

  return [onClick, canDeleteSpace]
}

import { ConsoleLogMethod, KibanaSeverity } from './interfaces'
import { isNil } from 'lodash'

const FIREFOX_UNHANDLED_FAILED_NETWORK_MESSAGE = 'NetworkError when attempting to fetch resource.'
const GENERIC_UNHANDLED_FAILED_NETWORK_MESSAGE = 'Failed to fetch'
const SAFARI_UNHANDLED_FAILED_NETWORK_MESSAGE = 'Load failed'
const GENERIC_ABORTED_REQUEST_MESSAGE = 'The user aborted a request'
const FIREFOX_ABORTED_REQUEST_MESSAGE = 'The operation was aborted'
const SAFARI_ABORTED_REQUEST_MESSAGE = 'Fetch was aborted'
const THIRD_PARTY_SCRIPT_ERROR = 'Script error.'
const VIEWER_COMPONENT_UNMOUNTED_MESSAGE = 'viewer component unmounted'
const USERFLOW_MESSAGE = 'Userflow.js'
const EMPTY_MESSAGE = 'No message or stack trace provided'
const RESIZE_OBSERVER_LOOP_ERROR_MESSAGE = 'ResizeObserver loop'
export const WARNING_MESSAGES = [
  FIREFOX_UNHANDLED_FAILED_NETWORK_MESSAGE,
  GENERIC_UNHANDLED_FAILED_NETWORK_MESSAGE,
  SAFARI_UNHANDLED_FAILED_NETWORK_MESSAGE,
  GENERIC_ABORTED_REQUEST_MESSAGE,
  FIREFOX_ABORTED_REQUEST_MESSAGE,
  SAFARI_ABORTED_REQUEST_MESSAGE,
  THIRD_PARTY_SCRIPT_ERROR,
  VIEWER_COMPONENT_UNMOUNTED_MESSAGE,
  USERFLOW_MESSAGE,
  EMPTY_MESSAGE,
  RESIZE_OBSERVER_LOOP_ERROR_MESSAGE
]

export const getSeverityFromLogPayloadToKibanaPayload = (logMethod: ConsoleLogMethod): KibanaSeverity => {
  switch (logMethod) {
    case 'debug':
      return KibanaSeverity.DEBUG
    case 'warn':
      return KibanaSeverity.WARNING
    case 'error':
      return KibanaSeverity.ERROR
    default:
      return KibanaSeverity.INFO
  }
}

export const isValidTraceId = (traceId: string): boolean => {
  return !isNil(traceId) && traceId !== 'undefined' && traceId !== 'null'
}

export const makeUniqueId = (): string => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 8; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const isReactRouterErrorArray = (logEntryData: Array<unknown>): boolean => {
  return logEntryData.some((entry: unknown) => typeof entry === 'object' && 'componentStack' in entry)
}

export const generateReactRouterErrorMessage = (logEntryData: Array<unknown>): string => {
  return logEntryData
    .filter((entry: unknown) => typeof entry !== 'object' || !('componentStack' in entry))
    .map((entry: unknown) => entry.toString())
    .join(': ')
}

export const generateReactRouterErrorStackTrace = (logEntryData: Array<unknown>): string => {
  return logEntryData
    .filter((entry: unknown) => typeof entry === 'object' && 'componentStack' in entry && !isNil(entry.componentStack))
    .map((entry: { componentStack: string }) => entry.componentStack)
    .join('\n')
}

import { FixedSizeVirtualizedList } from './FixedSizeVirtualizedList'
import { UserListDataType } from '../../../../containers/list/interfaces'
import { TeamSpaceUserListItem } from '../../../../containers/list/TeamSpaceUserListItem'
import { PermissionType } from '../share/interfaces'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setCheckboxListItems } from '../../../../store/features/uiSlice/uiSlice'
import { sortUsers } from '../../../../containers/list/helpers'
import * as React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'

export const ManageMembers = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const checkboxListItems = useAppSelector<UserListDataType[]>((state) => state.ui.checkboxListItems)
  const addedMembers = React.useMemo(
    () =>
      sortUsers(
        checkboxListItems.filter((item) => !isNil(item.permission) && item.isMember),
        'asc'
      ),
    [checkboxListItems]
  )

  const handleRemoveUser = React.useCallback(
    (id: string) => {
      const updatedList = checkboxListItems.map((member) => {
        if (member.id === id) {
          return { ...member, permission: PermissionType.REMOVE, isMember: false, checked: false }
        }
        return member
      })
      dispatch(setCheckboxListItems(updatedList))
    },
    [checkboxListItems]
  )

  const handlePermissionChange = React.useCallback(
    (permission: PermissionType, id: string): void => {
      const permissionUpdates = checkboxListItems.map((item) => (item.id === id ? { ...item, permission } : item))
      dispatch(setCheckboxListItems(permissionUpdates))
    },
    [checkboxListItems]
  )

  return (
    !isEmpty(addedMembers) && (
      <Stack direction="column" gap={2}>
        <Typography variant="text">{t('teamSpace.members')}:</Typography>
        <FixedSizeVirtualizedList
          items={addedMembers}
          renderComponent={({ item, style }): React.ReactElement => (
            <TeamSpaceUserListItem
              userListData={item}
              handleRemoveButtonClick={handleRemoveUser}
              handlePermissionChange={handlePermissionChange}
              style={style}
              showPermissionsDropdown
            />
          )}
        />
      </Stack>
    )
  )
}

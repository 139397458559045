import { useNavigateToInfinitePage } from './hooks'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  deselectItem,
  getCurrentProjectPage,
  getCurrentSelectedProjectId,
  setCurrentProjectPage
} from '../../../../store/features/uiSlice/uiSlice'
import { PROJECT_PAGE_SIZE } from '../../../../store/features/apis/slices/project/helpers'
import { InfinitePagination } from '../../../../components/pagination/InfinitePagination'
import { Project } from '../../../../store/features/apis/slices/project/interfaces'
import * as React from 'react'
import { isNil } from 'lodash'
import { Stack } from '@mui/material'

interface InfiniteProjectsPaginationProps {
  projects: Project[]
  isLoading: boolean
  hideNextPage?: boolean
}

export const InfiniteProjectsPagination = ({
  projects,
  isLoading,
  hideNextPage
}: InfiniteProjectsPaginationProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const currentProjectPage = useAppSelector(getCurrentProjectPage)
  const selectedProjectId = useAppSelector(getCurrentSelectedProjectId)
  useNavigateToInfinitePage()

  const setPageCallback = React.useCallback(
    (page: number) => {
      dispatch(setCurrentProjectPage(page))
      if (!isNil(selectedProjectId)) {
        dispatch(deselectItem({ id: selectedProjectId, type: 'project' }))
      }
    },
    [selectedProjectId]
  )

  if (isNil(projects)) {
    return null
  }

  return (
    <Stack direction="row" justifyContent="center" pb={2}>
      <InfinitePagination
        currentPage={currentProjectPage}
        currentNumberOfItems={projects.length}
        pageSize={PROJECT_PAGE_SIZE}
        setPageCallback={setPageCallback}
        hideNextPage={hideNextPage}
        isLoading={isLoading}
      />
    </Stack>
  )
}

import { TableHeaderCell } from '../../../components/table/enhancedTable/interfaces'

export const tableHeaderData: TableHeaderCell[] = [
  {
    id: 'spaceName',
    label: 'Spaces',
    numeric: false,
    disablePadding: true
  }
]

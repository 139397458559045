import { ContextMenuType } from '../../../components/contextMenu/ContextMenu'
import { useContextMenu } from '../../../hooks/useContextMenu'
import { useMultiSelect } from '../../../hooks/useMultiSelect'
import { ViewMode } from '../../../store/features/uiSlice/interfaces'
import { FolderCard } from '../../../components/folders/FolderCard'
import { getCurrentFolderPage, getViewingMode } from '../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../store/hooks'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { getPaginatedFolderData, useGetFolders } from '../../../store/features/apis/slices/folder/helpers'
import { FolderRow } from '../../../components/folders/FolderRow'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import * as React from 'react'
import Grid from '@mui/material/Grid'
import { first, last } from 'lodash'
import { useOpenFolderOperation } from '@/operations/folder/openFolderOperation'

export const FolderDataGridComponents = (): React.ReactElement => {
  const { currentPath } = useCurrentPath()
  const { data: folders, isLoading } = useGetFolders({ spaceId: first(currentPath), folderId: last(currentPath) })
  const { isSelectedItem, handleMultiSelection } = useMultiSelect()
  const { show } = useContextMenu()
  const displayType = useAppSelector(getViewingMode)
  const currentFolderPage = useAppSelector(getCurrentFolderPage)
  const paginatedFolders = getPaginatedFolderData(folders, currentFolderPage)
  const isMobile = useIsMobile()
  const [openFolder] = useOpenFolderOperation()

  if (isLoading) {
    return null
  }

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, folderId: string): void => {
    const isDoubleClick = event.detail === 2

    if (isDoubleClick || isMobile) {
      openFolder([...currentPath, folderId])
    }
    handleMultiSelection(event, folderId, 'folder')
  }

  if (displayType === ViewMode.GRID) {
    return (
      <>
        {paginatedFolders.map((folder) => (
          <Grid key={folder.folderId}>
            <FolderCard
              folder={folder}
              selected={isSelectedItem(folder.folderId, 'folder')}
              onClick={handleOnClick}
              onContextMenu={(event): void => show({ event, type: ContextMenuType.FOLDER, folder })}
              className="selectable-card"
            />
          </Grid>
        ))}
      </>
    )
  }

  return (
    <>
      {paginatedFolders.map((folder) => (
        <Grid key={folder.folderId}>
          <FolderRow
            folder={folder}
            selected={isSelectedItem(folder.folderId, 'folder')}
            onClick={handleOnClick}
            onContextMenu={(event): void => show({ event, type: ContextMenuType.FOLDER, folder })}
            className="selectable-card"
          />
        </Grid>
      ))}
    </>
  )
}

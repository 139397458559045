import theme from '../../../theme/defaultTheme'
import * as React from 'react'
import { Checkbox, ListItem, ListItemIcon } from '@mui/material'

interface CheckboxListItemProps {
  id: string | number
  isChecked: boolean
  isSelected?: boolean
  onCheckboxClick: (id: string | number) => void
  isDisabled?: boolean
  style?: React.CSSProperties
  renderComponent?: React.ReactElement
}

export const CheckboxListItem = ({
  id,
  isChecked,
  isSelected,
  onCheckboxClick,
  isDisabled,
  style,
  renderComponent
}: CheckboxListItemProps): React.ReactElement => {
  const [isHovered, setIsHovered] = React.useState(false)
  const labelId = `checkbox-list-item-${id}`

  return (
    <ListItem
      sx={{
        gridGap: theme.spacing(3),
        padding: `0 ${theme.spacing(1)}`,
        ...(isSelected ? { backgroundColor: 'action.selected' } : {})
      }}
      style={style}
      key={id}
      disablePadding
      divider
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={(): void => setIsHovered(false)}
    >
      <ListItemIcon sx={{ minWidth: 'initial' }}>
        <Checkbox
          onClick={(): void => {
            onCheckboxClick(id)
          }}
          checked={isChecked}
          disabled={isDisabled}
          disableRipple
          inputProps={{ 'aria-labelledby': labelId }}
          sx={{
            '&.MuiCheckbox-root': {
              color: isDisabled && 'rgba(0, 0, 0, 0.26)'
            },
            ...(isHovered ? { borderColor: 'primary.main' } : {})
          }}
        />
      </ListItemIcon>
      {renderComponent}
    </ListItem>
  )
}

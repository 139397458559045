import { Button } from '../../../../../components/button/Button'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import {
  ValidationResultProps,
  isNotEmpty,
  isRequired,
  isValidDomain,
  isValidInput
} from '../../../../../helpers/validationHelpers'
import { useValidation } from '../../../../../hooks/useValidation'
import { useAppDispatch } from '../../../../../store/hooks'
import { openModal } from '../../../../../store/features/uiSlice/uiSlice'
import { ModalTemplate } from '../../../../../store/features/uiSlice/interfaces'
import { usePostOrganizationDomainMutation } from '../../../../../store/features/apis/slices/organization/organizationSlice'
import { useOrganizationId } from '../../../../../hooks/newWorld'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

export const AddDomainForm = (): React.ReactElement => {
  const { t } = useTranslation('domains')
  const dispatch = useAppDispatch()
  const [domain, setDomain] = React.useState<string>('')
  const { validateField } = useValidation()
  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    domain: { valid: true, message: '' }
  })
  const { data: organizationId } = useOrganizationId()
  const [postOrganizationDomain] = usePostOrganizationDomainMutation()

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDomain(event.target.value)
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      handleAddDomain()
    }
  }

  const handleAddDomain = (): void => {
    const freshValidation: Record<string, ValidationResultProps> = {
      ...validationErrors,
      domain: validateField(domain, [isRequired, isNotEmpty, isValidInput, isValidDomain])
    }
    setValidationErrors(freshValidation)
    const isValid = Object.keys(freshValidation).every((key) => freshValidation[key].valid)

    if (isValid) {
      postOrganizationDomain({ domain, organizationId })
        .unwrap()
        .then(() => {
          dispatch(
            openModal({
              template: ModalTemplate.DOMAIN_VERIFICATION,
              showCloseButton: true,
              modalProps: { domain }
            })
          )
          setDomain('')
        })
        .catch(handleApiHookErrors)
    }
  }

  return (
    <Stack direction="row" gap={2} qa-attribute="domains-management--form">
      <FormControl error={!validationErrors['domain'].valid} sx={{ width: '100%', maxWidth: '500px' }}>
        <TextField
          value={domain}
          variant="standard"
          placeholder={t('form.inputPlaceholder')}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          fullWidth
          qa-attribute="domains-management--form--input"
        />
        <FormHelperText error={!validationErrors['domain'].valid} qa-attribute="domains-management--form--helperText">
          {validationErrors['domain'].message}
        </FormHelperText>
      </FormControl>
      <Button
        onClick={handleAddDomain}
        color="primary"
        variant="outlined"
        qa-attribute="domains-management--form--submit"
      >
        {t('form.addDomain')}
      </Button>
    </Stack>
  )
}

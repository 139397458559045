export enum URLConstants {
  AUTHENTICATION_URL = '/signin/',
  LOGOUT_URL = '/signin/?action=logout',
  VALIDATE_URL = '/api/v1/auth/validate'
}

export const openTab = (url: string): void => {
  window.open ? window.open(url, '_blank') : window.location.assign(url)
}

export const navigateToUrl = (url: string): void => {
  window.location.href = url
}

export const isLocalhost = (): boolean => window.location.hostname === 'localhost'
export const isProduction = (): boolean => window.location.host.includes('www.simscale.com')
export const isStaging = (): boolean => window.location.host.includes('staging.simscale.com')

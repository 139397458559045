import { Alert, Grow } from '@mui/material'
import { isNil } from 'lodash'
import * as React from 'react'

export const SharingAlert = ({ children }): React.ReactElement => {
  return (
    <Grow in={!isNil(children)} style={{ flexShrink: 0 }}>
      <Alert icon={false} severity="warning">
        {children}
      </Alert>
    </Grow>
  )
}

import { Order, TableFilter, TableFilterType, TableHeaderCell, TableRowProps } from './interfaces'
import { isBoolean, isEmpty, isNil } from 'lodash'

const descendingComparator = (a: TableRowProps, b: TableRowProps, orderByIndex: number): number => {
  const getValue = (row: TableRowProps): any =>
    isNil(row.tableRowCells[orderByIndex]?.title)
      ? row.tableRowCells[orderByIndex]?.value
      : row.tableRowCells[orderByIndex]?.title

  const aValue = getValue(a)
  const bValue = getValue(b)

  if (isNil(aValue) || isNil(bValue)) return 0

  if (isBoolean(aValue) && isBoolean(bValue)) {
    return aValue === bValue ? 0 : aValue ? -1 : 1
  }

  return aValue > bValue ? -1 : aValue < bValue ? 1 : 0
}

export const getComparator = (
  order: Order,
  orderBy: string,
  tableHeaderData: TableHeaderCell[]
): ((a: TableRowProps, b: TableRowProps) => number) => {
  const orderByIndex = tableHeaderData.findIndex((headerCell) => headerCell.id === orderBy)
  return order === 'desc'
    ? (a, b): number => descendingComparator(a, b, orderByIndex)
    : (a, b): number => -descendingComparator(a, b, orderByIndex)
}

export const applyFilters = (row: TableRowProps, filters: TableFilter[]): boolean => {
  const activeFilters = filters.filter(({ value, type }) => {
    switch (type) {
      case TableFilterType.DATE:
        return !isNil(value.fromDate) && !isNil(value.toDate)
      case TableFilterType.NUMBER:
        return value !== ''
      default:
        return !isNil(value)
    }
  })

  if (isEmpty(activeFilters)) {
    return true
  }

  return activeFilters.every((activeFilter) => {
    const selectedCell = row.tableRowCells.find(({ id }) => id === activeFilter.id)

    if (isNil(selectedCell)) {
      throw new Error(`Cell with id ${activeFilter.id} not found`)
    }

    if (activeFilter.type === 'string') {
      return selectedCell.value?.toLowerCase().includes(activeFilter.value.toLowerCase())
    }

    if (activeFilter.type === 'date' && !isNil(activeFilter.value.fromDate) && !isNil(activeFilter.value.toDate)) {
      const selectedDate = new Date(selectedCell.value)
      return selectedDate >= activeFilter.value.fromDate && selectedDate <= activeFilter.value.toDate
    }

    if (activeFilter.type === 'number' && !isEmpty(activeFilter.value)) {
      return activeFilter.value === selectedCell.value.toString()
    }

    // TODO add other types of filters ENG-42722
    // number
    // date

    return true
  })
}

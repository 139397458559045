import { Space } from './interfaces'
import { useGetOrganizationSpacesQuery, useGetUserSpacesQuery } from './spacesSlice'
import { useGetOrganizationId } from '../organization/hooks'
import { isNil } from 'lodash'

export const usePersonalSpaceId = (): string => {
  const { data: spaces, isSuccess, isLoading, isFetching } = useGetUserSpacesQuery()
  if (!isSuccess || isLoading || isFetching) {
    return null
  }

  return spaces.find((space) => space.spaceType === 'PERSONAL').spaceId
}

export const useOrganizationTeamSpaces = (): { spaces: Space[]; isLoading: boolean } => {
  const organizationId = useGetOrganizationId()

  const {
    data: spaces,
    isLoading,
    isFetching
  } = useGetOrganizationSpacesQuery(
    {
      organizationId
    },
    { skip: isNil(organizationId) }
  )

  return {
    spaces,
    isLoading: isLoading || isFetching
  }
}

export const useSpaceById = (
  spaceId: string,
  options?: { skip: boolean }
): { space: Space; isLoading: boolean; isError: boolean; isFetching: boolean } => {
  const { data: spaces, isSuccess, isLoading, isError, isFetching } = useGetUserSpacesQuery(undefined, options)
  return {
    space: !isSuccess ? null : spaces.find((space) => space.spaceId === spaceId),
    isLoading,
    isError,
    isFetching
  }
}

import { UserProfileWidget } from './UserProfileWidget'
import { UserQuotaWidget } from '../../../userQuotaWidget/UserQuotaWidget'
import Box, { BoxProps } from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import * as React from 'react'

type UserWidgetProps = BoxProps

export const UserWidget = ({ ...props }: UserWidgetProps): React.ReactElement => {
  return (
    <Box {...props}>
      <UserQuotaWidget />
      <Divider sx={{ marginX: 4 }} />
      <UserProfileWidget />
    </Box>
  )
}

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton, Stack, Typography } from '@mui/material'
import { isEmpty, isNil } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { useAppDispatch } from '@/store/hooks'
import { deselectItem } from '@/store/features/uiSlice/uiSlice'
import { useGetProjectDetailsQuery } from '@/store/features/apis/slices/project/projectSlice'
import { ApiVersion } from '@/store/features/apis/helpers'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useGetSupportedAnalysisTypes } from '@/containers/ai/trainingDataCart/hooks/useGetSupportedAnalysisTypes'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { AiSectionPaths } from '@/constants'

type ProjectManagementType = {
  projectId: string
  handleSuccessfulRunIncrement: (successfulRuns: number) => void
}

const ProjectManagement = ({ projectId, handleSuccessfulRunIncrement }: ProjectManagementType): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const successfulRuns = React.useRef<number>()
  const { data: supportedAnalysis, isLoading: isLoadingSupportedAnalysis } = useGetSupportedAnalysisTypes(projectId)
  const [searchParams] = useSearchParams()

  const {
    data,
    isLoading: isLoadingProject,
    isError: isErrorProject,
    error: errorProject
  } = useGetProjectDetailsQuery({ apiVersion: ApiVersion.V3, projectId }, { skip: isNil(projectId) })

  const isOnSecondStep = React.useMemo(
    () => searchParams.get('page') === AiSectionPaths.dataSettings.value,
    [searchParams]
  )

  React.useEffect(() => {
    if (isErrorProject) {
      handleApiHookErrors(errorProject)
    }
  }, [isErrorProject, errorProject])

  React.useEffect(() => {
    if (!isLoadingSupportedAnalysis && !isNil(supportedAnalysis)) {
      const numberOfSuccessfulSupportedRuns = supportedAnalysis?.reduce(
        (acc: number, data) => acc + data.numberOfSuccessfulRuns,
        0
      )
      if (numberOfSuccessfulSupportedRuns > 0) {
        handleSuccessfulRunIncrement(numberOfSuccessfulSupportedRuns)
      }
      successfulRuns.current = numberOfSuccessfulSupportedRuns
    }
  }, [isLoadingSupportedAnalysis, supportedAnalysis])

  React.useEffect(() => {
    return () => {
      if (!isNil(successfulRuns.current) && successfulRuns.current > 0) {
        handleSuccessfulRunIncrement(-successfulRuns.current)
      }
    }
  }, [])

  const handleProjectRemove = React.useCallback(() => {
    dispatch(deselectItem({ id: projectId, type: 'project' }))
  }, [projectId])

  if (isLoadingProject || isLoadingSupportedAnalysis) {
    return <Skeleton variant="rounded" width="100%" height={30} animation="wave" sx={{ flexShrink: 0 }} />
  }

  return (
    <Stack direction="row">
      <Stack direction="column" marginRight="auto" sx={{ width: '242px' }}>
        <Typography variant="text" component="p">
          {data?.projectName}
        </Typography>
        <Typography variant="subtitle" textTransform="uppercase" color="grey.600">
          {successfulRuns.current} {t('projectsCart.runs')}
        </Typography>
      </Stack>
      {!isOnSecondStep && (
        <SpriteIcon
          spriteId="times"
          height=".8125rem"
          width=".8125rem"
          style={{ cursor: 'pointer', paddingLeft: 8 }}
          onClick={handleProjectRemove}
        />
      )}
    </Stack>
  )
}

type ProjectsManagementType = {
  selectedProjects: string[]
  handleSuccessfulRunIncrement: (successfulRuns: number) => void
}

export const TrainingDataProjectsManagement = ({
  selectedProjects,
  handleSuccessfulRunIncrement
}: ProjectsManagementType): React.ReactElement => {
  const { t } = useTranslation('aiTraining')

  if (isEmpty(selectedProjects)) {
    return (
      <Stack alignItems="center" paddingY={8}>
        <Typography variant="title" color="grey.400" sx={{ fontStyle: 'italic' }}>
          {t('projectsCart.addTrainingData')}
        </Typography>
        <Typography variant="subtitle" color="grey.400">
          {t('projectsCart.noDataSelected')}
        </Typography>
      </Stack>
    )
  }

  return (
    <React.Fragment>
      {selectedProjects.map((projectId) => (
        <ProjectManagement
          key={projectId}
          projectId={projectId}
          handleSuccessfulRunIncrement={handleSuccessfulRunIncrement}
        />
      ))}
    </React.Fragment>
  )
}

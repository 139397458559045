import * as React from 'react'
import { FixedSizeList } from 'react-window'
import Box from '@mui/material/Box'
import { isNil } from 'lodash'

type FixedSizeVirtualizedListType<T> = {
  items: T[]
  renderComponent: (props: { item: T; style?: React.CSSProperties }) => React.ReactElement
  itemHeight?: number
  overscanCount?: number
  additionalHeightOffset?: number
}

export const FixedSizeVirtualizedList = <T,>({
  items,
  renderComponent,
  itemHeight = 48,
  overscanCount = 5,
  additionalHeightOffset = 0
}: FixedSizeVirtualizedListType<T>): React.ReactElement => {
  const listRef = React.useRef<HTMLDivElement>(null)
  const [fixedSizeListHeight, setFixedSizeListHeight] = React.useState<number | undefined>()

  React.useLayoutEffect(() => {
    if (listRef.current) {
      const windowHeight = document.documentElement.clientHeight
      const itemsHeight = itemHeight * items.length
      const availableSpace = windowHeight / 3 - additionalHeightOffset
      setFixedSizeListHeight(itemsHeight < availableSpace ? itemsHeight : availableSpace)
    }
  }, [itemHeight, additionalHeightOffset, items.length])

  return (
    <Box ref={listRef}>
      {!isNil(fixedSizeListHeight) && (
        <FixedSizeList
          height={fixedSizeListHeight}
          width="100%"
          itemSize={itemHeight}
          itemCount={items.length}
          overscanCount={overscanCount}
          itemData={items}
        >
          {({ index, style, data }): React.ReactElement => renderComponent({ item: data[index], style })}
        </FixedSizeList>
      )}
    </Box>
  )
}

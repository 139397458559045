import { SpaceCardData } from './interfaces'
import { useIsMobile } from '../../hooks/useIsMobile'
import { getHumanReadableDate } from '../../utils/helpers'
import { RowDate } from '../rows/RowDate'
import { Row } from '../rows/Row'
import { pathToRoute } from '../../helpers/router'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'

export const SpaceRow = ({ selected, onClick, ...spaceProps }: SpaceCardData): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const isMobile = useIsMobile()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const isDoubleClick = event.detail === 2

    if (isDoubleClick || isMobile) {
      navigate(`/${pathToRoute([spaceProps.spaceId])}`)
    }

    if (onClick) {
      onClick(event, spaceProps.spaceId)
    }
  }

  return (
    <Row title={spaceProps.name} selected={selected} onClick={handleClick} useTeamIcon>
      <RowDate data-testid="space-date">{getHumanReadableDate(spaceProps.lastModifiedAt)}</RowDate>
    </Row>
  )
}

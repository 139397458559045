import { ActionButton } from '../../components/button/ActionButton'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { OperationId } from '../../operations/interfaces'
import { useProjectOperation } from '../../operations/project/operations'
import { useProjectById } from '../../components/modal/templates/moveProject/hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, isNil } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const ShareButton = (): React.ReactElement => {
  const { t } = useTranslation(['tooltips'])
  const { getFirstSelectedProject } = useMultiSelect()
  const localProjectIdExt = getFirstSelectedProject()
  const { project, isLoading } = useProjectById(localProjectIdExt)

  const [shareProject, canShareProject] = useProjectOperation(OperationId.SHARE, { projectId: localProjectIdExt })

  const isShareButtonHidden = isLoading || isEmpty(project?.projectName) || !canShareProject

  // TODO: Not showing button if no project is selected, in the future we should also implement the share button for folders
  if (isNil(localProjectIdExt) || isShareButtonHidden) {
    return null
  }

  return (
    <ActionButton
      onClick={(): void => {
        shareProject()
      }}
      title={t('shareProject', { ns: 'tooltips' })}
    >
      <SpriteIcon spriteId="share-nodes" width={14} height={14} color="grey.550" />
    </ActionButton>
  )
}

import { useAppDispatch } from '../../../store/hooks'
import { setSearchSpaceString } from '../../../store/features/uiSlice/uiSlice'
import { useGeneralBlockingError } from '../../../hooks/useGeneralBlockingError'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import * as React from 'react'
import { styled } from '@mui/system'
import { InputAdornment, TextField, debounce } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { first } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

const TextFieldStyled = styled(TextField)({
  '& .MuiInput-root': {
    minWidth: '200px'
  }
})

export const SearchSpaces = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('toolbar')
  const { currentPath } = useCurrentPath()
  const spaceId = first(currentPath)

  const [queryString, setQueryString] = React.useState<string>('')

  React.useEffect(() => {
    setQueryString('')
    dispatch(setSearchSpaceString(''))
  }, [spaceId])

  const debouncedRequest = debounce((value) => {
    dispatch(setSearchSpaceString(value))
  }, 500)

  const handleOnChange = (e): void => {
    setQueryString(e.target.value)
    debouncedRequest(e.target.value)
  }

  const handleOnClick = (): void => {
    dispatch(setSearchSpaceString(queryString))
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      dispatch(setSearchSpaceString(queryString))
    }
  }

  const isBlockingError = useGeneralBlockingError()

  return (
    <TextFieldStyled
      placeholder={t('filterSpacesPlaceholder')}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ p: 1 }}>
            <SpriteIcon spriteId="magnifying" width=".8125rem" onClick={handleOnClick} style={{ cursor: 'pointer' }} />
          </InputAdornment>
        )
      }}
      value={queryString}
      variant="standard"
      onKeyDown={handleOnKeyDown}
      onChange={handleOnChange}
      qa-attribute="filter-spaces"
      disabled={isBlockingError}
    />
  )
}

import { useGetManageInvitationsTableBodyData, useGetManageInvitationsTableHeaderData } from './invitationsHooks'
import { EnhancedTable } from '../../../components/table/enhancedTable/EnhancedTable'
import { CounterCard } from '../../../components/counterCard/CounterCard'
import { useIsManageInvitationsEnabled, useRedirectIfPermissionDenied } from '../../../permissions/areas'
import { SearchUsers } from '../manageUsers/SearchUsers'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'

export const ManageInvitationsDisplay = (): React.ReactElement => {
  const { t } = useTranslation()

  const isManageInvitationsEnabled = useIsManageInvitationsEnabled()
  useRedirectIfPermissionDenied([isManageInvitationsEnabled])

  const tableHeaderData = useGetManageInvitationsTableHeaderData()

  const {
    total: totalInvitations,
    tableData: tableBodyData,
    isLoading: isTableBodyLoading
  } = useGetManageInvitationsTableBodyData()

  if (isTableBodyLoading) {
    return null
  }

  return (
    <Stack direction="column" gap={5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" height={48}>
        <CounterCard
          currentAmount={totalInvitations}
          description={t('manageInvitations.cards.invitations', { ns: 'adminArea' })}
        />
        <SearchUsers />
      </Stack>
      <EnhancedTable
        isLoading={isTableBodyLoading}
        tableHeaderData={tableHeaderData}
        tableBodyData={tableBodyData}
        defaultSortByColumn={3}
        defaultOrder="desc"
      />
    </Stack>
  )
}

import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { ProfileDisplay } from '../../containers/dashboard/personalArea/profile/ProfileDisplay'
import { ProfilePages } from '../../hooks/useIsProfileSectionPage'
import * as React from 'react'

const SecurityPage = (): React.ReactElement => {
  return (
    <MainDisplay>
      <ProfileDisplay activeTab={ProfilePages.SECURITY} />
    </MainDisplay>
  )
}

export default SecurityPage

import { ToggleWithDescription } from '../../../../../components/toggle/ToggleWithDescription'
import { useErrorHandler } from '../../../../../hooks/useErrorHandler'
import { useGetOrganizationId } from '../../../../../store/features/apis/slices/organization/hooks'
import {
  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation
} from '../../../../../store/features/apis/slices/organization/organizationSlice'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Stack, Typography } from '@mui/material'
import { addSnackbarToState } from '@/store/features/uiSlice/uiSlice'
import { useAppDispatch } from '@/store/hooks'

export const AccessPreferenceSettingToggles = (): React.ReactElement => {
  const { handleApiHookErrors } = useErrorHandler()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['adminArea'])
  const organizationId = useGetOrganizationId()
  const {
    data: settings,
    isLoading: isLoadingGetOrganizationSettings,
    isFetching: isFetchingGetOrganizationSettings,
    isError: isErrorGetOrganizationSettings,
    error: errorGetOrganizationSettings
  } = useGetOrganizationSettingsQuery({ organizationId }, { skip: isNil(organizationId) })

  const [
    updateOrganizationSettings,
    {
      isLoading: isLoadingUpdateOrganizationSettings,
      isError: isErrorUpdateOrganizationSettings,
      error: errorUpdateOrganizationSettings
    }
  ] = useUpdateOrganizationSettingsMutation()

  const isLoadingOrFetching =
    isLoadingGetOrganizationSettings || isFetchingGetOrganizationSettings || isLoadingUpdateOrganizationSettings

  React.useEffect(() => {
    if (isErrorGetOrganizationSettings) {
      handleApiHookErrors(errorGetOrganizationSettings)
    }
  }, [isErrorGetOrganizationSettings])

  React.useEffect(() => {
    if (isErrorUpdateOrganizationSettings) {
      handleApiHookErrors(errorUpdateOrganizationSettings)
    }
  }, [isErrorUpdateOrganizationSettings])

  const sendMessageOnToggleChange = (checked: boolean): void => {
    dispatch(
      addSnackbarToState({
        severity: 'info',
        message: checked
          ? t('organizationSettings.enablePasswordLoginToggleMessage')
          : t('organizationSettings.disablePasswordLoginToggleMessage')
      })
    )
  }

  const onChange = React.useCallback(
    (checked: boolean): void => {
      updateOrganizationSettings({ organizationId, payload: { ...settings, enablePasswordLogin: checked } })
      sendMessageOnToggleChange(checked)
    },
    [settings]
  )

  if (isNil(settings)) {
    return null
  }

  return (
    <ToggleWithDescription
      checked={settings.enablePasswordLogin}
      onChange={onChange}
      disabled={isLoadingOrFetching}
      qa-attribute="enable-password-login--switch"
    >
      <Stack direction="column" gap={1}>
        <Typography variant="text">{t('organizationSettings.allowPasswordLoginForUsers')}</Typography>
        <Typography variant="text" color="grey.700">
          {t('organizationSettings.usersWillLogInUsingTheirPasswords')}
        </Typography>
      </Stack>
    </ToggleWithDescription>
  )
}

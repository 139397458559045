import { PathSelector } from '../spaces/interfaces'
import { pathToRoute } from '../../helpers/router'
import { BreadcrumbsItem } from '../../components/breadcrumb/BreadcrumbsItem'
import { useSpaceById } from '../../store/features/apis/slices/space/hooks'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import * as React from 'react'

interface FolderBreadcrumbItemProps {
  spaceId: string
  isLastElement?: boolean
}

export const SpaceBreadcrumbItem = ({ spaceId, isLastElement }: FolderBreadcrumbItemProps): React.ReactElement => {
  const { navigate } = useDashboardNavigate()

  const { space, isLoading } = useSpaceById(spaceId)

  const onElementSelect = React.useCallback((path: PathSelector) => {
    navigate(`/${pathToRoute(path)}`)
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <BreadcrumbsItem
      isLastElement={isLastElement}
      path={[spaceId]}
      label={space?.name}
      onElementSelect={onElementSelect}
    />
  )
}

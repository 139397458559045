import * as React from 'react'

export const useBlockPageLeave = (): {
  blockPageLeave: () => void
  unblockPageLeave: () => void
} => {
  const [isPageLeaveBlocked, setIsPageLeaveBlocked] = React.useState(false)

  const blockPageLeave = React.useCallback(() => {
    setIsPageLeaveBlocked(true)
  }, [])

  const unblockPageLeave = React.useCallback(() => {
    setIsPageLeaveBlocked(false)
  }, [])

  // block page and show a warning message when the user tries to leave the page
  React.useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      if (isPageLeaveBlocked) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isPageLeaveBlocked])

  return { blockPageLeave, unblockPageLeave }
}

import { Modal } from './Modal'
import { FolderModalTemplate } from './templates/FolderModalTemplate'
import { ProjectModalTemplate } from './templates/ProjectModalTemplate'
import { CopyProjectModalTemplate } from './templates/CopyProjectModalTemplate'
import { ShareModalTemplate } from './templates/ShareModalTemplate'
import { ShareWithUsersModalTemplate } from './templates/ShareWithUsersModalTemplate'
import { TrashProjectModalTemplate } from './templates/TrashProjectModalTemplate'
import { TrashFolderModalTemplate } from './templates/TrashFolderModalTemplate'
import { TrashSpaceModalTemplate } from './templates/TrashSpaceModalTemplate'
import { MoveItemModalTemplate } from './templates/moveProject/MoveModal'
import { TeamSpaceModal } from './templates/teamSpace/TeamSpaceModal'
import { RemoveUserModalTemplate } from './templates/manageUsers/RemoveUser'
import { AssignOrganizationAdmin } from './templates/manageUsers/AssignOrganizationAdmin'
import { UnassignOrganizationAdmin } from './templates/manageUsers/UnassignOrganizationAdmin'
import { InviteColleaguesModalTemplate } from './templates/manageUsers/InviteColleaguesModalTemplate'
import { SeatsAreFullTemplate } from './templates/manageUsers/SeatsAreFullTemplate'
import { TrashUserApiKeyTemplate } from './templates/profile/TrashUserApiKeyTemplate'
import { AssignCoreHours } from './templates/manageUsers/AssignCoreHours'
import { RejectInvitationModalTemplate } from './templates/manageUsers/RejectInvitationModalTemplate'
import { ApproveInvitationModalTemplate } from './templates/manageUsers/ApproveInvitationModalTemplate'
import { AcceptInvitationModalTemplate } from './templates/profile/AcceptInvitationModalTemplate'
import { ConfirmPublicProjectPermission } from './templates/ConfirmPublicProjectPermission'
import { GenerateKeyTemplate } from './templates/profile/GenerateKeyTemplate'
import { DomainVerificationTemplate } from './templates/domains/DomainVerificationTemplate'
import { DomainDeclinedTemplate } from './templates/domains/DomainDeclinedTemplate'
import { WelcomeTemplate } from './templates/organization/WelcomeTemplate'
import { ConfirmationModalTemplate } from './templates/ConfirmationModalTemplate'
import { CreateAiModel } from './templates/ai/CreateAiModel'
import { DeleteModelVersionTemplate } from './templates/ai/models/versions/DeleteModelVersionTemplate'
import { ReleaseHistoryTemplate } from './templates/ai/models/versions/ReleaseHistoryTemplate'
import { ReleaseModelVersionTemplate } from './templates/ai/models/versions/ReleaseModelVersionTemplate'
import { UnreleaseModelVersionTemplate } from './templates/ai/models/versions/UnreleaseModelVersionTemplate'
import { ModelTrainingRunLogsTemplate } from './templates/ai/models/versions/ModelTrainingRunLogsTemplate'
import { ModalQueueItem, ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { closeModal, getModalsQueue, setCheckboxListItems } from '../../store/features/uiSlice/uiSlice'
import { useDashboardNavigate } from '../../hooks/useDashboardNavigate'
import { filterSearchParams } from '../../helpers/navigation'
import * as React from 'react'
import { isEmpty, isNil, last } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { SelectAiTrainingAnalysisType } from '@/components/modal/templates/ai/SelectAiTrainingAnalysisType/SelectAiTrainingAnalysisType'

type ActiveModalProps = {
  modal: ModalQueueItem
}

const modalsWithPath = [
  ModalTemplate.EDIT_PROJECT,
  ModalTemplate.COPY_PROJECT,
  ModalTemplate.SHARE,
  ModalTemplate.TRASH_PROJECT
]

const ActiveModal = ({ modal }: ActiveModalProps): React.ReactElement => {
  const { navigate, navigateNoReset } = useDashboardNavigate()
  const [searchParams] = useSearchParams()
  const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
  const dispatch = useAppDispatch()

  if (modal.template === ModalTemplate.CREATE_FOLDER) {
    return (
      <FolderModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        targetPath={modal.modalProps.targetPath}
        onFolderCreated={modal.modalProps.onFolderCreated}
      />
    )
  }

  if (modal.template === ModalTemplate.EDIT_FOLDER) {
    return (
      <FolderModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        folderId={modal.modalProps.folderId}
        targetPath={modal.modalProps.targetPath}
      />
    )
  }

  if (modal.template === ModalTemplate.MOVE_ITEM) {
    return (
      <MoveItemModalTemplate
        onClose={(redirectUrl: string): void => {
          dispatch(closeModal())
          if (!isNil(redirectUrl) && typeof redirectUrl === 'string') {
            navigateNoReset(`/${redirectUrl}`)
          }
        }}
        showCloseButton={modal.showCloseButton}
        {...(modal.modalProps as any)}
      />
    )
  }

  if (modal.template === ModalTemplate.CREATE_PROJECT) {
    return (
      <ProjectModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        title={modal.modalProps.title}
        targetFolderPath={modal.modalProps.targetFolderPath}
      />
    )
  }

  if (modal.template === ModalTemplate.EDIT_PROJECT) {
    return (
      <ProjectModalTemplate
        onClose={(): void => {
          navigateNoReset(`/projects/${modal.modalProps.projectIdExt}${filteredParams}`)
        }}
        onCancel={(): void => {
          navigateNoReset(-1)
        }}
        title={modal.modalProps.title}
        projectIdExt={modal.modalProps.projectIdExt}
      />
    )
  }

  if (modal.template === ModalTemplate.COPY_PROJECT) {
    return (
      <CopyProjectModalTemplate
        onClose={(redirectUrl: string): void => {
          if (!isNil(redirectUrl) && typeof redirectUrl === 'string') {
            navigateNoReset(`/${redirectUrl}${filteredParams}`)
          } else {
            navigateNoReset(`/projects/${modal.modalProps.selectedProjectIdExt}${filteredParams}`)
          }
        }}
        onCancel={(): void => {
          navigateNoReset(-1)
        }}
        title={modal.modalProps.title}
        selectedProjectIdExt={modal.modalProps?.selectedProjectIdExt}
        selectedProjectName={modal.modalProps?.selectedProjectName}
      />
    )
  }

  if (modal.template === ModalTemplate.SHARE) {
    return (
      <ShareModalTemplate
        onClose={(): void => {
          navigateNoReset(`/projects/${modal.modalProps.projectIdExt}${filteredParams}`)
        }}
        onCancel={(): void => {
          navigateNoReset(-1)
        }}
        ownerName={modal.modalProps.ownerName}
        publicProjectName={modal.modalProps.publicProjectName}
        projectName={modal.modalProps.projectName}
        requesterPermissions={modal.modalProps.requesterPermissions}
        publicPermission={modal.modalProps.publicPermission}
        projectIdExt={modal.modalProps.projectIdExt}
      />
    )
  }

  if (modal.template === ModalTemplate.SHARE_WITH_USERS) {
    return (
      <ShareWithUsersModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        {...(modal.modalProps as any)}
      />
    )
  }

  if (modal.template === ModalTemplate.TRASH_PROJECT) {
    return (
      <TrashProjectModalTemplate
        onClose={(successfullyDeleted?: boolean): void => {
          if (!successfullyDeleted) {
            navigateNoReset(`/projects/${modal.modalProps.projectIdExt}${filteredParams}`)
          } else {
            navigate(-1)
          }
        }}
        onCancel={(): void => {
          navigate(-1)
        }}
        title={modal.modalProps.title}
        projectName={modal.modalProps.projectName}
        publicProjectName={modal.modalProps.publicProjectName}
        projectOwnerName={modal.modalProps.projectOwnerName}
      />
    )
  }

  if (modal.template === ModalTemplate.TRASH_FOLDER) {
    return (
      <TrashFolderModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        folderId={modal.modalProps.folderId}
      />
    )
  }

  if (modal.template === ModalTemplate.TRASH_SPACE) {
    return (
      <TrashSpaceModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedTeamSpace={modal.modalProps.selectedTeamSpace}
      />
    )
  }

  if (modal.template === ModalTemplate.SPACE_MEMBERS) {
    return (
      <TeamSpaceModal
        onClose={(): void => {
          dispatch(setCheckboxListItems(undefined))
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedTeamSpace={modal.modalProps.selectedTeamSpace}
      />
    )
  }

  if (modal.template === ModalTemplate.REMOVE_ORGANIZATION_USER) {
    return (
      <RemoveUserModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedUser={modal.modalProps.selectedUser}
      />
    )
  }

  if (modal.template === ModalTemplate.INVITE_COLLEAGUES) {
    return (
      <InviteColleaguesModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
      />
    )
  }

  if (modal.template === ModalTemplate.ASSIGN_ORGANIZATION_ADMIN) {
    return (
      <AssignOrganizationAdmin
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedUser={modal.modalProps.selectedUser}
      />
    )
  }

  if (modal.template === ModalTemplate.UNASSIGN_ORGANIZATION_ADMIN) {
    return (
      <UnassignOrganizationAdmin
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedUser={modal.modalProps.selectedUser}
      />
    )
  }

  if (modal.template === ModalTemplate.SEATS_ARE_FULL) {
    return (
      <SeatsAreFullTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
      />
    )
  }

  if (modal.template === ModalTemplate.TRASH_USER_API_KEY) {
    return (
      <TrashUserApiKeyTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedApiKeySuffix={modal.modalProps.selectedApiKeySuffix}
      />
    )
  }

  if (modal.template === ModalTemplate.ASSIGN_CORE_HOURS) {
    return (
      <AssignCoreHours
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        selectedUser={modal.modalProps.selectedUser}
      />
    )
  }

  if (modal.template === ModalTemplate.APPROVE_INVITATION) {
    return (
      <ApproveInvitationModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        inviteId={modal.modalProps.inviteId}
      />
    )
  }

  if (modal.template === ModalTemplate.ACCEPT_INVITATION) {
    return (
      <AcceptInvitationModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        inviteId={modal.modalProps.inviteId}
        organizationName={modal.modalProps.organizationName}
      />
    )
  }

  if (modal.template === ModalTemplate.REJECT_INVITATION) {
    return (
      <RejectInvitationModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        inviteId={modal.modalProps.inviteId}
      />
    )
  }

  if (modal.template === ModalTemplate.CONFIRM_PUBLIC_PROJECT_PERMISSION) {
    return (
      <ConfirmPublicProjectPermission
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        onConfirm={modal.modalProps.onConfirm}
      />
    )
  }

  if (modal.template === ModalTemplate.GENERATE_KEY) {
    return (
      <GenerateKeyTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
      />
    )
  }

  if (modal.template === ModalTemplate.DOMAIN_VERIFICATION) {
    return (
      <DomainVerificationTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        domain={modal.modalProps.domain}
      />
    )
  }

  if (modal.template === ModalTemplate.DOMAIN_DECLINED) {
    return (
      <DomainDeclinedTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        domain={modal.modalProps.domain}
      />
    )
  }

  if (modal.template === ModalTemplate.ORGANIZATION_WELCOME) {
    return (
      <WelcomeTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
      />
    )
  }

  if (modal.template === ModalTemplate.CONFIRMATION) {
    return (
      <ConfirmationModalTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        description={modal.modalProps?.description}
        onConfirm={modal.modalProps.onConfirm}
      />
    )
  }

  if (modal.template === ModalTemplate.CREATE_AI_MODEL) {
    return (
      <CreateAiModel
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
      />
    )
  }

  if (modal.template === ModalTemplate.SELECT_AI_TRAINING_ANALYSIS_TYPE) {
    return (
      <SelectAiTrainingAnalysisType
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
      />
    )
  }

  if (modal.template === ModalTemplate.DELETE_MODEL_VERSION) {
    return (
      <DeleteModelVersionTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        version={modal.modalProps.version}
      />
    )
  }

  if (modal.template === ModalTemplate.RELEASE_MODEL_VERSION) {
    return (
      <ReleaseModelVersionTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        version={modal.modalProps.version}
      />
    )
  }

  if (modal.template === ModalTemplate.UNRELEASE_MODEL_VERSION) {
    return (
      <UnreleaseModelVersionTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        version={modal.modalProps.version}
      />
    )
  }

  if (modal.template === ModalTemplate.RELEASE_HISTORY) {
    return (
      <ReleaseHistoryTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        modelId={modal.modalProps.modelId}
      />
    )
  }

  if (modal.template === ModalTemplate.MODEL_VERSION_LOGS) {
    return (
      <ModelTrainingRunLogsTemplate
        onClose={(): void => {
          dispatch(closeModal())
        }}
        showCloseButton={modal.showCloseButton}
        title={modal.modalProps.title}
        modelUuid={modal.modalProps.modelUuid}
        trainingRunId={modal.modalProps.trainingRunId}
      />
    )
  }

  return null
}

export const GlobalModal = (): React.ReactElement => {
  const { navigateNoReset } = useDashboardNavigate()
  const dispatch = useAppDispatch()
  const modalsQueue = useAppSelector(getModalsQueue)
  const [searchParams] = useSearchParams()

  if (isEmpty(modalsQueue)) {
    return null
  }

  const lastModal = last(modalsQueue)

  return (
    <>
      {modalsQueue.map((modal) => {
        const modalId = `${modal.template}-${modal.modalProps?.title}`
        const lastModalId = `${lastModal.template}-${lastModal.modalProps?.title}`
        return (
          <Modal
            key={modalId}
            open
            onClose={(): void => {
              dispatch(closeModal())
              const projectIdExt = modal.modalProps?.projectIdExt ?? modal.modalProps?.selectedProjectIdExt
              if (modalsWithPath.includes(modal.template) && !isNil(projectIdExt)) {
                const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
                navigateNoReset(`/projects/${projectIdExt}${filteredParams}`)
              }
            }}
            isLastInQueue={modalId === lastModalId}
            size={modal.modalProps?.size || 'md'}
          >
            <ActiveModal modal={modal} />
          </Modal>
        )
      })}
    </>
  )
}

import { ActionButtonStyled } from '../button/ActionButton'
import { getQaSelectorText } from '../../helpers/qaSelectors'
import { Menu, MenuItem } from '@mui/material'
import * as React from 'react'

interface MenuItemProps {
  label: string
  onClick: () => void
}

export const ContextMenuWrapper = ({
  children,
  menuItems,
  disabled = false,
  ...props
}: {
  children: React.ReactNode
  menuItems: MenuItemProps[]
  disabled?: boolean
}): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <ActionButtonStyled onClick={handleClick} disabled={disabled} {...props}>
        {children}
      </ActionButtonStyled>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map(
          (item): React.ReactElement => (
            <MenuItem
              qa-attribute={`${getQaSelectorText(item.label)}--context-menu-item`}
              key={item.label}
              onClick={(): void => {
                item.onClick()
                handleClose()
              }}
            >
              {item.label}
            </MenuItem>
          )
        )}
      </Menu>
    </>
  )
}

import { InviteMembers } from './InviteMembers'
import { ManageMembers } from './ManageMembers'
import { useAppDispatch } from '../../../../store/hooks'
import { setCheckboxListItems } from '../../../../store/features/uiSlice/uiSlice'
import { useGetTeamMemberListData } from '../../../../containers/list/hooks'
import * as React from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { isNil } from 'lodash'

const MembersManagement = ({ selectedTeamSpace, setMembersSectionHasValidationErrors }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { userList, isLoading } = useGetTeamMemberListData(selectedTeamSpace)

  React.useEffect(() => {
    if (!isLoading) {
      const updatedMembers = userList.map((member) => {
        if (!isNil(member.permission)) {
          return { ...member, isMember: true }
        }
        return member
      })
      dispatch(setCheckboxListItems(updatedMembers))
    }
  }, [userList, isLoading])

  if (isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress size="28px" />
      </Stack>
    )
  }

  return (
    <React.Fragment>
      <InviteMembers setHasValidationErrors={setMembersSectionHasValidationErrors} />
      <ManageMembers />
    </React.Fragment>
  )
}

export default React.memo(MembersManagement)

import { ActionButton } from '../../components/button/ActionButton'
import { getShowRightPanel, setShowRightPanel } from '../../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const ToggleInfoPanelButton = (): React.ReactElement => {
  const { t } = useTranslation(['tooltips'])
  const dispatch = useAppDispatch()
  const showRightPanel = useAppSelector(getShowRightPanel)

  const toggleInfoPanel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.stopPropagation()
    dispatch(setShowRightPanel(!showRightPanel))
  }

  return (
    <ActionButton
      selected={showRightPanel}
      onClick={toggleInfoPanel}
      title={t('toggleInfoPanel')}
      tabIndex={TAB_INDEXES.INFO_PANEL_BUTTON}
    >
      <SpriteIcon color="grey.500" spriteId="circle-info" width={16} height={16} />
    </ActionButton>
  )
}

import { isInsideCypress } from './cookies'
import { getBaseUrl } from './network'

export const openProjectInWorkbench = (projectIdExt: string): void => {
  const projectUrl = `/workbench/?pid=${projectIdExt}`
  window.open(projectUrl, isInsideCypress() ? '_self' : '_blank')
}

export const getUrl = (path: string): string => {
  if (path.startsWith('/')) {
    path = path.slice(1)
  }
  return `${getBaseUrl()}/${path}`
}

export const filterSearchParams = (searchParams: URLSearchParams, filter: string[]): string => {
  const filteredSearchParams = new URLSearchParams()
  for (const key of filter) {
    const value = searchParams.get(key)
    if (value) {
      filteredSearchParams.set(key, value)
    }
  }
  const search = filteredSearchParams.toString()
  return search ? `?${search}` : ''
}

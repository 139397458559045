import { Invite, InviteResponse, InvitesResponse } from './interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { apiTags } from '../../apiTags'

const invitationSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvites: builder.query<Invite[], { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/invites`,
      transformResponse: (response: InvitesResponse) => response.invites,
      providesTags: [apiTags.INVITATIONS]
    }),
    postInvite: builder.mutation<InviteResponse, { organizationId: string; emails: string[] }>({
      query: ({ organizationId, emails }) => ({
        url: `api/v1/invites`,
        method: 'POST',
        body: { organizationId, emails }
      }),
      invalidatesTags: [apiTags.INVITATIONS]
    }),
    approveInvitation: builder.mutation<void, { inviteId: string }>({
      query: ({ inviteId }) => ({
        url: `api/v1/invites/${inviteId}/approve`,
        method: 'PUT'
      }),
      invalidatesTags: [apiTags.INVITATIONS]
    }),
    rejectInvitation: builder.mutation<void, { inviteId: string }>({
      query: ({ inviteId }) => ({
        url: `api/v1/invites/${inviteId}/reject`,
        method: 'PUT'
      }),
      invalidatesTags: [apiTags.INVITATIONS]
    }),
    acceptInvitation: builder.mutation<void, { inviteId: string }>({
      query: ({ inviteId }) => ({
        url: `api/v1/invites/${inviteId}/accept`,
        method: 'PUT'
      }),
      invalidatesTags: [apiTags.INVITATIONS]
    })
  })
})

export const {
  useGetInvitesQuery,
  usePostInviteMutation,
  useApproveInvitationMutation,
  useRejectInvitationMutation,
  useAcceptInvitationMutation
} = invitationSlice

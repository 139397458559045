import { useAppDispatch, useAppSelector } from './../../store/hooks'
import { OperationWithPermission } from '../interfaces'
import { MoveItem } from '../../components/modal/templates/moveProject/interfaces'
import { ModalTemplate } from '../../store/features/uiSlice/interfaces'
import { getCurrentSelectedProjectId, openModal } from '../../store/features/uiSlice/uiSlice'
import { useSpaceById } from '../../store/features/apis/slices/space/hooks'
import { useGetFolderDetails } from '../../store/features/apis/slices/folder/helpers'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { useGetProjectV3Details } from '../../store/features/apis/slices/project/helpers'
import { t } from 'i18next'
import { first, isNil, last } from 'lodash'

export const useMoveOperation = (): OperationWithPermission<MoveItem[]> => {
  const dispatch = useAppDispatch()
  const { currentPath } = useCurrentPath()
  const spaceId = first(currentPath)
  const folderLocationId = last(currentPath)
  const isSpace = spaceId === folderLocationId
  const isSearchMode = useIsSearchMode()
  const selectedProjectId = useAppSelector(getCurrentSelectedProjectId)

  const { space } = useSpaceById(first(currentPath), { skip: !isSpace })

  const { data: folder } = useGetFolderDetails({
    spaceId,
    folderId: folderLocationId
  })

  const { data: project } = useGetProjectV3Details({ projectId: selectedProjectId }, { skip: isNil(selectedProjectId) })
  const { space: projectSpace } = useSpaceById(project?.spaceId, { skip: isNil(project?.spaceId) })

  const onClick = (items: MoveItem[]): void => {
    dispatch(
      openModal({
        template: ModalTemplate.MOVE_ITEM,
        showCloseButton: true,
        modalProps: {
          items,
          title: t('move.title', { ns: 'modals' })
        }
      })
    )
  }

  // project move permission
  if (!isNil(project)) {
    return [
      onClick,
      project.requesterPermissions?.canMoveProjectToPersonalSpace ||
        projectSpace?.requesterPermissions?.canMoveContentOutOfRoot ||
        folder?.requesterPermissions?.canMoveContentOutOfFolder
    ]
  }

  // space or folder move permission
  const hasRequesterPermission = isSpace
    ? space?.requesterPermissions?.canMoveContentOutOfRoot
    : folder?.requesterPermissions?.canMoveContentOutOfFolder

  return [onClick, hasRequesterPermission || isSearchMode]
}

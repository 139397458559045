import {
  EXISTENT_USER_SHARE_PERMISSIONS,
  NEW_USER_SHARE_PERMISSIONS
} from '../components/modal/templates/share/helpers'
import { SharePermission } from '../components/modal/templates/share/interfaces'

type AvailableShareProjectPermissions = {
  availableSharePermissions: SharePermission[]
}

type AvailableShareProjectPermissionsProps = {
  includeRemoveAccess?: boolean
}

export const useAvailableShareProjectPermissions = (
  props?: AvailableShareProjectPermissionsProps
): AvailableShareProjectPermissions => {
  const { includeRemoveAccess } = props || {}

  return {
    availableSharePermissions: includeRemoveAccess ? EXISTENT_USER_SHARE_PERMISSIONS : NEW_USER_SHARE_PERMISSIONS
  }
}

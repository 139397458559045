import { getUsername } from '../helpers/cookies'
import { useGetUserQuery } from '../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'

type UserNames = {
  username: string
  userShortName: string
  userFullName: string
  isLoading: boolean
}

export const useUserNames = (): UserNames => {
  const username = getUsername()
  const [userShortName, setUserShortName] = React.useState<string>()
  const [userFullName, setUserFullName] = React.useState<string>()
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery({ username }, { skip: isEmpty(username) })

  React.useEffect(() => {
    if (!isNil(user) && !isLoadingUser) {
      const shortName = user.firstname
      const fullName = `${user.firstname} ${user.lastname}`

      setUserShortName(shortName)
      setUserFullName(fullName)
    }
  }, [user, isLoadingUser])

  return {
    username,
    userShortName,
    userFullName,
    isLoading: isLoadingUser
  }
}

import { MainDisplay } from '../containers/dashboard/MainDisplay'
import { SpacesView } from '../containers/dashboard/mainDisplay/desktopViews/SpacesView'
import { RightSidebar } from '../containers/dashboard/rightSidebar/RightSidebar'
import GenericDetailsPanel from '../containers/detailsPanel/GenericDetailsPanel'
import * as React from 'react'

const SpacesPage = (): React.ReactElement => {
  return (
    <>
      <MainDisplay isOperationToolbarEnabled>
        <SpacesView />
      </MainDisplay>
      <RightSidebar>
        <GenericDetailsPanel />
      </RightSidebar>
    </>
  )
}

export default SpacesPage

import { ProjectAction, useActionModalHandler } from '../hooks/useActionModalHandler'
import { closeAllActionModals } from '../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../store/hooks'
import { useGetProjectV3Details } from '../store/features/apis/slices/project/helpers'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import * as React from 'react'

export const useRoutedModals = (): void => {
  const dispatch = useAppDispatch()
  let { projectId, action } = useParams<{ projectId: string; action?: ProjectAction }>()
  const { showActionModal } = useActionModalHandler()
  const { data: project } = useGetProjectV3Details({ projectId })

  React.useEffect(() => {
    if (!isNil(action) && !isNil(project)) {
      showActionModal(action, project)
    } else if (isNil(action)) {
      dispatch(closeAllActionModals())
    }
  }, [project?.projectIdExt, action, showActionModal])
}

import * as React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/system'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'

const iconTemp = require('../../../assets/svg/geo-cad-icon.svg') // TODO: replace with real icon

const PreviewWrappper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden'
}))

const PreviewNotFoundIcon = (): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])

  return (
    <Box
      border={2}
      borderRadius={5}
      borderColor="border.disabled"
      width={106}
      height={94}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <img src={iconTemp} alt={t('modelDetails.preview.notFound')} />
    </Box>
  )
}

const PreviewNotFoundPlaceholder = (): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])

  return (
    <>
      <PreviewNotFoundIcon />
      <Typography variant="body1" color="text.disabled" mt={2} textAlign="center">
        {t('modelDetails.preview.notFound')}
      </Typography>
    </>
  )
}

export const ModelPreviewImageSkeleton = (props: BoxProps): React.ReactElement => {
  return (
    <PreviewWrappper {...props}>
      <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
    </PreviewWrappper>
  )
}

type ModelPreviewImageProps = BoxProps & {
  url: string
}

export const ModelPreviewImage = ({ url, ...props }: ModelPreviewImageProps): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])
  const [imageNotFound, setImageNotFound] = React.useState(true)
  const [isCheckingImageExistence, setIsCheckingImageExistence] = React.useState(true)

  React.useEffect(() => {
    const image = new Image()
    image.src = url
    image.onload = (): void => {
      setImageNotFound(false)
      setIsCheckingImageExistence(false)
    }
    image.onerror = (): void => {
      setImageNotFound(true)
      setIsCheckingImageExistence(false)
    }

    return (): void => {
      image.onload = null
      image.onerror = null
    }
  }, [url])

  if (isCheckingImageExistence) {
    return <ModelPreviewImageSkeleton width={435} height={315} {...props} />
  }

  return (
    <PreviewWrappper width={435} height={315} qa-attribute="aiModel--preview" {...props}>
      {imageNotFound && <PreviewNotFoundPlaceholder />}
      {!imageNotFound && (
        <img
          src={url}
          alt={t('modelDetails.preview.alt')}
          style={{ width: '100%', height: '100%', display: 'inline-block', objectFit: 'cover' }}
        />
      )}
    </PreviewWrappper>
  )
}

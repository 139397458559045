import * as React from 'react'

const FAVICON_BASE_URL = 'https://s2.googleusercontent.com/s2/favicons?domain_url='

export const FaviconThumbnail = ({
  url,
  size,
  alt
}: {
  url: string
  size?: string
  alt?: string
}): React.ReactElement => {
  const faviconSize = size ?? '28'
  const faviconUrl = `${FAVICON_BASE_URL}${url}&sz=${faviconSize}`
  return <img src={faviconUrl} alt={alt} width={size} height={size} style={{ display: 'inline-block' }} />
}

import { useNavigateToPage } from './hooks'
import { useFolderedProjects } from '../hooks'
import {
  deselectItem,
  getCurrentSelectedProjectId,
  setCurrentProjectPage
} from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { useIsSearchMode } from '../../../../hooks/useIsSearchMode'
import { Pagination, Stack } from '@mui/material'
import { isNil } from 'lodash'
import * as React from 'react'
import { TAB_INDEXES } from '@/constants/tabIndexes'

export const ProjectPagination = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const isSearchMode = useIsSearchMode()
  const { metadata: paginationData } = useFolderedProjects()
  const selectedProjectId = useAppSelector(getCurrentSelectedProjectId)
  useNavigateToPage()

  const onPaginationChange = React.useCallback(
    (page: number) => {
      dispatch(setCurrentProjectPage(page))
      if (!isNil(selectedProjectId)) {
        dispatch(deselectItem({ id: selectedProjectId, type: 'project' }))
      }
    },
    [selectedProjectId]
  )

  const showPagination = !isSearchMode && !isNil(paginationData) && paginationData?.totalPages > 1

  if (!showPagination) {
    return null
  }

  return (
    <Stack alignItems="center" marginBottom={5} marginTop={3}>
      <Pagination
        count={paginationData?.totalPages}
        page={paginationData?.page}
        onChange={(_e, val): void => onPaginationChange(val)}
        shape="rounded"
        tabIndex={TAB_INDEXES.PROJECT_PAGINATION}
      />
    </Stack>
  )
}

import { TooltipComponent } from '../../../tooltip/TooltipComponent'
import * as React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

export const ProjectNameTooltip = (): React.ReactElement => {
  const { t } = useTranslation('tooltips')
  return (
    <TooltipComponent
      title={t('projectTitleComponent.title')}
      body={
        <React.Fragment>
          <Typography variant="textBold">{t('projectTitleComponent.bodyParagraph1')}</Typography>
          <Typography>{t('projectTitleComponent.bodyParagraph2')}</Typography>
        </React.Fragment>
      }
    />
  )
}

export const ProjectDescriptionTooltip = (): React.ReactElement => {
  const { t } = useTranslation('tooltips')
  return (
    <TooltipComponent
      title={t('projectDescriptionComponent.title')}
      body={
        <React.Fragment>
          <Typography component="li">{t('projectDescriptionComponent.bodyParagraph1')}</Typography>
          <Typography component="li">{t('projectDescriptionComponent.bodyParagraph2')}</Typography>
          <Typography component="li">{t('projectDescriptionComponent.bodyParagraph3')}</Typography>
        </React.Fragment>
      }
    />
  )
}

import * as React from 'react'
import { isNil } from 'lodash'

export const useModalEvents = (onSubmit?: () => void, submitDisabled?: boolean): void => {
  const [targetElementFocused, setTargetElementFocused] = React.useState(false)

  const setFocusedElement = React.useCallback(() => {
    const focusedElement = document.activeElement
    if (!isNil(focusedElement)) {
      const modalFocused = focusedElement.classList.contains('MuiDialog-container')
      const inputFocused = focusedElement.classList.contains('MuiInput-input')
      setTargetElementFocused(modalFocused || inputFocused)
    }
  }, [])

  React.useEffect(() => {
    setFocusedElement()
  }, [setFocusedElement])

  React.useEffect(() => {
    window.addEventListener('focus', setFocusedElement, true)
    window.addEventListener('blur', setFocusedElement, true)

    return () => {
      window.removeEventListener('focus', setFocusedElement, true)
      window.removeEventListener('blur', setFocusedElement, true)
    }
  }, [setFocusedElement])

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Enter' && !event.shiftKey) {
        onSubmit()
      }
    },
    [onSubmit]
  )

  React.useEffect(() => {
    if (!isNil(onSubmit) && !submitDisabled && targetElementFocused) {
      window.addEventListener('keypress', handleKeyPress)

      return (): void => {
        window.removeEventListener('keypress', handleKeyPress)
      }
    }
  }, [handleKeyPress, onSubmit, submitDisabled, targetElementFocused])
}

import { useCurrentPath } from '../../hooks/useCurrentPath'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const NoProjectsWarning = (): React.ReactElement => {
  const { t } = useTranslation(['detailsPanel', 'translation'])
  const { currentPath, isLoading } = useCurrentPath()

  if (isLoading || isEmpty(currentPath)) {
    return null
  }

  return (
    <Alert icon={false} severity="info">
      <Typography variant="text" color="text.primary">
        {t('noProjectsFound', { ns: 'translation' })}
      </Typography>
    </Alert>
  )
}

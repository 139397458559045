/* eslint-disable no-console */
import { Space } from '../../../store/features/apis/slices/space/interfaces'
import { getCurrentProjectPage, getSortingModeV2, openModal } from '../../../store/features/uiSlice/uiSlice'
import { ModalTemplate } from '../../../store/features/uiSlice/interfaces'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { useGetSpaceUsersPermissionsQuery } from '../../../store/features/apis/slices/space/spaceSlice'
import { AdditionalUsers } from '../../../components/share/SharedWith'
import { PermissionType, ScopeType } from '../../../components/modal/templates/share/interfaces'
import { useGetProjectsQuery } from '../../../store/features/apis/slices/project/projectsSlice'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Skeleton, Stack, Typography } from '@mui/material'
import { isNil } from 'lodash'

const TeamRowTitle = ({ space }: { space: Space }): React.ReactElement => {
  return (
    <Typography variant="titleLight" color="text.primary" component="p">
      {space.name}
    </Typography>
  )
}

type TeamRowSubtitleType = {
  spaceId: string
  numberOfMembers: number
  isLoadingPermissions: boolean
}

const TeamRowSubtitle = ({
  spaceId,
  numberOfMembers,
  isLoadingPermissions
}: TeamRowSubtitleType): React.ReactElement => {
  const { t } = useTranslation()
  const sortingModeV2 = useAppSelector(getSortingModeV2)
  const sortingDirection = useAppSelector((state) => state.ui.sortingDirection)
  const targetPage = useAppSelector(getCurrentProjectPage)

  const {
    data: projects,
    isLoading,
    isFetching
  } = useGetProjectsQuery(
    { spaceId, folderId: spaceId, sortingModeV2, sortingDirection, targetPage },
    { skip: isNil(spaceId) }
  )

  if (isLoadingPermissions || isLoading || isFetching) {
    return <Skeleton variant="rectangular" height={20} />
  }

  return (
    <Typography variant="subtitle" color="grey.700" component="p">
      {numberOfMembers === 1 ? t('singleMember') : t('multipleMembers', { count: numberOfMembers })}
      {!isNil(projects?.pagingMetadata?.totalItems) &&
        (projects.pagingMetadata.totalItems === 1
          ? ' | ' + t('singleProject')
          : ' | ' + t('multipleProjects', { count: projects.pagingMetadata.totalItems }))}
    </Typography>
  )
}

export const TeamRowDisplay = ({ space }: { space: Space }): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const {
    data: spaceUsersPermissions,
    isLoading: isSpacePermissionLoading,
    isFetching: isSpacePermissionFetching
  } = useGetSpaceUsersPermissionsQuery({ spaceId: space?.spaceId }, { skip: isNil(space?.spaceId) })

  const isLoading = isSpacePermissionLoading || isSpacePermissionFetching

  const usernames = React.useMemo(() => {
    if (isNil(spaceUsersPermissions?.permissions)) {
      return []
    }

    const extractedData = spaceUsersPermissions.permissions
      .map((data) => {
        if (data.scope.scopeType === ScopeType.USER) {
          return { username: data.scope.username, isAdmin: data.permission === PermissionType.FULL_ACCESS }
        }
        return null
      })
      .filter(Boolean)

    return extractedData.map((data) => data.username).filter(Boolean)
  }, [spaceUsersPermissions])

  const handleLinkClick = React.useCallback((space: Space): void => {
    dispatch(
      openModal({
        template: ModalTemplate.SPACE_MEMBERS,
        showCloseButton: true,
        modalProps: {
          title: t('teamSpace.editSpace', { ns: 'modals', spaceName: space.name }),
          selectedTeamSpace: space
        }
      })
    )
  }, [])

  return (
    <Stack direction="row" onClick={(): void => handleLinkClick(space)} sx={{ cursor: 'pointer', padding: 4 }}>
      <Stack direction="column" width="256px">
        <TeamRowTitle space={space} />
        <TeamRowSubtitle spaceId={space.spaceId} numberOfMembers={usernames?.length} isLoadingPermissions={isLoading} />
      </Stack>
      <AdditionalUsers restOfTheUsernames={usernames} />
    </Stack>
  )
}

import {
  GetProjectsProps,
  GetProjectsReturnType,
  ProjectTag,
  SearchProjectsProps,
  SearchedProjectsResponse,
  SearchedProjectsReturnType
} from './interfaces'
import {
  convertPageNumberToOffset,
  convertSearchProjectsToProjects,
  convertSortingQueryParamsToSearchFormat,
  getProjectContentUrl
} from './helpers'
import { encodeQueryParams } from '../../../../../utils/helpers'
import { apiTags } from '../../apiTags'
import { ApiVersion, isSpace } from '../../helpers'
import { ApplicationApi } from '../../ApplicationApi'
import { MAX_SEARCH_RESULTS } from '../../../../../containers/dashboard/mainDisplay/hooks'

const projectsSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<GetProjectsReturnType, GetProjectsProps>({
      query: ({
        spaceId,
        folderId,
        sortingModeV2,
        sortingDirection,
        targetPage,
        projectsPerPage = MAX_SEARCH_RESULTS
      }) => {
        const projectContentUrl = getProjectContentUrl(
          spaceId,
          folderId,
          sortingModeV2,
          sortingDirection,
          targetPage,
          projectsPerPage
        )
        return `api/${ApiVersion.V1}/${projectContentUrl}`
      },
      providesTags: (_result, _error, { spaceId, folderId }) => {
        if (isSpace({ spaceId, folderId })) {
          return [{ type: apiTags.PROJECTS, spaceId }]
        } else {
          return [{ type: apiTags.PROJECTS, spaceId, folderId }]
        }
      }
    }),
    getSearchProjects: builder.query<SearchedProjectsReturnType, SearchProjectsProps>({
      query: ({
        sortingModeV2,
        sortingDirection,
        username,
        targetPage,
        scope,
        query,
        filterby,
        projectsPerPage = MAX_SEARCH_RESULTS,
        offset = projectsPerPage
      }) => {
        const sortby = convertSortingQueryParamsToSearchFormat(sortingModeV2)
        const offsetFromTargetPage = convertPageNumberToOffset(targetPage, offset)

        const queryParams = encodeQueryParams({
          offset: offsetFromTargetPage,
          order: sortingDirection,
          sortby,
          limit: projectsPerPage,
          scope,
          filterby,
          filter: query
        })

        return `api/${ApiVersion.V1}/projects/${username}/?${queryParams}`
      },
      transformResponse: (response: SearchedProjectsResponse) => ({
        ...response,
        projects: convertSearchProjectsToProjects(response?.projects)
      }),
      providesTags: (_result, _error, { spaceId }) => [{ type: apiTags.PROJECTS, spaceId }]
    }),
    getProjectsTags: builder.query<ProjectTag[], { prefix?: string; limit?: number }>({
      query: ({ prefix, limit }) => {
        return {
          url: `api/${ApiVersion.V1}/projects/tags`,
          params: { prefix, limit }
        }
      }
    })
  })
})

export const { useGetProjectsQuery, useGetSearchProjectsQuery, useGetProjectsTagsQuery } = projectsSlice

export const getProjects = projectsSlice.endpoints.getProjects.initiate
export const getSearchProjects = projectsSlice.endpoints.getSearchProjects.initiate

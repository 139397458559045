import { Job, UserPasswordUpdateResponse, UserSettingsContent, UserV2, UserV2Balances } from './interfaces'
import { UserData } from '../../../../../components/modal/templates/share/interfaces'
import { ApiKey, ApiKeys } from '../../../../../containers/dashboard/personalArea/apiKeys/interfaces'
import { getUserId, getUsername } from '../../../../../helpers/cookies'
import { getTomorrowDate } from '../../../../helper'
import { ApplicationApi } from '../../ApplicationApi'
import { apiTags } from '../../apiTags'
import { Invite, InvitesResponse } from '../invitation/interfaces'

const userSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<UserData, { username: string }>({
      query: ({ username }) => `api/v1/users/${username}`,
      providesTags: [apiTags.USER]
    }),
    getUserById: builder.query<UserData, { uidExt: string }>({
      query: ({ uidExt }) => `api/v1/users/uidExt/${uidExt}`,
      providesTags: [apiTags.USER]
    }),
    putUser: builder.mutation<
      UserData,
      { username: string; notifyOnSimulationFinish?: boolean; firstName?: string; lastName?: string }
    >({
      query: ({ username, notifyOnSimulationFinish, firstName, lastName }) => ({
        url: `api/v1/users/${username}`,
        method: 'PUT',
        body: {
          username,
          notifyOnSimulationFinish,
          firstname: firstName,
          lastname: lastName
        }
      }),
      invalidatesTags: [apiTags.USER]
    }),
    getSettingsMap: builder.query<UserSettingsContent, void>({
      query: () => {
        const username = getUsername()
        return `api/v1/users/${username}/settingsmap`
      }
    }),
    getJobs: builder.query<Job[], void>({
      query: () => {
        const username = getUsername()
        return `api/v1/users/${username}/jobs?from=2010-01-01&to=${getTomorrowDate()}`
      },
      providesTags: [apiTags.USER_JOBS]
    }),
    getUserApiKeys: builder.query<ApiKey[], void>({
      query: () => {
        const username = getUsername()
        return `api/v1/users/${username}/api-keys`
      },
      transformResponse: (response: ApiKeys) => response.apiKeys ?? [],
      providesTags: [apiTags.USER_API_KEYS]
    }),
    postUserApiKey: builder.mutation<ApiKey, { description: string }>({
      query: ({ description }) => {
        const username = getUsername()
        return {
          url: `api/v1/users/${username}/api-keys`,
          method: 'POST',
          body: { description }
        }
      },
      invalidatesTags: [apiTags.USER_API_KEYS]
    }),
    deleteUserApiKey: builder.mutation<void, { apiKeySuffix: string }>({
      query: ({ apiKeySuffix }) => {
        const username = getUsername()
        return {
          url: `api/v1/users/${username}/api-keys/${apiKeySuffix}`,
          method: 'DELETE'
        }
      },
      invalidatesTags: [apiTags.USER_API_KEYS]
    }),
    putUserApiKey: builder.mutation<ApiKey, { apiKeySuffix: string; description: string; enabled: boolean }>({
      query: ({ apiKeySuffix, description, enabled }) => {
        const username = getUsername()
        return {
          url: `api/v1/users/${username}/api-keys/${apiKeySuffix}`,
          method: 'PUT',
          body: { description, enabled }
        }
      },
      invalidatesTags: [apiTags.USER_API_KEYS]
    }),
    getUsersV2: builder.query<UserV2[], { organizationId: string }>({
      query: ({ organizationId }) => `/api/v2/users?organizationId=${organizationId}`,
      transformResponse: (response: { users: UserV2[] }) => response.users,
      providesTags: [apiTags.USERS]
    }),
    getUserV2Balances: builder.query<UserV2Balances, { uidExt: number }>({
      query: ({ uidExt }) => `/api/v2/users/${uidExt}/balances`,
      providesTags: [apiTags.USER_BALANCES]
    }),
    getUserV2Invitations: builder.query<Invite[], void>({
      query: () => {
        const uidExt = getUserId()
        return `/api/v2/users/${uidExt}/invites`
      },
      transformResponse: (response: InvitesResponse) => response.invites,
      providesTags: [apiTags.USER_INVITATIONS]
    }),
    putPassword: builder.mutation<UserPasswordUpdateResponse, { oldPassword: string; newPassword: string }>({
      query: ({ oldPassword, newPassword }) => ({
        url: 'api/v1/auth/password',
        method: 'PUT',
        body: { oldPassword, newPassword }
      })
    })
  })
})

export const {
  useGetUsersV2Query,
  useGetUserV2BalancesQuery,
  useGetUserV2InvitationsQuery,
  useGetUserQuery,
  usePutUserMutation,
  useGetSettingsMapQuery,
  useGetUserApiKeysQuery,
  usePostUserApiKeyMutation,
  useDeleteUserApiKeyMutation,
  usePutUserApiKeyMutation,
  useGetJobsQuery,
  usePutPasswordMutation,
  useGetUserByIdQuery
} = userSlice

import { useGetMyInvitationsTableBodyData, useGetMyInvitationsTableHeaderData } from './tableDataHooks'
import { NoInvitationsAlert } from './NoInvitationsAlert'
import { EnhancedTable } from '../../../../components/table/enhancedTable/EnhancedTable'
import { useRedirectIfPermissionDenied } from '../../../../permissions/areas'
import { useOrganizationId } from '../../../../hooks/newWorld'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import { useCenteredCircularProgress } from '../../../../components/progress/CenteredCircularProgress'
import { useGetUserV2InvitationsQuery } from '../../../../store/features/apis/slices/user/userSlice'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'

export const InvitationsDisplay = (): React.ReactElement => {
  const { data: myInvitations, isLoading, isFetching, isError, error } = useGetUserV2InvitationsQuery()
  const tableHeaderData = useGetMyInvitationsTableHeaderData()
  const tableData = useGetMyInvitationsTableBodyData(myInvitations)
  const { data: organizationId, isLoading: isLoadingOrganizationId } = useOrganizationId()
  const showMyInvitations = isNil(organizationId)

  useRedirectIfPermissionDenied([[isLoadingOrganizationId, showMyInvitations]])

  const { handleApiHookErrors } = useErrorHandler()
  const firstRenderWithError = React.useRef(false)

  React.useEffect(() => {
    if (isError) {
      firstRenderWithError.current = true
    }
  }, [])

  React.useEffect(() => {
    if (isError && !firstRenderWithError.current) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  useCenteredCircularProgress(isLoading || isFetching)

  if (isError) {
    firstRenderWithError.current = false
    return null
  }

  return (
    <Stack direction="column" gap={4} mt={2.5} mb={4}>
      {!isLoading && isEmpty(myInvitations) && <NoInvitationsAlert />}
      {!isEmpty(myInvitations) && (
        <Stack
          direction="column"
          padding={6}
          border={1}
          borderRadius={1}
          borderColor="grey.200"
          qa-attribute="my-invitations--table"
        >
          <EnhancedTable
            isLoading={isLoading || isFetching}
            tableHeaderData={tableHeaderData}
            tableBodyData={tableData}
            defaultSortByColumn={2}
          />
        </Stack>
      )}
    </Stack>
  )
}

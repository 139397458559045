import { AvatarImageSize } from '../avatar/Avatar'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import styled from '@mui/system/styled'
import Box from '@mui/material/Box'
import { UserAvatarPicture } from '@/containers/user/UserAvatarPicture'

type UsersAvatarRowProps = {
  users: string[]
  maxVisibleUsers?: number
}

const RowCounter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.text.primary,
  fontSize: '1.0625rem',
  lineHeight: '1.2em',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minWidth: AvatarImageSize.MEDIUM,
  height: AvatarImageSize.MEDIUM,
  borderRadius: AvatarImageSize.MEDIUM / 2,
  backgroundColor: theme.palette.grey[200]
}))

export const UsersAvatarRow = ({ users, maxVisibleUsers = 5 }: UsersAvatarRowProps): React.ReactElement => {
  const visibleUsers = users.slice(0, maxVisibleUsers)
  const remainingUsersCount = users.length - maxVisibleUsers

  return (
    <Stack direction="row" spacing={1} qa-attribute="users-avatar-row">
      {visibleUsers.map((userId) => (
        <UserAvatarPicture key={userId} userId={userId} avatarImageSize={AvatarImageSize.MEDIUM} showUsernameTooltip />
      ))}
      {remainingUsersCount > 0 && (
        <RowCounter qa-attribute="users-avatar-row--counter">+{remainingUsersCount}</RowCounter>
      )}
    </Stack>
  )
}

import { NavigationItemTitle } from '../navigation/NavigationItemTitle'
import { PagePath } from '../../constants'
import { t } from 'i18next'
import * as React from 'react'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'
import { useIsSharedWithMePage } from '@/hooks/useIsSharedWithMePage'

export const SharedWithMe = (): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const isSharedWithMePage = useIsSharedWithMePage()

  return (
    <NavigationItemTitle
      spriteId="share-nodes"
      label={t('sharedWithMe')}
      onClick={(): void => navigate(PagePath.SHARED_WITH_ME)}
      isSelected={isSharedWithMePage}
    />
  )
}

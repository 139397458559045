import * as React from 'react'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import { ModelStage, ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { formatVersion } from '@/containers/ai/helpers'

type VersionsHistoryItemProps = {
  version: ModelVersion
  isLatestVersion?: boolean
}

export const ModelVersionCustomChip = styled(Chip)(({ theme }) => ({
  flexShrink: 0,
  width: theme.spacing(13),
  height: theme.spacing(6),
  fontSize: '.8125rem',
  fontWeight: 700,
  letterSpacing: '0.03em',
  lineHeight: '1.2',
  '&.MuiChip-colorDefault': {
    backgroundColor: theme.palette.grey[150],
    color: theme.palette.text.primary
  },
  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
    textOverflow: 'unset'
  }
}))

export const VersionsHistoryItem = ({ version }: VersionsHistoryItemProps): React.ReactElement => {
  const isInProduction = version?.stage === ModelStage.PRODUCTION

  return (
    <Stack direction="row" spacing={4} alignItems="center" qa-attribute="aiModel--modelVersions--history--item">
      <ModelVersionCustomChip label={formatVersion(version.version)} color={isInProduction ? 'primary' : 'default'} />
      <Typography variant="textV2" width="auto" flexShrink={1}>
        {version.name}
      </Typography>
    </Stack>
  )
}

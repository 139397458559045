import Box from '@mui/material/Box'
import { styled } from '@mui/system'

export const RowDetails = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
  alignContent: 'center',
  alignItems: 'center',
  gap: '12px',
  marginRight: 'auto',
  minWidth: '0'
})

import { useAttributes } from './newWorld'
import { OrganizationAttributes } from '@/store/features/apis/slices/organization/interfaces'
import { IntercomProductPlan } from '@/containers/userPlan/interfaces'

export const useGetUserProductPlan = (): IntercomProductPlan => {
  const { data: attributes, isLoading } = useAttributes()

  if (isLoading) {
    return undefined
  }

  if (!attributes.isProfessional) {
    return IntercomProductPlan.COMMUNITY_FREE_12M
  }

  if (attributes.isTrial) {
    if (attributes.isAcademic) {
      return IntercomProductPlan.ACADEMIC_TEST
    }
    return IntercomProductPlan.PROFESSIONAL_TEST
  }

  if (attributes.isAcademic) {
    return IntercomProductPlan.ACADEMIC_PRIVATE
  }

  return IntercomProductPlan.PROFESSIONAL_12M_FIXED_QUOTA
}

export const getUserPlanLabelKey = (attributes: OrganizationAttributes): string => {
  if (!attributes.isProfessional) {
    return 'community'
  }

  if (attributes.isTrial) {
    if (attributes.isAcademic) {
      return 'academicTest'
    }
    return 'professionalTest'
  }

  if (attributes.isAcademic) {
    return 'academic'
  }

  return 'professional'
}

type ChipColor = 'default' | 'primary'

export const getUserPlanChipColor = (attributes: OrganizationAttributes): ChipColor => {
  if (!attributes.isProfessional) {
    return 'default'
  }

  if (attributes.isTrial || attributes.isAcademic) {
    return 'primary'
  }

  return 'primary'
}

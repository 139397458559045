import { isProduction, isStaging } from '../utils/windowUtils'
import { USERFLOW_PRODUCTION_ID, USERFLOW_STAGING_ID } from '../constants/userflow'

export const getUserFlowTokenByEnv = (): string | undefined => {
  // Available only on staging and production
  if (isProduction()) {
    return USERFLOW_PRODUCTION_ID
  } else if (isStaging()) {
    return USERFLOW_STAGING_ID
  }
  return undefined
}

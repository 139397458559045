import Box from '@mui/material/Box'
import { styled } from '@mui/system'

const iconFolder = require('../../assets/svg/folder.svg')
const iconFolderShared = require('../../assets/svg/folder-shared.svg')
const iconFolderTeam = require('../../assets/svg/folder-team.svg')

interface CardIconProps {
  shared?: boolean
  addExtraMargin?: boolean
  size?: string | number
  useTeamIcon?: boolean
}

export const CardIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shared' && prop !== 'useTeamIcon' && prop !== 'addExtraMargin'
})<CardIconProps>(({ shared, size, useTeamIcon }) => ({
  width: size ?? '32px',
  height: size ?? '32px',
  backgroundSize: 'contain',
  backgroundImage: useTeamIcon ? `url(${iconFolderTeam})` : shared ? `url(${iconFolderShared})` : `url(${iconFolder})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  flexShrink: '0'
}))

import { NavigationTree } from '../../../navigationTree/NavigationTree'
import { PathSelector } from '../../../../containers/spaces/interfaces'
import { useGetFolders } from '../../../../store/features/apis/slices/folder/helpers'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setMobileSidebarOpen } from '../../../../store/features/uiSlice/uiSlice'
import * as React from 'react'
import { first, isEmpty, last } from 'lodash'

interface PathSelectorNavigationTreeProps {
  path?: PathSelector
  selectedPath?: PathSelector
  setSelectedPath?: (path: PathSelector) => void
  label?: string
  level?: number
  from?: string[]
  isSingleSpace?: boolean
  currentFolderId?: string
  expandedPath?: PathSelector
  disabled?: boolean
}

export const FolderSelectorNavigationTree = ({
  path,
  selectedPath,
  setSelectedPath,
  currentFolderId,
  label,
  from,
  level = 0,
  expandedPath = [],
  disabled
}: PathSelectorNavigationTreeProps): React.ReactElement => {
  const dispatch = useAppDispatch()
  const isDisabled = disabled ?? from?.some((id) => path.includes(id))
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
  const isMobileSidebarOpen = useAppSelector((state) => state.ui.mobileSidebarOpen)

  React.useEffect(() => {
    if (!isEmpty(expandedPath) && expandedPath.includes(last(path))) {
      setIsExpanded(true)
    }
  }, [expandedPath])

  const { data: folders, isLoading } = useGetFolders({ spaceId: first(path), folderId: last(path) })

  const handleOnSelected = React.useCallback(
    (selectedPath: PathSelector, path: PathSelector, isMobileSidebarOpen: boolean) => {
      if (last(selectedPath) === last(path)) {
        setSelectedPath(null)
      } else {
        setSelectedPath(path)
      }
      if (isMobileSidebarOpen) {
        dispatch(setMobileSidebarOpen(!isMobileSidebarOpen))
      }
    },
    []
  )

  return (
    <>
      <NavigationTree
        isCurrentLocation={currentFolderId === last(path)}
        isDisabled={isDisabled}
        label={label}
        isExpanded={isExpanded}
        level={level}
        isLeaf={isEmpty(folders)}
        onCollapsedCallback={(): void => {
          setIsExpanded((p) => !p)
        }}
        onSelectedCallback={(): void => {
          handleOnSelected(selectedPath, path, isMobileSidebarOpen)
        }}
        isSelected={last(path) === last(selectedPath)}
        isTeamSpace={path.length === 1}
        hasFolders={folders.length > 0}
      />
      {isExpanded &&
        !isLoading &&
        folders?.map((folder) => (
          <FolderSelectorNavigationTree
            currentFolderId={currentFolderId}
            from={from}
            path={[...path, folder.folderId]}
            selectedPath={selectedPath}
            setSelectedPath={setSelectedPath}
            label={folder.name}
            key={folder.folderId}
            level={level + 1}
            expandedPath={expandedPath}
            disabled={disabled}
          />
        ))}
    </>
  )
}

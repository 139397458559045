import { useScrollShadow } from '../hooks/useScrollShadow'
import Box, { BoxProps } from '@mui/material/Box'
import * as React from 'react'
import MenuList from '@mui/material/MenuList'

type BoxWithScrollShadowProps = BoxProps

const BottomElement = ({ innerRef }: { innerRef: React.MutableRefObject<Element> }): React.ReactElement => {
  return <Box width="100%" height="1px" bgcolor="transparent" ref={innerRef} />
}

export const BoxWithScrollShadow = ({ children, ...props }: BoxWithScrollShadowProps): React.ReactElement => {
  const scrollElementRef = React.useRef<Element>(null)
  const bottomElementRef = React.useRef<Element>(null)
  const { boxShadow, eventHandler } = useScrollShadow({ scrollElementRef, bottomElementRef })

  return (
    <Box
      {...props}
      boxShadow={boxShadow}
      onScroll={eventHandler}
      onMouseUp={eventHandler}
      onMouseEnter={eventHandler}
      onMouseLeave={eventHandler}
      ref={scrollElementRef}
      sx={{ transition: 'all 0.2s ease-in-out' }}
    >
      <MenuList>{children}</MenuList>
      <BottomElement innerRef={bottomElementRef} />
    </Box>
  )
}

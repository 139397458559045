import { UserWidget } from './userWidget/UserWidget'
import { Navigation } from '../../navigation/Navigation'
import Stack, { StackProps } from '@mui/material/Stack'
import * as React from 'react'

export const LeftSidebar = (props?: StackProps): React.ReactElement => {
  return (
    <Stack sx={{ height: '100%' }} {...props}>
      <Navigation p={4} pr={2.5} flexGrow={1} className="u-overflowOverlay" />
      <UserWidget flexGrow={0} mt="auto" bgcolor="grey.100" />
    </Stack>
  )
}

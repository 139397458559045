import { FirstName } from './FirstName'
import { LastName } from './LastName'
import { CardStyled } from '../../../../components/card/Card'
import { UserAvatarPicture } from '../../../user/UserAvatarPicture'
import { useCenteredCircularProgress } from '../../../../components/progress/CenteredCircularProgress'
import { SelfUpdatableSettingsRow } from '../../../../components/settingsForm/SelfUpdatableSettingsRow'
import { SettingsTitle } from '../../../../components/settingsForm/SettingsTitle'
import { useGetOrganizationV2Query } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { useGetUserQuery, usePutUserMutation } from '../../../../store/features/apis/slices/user/userSlice'
import { useUserNames } from '../../../../hooks/useUserNames'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Alert, AlertTitle, Link, Button as MuiButton, Typography } from '@mui/material'
import * as React from 'react'
import { isNil } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'

const CancelButton = styled(MuiButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.error['300']}`,
  background: theme.palette.error['100'],
  color: theme.palette.error.main,
  '&:hover': {
    border: `1px solid ${theme.palette.error['300']}`,
    background: theme.palette.error['100'],
    color: theme.palette.error.main
  }
}))

export const HomeDisplay = (): React.ReactElement => {
  const { t } = useTranslation(['profileArea'])

  const { username } = useUserNames()
  const { data: user, isFetching, isLoading } = useGetUserQuery({ username }, { skip: isNil(username) })
  const { data: organization, isLoading: isOrganizationLoading } = useGetOrganizationV2Query(
    { organizationId: user?.organizationInfo?.organizationId },
    { skip: isLoading || isNil(user?.organizationInfo?.organizationId) }
  )
  const [putUser, { isLoading: isPutUserLoading }] = usePutUserMutation()

  const onCancelClick = useCallback(() => {}, [])

  const handleEmailNotificationsChange = useCallback(() => {
    putUser({ username: user.username, notifyOnSimulationFinish: !user.notifyOnSimulationFinish })
  }, [user])

  useCenteredCircularProgress(isOrganizationLoading || isLoading)

  if (isLoading || isOrganizationLoading) {
    return null
  }

  return (
    <Stack direction="column" mx="auto" width="600px" mt={2.5} mb={4} qa-attribute="personal-details--wrapper">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <SettingsTitle>{t('profile.labels.publicProfile')}</SettingsTitle>
        <CardStyled notInteractive sx={{ padding: 4, gap: 4 }}>
          <UserAvatarPicture userName={username} editProfile />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography color="GrayText">{t('profile.labels.username')}</Typography>
            <Typography variant="textBold" color="GrayText" qa-attribute="personal-details--username">
              {username}
            </Typography>
          </Box>
        </CardStyled>
        <SettingsTitle>{t('profile.labels.account')}</SettingsTitle>
        <CardStyled notInteractive sx={{ padding: 4, gap: 4, width: '100%', display: 'flex', flexDirection: 'column' }}>
          <FirstName username={user.username} firstName={user.firstname} />
          <LastName username={user.username} lastName={user.lastname} />
          <SelfUpdatableSettingsRow
            label={t('profile.labels.email')}
            value={user.emailAddress}
            disabled
            qaAttributePrefix="personal-details"
          />
          <SelfUpdatableSettingsRow
            label={t('profile.labels.phoneNumber')}
            value={user.phone}
            disabled
            qaAttributePrefix="personal-details"
          />
          {!isNil(organization) && (
            <SelfUpdatableSettingsRow
              label={t('profile.labels.organization')}
              value={organization.name}
              disabled
              qaAttributePrefix="personal-details"
            />
          )}
        </CardStyled>
        <Alert icon={false} severity="info">
          <AlertTitle sx={{ fontWeight: 'bold' }}>{t('profile.alerts.updateAccountAlert.title')}</AlertTitle>
          <Typography variant="text" color="text.primary">
            <Trans i18nKey="profile.alerts.updateAccountAlert.message" t={t}>
              <Link
                href="#"
                onClick={(): void => {
                  window['Intercom']?.('show')
                }}
                id="updateMyAccountRequest"
              >
                support team
              </Link>
            </Trans>
          </Typography>
        </Alert>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CancelButton
            variant="outlined"
            onClick={onCancelClick}
            id="cancelAccountRequest"
            qa-attribute="cancel--button"
          >
            {t('profile.labels.cancelAccount')}
          </CancelButton>
        </Box>
        <SettingsTitle>{t('profile.labels.emailNotifications')}</SettingsTitle>
        <SelfUpdatableSettingsRow
          label={t('profile.labels.finishedSimulationRuns')}
          value={Boolean(user?.notifyOnSimulationFinish)}
          reverse
          disabled={isFetching || isPutUserLoading}
          onChange={handleEmailNotificationsChange}
          qaAttributePrefix="personal-details"
        />
      </Box>
    </Stack>
  )
}

import { ProjectDetailsDisplay } from './ProjectDetailsDisplay'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { Stack } from '@mui/material'
import { CollapseWrapper } from '@/components/elements/Collapse'
import { useAppSelector } from '@/store/hooks'
import { getSelectedItems } from '@/store/features/uiSlice/uiSlice'

export const ProjectsDisplay = ({ onAnalysisSelect }): React.ReactElement => {
  const { t } = useTranslation('modals')
  const selectedProjects = useAppSelector(getSelectedItems).projects

  return (
    <CollapseWrapper
      collapseText={t('projects')}
      defaultCollapseValue={false}
      fontSize="1.1rem"
      fontWeight={700}
      collapsedIconRotationValue={90}
      noMarginLeft
      letterSpacing="normal"
    >
      <Stack qa-attribute="training-projects-display" gap={4}>
        {selectedProjects.map((projectId) => (
          <ProjectDetailsDisplay key={projectId} projectId={projectId} onAnalysisSelect={onAnalysisSelect} />
        ))}
      </Stack>
    </CollapseWrapper>
  )
}

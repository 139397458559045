export const convertSecondsToHoursMinutes = (seconds: number): string | null => {
  const sign = Math.sign(seconds)
  const hours = Math.floor(Math.abs(seconds) / 3600)
  const minutes = Math.floor((Math.abs(seconds) % 3600) / 60)

  const timeSignText = sign < 0 ? '-' : ''
  const hoursText = `${timeSignText}${hours}h`
  const minutesText = `${timeSignText}${minutes}m`

  if (hours === 0) {
    return minutesText
  }
  return hoursText
}

import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { Button } from '../../button/Button'
import { ModalTemplateProps } from '../interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { isNil } from 'lodash'

type ConfirmPublicProjectPermissionType = ModalTemplateProps & {
  title: string
  description?: string
  onConfirm: () => void
}

export const ConfirmationModalTemplate = ({
  title,
  description,
  onClose,
  showCloseButton,
  onConfirm
}: ConfirmPublicProjectPermissionType): React.ReactElement => {
  const { t } = useTranslation('modals')

  const handleConfirmOnClick = (): void => {
    onConfirm()
    onClose()
  }

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleConfirmOnClick}>
        {!isNil(description) && <Typography component="span">{description}</Typography>}
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>

        <Button onClick={handleConfirmOnClick} color="primary" variant="contained">
          {t('confirm')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

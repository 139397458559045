import { ProjectAction } from './useActionModalHandler'
import { useDashboardNavigate } from './useDashboardNavigate'
import { useCurrentPath } from './useCurrentPath'
import { getCookie } from '../helpers/cookies'
import { ModalTemplate } from '../store/features/uiSlice/interfaces'
import { openModal } from '../store/features/uiSlice/uiSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { useGetProjectV1Details } from '../store/features/apis/slices/project/helpers'
import { filterSearchParams } from '../helpers/navigation'
import { useIsInOrganization } from '../permissions/areas'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import * as React from 'react'

const isTempAction = (urlSearchParams: URLSearchParams): boolean => {
  return Object.values(ProjectAction).some((action) => urlSearchParams.has(`${action}Project`))
}

const getTempAction = (urlSearchParams: URLSearchParams): string | undefined => {
  return Object.values(ProjectAction).find((action) => urlSearchParams.has(`${action}Project`))
}

export const useQueryParamTrigger = (): void => {
  const { navigateNoReset } = useDashboardNavigate()
  const { currentPath, isLoading: isLoadingCurrentPath } = useCurrentPath()
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['modals'])
  const { data: isInOrganization, isLoading: isLoadingIsInOrganization } = useIsInOrganization()
  const isCreateModalOpen = useAppSelector((state) =>
    state.ui.modalsQueue.some((modal) => modal.template === ModalTemplate.CREATE_PROJECT)
  )
  const isWelcomeModalOpen = useAppSelector((state) =>
    state.ui.modalsQueue.some((modal) => modal.template === ModalTemplate.ORGANIZATION_WELCOME)
  )

  React.useEffect(() => {
    if (!isLoadingCurrentPath && !isLoadingIsInOrganization) {
      if (searchParams.has('new') && !isCreateModalOpen) {
        dispatch(
          openModal({
            template: ModalTemplate.CREATE_PROJECT,
            modalProps: { title: t('project.createTitle', { ns: 'modals' }), targetFolderPath: currentPath }
          })
        )
      }

      if ((searchParams.has('welcome') || getCookie().welcome) && isInOrganization && !isWelcomeModalOpen) {
        dispatch(
          openModal({
            template: ModalTemplate.ORGANIZATION_WELCOME,
            showCloseButton: true
          })
        )
      }

      if (isTempAction(searchParams)) {
        const tempAction = getTempAction(searchParams)
        if (tempAction) {
          const projectSlug = searchParams.get(`${tempAction}Project`)
          if (!isEmpty(projectSlug)) {
            const { isSuccess, data } = useGetProjectV1Details({ projectSlug })
            if (isSuccess) {
              const filteredParams = filterSearchParams(searchParams, ['q', 'section'])
              navigateNoReset(`/projects/${data.projectIdExt}/${tempAction}${filteredParams}`)
            }
          }
        }
      }
    }
  }, [currentPath, isLoadingCurrentPath, isInOrganization, isLoadingIsInOrganization, searchParams])
}

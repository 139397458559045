import { TrainingRunDetails } from '../trainingRun/TrainingRunDetails'
import { TrainingRunListItemDetails } from '../trainingRun/TrainingRunListItemDetails'
import * as React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import styled from '@mui/system/styled'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import format from 'date-fns/format'
import { first } from 'lodash'
import { TrainingPipeline } from '@/store/features/apis/slices/ai/interfaces'
import { ActionButton } from '@/components/button/ActionButton'
import { ContextMenuType } from '@/components/contextMenu/ContextMenu'
import { useContextMenu } from '@/hooks/useContextMenu'
import { SpriteIcon } from '@/assets/SpriteIcon'

type ModelTrainingPipelinesListItemProps = {
  trainingPipeline: TrainingPipeline
  isOpen?: boolean
  onClick?: (trainingPipeline: TrainingPipeline) => void
}

type ModelTrainingPipelinesListItemRowProps = {
  trainingPipeline: TrainingPipeline
  isOpen?: boolean
  onClick?: (trainingPipeline: TrainingPipeline) => void
}

export const ModelTrainingPipelinesListItemSkeleton = (): React.ReactElement => {
  return <Skeleton variant="rounded" width="100%" height={65} animation="wave" />
}

const ModelTrainingPipelinesListItemCard = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.border.default}`,
  borderRadius: theme.spacing(1)
}))

const ModelTrainingPipelinesListItemRow = ({
  trainingPipeline,
  isOpen,
  onClick
}: ModelTrainingPipelinesListItemRowProps): React.ReactElement => {
  const { show } = useContextMenu()

  return (
    <Stack
      spacing={5}
      paddingX={4}
      paddingY={2}
      height={65}
      direction="row"
      alignItems="center"
      onClick={(): void => onClick(trainingPipeline)}
      qa-attribute="aiModel--trainingPipelinesList--item--row"
    >
      <Stack spacing={3} direction="row" alignItems="center">
        <SpriteIcon
          color="grey.600"
          width={20}
          spriteId="angle-right"
          rotatingAngle={isOpen ? 90 : 0}
          rotationTime={0.3}
        />
        <Box minWidth={180} flexShrink={0}>
          <Typography variant="textV2" display="block">
            {trainingPipeline.training_name}
          </Typography>
          <Typography variant="textSmallV2" color="grey.700">
            {format(new Date(trainingPipeline.created_at), 'MMM d, yyyy')}
          </Typography>
        </Box>
        <TrainingRunDetails trainingRunId={first(trainingPipeline.training_run_ids)} />
      </Stack>
      <Stack marginLeft="auto" direction="row" spacing={5} flexGrow={1} justifyContent="end">
        <ActionButton
          onClick={(event): void => {
            show({ event, type: ContextMenuType.MODEL_TRAINING_PIPELINE, trainingPipeline })
          }}
          qa-attribute="table-row-action--button"
        >
          <SpriteIcon spriteId="ellipsis-vertical" color="text.primary" width={16} height={16} />
        </ActionButton>
      </Stack>
    </Stack>
  )
}

export const ModelTrainingPipelinesListItem = ({
  trainingPipeline,
  ...props
}: ModelTrainingPipelinesListItemProps): React.ReactElement => {
  return (
    <ModelTrainingPipelinesListItemCard
      sx={{ backgroundColor: props.isOpen ? 'grey.100' : 'transparent' }}
      qa-attribute="aiModel--versionsList--item"
    >
      <ModelTrainingPipelinesListItemRow trainingPipeline={trainingPipeline} {...props} />
      {props.isOpen && <TrainingRunListItemDetails trainingRunId={first(trainingPipeline.training_run_ids)} />}
    </ModelTrainingPipelinesListItemCard>
  )
}

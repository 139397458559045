import { AdminSelector } from './AdminSelector'
import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { User } from '../../../../components/user/User'
import { useDeleteOrganizationV2UserMutation } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { useGetOrganizationId } from '../../../../store/features/apis/slices/organization/hooks'
import { UserV2 } from '../../../../store/features/apis/slices/user/interfaces'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { styled } from '@mui/system'

interface RemoveUserProps extends ModalTemplateProps {
  title: string
  selectedUser?: UserV2
}

const SelectedUserBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey['100'],
  borderLeft: `${theme.spacing(1)} solid ${theme.palette.grey['400']}`,
  borderTop: `1px solid ${theme.palette.grey['200']}`,
  borderBottom: `1px solid ${theme.palette.grey['200']}`,
  padding: `${theme.spacing(3)} ${theme.spacing(6)} ${theme.spacing(3)} ${theme.spacing(5)}`,
  marginBottom: theme.spacing(6),
  display: 'flex'
}))

export const RemoveUserModalTemplate = ({
  title,
  onClose,
  showCloseButton,
  selectedUser
}: RemoveUserProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const organizationId = useGetOrganizationId()
  const [deleteOrganizationUser, { isLoading }] = useDeleteOrganizationV2UserMutation()
  const [assignedUser, setAssignedUser] = React.useState<UserV2>(selectedUser)

  const onClick = React.useCallback(() => {
    deleteOrganizationUser({
      organizationId,
      inheritor: assignedUser.uidExt,
      userId: selectedUser.uidExt
    })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: (
              <Trans
                i18nKey="removeOrganizationUser.userRemovedSuccessfully"
                t={t}
                values={{ name: `${selectedUser.firstName} ${selectedUser.lastName}` }}
              />
            )
          })
        )
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: (
              <Trans
                i18nKey="removeOrganizationUser.projectAssignedSuccessfully"
                t={t}
                values={{ name: `${assignedUser.firstName} ${assignedUser.lastName}` }}
              />
            )
          })
        )
      })
      .catch(() => {
        dispatch(
          addSnackbarToState({
            severity: 'error',
            message: (
              <Trans
                i18nKey="removeOrganizationUser.userRemovedError"
                t={t}
                values={{ name: `${selectedUser.firstName} ${selectedUser.lastName}` }}
              />
            )
          })
        )
      })
    onClose()
  }, [selectedUser.firstName, selectedUser.lastName, assignedUser.firstName, assignedUser.lastName])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={onClick} submitDisabled={isLoading} noPadding>
        <SelectedUserBoxStyled direction="row" alignItems="center" gap={3}>
          <User
            username={selectedUser.username}
            firstName={selectedUser.firstName}
            lastName={selectedUser.lastName}
            email={selectedUser.email}
          />
        </SelectedUserBoxStyled>
        <AdminSelector assignedUser={assignedUser} setAssignedUser={setAssignedUser} selectedUser={selectedUser} />
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('cancel')}
        </Button>
        <Button onClick={onClick} color="primary" variant="contained" disabled={isLoading}>
          {t('removeOrganizationUser.remove')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import { EnhancedTableRowProps, EnhancedTableRowsProps } from './interfaces'
import { EnhancedTableCells } from './EnhancedTableCells'
import { ActionButton } from '../../button/ActionButton'
import { useIsMobile } from '../../../hooks/useIsMobile'
import * as React from 'react'
import { Checkbox, TableCell, TableRow, styled } from '@mui/material'
import { isNil } from 'lodash'
import { SpriteIcon } from '@/assets/SpriteIcon'

// TODO: When design specs are available, implement correct enhanced table styles. Currently, it's not clear how it should look, and it's breaking our mobile tests for simba. (https://simscale.atlassian.net/browse/ENG-58023)
const TableRowStyled = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isClickable'
})<{ isMobile?: boolean; isClickable?: boolean }>(({ isMobile, isClickable, theme }) => ({
  '&:last-child': {
    borderBottom: 'none',
    '& td': {
      borderBottom: 'none'
    }
  },
  ...(isMobile
    ? {
        display: 'block',
        '& > .MuiTableCell-root': {
          display: 'block',
          paddingTop: 0
        }
      }
    : {}),
  ...(isClickable && {
    cursor: 'pointer',
    '&:hover td': {
      backgroundColor: theme.palette.action.hover
    }
  })
}))

const EnhancedTableRow = ({
  tableRowData,
  isSelected,
  useRowSelectionCheckbox,
  handleCheckboxOnClick,
  expandedRowComponent
}: EnhancedTableRowProps): React.ReactElement => {
  const isMobile = useIsMobile({ breakpoint: 'md' })
  const isItemSelected = isSelected(tableRowData.tableRowId) ?? false
  const labelId = `enhanced-table-checkbox-${tableRowData.tableRowId}`

  return (
    <React.Fragment>
      <TableRowStyled
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={tableRowData.tableRowId}
        selected={isItemSelected}
        isMobile={isMobile}
        onClick={tableRowData.onRowClick}
        isClickable={!isNil(tableRowData.onRowClick)}
      >
        {useRowSelectionCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              onClick={(): void => handleCheckboxOnClick(tableRowData.tableRowId)}
              role="checkbox"
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId
              }}
            />
          </TableCell>
        )}
        <EnhancedTableCells tableCellsData={tableRowData.tableRowCells} />
        {tableRowData.actionButton && (
          <TableCell component="td" align="right" width="50px">
            <ActionButton
              onClick={(event): void => {
                tableRowData.actionButton(event, tableRowData.tableRowId)
              }}
              qa-attribute="table-row-action--button"
            >
              <SpriteIcon spriteId="ellipsis-vertical" color="text.primary" width={16} height={16} />
            </ActionButton>
          </TableCell>
        )}
      </TableRowStyled>
      {!isNil(expandedRowComponent) && (
        <TableRowStyled>
          <TableCell colSpan={5}>{expandedRowComponent}</TableCell>
        </TableRowStyled>
      )}
    </React.Fragment>
  )
}

export const EnhancedTableRows = ({
  tableRowsData,
  selected,
  useRowSelectionCheckbox,
  handleCheckboxOnClick,
  rowComponent: RowComponent
}: EnhancedTableRowsProps): React.ReactElement => {
  const isSelected = (tableRowId: string): boolean => selected?.includes(tableRowId)

  return (
    <React.Fragment>
      {tableRowsData.map((tableRowData) => {
        if (!isNil(RowComponent)) {
          return <RowComponent key={tableRowData.tableRowId} tableRowData={tableRowData} isSelected={isSelected} />
        }
        return (
          <EnhancedTableRow
            key={tableRowData.tableRowId}
            tableRowData={tableRowData}
            isSelected={isSelected}
            useRowSelectionCheckbox={useRowSelectionCheckbox}
            handleCheckboxOnClick={handleCheckboxOnClick}
            expandedRowComponent={tableRowData.expandedRowComponent}
          />
        )
      })}
    </React.Fragment>
  )
}

import { CollapsedBreadcrumbsLabelProps } from './interfaces'
import { TOOLTIP_DELAY } from './constants'
import { CardIcon } from '../card/CardIcon'
import { StyledTooltip } from '../tooltip/StyledTooltip'
import ListItemIcon from '@mui/material/ListItemIcon'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import * as React from 'react'

export const CollapsedBreadcrumbsLabel = ({ label, onClick }: CollapsedBreadcrumbsLabelProps): React.ReactElement => {
  return (
    <StyledTooltip title={label} placement="right" enterDelay={TOOLTIP_DELAY}>
      <MenuItem style={{ width: 200 }} onClick={onClick}>
        <ListItemIcon>
          <CardIcon size={18} />
        </ListItemIcon>
        <Typography noWrap>{label}</Typography>
      </MenuItem>
    </StyledTooltip>
  )
}

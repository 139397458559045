import { staggeredBaseQueryWithBailOut } from './helpers'
import { apiTags } from './apiTags'
import { createApi } from '@reduxjs/toolkit/query/react'

export const ApplicationApi = createApi({
  baseQuery: staggeredBaseQueryWithBailOut('/'),
  reducerPath: 'APPLICATION_API',
  tagTypes: Object.values(apiTags),
  endpoints: () => ({})
})

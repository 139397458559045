import { UserPlanContent } from './interfaces'
import { getUsername } from '../../../helpers/cookies'
import { RootState } from '../../configureStore'
import { RequestLoadingStatus } from '../../interfaces'
import { get } from '../../../helpers/network'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { isError } from 'lodash'

export const getUserPlanThunk = createAsyncThunk<UserPlanContent, void, { state: RootState }>(
  'userPlan/requestStatus',
  async (_, { rejectWithValue }) => {
    const username = getUsername()
    const [userPlan, quota, subscriptions] = await Promise.all([
      get<UserPlanContent>(`/api/v1/users/user`),
      get<UserPlanContent>(`/api/v1/users/${username}/quota`),
      get<UserPlanContent>(`/api/v1/billing/users/${username}/subscriptions`)
    ])

    if (isError(userPlan) || isError(quota) || isError(subscriptions)) {
      return rejectWithValue('error')
    }

    return {
      ...userPlan.data,
      ...quota.data,
      subscriptions: subscriptions.data as UserPlanContent['subscriptions']
    }
  },
  {
    condition: (_, { getState }) => {
      const state = getState()
      const fetchStatus = state.resources.userPlan.loading

      if (fetchStatus === RequestLoadingStatus.FULFILLED || fetchStatus === RequestLoadingStatus.PENDING) {
        return false
      }
    }
  }
)

import { MainDisplay } from '../../containers/dashboard/MainDisplay'
import { useIsAiAreaEnabled, useRedirectIfPermissionDenied } from '../../permissions/areas'
import * as React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { CircularProgress, Stack } from '@mui/material'
import { AiModelDataSelection } from '@/containers/ai/dataSelection/AiModelDataSelection'
import { useGetModelQuery } from '@/store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { AiModelTrainingBreadcrumbs } from '@/containers/ai/breadcrumbs/AiModelTrainingBreadcrumbs'
import { AiSectionPaths, PagePath } from '@/constants'
import { AiModelDataSettings } from '@/containers/ai/dataSettings/AiModelDataSettings'
import { TrainingDataCart } from '@/containers/ai/trainingDataCart/TrainingDataCart'
import { useAppDispatch } from '@/store/hooks'
import { deselectAllItems } from '@/store/features/uiSlice/uiSlice'
import { useCanCreateNewModelVersion } from '@/operations/ai/models/versions/useCanCreateNewModelVersion'
import { useDashboardNavigate } from '@/hooks/useDashboardNavigate'

const CreateAiModelPage = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { navigate } = useDashboardNavigate()
  const [searchParams] = useSearchParams()
  const { modelUuid } = useParams<{ modelUuid: string }>()
  const { handleApiHookErrors } = useErrorHandler()
  const permissionAccess = useIsAiAreaEnabled()
  const [isLoading, hasAccess] = permissionAccess
  useRedirectIfPermissionDenied([permissionAccess])

  const {
    data: model,
    isLoading: isLoadingModel,
    isError: isErrorModel,
    error: errorModel
  } = useGetModelQuery({ modelUuid })

  const canCreateNewVersion = useCanCreateNewModelVersion(model?.uuid)

  React.useEffect(() => {
    if (isErrorModel) {
      handleApiHookErrors(errorModel)
    }
  }, [isErrorModel, errorModel])

  React.useEffect(() => {
    dispatch(deselectAllItems())
  }, [])

  if (isLoading || isLoadingModel) {
    return (
      <MainDisplay>
        <Stack alignItems="center" style={{ marginTop: 50 }}>
          <CircularProgress />
        </Stack>
      </MainDisplay>
    )
  }

  if (!hasAccess || (!isLoadingModel && isNil(model)) || !canCreateNewVersion) {
    navigate(PagePath.MY_PROJECTS)
  }

  return (
    <MainDisplay>
      <Stack direction="column" gap={8} pt={4}>
        <AiModelTrainingBreadcrumbs />
        <Stack direction="row" spacing={5} minHeight={600} justifyContent="space-between">
          {searchParams.get('page') === AiSectionPaths.dataSelection.value ? (
            <AiModelDataSelection model={model} />
          ) : (
            <AiModelDataSettings model={model} />
          )}
          <TrainingDataCart />
        </Stack>
      </Stack>
    </MainDisplay>
  )
}

export default CreateAiModelPage

import { UserQuotaRow } from './UserQuotaRow'
import { useGetOrganizationId } from '../../store/features/apis/slices/organization/hooks'
import { useGetOrganizationBalancesQuery } from '../../store/features/apis/slices/organization/organizationSlice'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

export const OrganizationQuotaWidget = (): React.ReactElement => {
  const { t } = useTranslation('productPlan')

  const organizationId = useGetOrganizationId()
  const {
    data: balances,
    isLoading,
    isFetching
  } = useGetOrganizationBalancesQuery({ organizationId }, { skip: isNil(organizationId) })

  return (
    <Stack gap={4} qa-attribute="organizational-quota-widget">
      <UserQuotaRow
        isLoading={isLoading || isFetching}
        title={t('organizationalCpuUsage')}
        balances={balances?.quotas.cpuSeconds}
      />
      <UserQuotaRow
        isLoading={isLoading || isFetching}
        title={t('organizationalGpuUsage')}
        balances={balances?.quotas.gpuSeconds}
      />
    </Stack>
  )
}

import { ApiVersion } from './../../../../store/features/apis/helpers'
import { MoveItem } from './interfaces'
import { Space } from '../../../../store/features/apis/slices/space/interfaces'
import { useAppDispatch } from '../../../../store/hooks'
import { FullProject } from '../../../../store/features/apis/slices/project/interfaces'
import { ResourceType } from '../../../../containers/detailsPanel/components/DetailsList'
import { useGetFolderDetails } from '../../../../store/features/apis/slices/folder/helpers'
import { useGetProjectV3Details } from '../../../../store/features/apis/slices/project/helpers'
import { useGetUserSpacesQuery } from '../../../../store/features/apis/slices/space/spacesSlice'
import { getProjectDetails } from '../../../../store/features/apis/slices/project/projectSlice'
import { useCurrentPath } from '../../../../hooks/useCurrentPath'
import { first, isEmpty, isNil } from 'lodash'
import { useEffect, useState } from 'react'

export const useAvailableSpacesToMoveElement = (items: MoveItem[]): [Space[], boolean] => {
  const dispatch = useAppDispatch()
  const { currentPath: currentFolderPath } = useCurrentPath()
  const { data: spaces } = useGetUserSpacesQuery()
  const currentSpaceId = first(currentFolderPath)

  const [availableSpaces, setAvailableSpaces] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const asyncFunction = async (): Promise<void> => {
      // Client logic, folders cannot be moved to other spaces
      const folders = items.filter(({ type }) => type === ResourceType.FOLDER)
      if (!isEmpty(folders)) {
        setAvailableSpaces(spaces.filter(({ spaceId }) => spaceId === currentSpaceId))
        setIsLoading(false)
        return
      }

      const projects = items.filter((item) => item.type === ResourceType.PROJECT)

      const projectDetails = await Promise.all(
        projects.map((moveItem) =>
          dispatch(getProjectDetails({ apiVersion: ApiVersion.V3, projectId: moveItem.id })).then(({ data }) => data)
        )
      )

      let availableSpaces = spaces.filter(({ requesterPermissions }) => requesterPermissions?.canMoveContentToRoot)

      const canMoveToPersonalSpace = projectDetails.every(
        ({ requesterPermissions }) => requesterPermissions?.canMoveProjectToPersonalSpace
      )
      if (!canMoveToPersonalSpace) {
        availableSpaces = availableSpaces.filter(({ spaceType }) => spaceType !== 'PERSONAL')
      }

      setAvailableSpaces(availableSpaces)
      setIsLoading(false)
    }

    asyncFunction()
  }, [items.map(({ id }) => id).join('-')])

  return [availableSpaces, isLoading]
}

export const useProjectById = (projectId: string): { project: FullProject; isLoading: boolean } => {
  const { data: project, isLoading } = useGetProjectV3Details({ projectId })
  return { project, isLoading }
}

export const useFirstSelectedItemName = (items: MoveItem[]): string => {
  if (isEmpty(items)) {
    return null
  }

  const isProjectSelection = items.every((item) => item?.type === ResourceType.PROJECT)
  const { currentPath: currentFolderPath } = useCurrentPath()

  const { data: firstSelectedFolder } = useGetFolderDetails(
    { spaceId: first(currentFolderPath), folderId: items[0].id },
    { skip: isProjectSelection }
  )

  const { data: firstSelectedProject } = useGetProjectV3Details(
    {
      projectId: items[0].id
    },
    { skip: !isProjectSelection }
  )

  // After a move action, the currentFolderPath is still targeting the moved element
  if (isNil(firstSelectedProject) && isNil(firstSelectedFolder)) {
    return null
  }

  return isProjectSelection ? firstSelectedProject.projectName : firstSelectedFolder.name
}

export const useParentInformation = (itemsToMove: MoveItem[]): { parentSpaceId: string; parentFolderId: string } => {
  const { type, id } = first(itemsToMove)
  const {
    currentPath: [parentSpaceId, ..._rest]
  } = useCurrentPath()

  // We can assume space and folder data are already fetched
  const { data: folder } = useGetFolderDetails(
    {
      spaceId: parentSpaceId,
      folderId: id
    },
    { skip: type !== ResourceType.FOLDER }
  )

  const { data: project } = useGetProjectV3Details(
    {
      projectId: id
    },
    { skip: type !== ResourceType.PROJECT }
  )

  return {
    parentFolderId: project?.parentFolderId ?? folder?.parentFolderId,
    parentSpaceId: project?.spaceId ?? folder?.spaceId
  }
}

import { TeamsSpaces } from './TeamSpaces'
import { PersonalSpace } from './PersonalSpace'
import { SharedWithMe } from './SharedWithMe'
import { PublicProjects } from './PublicProjects'
import { CollapseWrapper } from '../../components/elements/Collapse'
import { useGetUserSpacesQuery } from '../../store/features/apis/slices/space/spacesSlice'
import * as React from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'

export const SpacesSection = (): React.ReactElement => {
  const { t } = useTranslation()
  const { data: spaces, isLoading, isFetching, isError } = useGetUserSpacesQuery()
  if (isLoading || isError || isFetching) {
    return null
  }

  const personalSpace = spaces.find((space) => space.spaceType === 'PERSONAL')
  const teamSpaces = spaces.filter((space) => space.spaceType === 'TEAM')

  return (
    <CollapseWrapper
      collapseText={t('projects')}
      defaultCollapseValue={true}
      textTransform="uppercase"
      fontWeight={400}
      textColor="grey.700"
      collapsedIconRotationValue={90}
    >
      <Box qa-attribute="navigation-tree">
        <PersonalSpace path={[personalSpace.spaceId]} />
        <TeamsSpaces spaces={teamSpaces} />
        <SharedWithMe />
        <PublicProjects />
      </Box>
    </CollapseWrapper>
  )
}

import { DetailsPanelStyled, DetailsPanelTypes } from './GenericDetailsPanel'
import { ActionButtons } from './components/ActionButtons'
import { SimulationCards, ThumbnailProps } from './components/SimulationCard'
import { DetailListDataProps, DetailsList, ResourceType } from './components/DetailsList'
import { ActionsHistory } from './components/ActionsHistory'
import { TagData, Tags } from './components/Tags'
import { ProjectDescription } from './components/ProjectDescription'
import { CommentsTab } from './components/comments/CommentsTab'
import { Comments } from './components/comments/Comments'
import { useComments } from './components/comments/hooks'
import { SharedWith } from '../../components/share/SharedWith'
import useStateIfMounted from '../../hooks/useStateIfMounted'
import { TabPanel } from '../../components/TabPanel'
import { useErrorHandler } from '../../hooks/useErrorHandler'
import { useGetProjectV3Details } from '../../store/features/apis/slices/project/helpers'
import { getResolvedThumbnailFullServicePath } from '../../utils/helpers'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { useGetProjectResultsQuery } from '../../store/features/apis/slices/project/projectSlice'
import { selectSingleItem } from '../../store/features/uiSlice/uiSlice'
import { usePersonalSpaceId } from '../../store/features/apis/slices/space/hooks'
import { useIsSearchMode } from '../../hooks/useIsSearchMode'
import { ProjectAction } from '../../hooks/useActionModalHandler'
import { Box, CircularProgress, Stack, Tab, Tabs, Typography } from '@mui/material'
import * as React from 'react'
import { isEmpty, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import { useParams } from 'react-router-dom'

const ProjectDetailsPanel = ({ projectId: activeProjectId }: { projectId?: string }): React.ReactElement => {
  const dispatch = useAppDispatch()
  let { projectId } = useParams<{ projectId: string; action?: ProjectAction }>()
  if (!isNil(activeProjectId)) {
    projectId = activeProjectId
  }
  const { t } = useTranslation('detailsPanel')
  const isSearchMode = useIsSearchMode()
  const personalSpaceId = usePersonalSpaceId()
  const [detailsListData, setDetailsListData] = useStateIfMounted<DetailListDataProps[]>([])
  const [tagsData, setTagsData] = useStateIfMounted<TagData[]>([])
  const [resultThumbnails, setResultThumbnails] = useStateIfMounted<ThumbnailProps[]>([])
  const [selectedTabValue, setSelectedTabValue] = React.useState<number>(0)
  const [commentsPage, setCommentsPage] = React.useState(1)
  const { handleApiHookErrors, handleNotFound } = useErrorHandler()
  const latestCopiedProject = useAppSelector((state) => state.ui.latestCopiedProject)

  const { data: project, ...projectQuery } = useGetProjectV3Details({ projectId })

  const { data: projectResults, ...projectResultsQuery } = useGetProjectResultsQuery(
    { ownerName: project?.ownerName, publicProjectName: project?.publicProjectName },
    { skip: isNil(project?.ownerName) && isNil(project?.publicProjectName) }
  )

  const { comments, totalNumberOfComments, isLoading } = useComments({
    publicProjectName: project?.publicProjectName,
    ownerName: project?.ownerName,
    commentsPage
  })

  React.useEffect(() => {
    if (!projectQuery.isLoading && (projectQuery.isError || isNil(project))) {
      handleNotFound()
    }
  }, [project, projectQuery.isError])

  React.useEffect(() => {
    if (projectResultsQuery.isError) {
      handleApiHookErrors(projectResultsQuery.error)
    }
  }, [projectResultsQuery.isError, projectResultsQuery.error])

  React.useEffect(() => {
    setDetailsListData([])
    setTagsData([])
    setResultThumbnails([])
    setCommentsPage(1)
  }, [projectId])

  React.useEffect(() => {
    if (!projectQuery.isLoading) {
      if (projectQuery.isSuccess) {
        dispatch(selectSingleItem({ id: projectId, type: 'project' }))

        setDetailsListData([
          {
            name: t('owner', 'Owner'),
            value: project.ownerName,
            href: `/projects/${project.ownerName}/?viewMode=thumbView&sortBy=project_popular`
          },
          { name: t('created', 'Created'), value: format(new Date(project.creationDate), 'MMM d, yyyy') },
          {
            name: t('lastModified', 'Last modified'),
            value: format(new Date(project.lastModificationDate), 'MMM d, yyyy')
          },
          {
            name: t('statistics', 'Statistics'),
            value: {
              geometry: project.numberOfGeometries,
              mesh: project.numberOfMeshes,
              simulation: project.numberOfSimulations,
              simulationRun: project.numberOfSimulationRuns
            }
          }
        ])
        setTagsData(project.tags?.map((tag) => ({ name: tag })) || [])
      }
    }
  }, [project, projectQuery.isSuccess, projectQuery.isLoading])

  React.useEffect(() => {
    if (!projectResultsQuery.isFetching) {
      if (projectResultsQuery.isSuccess) {
        const results = [...projectResults].reverse()
        if (!isNil(results)) {
          const thumbnails = results.map((result) => {
            if (result?.thumbnailUrl) {
              return {
                imageUrl: getResolvedThumbnailFullServicePath(result.thumbnailUrl)
              }
            }
          })
          setResultThumbnails(thumbnails.filter(Boolean))
        }
      }
    }
  }, [projectResults, projectResultsQuery.isSuccess, projectResultsQuery.isFetching])

  const projectThumbnail = React.useMemo(() => {
    let thumbnailUrl = project?.thumbnailUrl
    if (!projectQuery.isLoading && projectQuery.isSuccess && isNil(project?.thumbnailUrl)) {
      if (project.projectIdExt === latestCopiedProject?.projectIdExt) {
        thumbnailUrl = latestCopiedProject?.thumbnailUrl
      } else if (!isEmpty(resultThumbnails)) {
        thumbnailUrl = resultThumbnails[0].imageUrl
      }
    }
    return {
      imageUrl: getResolvedThumbnailFullServicePath(thumbnailUrl)
    }
  }, [
    project,
    projectQuery.isSuccess,
    projectQuery.isLoading,
    resultThumbnails,
    latestCopiedProject,
    personalSpaceId,
    isSearchMode
  ])

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number): void => {
    setSelectedTabValue(newValue)
  }

  const handleCommentsPageChange = React.useCallback((page: number) => {
    setCommentsPage(page)
  }, [])

  // Web accessibility props
  const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
    return {
      id: `comments-tab-${index}`,
      'aria-controls': `project-details-tabpanel-${index}`
    }
  }

  if (projectQuery.isLoading) {
    return (
      <Box qa-attribute="project-details-panel">
        <Stack alignItems="center" style={{ marginTop: 50 }}>
          <CircularProgress />
        </Stack>
      </Box>
    )
  }

  if (!projectQuery.isSuccess) {
    return null
  }

  return (
    <Box qa-attribute="project-details-panel">
      <ActionButtons panelType={DetailsPanelTypes.PROJECT_DETAILS} projectData={project} />
      <Typography
        variant="title"
        color="text.primary"
        paragraph
        sx={{ whiteSpace: 'normal', wordBreak: 'break-word', hyphens: 'auto' }}
      >
        {project?.projectName}
      </Typography>
      <Box sx={{ my: 5, borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTabValue} onChange={handleTabChange}>
          <Tab label={t('Details')} {...a11yProps(0)} />
          <CommentsTab totalNumberOfComments={totalNumberOfComments} {...a11yProps(1)} />
          <Tab label={t('history')} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTabValue} tabIndex={0}>
        <>
          <SimulationCards
            panelType={DetailsPanelTypes.PROJECT_DETAILS}
            projectThumbnail={projectThumbnail}
            resultThumbnails={resultThumbnails}
          />
          <SharedWith projectData={project} />
          <DetailsList resourceType={ResourceType.PROJECT} detailsListData={detailsListData} />
          <ProjectDescription description={project?.description} />
          <Tags tagsData={tagsData} />
        </>
      </TabPanel>
      <TabPanel value={selectedTabValue} tabIndex={1}>
        <Comments
          comments={comments}
          totalNumberOfComments={totalNumberOfComments}
          isLoading={isLoading}
          page={commentsPage}
          handlePageChange={handleCommentsPageChange}
        />
      </TabPanel>
      <TabPanel value={selectedTabValue} tabIndex={2}>
        <ActionsHistory project={project} />
      </TabPanel>
    </Box>
  )
}

const ProjectDetailsPanelDelayed = (): React.ReactElement => {
  return (
    <DetailsPanelStyled>
      <ProjectDetailsPanel />
    </DetailsPanelStyled>
  )
}

export default ProjectDetailsPanelDelayed

import { useJobsStatusMap } from './helpers'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const StatusLabel = ({ value }: { value: string }): React.ReactElement => {
  const jobsStatusMap = useJobsStatusMap()
  const { t } = useTranslation(['profileArea'])

  return (
    <Stack
      display="inline-flex"
      direction="row"
      alignItems="center"
      gap={1}
      style={{ color: jobsStatusMap[value]?.color }}
      className="jobStatus"
      fontWeight="bold"
    >
      {t(`profile.usageTable.jobStatus.${value}`)}
    </Stack>
  )
}

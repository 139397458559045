import theme from '../../../theme/defaultTheme'
import { useAppSelector } from '../../../store/hooks'
import { getCurrentProjectPage } from '../../../store/features/uiSlice/uiSlice'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import { PagePath } from '../../../constants'
import { Alert, Typography } from '@mui/material'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

export const NoSearchResultsWarning = (): React.ReactElement => {
  const { t } = useTranslation()
  const currentProjectPage = useAppSelector(getCurrentProjectPage)
  const isInFirstPage = currentProjectPage === 1
  const [searchParams] = useSearchParams()
  const { currentPath, isLoading } = useCurrentPath()
  const searchSection = searchParams.get('section')

  if (isLoading || isEmpty(currentPath)) {
    return null
  }

  const pageTitle = React.useMemo(() => {
    if (searchSection === PagePath.MY_PROJECTS) {
      return t('myProjects')
    } else if (searchSection === PagePath.SHARED_WITH_ME) {
      return t('sharedWithMe')
    } else {
      return t('all')
    }
  }, [searchSection])

  return (
    pageTitle && (
      <Alert icon={false} severity="info">
        <Typography variant="text" color={theme.palette.text.primary}>
          {t(isInFirstPage ? 'noResultsForSearch' : 'noProjectsOnThisPage', {
            pageTitle,
            searchString: searchParams.get('q')
          })}
        </Typography>
      </Alert>
    )
  )
}

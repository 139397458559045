import { InternalDistribution } from '../InternalDistribution'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import { isNil } from 'lodash'
import Skeleton from '@mui/material/Skeleton'
import { useTranslation } from 'react-i18next'
import { SidebarCard } from '@/components/aiModels/cards/SidebarCard'
import { SidebarCardSection } from '@/components/aiModels/cards/SidebarCardSection'
import { User } from '@/components/user/User'
import { AvatarImageSize } from '@/components/avatar/Avatar'
import { Model } from '@/store/features/apis/slices/ai/interfaces'
import { useGetUserByIdQuery } from '@/store/features/apis/slices/user/userSlice'

const SectionSkeleton = (): React.ReactElement => (
  <Skeleton variant="rounded" width="100%" height={54} animation="wave" />
)

export const ModelAccess = ({
  model,
  isLoading: isLoadingModel
}: {
  model?: Model
  isLoading?: boolean
}): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])

  // TODO: Add button to manage access. It still in discussion if it will be included in the MVP. https://simscale.atlassian.net/browse/ENG-69195

  const { data: user, isLoading: isLoadingUser } = useGetUserByIdQuery(
    { uidExt: model?.creator_user_id },
    { skip: isNil(model) }
  )
  const isLoading = isLoadingModel || isLoadingUser
  const showSkeleton = isLoading || isNil(model)

  return (
    <SidebarCard title={t('modelDetails.access')} isLoading={isLoading} qa-attribute="aiModel--section--access">
      <Stack spacing={4}>
        {showSkeleton ? (
          <SectionSkeleton />
        ) : (
          <SidebarCardSection title={t('modelDetails.owner')}>
            <User
              username={user.username}
              firstName={user.firstname}
              lastName={user.lastname}
              avatarImageSize={AvatarImageSize.MEDIUM}
            />
          </SidebarCardSection>
        )}
        {/* Edit Rights is not supported yet by BE, it's still in discussion on how it will be implemented, no ticket yet. */}
        {/* <SidebarCardSection title={t('modelDetails.editRights')}>
          <Typography variant="textV2">Not Shared [mock data]</Typography>
        </SidebarCardSection> */}
        {showSkeleton ? (
          <SectionSkeleton />
        ) : (
          <SidebarCardSection title={t('modelDetails.internalDistribution')}>
            <InternalDistribution model={model} />
          </SidebarCardSection>
        )}
      </Stack>
    </SidebarCard>
  )
}

import { TableHeaderCell, TableRowProps } from '../../../../components/table/enhancedTable/interfaces'
import { Button } from '../../../../components/button/Button'
import { useAppDispatch } from '../../../../store/hooks'
import { openModal } from '../../../../store/features/uiSlice/uiSlice'
import { ModalTemplate } from '../../../../store/features/uiSlice/interfaces'
import { FormattedDate } from '../../../../components/FormattedDate'
import { Invite, InviteStatus } from '../../../../store/features/apis/slices/invitation/interfaces'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import format from 'date-fns/format'
import { Box, Typography } from '@mui/material'
import { SpriteIcon } from '@/assets/SpriteIcon'

export const useGetMyInvitationsTableHeaderData = (): TableHeaderCell[] => {
  const { t } = useTranslation(['profileArea'])

  return [
    {
      id: 'organizationName',
      label: t('myInvitations.labels.organizationName'),
      numeric: false,
      disablePadding: true,
      sx: { width: '45%' }
    },
    {
      id: 'status',
      label: t('myInvitations.labels.status'),
      numeric: false,
      disablePadding: true,
      sx: { width: '25%' }
    },
    {
      id: 'expiresAt',
      label: t('myInvitations.labels.expiration'),
      numeric: false,
      disablePadding: true,
      sx: { width: '15%' }
    },
    {
      id: 'actions',
      label: '',
      numeric: false,
      disablePadding: true,
      disableSorting: true,
      sx: { width: 'auto', textAlign: 'right' }
    }
  ]
}

export const useGetMyInvitationsTableBodyData = (invitations: Invite[]): TableRowProps[] => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['profileArea'])

  const handleAcceptInvitationClick = React.useCallback((invitation: Invite) => {
    dispatch(
      openModal({
        template: ModalTemplate.ACCEPT_INVITATION,
        showCloseButton: true,
        modalProps: {
          title: t('acceptInvitation.title', { ns: 'modals' }),
          inviteId: invitation.inviteId,
          organizationName: invitation.organizationName
        }
      })
    )
  }, [])

  const tableData = React.useMemo(() => {
    if (!isNil(invitations)) {
      return invitations.map((invitation: Invite) => {
        const isExpired = new Date(invitation.expiresAt) < new Date() || invitation.status === InviteStatus.EXPIRED
        const textColor = isExpired ? 'text.disabled' : 'text.primary'

        return {
          tableRowId: `${invitation.inviteId}`,
          tableRowCells: [
            {
              id: `${invitation.inviteId}-organizationName`,
              value: invitation.organizationName,
              component: (
                <Typography variant="text" color={textColor}>
                  {invitation.organizationName}
                </Typography>
              )
            },
            {
              id: `${invitation.inviteId}-status`,
              value: invitation.status,
              component: (
                <Typography variant="text" color={textColor}>
                  {!isNil(invitation.status)
                    ? t(`myInvitations.status.${invitation.status}`)
                    : t('myInvitations.status.EXPIRED')}
                </Typography>
              )
            },
            {
              id: `${invitation.inviteId}-expiresAt`,
              value: invitation.expiresAt,
              component: <FormattedDate date={new Date(invitation.expiresAt)} disabled={isExpired} showTime />,
              formattedValue: format(new Date(invitation.expiresAt), 'yyyy-MM-dd HH:mm:ss')
            },
            {
              id: `${invitation.inviteId}-actions`,
              value: '',
              component: (
                <Box textAlign="right">
                  {invitation.status === InviteStatus.PENDING_USER_CONFIRMATION && !isExpired && (
                    <Button
                      startIcon={<SpriteIcon spriteId="check" height=".8125rem" width=".8125rem" />}
                      variant="contained"
                      color="primary"
                      onClick={(): void => handleAcceptInvitationClick(invitation)}
                    >
                      {t('myInvitations.buttons.acceptInvitation')}
                    </Button>
                  )}
                </Box>
              )
            }
          ]
        }
      })
    }
  }, [invitations])

  return tableData ?? []
}

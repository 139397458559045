import { TypographyOptions } from '@mui/material/styles/createTypography'
import React from 'react'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties
    titleLight: React.CSSProperties
    subtitle: React.CSSProperties
    textLight: React.CSSProperties
    text: React.CSSProperties
    textBold: React.CSSProperties
    textBoldWithEllipsis: React.CSSProperties
    textItalicWithEllipsis: React.CSSProperties
    textSmall: React.CSSProperties
    textSmallV2: React.CSSProperties
    fontAwesomeIcon: React.CSSProperties
    fontAwesomeIconBold: React.CSSProperties
    pageTitle: React.CSSProperties
    newPageTitle: React.CSSProperties
    tableTitle: React.CSSProperties
    summaryCardCounter: React.CSSProperties
    summaryCardCounterMax: React.CSSProperties
    summaryCardCounterTotal: React.CSSProperties
    summaryCardDescription: React.CSSProperties
    summaryCardDescriptionBold: React.CSSProperties
    toolbarTitle: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties
    titleLight?: React.CSSProperties
    subtitle?: React.CSSProperties
    textLight?: React.CSSProperties
    text?: React.CSSProperties
    textV2?: React.CSSProperties
    textBold?: React.CSSProperties
    textBoldWithEllipsis?: React.CSSProperties
    textItalicWithEllipsis?: React.CSSProperties
    textSmall?: React.CSSProperties
    textSmallV2?: React.CSSProperties
    fontAwesomeIcon?: React.CSSProperties
    fontAwesomeIconBold?: React.CSSProperties
    pageTitle?: React.CSSProperties
    newPageTitle?: React.CSSProperties
    tableTitle?: React.CSSProperties
    summaryCardCounter?: React.CSSProperties
    summaryCardCounterMax?: React.CSSProperties
    summaryCardCounterTotal?: React.CSSProperties
    summaryCardDescription?: React.CSSProperties
    summaryCardDescriptionBold?: React.CSSProperties
    toolbarTitle?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true
    titleLight: true
    subtitle: true
    textLight: true
    text: true
    textV2: true
    textBold: true
    textBoldWithEllipsis: true
    textItalicWithEllipsis: true
    textSmall: true
    textSmallV2: true
    fontAwesomeIcon: true
    fontAwesomeIconBold: true
    pageTitle: true
    newPageTitle: true
    tableTitle: true
    summaryCardCounter: true
    summaryCardCounterMax: true
    summaryCardCounterTotal: true
    summaryCardDescription: true
    summaryCardDescriptionBold: true
    toolbarTitle: true
  }
}

export const defaultThemeTypographyOptions: TypographyOptions = {
  fontFamily: 'Lato, sans-serif',
  fontSize: 12,
  button: {
    textTransform: 'none'
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '.9375rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  titleLight: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.9375rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '.75rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    overflowY: 'clip'
  },
  textLight: {
    fontWeight: 400,
    fontSize: '.625rem',
    lineHeight: '1.2em'
  },
  text: {
    fontWeight: 400,
    fontSize: '.8125rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  // TODO: New default size will be 14px, so we need to update all the components that use the old "text" variant to use the new "textV2" variant. When all components are updated, we can rename "textV2" to "text" and remove the old "text" variant.
  textV2: {
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  textBold: {
    fontWeight: 700,
    fontSize: '.8125rem',
    lineHeight: '1.2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textBoldWithEllipsis: {
    fontWeight: 700,
    fontSize: '.8125rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  textItalicWithEllipsis: {
    fontWeight: 400,
    fontSize: '.8125rem',
    lineHeight: '1.2em',
    fontStyle: 'italic',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  textSmall: {
    fontWeight: 400,
    fontSize: 9
  },
  // TODO: New default size will be 14px, so we need to update all the components that use the old "text" variant to use the new "textV2" variant. When all components are updated, we can rename "textV2" to "text" and remove the old "text" variant.
  textSmallV2: {
    fontWeight: 400,
    fontSize: '.8125rem',
    lineHeight: '1.2em',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  fontAwesomeIcon: {
    fontWeight: 400,
    fontSize: '.8125rem'
  },
  fontAwesomeIconBold: {
    fontWeight: 700,
    fontSize: '.6875rem'
  },
  h1: {
    fontWeight: 400,
    fontSize: '1.8125rem',
    lineHeight: '1.2em'
  },
  h2: {
    fontWeight: 400,
    fontSize: '.875rem',
    lineHeight: '1.2em'
  },
  h4: {
    fontWeight: 400,
    fontSize: '1.05rem',
    lineHeight: '1.2em'
  },
  newPageTitle: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '28.8px'
  },
  pageTitle: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.125rem',
    lineHeight: '2.667em',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableTitle: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: '1.2em'
  },
  summaryCardCounter: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.3125
  },
  summaryCardCounterMax: {
    fontWeight: 700,
    fontSize: '.8125rem',
    lineHeight: 1.2
  },
  summaryCardCounterTotal: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: 1.05
  },
  summaryCardDescription: {
    fontWeight: 400,
    fontSize: '.8125rem',
    lineHeight: 1.2
  },
  summaryCardDescriptionBold: {
    fontWeight: 700,
    fontSize: '.8125rem',
    lineHeight: 1.2
  },
  toolbarTitle: {
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '40px'
  },
  overline: {
    fontSize: '.725rem',
    fontWeight: 400,
    lineHeight: '1.2em',
    letterSpacing: '0.05em'
  }
}

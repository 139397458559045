import { isString } from 'lodash'
import * as React from 'react'

export const getQaSelectorText = (item: string | React.ReactNode): string => {
  if (isString(item)) {
    return item.replaceAll(' ', '-').toLowerCase()
  } else if (React.isValidElement(item)) {
    return item?.props?.children?.replaceAll(' ', '-').toLowerCase() ?? 'unknown'
  } else {
    return 'unknown'
  }
}

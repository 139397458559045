import { PeriodType } from './interfaces'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const usePeriodOptions = (): {
  value: PeriodType
  label: string
}[] => {
  const { t } = useTranslation(['modals'])

  return useMemo(
    () => [
      {
        value: PeriodType.unlimited,
        label: t('project.sharingDurationActions.unlimited')
      },
      {
        value: PeriodType.oneYearDuration,
        label: t('project.sharingDurationActions.oneYearDuration')
      },
      {
        value: PeriodType.sixMonthsDuration,
        label: t('project.sharingDurationActions.sixMonthsDuration')
      },
      { value: PeriodType.threeMonthsDuration, label: t('project.sharingDurationActions.threeMonthsDuration') },
      {
        value: PeriodType.oneMonthDuration,
        label: t('project.sharingDurationActions.oneMonthDuration')
      },
      {
        value: PeriodType.twoWeeksDuration,
        label: t('project.sharingDurationActions.twoWeeksDuration')
      },
      {
        value: PeriodType.oneWeekDuration,
        label: t('project.sharingDurationActions.oneWeekDuration')
      }
    ],
    []
  )
}

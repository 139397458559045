import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useDeleteUserApiKeyMutation } from '../../../../store/features/apis/slices/user/userSlice'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

interface TrashUserApiKeyTemplateProps extends ModalTemplateProps {
  title: string
  selectedApiKeySuffix: string
}

export const TrashUserApiKeyTemplate = ({
  title,
  onClose,
  showCloseButton,
  selectedApiKeySuffix
}: TrashUserApiKeyTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const [deleteApiKey] = useDeleteUserApiKeyMutation()

  const handleTrashOnClick = React.useCallback((): void => {
    deleteApiKey({ apiKeySuffix: selectedApiKeySuffix })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t(`trash.apiKey.success`)
          })
        )
      })
      .catch(() => {
        dispatch(
          addSnackbarToState({
            severity: 'error',
            message: t(`trash.apiKey.failed`)
          })
        )
      })
      .finally(() => onClose())
  }, [])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleTrashOnClick}>
        <Typography variant="text" color="grey.700">
          <Trans
            i18nKey="trash.apiKey.areYouSureYouWantToDeleteApiKey"
            t={t}
            values={{ apiKeySuffix: selectedApiKeySuffix }}
          />
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('trash.cancel')}
        </Button>
        <Button onClick={handleTrashOnClick} color="primary" variant="contained">
          {t('trash.trash')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

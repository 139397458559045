import Box from '@mui/material/Box'
import { styled } from '@mui/system'

export const CardName = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<{ selected?: boolean }>({
  width: '100%',
  fontSize: '.875rem',
  fontWeight: 400,
  lineHeight: '1.2',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
})

import { useValidation } from './useValidation'
import {
  PermissionType,
  Scope,
  ScopeType,
  SpacePermission,
  SpacePermissions
} from '../components/modal/templates/share/interfaces'
import { isValidEmail } from '../helpers/validationHelpers'

interface PermissionPayload {
  getScope: (scope: ScopeType, usernameOrEmail: string) => Scope
  getPermissions: (
    usernamesOrEmails: string[],
    selectedPermission: PermissionType,
    shareMessage?: string,
    notifyByEmail?: boolean
  ) => SpacePermissions
  getPermission: (
    usernameOrEmail: string,
    selectedPermission: PermissionType,
    shareMessage?: string,
    notifyByEmail?: boolean
  ) => SpacePermission
}

export const usePermissionsPayload = (): PermissionPayload => {
  const { validateField } = useValidation()

  const getScope = (scope: ScopeType, usernameOrEmail: string): Scope => {
    const scopeObject: Scope = { scopeType: scope }
    const isEmail = validateField(usernameOrEmail, [isValidEmail]).valid
    if (isEmail) {
      scopeObject.email = usernameOrEmail
    } else {
      scopeObject.username = usernameOrEmail
    }
    return scopeObject
  }

  const getPermission = (
    usernameOrEmail: string,
    selectedPermission: PermissionType,
    shareMessage?: string,
    notifyByEmail?: boolean
  ): SpacePermission => {
    return {
      scope: getScope(ScopeType.USER, usernameOrEmail),
      permission: selectedPermission,
      message: shareMessage,
      notifyByEmail: notifyByEmail
    }
  }

  const getPermissions = (
    usernamesOrEmails: string[],
    selectedPermission: PermissionType,
    shareMessage?: string,
    notifyByEmail?: boolean
  ): SpacePermissions => {
    const permissionsData = usernamesOrEmails.map((usernameOrEmail) => {
      return getPermission(usernameOrEmail, selectedPermission, shareMessage, notifyByEmail)
    })
    return {
      permissions: permissionsData
    }
  }

  return {
    getScope,
    getPermission,
    getPermissions
  }
}

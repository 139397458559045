import { useErrorHandler } from './useErrorHandler'
import { useAttributes } from './newWorld'
import { canEditProject } from '../operations/project/editProjectOperation'
import { FullProject } from '../store/features/apis/slices/project/interfaces'
import { ModalTemplate } from '../store/features/uiSlice/interfaces'
import { closeAllActionModals, openModal } from '../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../store/hooks'
import { canCopyProject } from '../operations/project/copyProjectOperation'
import { canDeleteProject } from '../operations/project/deleteProjectOperation'
import { canShareProject } from '../operations/project/operations'
import { useTranslation } from 'react-i18next'
import * as React from 'react'

type ShowActionModalFn = (action: ProjectAction, project: FullProject) => void

type UseActionModalHandlerHook = () => {
  showActionModal: ShowActionModalFn
}

export enum ProjectAction {
  EDIT = 'edit',
  MOVE = 'move',
  DELETE = 'delete',
  COPY = 'copy',
  SHARE = 'share'
}

export const RoutedActionModalTemplates = [
  ModalTemplate.EDIT_PROJECT,
  ModalTemplate.COPY_PROJECT,
  ModalTemplate.TRASH_PROJECT,
  ModalTemplate.SHARE
]

export const useActionModalHandler: UseActionModalHandlerHook = () => {
  const { handleNotFound } = useErrorHandler()
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const { data: attributes, isLoading } = useAttributes()

  const showActionModal = React.useCallback(
    (action: ProjectAction, project: FullProject): void => {
      if (!isLoading) {
        dispatch(closeAllActionModals())
        if (action === ProjectAction.EDIT) {
          if (!canEditProject(project)) {
            handleNotFound()
          } else {
            dispatch(
              openModal({
                template: ModalTemplate.EDIT_PROJECT,
                modalProps: { title: t('project.editTitle', { ns: 'modals' }), projectIdExt: project.projectIdExt },
                showCloseButton: true
              })
            )
          }
        }
        if (action === ProjectAction.COPY) {
          if (!canCopyProject(project, attributes)) {
            handleNotFound()
          } else {
            dispatch(
              openModal({
                template: ModalTemplate.COPY_PROJECT,
                modalProps: {
                  title: t('copyProject.title', { ns: 'modals' }),
                  selectedProjectIdExt: project.projectIdExt,
                  selectedProjectName: project.projectName
                },
                showCloseButton: true
              })
            )
          }
        }
        if (action === ProjectAction.DELETE) {
          if (!canDeleteProject(project)) {
            handleNotFound()
          } else {
            dispatch(
              openModal({
                template: ModalTemplate.TRASH_PROJECT,
                modalProps: {
                  title: t('trash.project.title', { ns: 'modals' }),
                  projectIdExt: project.projectIdExt,
                  projectName: project.projectName,
                  publicProjectName: project.publicProjectName,
                  projectOwnerName: project.ownerName
                }
              })
            )
          }
        }
        if (action === ProjectAction.SHARE) {
          if (!canShareProject(project)) {
            handleNotFound()
          } else {
            dispatch(
              openModal({
                template: ModalTemplate.SHARE,
                showCloseButton: true,
                modalProps: {
                  ownerName: project.ownerName,
                  projectIdExt: project.projectIdExt,
                  publicProjectName: project.publicProjectName,
                  projectName: project.projectName,
                  requesterPermissions: project.requesterPermissions,
                  publicPermission: project.publicPermission
                }
              })
            )
          }
        }
      }
    },
    [dispatch, handleNotFound, attributes, t, isLoading]
  )

  return { showActionModal }
}

import { DetailsPanelTypes } from './GenericDetailsPanel'
import { ActionButtons } from './components/ActionButtons'
import { FolderDetailsPanel } from './FolderDetailsPanel'
import { SpaceDetailsPanel } from './SpaceDetailsPanel'
import { get } from '../../helpers/network'
import useStateIfMounted from '../../hooks/useStateIfMounted'
import { FullProject } from '../../store/features/apis/slices/project/interfaces'
import { getResolvedThumbnailFullServicePath } from '../../utils/helpers'
import { useMultiSelect } from '../../hooks/useMultiSelect'
import { useCurrentPath } from '../../hooks/useCurrentPath'
import { Box, Typography, styled } from '@mui/material'
import * as React from 'react'
import { first, isEqual, isError, isNil } from 'lodash'

const imgFolderSimulationCard = require('../../assets/svg/folder-simulation-card.svg')
const imgSimulationDemo = require('../../assets/png/no-preview.png')

const MultiSelectionThumbnailWrapperStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'numberOfItems'
})<{ numberOfItems: number }>(({ theme, numberOfItems }) => {
  const distanceBetweenCards = 9
  const cardsMultiplier = numberOfItems > 2 ? 2 : 1
  const cardWidth = 255 - distanceBetweenCards * cardsMultiplier
  const cardHeight = 200 - distanceBetweenCards * cardsMultiplier

  return {
    position: 'relative',
    width: cardWidth,
    height: cardHeight,
    marginTop: 20,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 9,
      left: 9,
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.common.white,
      backgroundImage: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.action.hover} 100%)`,
      border: `1px solid ${theme.palette.grey['200']}`,
      zIndex: 1,
      borderRadius: 4
    },
    '&::after': {
      top: 17,
      left: 17,
      zIndex: 0,
      display: cardsMultiplier === 2 ? 'block' : 'none'
    }
  }
})

const MultiSelectionThumbnailStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'imageUrl' && prop !== 'isFolder'
})<{ imageUrl: string; isFolder?: boolean }>(({ theme, imageUrl, isFolder }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.white,
  backgroundImage: `url(${imageUrl}), linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.action.hover} 100%)`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: isFolder ? 'initial' : 'cover',
  border: `1px solid ${theme.palette.grey['200']}`,
  borderRadius: 4,
  zIndex: 2
}))

export const MultiSelectionDetails = (): React.ReactElement => {
  const { selectedItems } = useMultiSelect()
  const [imageUrl, setImageUrl] = useStateIfMounted<string>('')
  const [firstIsFolder, setFirstIsFolder] = useStateIfMounted<boolean>(false)
  const { currentPath } = useCurrentPath()
  const previousSelectedItems = React.useRef({})

  const totalItems = selectedItems.folders?.length + selectedItems.projects?.length + selectedItems.spaces?.length
  const title = `${totalItems} items selected`

  const loadProjectThumbnail = React.useCallback(async (project: string): Promise<void> => {
    const projectResponse = await get<FullProject>(`/api/v3/projects/${project}`)
    if (!isError(projectResponse)) {
      setImageUrl(getResolvedThumbnailFullServicePath(projectResponse.data.thumbnailUrl) ?? imgSimulationDemo)
    }
  }, [])

  React.useEffect(() => {
    previousSelectedItems.current = selectedItems
    const folderOrSpaceSelected = selectedItems.folders.length > 0 || selectedItems.spaces.length > 0
    setImageUrl(folderOrSpaceSelected ? imgFolderSimulationCard : imgSimulationDemo)
    setFirstIsFolder(folderOrSpaceSelected)

    const [firstSelectedProject] = selectedItems.projects
    if (!isNil(firstSelectedProject) && !folderOrSpaceSelected) {
      loadProjectThumbnail(firstSelectedProject)
    }
  }, [!isEqual(selectedItems, previousSelectedItems.current)])

  const isSingleFolderSelected =
    selectedItems.folders.length === 1 && selectedItems.projects.length === 0 && selectedItems.spaces.length === 0
  if (isSingleFolderSelected) {
    const folderId = selectedItems.folders[0]
    const spaceId = first(currentPath)
    return <FolderDetailsPanel folderId={folderId} spaceId={spaceId} />
  }

  const isSingleSpaceSelected =
    selectedItems.spaces.length === 1 && selectedItems.projects.length === 0 && selectedItems.folders.length === 0
  if (isSingleSpaceSelected) {
    const spaceId = selectedItems.spaces[0]
    return <SpaceDetailsPanel spaceId={spaceId} />
  }

  return (
    <Box qa-attribute="multi-selection-details-panel">
      <ActionButtons panelType={DetailsPanelTypes.MULTI_SELECTION_DETAILS} />
      <Typography variant="title" color="text.primary" paragraph>
        {title}
      </Typography>
      <MultiSelectionThumbnailWrapperStyled numberOfItems={totalItems}>
        <MultiSelectionThumbnailStyled imageUrl={imageUrl} isFolder={firstIsFolder} />
      </MultiSelectionThumbnailWrapperStyled>
    </Box>
  )
}

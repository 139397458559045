import { ResourceType } from './DetailsList'
import { DetailsPanelTypes } from '../GenericDetailsPanel'
import { MoveItem } from '../../../components/modal/templates/moveProject/interfaces'
import { useMultiSelect } from '../../../hooks/useMultiSelect'
import { ActionButton } from '../../../components/button/ActionButton'
import { FullProject } from '../../../store/features/apis/slices/project/interfaces'
import { OpenProjectButton } from '../../SmartButtons/OpenProjectButton'
import { ShareButton } from '../../SmartButtons/ShareButton'
import { useMoveOperation } from '../../../operations/commons/moveOperation'
import { useDeleteFolderOperation } from '../../../operations/folder/deleteFolderOperation'
import { useEditFolderOperation } from '../../../operations/folder/editFolderOperation'
import { useDeleteProjectOperation } from '../../../operations/project/deleteProjectOperation'
import { useEditProjectOperation } from '../../../operations/project/editProjectOperation'
import { useCopyProjectOperation } from '../../../operations/project/copyProjectOperation'
import { useIsSearchMode } from '../../../hooks/useIsSearchMode'
import { useIsSharedWithMePage } from '../../../hooks/useIsSharedWithMePage'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import * as React from 'react'
import { styled } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'

const ActionButtonsStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: '4px',
  marginBottom: theme.spacing(4)
}))

interface ActionButtonsProps {
  panelType: DetailsPanelTypes
  projectData?: FullProject
  folderId?: string
}

interface DisplayButtonsType {
  [key: string]: string[]
}

enum ActionButtonType {
  OPEN = 'open',
  SHARE = 'share',
  COPY = 'copy',
  MOVE = 'move',
  EDIT = 'edit',
  TRASH = 'trash'
}

export const ActionButtons = ({ panelType, projectData, folderId }: ActionButtonsProps): React.ReactElement => {
  const { t } = useTranslation(['modals', 'tooltips'])
  const { selectedItems } = useMultiSelect()
  const { currentPathNode } = useCurrentPath()

  const isSearchMode = useIsSearchMode()
  const isSharedWithMePage = useIsSharedWithMePage()

  const [deleteFolder, canDeleteFolder] = useDeleteFolderOperation(folderId)
  const [editFolder, canEditFolder] = useEditFolderOperation(folderId)

  const [moveItems, canMoveItems] = useMoveOperation()

  const [copyProject, canCopyProject] = useCopyProjectOperation(projectData?.projectIdExt)
  const [editProject, canEditProject] = useEditProjectOperation(projectData?.projectIdExt)
  const [deleteProject, canDeleteProject] = useDeleteProjectOperation(projectData?.projectIdExt)

  const displayButtons: DisplayButtonsType = {
    [DetailsPanelTypes.FOLDER_DETAILS]: [ActionButtonType.MOVE, ActionButtonType.EDIT, ActionButtonType.TRASH],
    [DetailsPanelTypes.PROJECT_DETAILS]: [
      ActionButtonType.OPEN,
      ActionButtonType.SHARE,
      ActionButtonType.COPY,
      ActionButtonType.MOVE,
      ActionButtonType.EDIT,
      ActionButtonType.TRASH
    ],
    [DetailsPanelTypes.MULTI_SELECTION_DETAILS]: [...(!isSearchMode ? [ActionButtonType.MOVE] : [])],
    [DetailsPanelTypes.LOCATION_DETAILS]: [],
    [DetailsPanelTypes.SEARCH_DETAILS]: []
  }

  const isCopyButtonHidden =
    (panelType === DetailsPanelTypes.PROJECT_DETAILS && !projectData.requesterPermissions.canCopyProject) ||
    !canCopyProject
  const isMoveButtonHidden = isSharedWithMePage || !canMoveItems
  const isEditButtonHidden =
    panelType === DetailsPanelTypes.PROJECT_DETAILS
      ? !projectData.requesterPermissions.canWriteProject || !canEditProject
      : !canEditFolder
  const isDeleteButtonHidden =
    panelType === DetailsPanelTypes.PROJECT_DETAILS
      ? !projectData.requesterPermissions.canWriteProject || !canDeleteProject
      : !canDeleteFolder

  const handleButtonClick = (buttonType: ActionButtonType): void => {
    switch (buttonType) {
      case ActionButtonType.COPY:
        copyProject()
        break
      case ActionButtonType.EDIT:
        if (panelType === DetailsPanelTypes.PROJECT_DETAILS) {
          editProject()
        } else {
          editFolder()
        }
        break
      case ActionButtonType.TRASH:
        if (panelType === DetailsPanelTypes.PROJECT_DETAILS) {
          deleteProject()
        } else {
          deleteFolder()
        }
        break
      case ActionButtonType.MOVE:
        const { folders, projects } = selectedItems

        const mainDisplaySelectedItems: MoveItem[] = [
          ...folders.map((folderId) => ({ id: folderId, type: ResourceType.FOLDER })),
          ...projects.map((projectId) => ({ id: projectId, type: ResourceType.PROJECT }))
        ]

        let items = []
        if (!isEmpty(mainDisplaySelectedItems)) {
          items = mainDisplaySelectedItems
        } else {
          const navigationTreeFolder = { id: currentPathNode, type: ResourceType.FOLDER }
          items = [navigationTreeFolder]
        }

        moveItems(items)
        break
    }
  }

  return (
    <ActionButtonsStyled>
      {displayButtons[panelType].includes(ActionButtonType.OPEN) && <OpenProjectButton />}
      {displayButtons[panelType].includes(ActionButtonType.SHARE) && <ShareButton />}
      {displayButtons[panelType].includes(ActionButtonType.COPY) && !isCopyButtonHidden && (
        <ActionButton
          onClick={(): void => {
            handleButtonClick(ActionButtonType.COPY)
          }}
          title={t('copyProject', { ns: 'tooltips' })}
        >
          <SpriteIcon spriteId="copy" width={16} height={16} color="grey.550" />
        </ActionButton>
      )}
      {displayButtons[panelType].includes(ActionButtonType.MOVE) && !isMoveButtonHidden && (
        <ActionButton
          onClick={(): void => {
            handleButtonClick(ActionButtonType.MOVE)
          }}
          title={t('move', { ns: 'tooltips' })}
        >
          <SpriteIcon spriteId="move-folder" width={16} height={16} color="grey.550" />
        </ActionButton>
      )}
      {displayButtons[panelType].includes(ActionButtonType.EDIT) && !isEditButtonHidden && (
        <ActionButton
          onClick={(): void => {
            handleButtonClick(ActionButtonType.EDIT)
          }}
          title={t('edit', { ns: 'tooltips' })}
        >
          <SpriteIcon spriteId="pencil" width={14} height={14} color="grey.550" />
        </ActionButton>
      )}
      {displayButtons[panelType].includes(ActionButtonType.TRASH) && !isDeleteButtonHidden && (
        <ActionButton
          onClick={(): void => {
            handleButtonClick(ActionButtonType.TRASH)
          }}
          title={t('delete', { ns: 'tooltips' })}
        >
          <SpriteIcon spriteId="trash-can" width={14} height={14} color="error.main" />
        </ActionButton>
      )}
    </ActionButtonsStyled>
  )
}

import { CheckboxListHeader } from './CheckboxListHeader'
import { CheckboxListItem } from './CheckboxListItem'
import { FixedSizeVirtualizedList } from '../../modal/templates/teamSpace/FixedSizeVirtualizedList'
import * as React from 'react'
import { Divider, List, Stack, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

export type CheckboxListItemType = {
  id: number | string
  checked: boolean
  alwaysOnTop?: boolean
  disabled?: boolean
  isMember?: boolean
  isInvited?: boolean
}

export type OrderBy = 'asc' | 'desc'

interface CheckboxListProps {
  headerTitle: string
  items: CheckboxListItemType[]
  orderBy: OrderBy
  handleCheckboxClick: (id: string | number) => void
  handleCheckAllClick: (allItemsChecked: boolean) => void
  handleOrderByClick: (orderBy: OrderBy) => void
  checkboxListItemComponent: (item: CheckboxListItemType) => React.ReactElement
  disabled?: boolean
}

export const CheckboxList = ({
  headerTitle,
  items,
  orderBy,
  handleCheckboxClick,
  handleCheckAllClick,
  handleOrderByClick,
  checkboxListItemComponent,
  disabled
}: CheckboxListProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  const allItemsChecked = React.useMemo(
    () => (!isEmpty(items) ? items.every((item) => item.checked === true) : false),
    [items]
  )
  const someItemsChecked = React.useMemo(
    () => (!isEmpty(items) ? items.some((item) => item.checked === true) : false),
    [items]
  )

  if (isEmpty(items)) {
    return (
      <Stack direction="row" width="100%">
        <Typography>{t('teamSpace.noItemsFound')}</Typography>
      </Stack>
    )
  }

  return (
    <List>
      <CheckboxListHeader
        onToggleAllClick={(): void => handleCheckAllClick(allItemsChecked)}
        allItemsChecked={allItemsChecked}
        someItemsChecked={someItemsChecked}
        orderBy={orderBy}
        onOrderByClick={handleOrderByClick}
        headerTitle={headerTitle}
        disabled={disabled}
      />
      <Divider />
      <FixedSizeVirtualizedList
        items={items}
        renderComponent={({ item, style }): React.ReactElement => (
          <CheckboxListItem
            id={item.id}
            isChecked={item.checked}
            onCheckboxClick={(id): void => handleCheckboxClick(id)}
            isDisabled={disabled || item?.isMember}
            style={style}
            renderComponent={checkboxListItemComponent(item)}
          />
        )}
        additionalHeightOffset={80}
      />
    </List>
  )
}

import { TableFilter, TableFilterType } from '../interfaces'
import { Button } from '../../../button/Button'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { DateTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'

interface DateValue {
  fromDate: Date
  toDate: Date
}

interface FromToDatePickerProps {
  value: DateValue
  handleFromDateOnChange: (fromDate: Date) => void
  handleToDateOnChange: (toDate: Date) => void
}

interface DateFilterDialogProps {
  filter: TableFilter
  id: string
  setFilters: React.Dispatch<React.SetStateAction<TableFilter<any>[]>>
}

const FromToDatePicker = ({
  value,
  handleFromDateOnChange,
  handleToDateOnChange
}: FromToDatePickerProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  return (
    <Stack direction="column" gap={4} padding={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker label={t('dateFilter.from')} value={value.fromDate} onChange={handleFromDateOnChange} />
        <DateTimePicker label={t('dateFilter.to')} value={value.toDate} onChange={handleToDateOnChange} />
      </LocalizationProvider>
    </Stack>
  )
}

export const DateFilterDialog = ({ filter, id, setFilters }: DateFilterDialogProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  const handleFromDateOnChange = React.useCallback((value: Date): void => {
    setFilters((filters: TableFilter[]): TableFilter[] =>
      filters.map((filter) =>
        filter.id === id ? { type: TableFilterType.DATE, id, value: { ...filter.value, fromDate: value } } : filter
      )
    )
  }, [])

  const handleToDateOnChange = React.useCallback((value: Date): void => {
    setFilters((filters: TableFilter[]): TableFilter[] =>
      filters.map((filter) =>
        filter.id === id ? { type: TableFilterType.DATE, id, value: { ...filter.value, toDate: value } } : filter
      )
    )
  }, [])

  const handleResetButtonOnClick = React.useCallback((): void => {
    handleFromDateOnChange(null)
    handleToDateOnChange(null)
  }, [])

  return (
    <Stack direction="column">
      <FromToDatePicker
        value={filter.value}
        handleFromDateOnChange={handleFromDateOnChange}
        handleToDateOnChange={handleToDateOnChange}
      />
      <Button
        disabled={isNil(filter.value.fromDate) && isNil(filter.value.toDate)}
        onClick={handleResetButtonOnClick}
        variant="outlined"
      >
        {t('reset')}
      </Button>
    </Stack>
  )
}

import {
  AiAccess,
  BalanceResponse,
  OrganizationAttributes,
  OrganizationDomain,
  OrganizationDomainStatus,
  OrganizationSettings,
  OrganizationUserAiAccess,
  OrganizationUsers,
  OrganizationV2,
  OrganizationV2User,
  QuotaBudgetsPayload,
  QuotaBudgetsResponse,
  UserOrganizationStatus,
  UserQuotaBudgets
} from './interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { apiTags } from '../../apiTags'

const organizationSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationUsers: builder.query<OrganizationUsers, { organizationId: string }>({
      query: ({ organizationId }) => `api/v1/organizations/${organizationId}/users`,
      providesTags: [apiTags.USERS]
    }),
    getOrganizationV2: builder.query<OrganizationV2, { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}`,
      providesTags: [apiTags.ORGANIZATION_DETAILS]
    }),
    getOrganizationV2Users: builder.query<OrganizationV2User[], { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/users`,
      transformResponse: (response: { users: OrganizationV2User[] }) => response.users,
      providesTags: [apiTags.USERS]
    }),
    updateOrganizationV2UserStatus: builder.mutation<
      void,
      { organizationId: string; userId: number; userRole: UserOrganizationStatus }
    >({
      query: ({ organizationId, userId, userRole }) => ({
        url: `api/v2/organizations/${organizationId}/users/${userId}/status`,
        method: 'PUT',
        body: { status: userRole }
      }),
      async onQueryStarted({ organizationId, userId, userRole }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            organizationSlice.util.updateQueryData('getOrganizationV2Users', { organizationId }, (draft) => {
              const user = draft.find((user) => user.uidExt === userId)
              if (user) {
                user.status = userRole
              }
            })
          )
        } catch {}
      },
      invalidatesTags: [apiTags.USER]
    }),
    deleteOrganizationV2User: builder.mutation<void, { organizationId: string; userId: number; inheritor: number }>({
      query: ({ organizationId, userId, inheritor }) => ({
        url: `api/v2/organizations/${organizationId}/users/${userId}?inheritor=${inheritor}`,
        method: 'DELETE'
      }),
      invalidatesTags: [apiTags.USERS, apiTags.PROJECTS, apiTags.FOLDERS_FROM_FOLDER, apiTags.FOLDERS_FROM_SPACE]
    }),
    getOrganizationBalances: builder.query<BalanceResponse, { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/balances`,
      providesTags: [apiTags.ORGANIZATION_BALANCES]
    }),
    getOrganizationV2QuotaBudgets: builder.query<QuotaBudgetsResponse, { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/quota-budgets`,
      providesTags: [apiTags.ORGANIZATION_QUOTA_BUDGETS]
    }),
    updateOrganizationV2UserQuotaBudgets: builder.mutation<
      QuotaBudgetsResponse,
      { organizationId: string; payload: QuotaBudgetsPayload }
    >({
      query: ({ organizationId, payload }) => ({
        url: `api/v2/organizations/${organizationId}/quota-budgets`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [apiTags.USERS, apiTags.ORGANIZATION_QUOTA_BUDGETS, apiTags.ORGANIZATION_QUOTA_BUDGET]
    }),
    getOrganizationV2UserQuotaBudgets: builder.query<UserQuotaBudgets, { uidExt: number; organizationId: string }>({
      query: ({ uidExt, organizationId }) => `api/v2/organizations/${organizationId}/quota-budgets/${uidExt}`,
      providesTags: (_result, _error, { uidExt }) => [{ type: apiTags.ORGANIZATION_QUOTA_BUDGET, uidExt }]
    }),
    getOrganizationV2Attributes: builder.query<OrganizationAttributes, { organization?: string; user?: number }>({
      query: ({ organization, user }) => ({
        url: 'api/v2/organizations/attributes',
        params: {
          organization,
          user
        }
      })
    }),
    getOrganizationSettings: builder.query<OrganizationSettings, { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/settings`,
      providesTags: [apiTags.ORGANIZATION_SETTINGS]
    }),
    updateOrganizationSettings: builder.mutation<
      OrganizationSettings,
      { organizationId: string; payload: OrganizationSettings }
    >({
      query: ({ organizationId, payload }) => ({
        url: `api/v2/organizations/${organizationId}/settings`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [apiTags.ORGANIZATION_SETTINGS]
    }),
    getOrganizationDomains: builder.query<OrganizationDomain[], { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/domains`,
      providesTags: [apiTags.ORGANIZATION_DOMAINS]
    }),
    postOrganizationDomain: builder.mutation<
      OrganizationDomain,
      { organizationId: string; domain: string; status?: OrganizationDomainStatus }
    >({
      query: ({ organizationId, domain, status }) => ({
        url: `api/v2/organizations/domains`,
        method: 'POST',
        body: { orgId: organizationId, domain, status: status || OrganizationDomainStatus.PENDING }
      }),
      invalidatesTags: [apiTags.ORGANIZATION_DOMAINS]
    }),
    deleteOrganizationDomain: builder.mutation<void, { domain: string }>({
      query: ({ domain }) => ({
        url: `api/v2/organizations/domains`,
        method: 'DELETE',
        body: { domain }
      }),
      invalidatesTags: [apiTags.ORGANIZATION_DOMAINS]
    }),

    getOrganizationUserAiAccess: builder.query<OrganizationUserAiAccess, { organizationId: string; uidExt: number }>({
      query: ({ organizationId, uidExt }) => `api/v2/organizations/${organizationId}/users/${uidExt}/ai-access`,
      providesTags: [apiTags.ORGANIZATION_USER_AI_PERMISSION]
    }),

    putOrganizationUserAiAccess: builder.mutation<void, { organizationId: string; uidExt: number; payload: AiAccess }>({
      query: ({ organizationId, uidExt, payload }) => ({
        url: `api/v2/organizations/${organizationId}/users/${uidExt}/ai-access`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: [apiTags.ORGANIZATION_USER_AI_PERMISSION, apiTags.ORGANIZATION_USERS_AI_PERMISSIONS]
    }),

    getOrganizationUsersAiAccess: builder.query<OrganizationUserAiAccess[], { organizationId: string }>({
      query: ({ organizationId }) => `api/v2/organizations/${organizationId}/users/ai-access`,
      providesTags: [apiTags.ORGANIZATION_USERS_AI_PERMISSIONS]
    })
  })
})

export const {
  useGetOrganizationUsersQuery,
  useGetOrganizationV2Query,
  useGetOrganizationV2UsersQuery,
  useUpdateOrganizationV2UserStatusMutation,
  useDeleteOrganizationV2UserMutation,
  useGetOrganizationV2QuotaBudgetsQuery,
  useGetOrganizationV2UserQuotaBudgetsQuery,
  useGetOrganizationBalancesQuery,
  useUpdateOrganizationV2UserQuotaBudgetsMutation,
  useGetOrganizationV2AttributesQuery,
  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
  useGetOrganizationDomainsQuery,
  usePostOrganizationDomainMutation,
  useDeleteOrganizationDomainMutation,
  useGetOrganizationUserAiAccessQuery,
  usePutOrganizationUserAiAccessMutation,
  useGetOrganizationUsersAiAccessQuery
} = organizationSlice

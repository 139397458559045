import { CardName } from '../card/CardName'
import { CardIcon } from '../card/CardIcon'
import { RowDetails } from '../rows/RowDetails'
import { RowStyled } from '../rows/RowStyled'
import { CardData } from '../card/interfaces'
import * as React from 'react'

export const Row = ({
  title,
  children,
  selected,
  useTeamIcon,
  onClick,
  onContextMenu,
  className
}: CardData): React.ReactElement => {
  return (
    <RowStyled
      data-testid="row"
      selected={selected}
      onClick={onClick}
      onContextMenu={onContextMenu}
      className={className}
    >
      <RowDetails>
        <CardIcon data-testid="row-icon" useTeamIcon={useTeamIcon} />
        <CardName data-testid="row-name" selected={selected}>
          {title}
        </CardName>
      </RowDetails>
      {children}
    </RowStyled>
  )
}

import { SwitchStyled } from '../../../../components/elements/Switch'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { usePutOrganizationUserAiAccessMutation } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type AiTrainingPermissionSwitchType = {
  fullName: string
  uidExt: number
  organizationId: string
  hasPermission: boolean
}

export const AiTrainingPermissionSwitch = ({
  fullName,
  uidExt,
  organizationId,
  hasPermission
}: AiTrainingPermissionSwitchType): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const { t } = useTranslation(['adminArea'])
  const [putOrganizationUserAiAccess, { isLoading }] = usePutOrganizationUserAiAccessMutation()

  const [isChecked, setIsChecked] = React.useState(hasPermission)

  const handleOnChange = React.useCallback(
    (isChecked: boolean) => {
      setIsChecked(isChecked)
      putOrganizationUserAiAccess({ organizationId, uidExt, payload: { hasAccess: isChecked } })
        .unwrap()
        .then(() => {
          dispatch(
            addSnackbarToState({
              severity: 'info',
              message: t('manageUsers.snackbar.aiPermissionUpdate', {
                result: isChecked ? 'enabled' : 'disabled',
                user: fullName
              })
            })
          )
        })
        .catch((error) => {
          handleApiHookErrors(error)
        })
    },
    [fullName, uidExt, organizationId]
  )

  return (
    <SwitchStyled
      qa-attribute={`aiTrainingPermissionSwitch`}
      onChange={(): void => {
        handleOnChange(!isChecked)
      }}
      color="primary"
      checked={isChecked}
      disabled={isLoading}
    />
  )
}

import { PermissionAccess } from './interfaces'
import { addSnackbarToState } from '../store/features/uiSlice/uiSlice'
import { useAppDispatch } from '../store/hooks'
import { getUsername } from '../helpers/cookies'
import { useOrganizationId } from '../hooks/newWorld'
import { Space } from '../store/features/apis/slices/space/interfaces'
import { useGetUserSpacesQuery } from '../store/features/apis/slices/space/spacesSlice'
import { UserOrganizationStatus } from '../store/features/apis/slices/organization/interfaces'
import { useGetSettingsMapQuery, useGetUserQuery } from '../store/features/apis/slices/user/userSlice'
import { PagePath } from '../constants'
import { useDashboardNavigate } from '../hooks/useDashboardNavigate'
import {
  useGetOrganizationSettingsQuery,
  useGetOrganizationUserAiAccessQuery
} from '../store/features/apis/slices/organization/organizationSlice'
import { useGetOrganizationId } from '../store/features/apis/slices/organization/hooks'
import { useErrorHandler } from '../hooks/useErrorHandler'
import { t } from 'i18next'
import * as React from 'react'
import { isNil } from 'lodash'

export const useIsTeamsAdminAreaEnabled = (): PermissionAccess => {
  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetSettingsMapQuery()
  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const { data: isInOrganization, isLoading: isInOrganizationLoading } = useIsInOrganization()
  const { data: isSpaceAdmin, isLoading: isSpaceAdminLoading } = useIsSpaceAdmin()

  if (isAdminLoading || isUserSettingsLoading || isInOrganizationLoading || isSpaceAdminLoading) {
    return [true, undefined]
  }

  const isFTEnabled = userSettings?.use_teams_admin_in_content_manager === 'true'
  const isOrganizationSettingForEssentialPlanHidden =
    userSettings?.hide_organization_settings_for_essential_user === 'true'

  return [
    false,
    (isFTEnabled && (isAdmin || isSpaceAdmin)) || (!isInOrganization && !isOrganizationSettingForEssentialPlanHidden)
  ]
}

export const useIsUsersAreaEnabled = (): PermissionAccess => {
  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const { data: organizationId, isLoading: isOrganizationIdLoading } = useOrganizationId({
    skipCondition: isAdminLoading
  })

  if (isAdminLoading || isOrganizationIdLoading) {
    return [true, undefined]
  }

  return [false, !isAdmin && !isNil(organizationId)]
}

export const useIsManageUsersAreaEnabled = (options?: { allowNonAdmins?: boolean }): PermissionAccess => {
  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const { data: organizationId, isLoading: isOrganizationIdLoading } = useOrganizationId({
    skipCondition: isAdminLoading
  })
  const { data: isInOrganization, isLoading: isInOrganizationLoading } = useIsInOrganization()
  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetSettingsMapQuery()

  const isOrganizationSettingForEssentialPlanHidden =
    userSettings?.hide_organization_settings_for_essential_user === 'true'

  if (isAdminLoading || isOrganizationIdLoading || isInOrganizationLoading || isUserSettingsLoading) {
    return [true, undefined]
  }

  return [
    false,
    ((isAdmin || options?.allowNonAdmins) && !isNil(organizationId)) ||
      (!isInOrganization && !isOrganizationSettingForEssentialPlanHidden)
  ]
}

export const useIsManageInvitationsEnabled = (): PermissionAccess => {
  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const { data: organizationId, isLoading: isOrganizationIdLoading } = useOrganizationId({
    skipCondition: isAdminLoading
  })
  const { data: isInOrganization, isLoading: isInOrganizationLoading } = useIsInOrganization()
  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetSettingsMapQuery()

  const isOrganizationSettingForEssentialPlanHidden =
    userSettings?.hide_organization_settings_for_essential_user === 'true'

  if (isAdminLoading || isOrganizationIdLoading || isInOrganizationLoading || isUserSettingsLoading) {
    return [true, undefined]
  }

  return [
    false,
    (isAdmin && !isNil(organizationId)) || (!isInOrganization && !isOrganizationSettingForEssentialPlanHidden)
  ]
}

export const useRedirectIfPermissionDenied = (
  permissions: PermissionAccess[],
  redirectPage: string = PagePath.MY_PROJECTS
): void => {
  const dispatch = useAppDispatch()
  const { navigate } = useDashboardNavigate()

  React.useEffect(() => {
    if (permissions.some(([isLoading, permission]) => isLoading === false && !permission)) {
      dispatch(
        addSnackbarToState({
          severity: 'error',
          message: t('pageNotFound')
        })
      )
      navigate(`/${redirectPage}`, { replace: true })
    }
  }, [JSON.stringify(permissions)])
}

const isSpaceAdmin = (space: Space): boolean =>
  space.requesterPermissions?.canEditSpaceSettings && space.requesterPermissions?.canEditSpacePermissions

const useIsSpaceAdmin = (): { data: boolean; isLoading: boolean } => {
  const { data: spaces, isLoading: isSpaceLoading } = useGetUserSpacesQuery()

  if (isSpaceLoading) {
    return { isLoading: true, data: undefined }
  }

  return { isLoading: false, data: spaces.some(isSpaceAdmin) }
}

export const useIsAdmin = (): { data: boolean; isLoading: boolean } => {
  const username = getUsername()
  const { data: user, isLoading, isFetching } = useGetUserQuery({ username }, { skip: isNil(username) })

  if (isLoading || isFetching) {
    return { isLoading: true, data: undefined }
  }

  const isAdmin = user.organizationInfo?.organizationStatus === UserOrganizationStatus.ADMIN
  return { isLoading: false, data: isAdmin }
}

export const useIsInOrganization = (): { data: boolean; isLoading: boolean } => {
  const username = getUsername()
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery({ username }, { skip: isNil(username) })

  return {
    isLoading: isLoadingUser,
    data:
      !isNil(user?.organizationInfo.organizationId) &&
      user.organizationInfo.organizationStatus !== UserOrganizationStatus.FREE
  }
}

export const useIsDomainAreaEnabled = (): PermissionAccess => {
  const { data: userSettings, isLoading: isUserSettingsLoading } = useGetSettingsMapQuery()
  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()
  const { data: isInOrganization, isLoading: isInOrganizationLoading } = useIsInOrganization()

  if (isAdminLoading || isUserSettingsLoading || isInOrganizationLoading) {
    return [true, undefined]
  }

  const isFTEnabled = userSettings?.enable_domains_area_in_dashboard === 'true'

  return [false, isFTEnabled && isAdmin && isInOrganization]
}

export const useIsAiInManageUsersEnabled = (): PermissionAccess => {
  const { handleApiHookErrors } = useErrorHandler()

  const { data: isAdmin, isLoading: isAdminLoading } = useIsAdmin()

  const organizationId = useGetOrganizationId()
  const {
    data: settings,
    isLoading,
    isError,
    error
  } = useGetOrganizationSettingsQuery({ organizationId }, { skip: isNil(organizationId) })

  React.useEffect(() => {
    if (isError) {
      handleApiHookErrors(error)
    }
  }, [isError, error])

  if (isLoading || isAdminLoading) {
    return [true, undefined]
  }

  if (isError) {
    return [false, false]
  }

  return [false, settings?.showAIModelManager === true && isAdmin]
}

export const useIsAiAreaEnabled = (): PermissionAccess => {
  const username = getUsername()
  const { handleApiHookErrors } = useErrorHandler()

  const organizationId = useGetOrganizationId()
  const {
    data: settings,
    isLoading: isLoadingOrganizationId,
    isError: isErrorOrganizationId,
    error: errorOrganizationId
  } = useGetOrganizationSettingsQuery({ organizationId }, { skip: isNil(organizationId) })

  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    error: errorUser
  } = useGetUserQuery({ username }, { skip: isNil(username) })

  const {
    data: userAiAccess,
    isLoading: isLoadingUserAiAccess,
    isError: isErrorUserAiAccess,
    error: errorUserAiAccess
  } = useGetOrganizationUserAiAccessQuery(
    { organizationId, uidExt: user?.uidExt },
    { skip: isNil(organizationId) || isNil(user?.uidExt) }
  )

  React.useEffect(() => {
    if (isErrorOrganizationId) {
      handleApiHookErrors(errorOrganizationId)
    }
  }, [isErrorOrganizationId, errorOrganizationId])

  React.useEffect(() => {
    if (isErrorUser) {
      handleApiHookErrors(errorUser)
    }
  }, [isErrorUser, errorUser])

  React.useEffect(() => {
    if (isErrorUserAiAccess) {
      handleApiHookErrors(errorUserAiAccess)
    }
  }, [isErrorUserAiAccess, errorUserAiAccess])

  if (isLoadingOrganizationId || isLoadingUser || isLoadingUserAiAccess) {
    return [true, undefined]
  }

  if (isErrorOrganizationId || isErrorUser || isErrorUserAiAccess) {
    return [false, false]
  }

  return [false, settings?.showAIModelManager === true && userAiAccess.hasAccess]
}

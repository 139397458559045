import { LeftSidebar } from './LeftSidebar'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { ActionButton } from '../../../components/button/ActionButton'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setMobileSidebarOpen } from '../../../store/features/uiSlice/uiSlice'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import * as React from 'react'
import { SpriteIcon } from '@/assets/SpriteIcon'

// TODO: When design specs are available, implement correct left sidebar styles, currently, it's not clear how it should look, and it's breaking our mobile tests for simba. (https://simscale.atlassian.net/browse/ENG-58023)
const LeftSidebarWrapperStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'isMobileOpen'
})<{
  isMobile?: boolean
  isMobileOpen?: boolean
}>(({ theme, isMobile, isMobileOpen }) => ({
  width: theme.spacing(66.5),
  maxHeight: '100%',
  backgroundColor: theme.palette.grey[100],
  borderRight: '1px solid',
  borderColor: theme.palette.grey[200],
  ...(isMobile
    ? {
        width: isMobileOpen ? '100vw' : theme.spacing(10),
        overflowX: 'hidden',
        ...(isMobileOpen
          ? {
              position: 'relative',
              '& > .MuiButton-root': {
                position: 'absolute',
                right: theme.spacing(1)
              }
            }
          : {
              display: 'flex',
              justifyContent: 'center',
              '& > .MuiStack-root': {
                display: 'none'
              }
            })
      }
    : {})
}))

export const LeftSidebarWrapper = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const isMobile = useIsMobile()
  const isMobileSidebarOpen = useAppSelector((state) => state.ui.mobileSidebarOpen)

  return (
    <LeftSidebarWrapperStyled item isMobile={isMobile} isMobileOpen={isMobileSidebarOpen} qa-attribute="left-sidebar">
      {isMobile && (
        <ActionButton
          onClick={(): void => {
            dispatch(setMobileSidebarOpen(!isMobileSidebarOpen))
          }}
          variant="text"
          sx={{ marginTop: 1 }}
          qa-attribute="left-sidebar--mobile-toggle"
        >
          <SpriteIcon spriteId="angle-right" rotatingAngle={isMobileSidebarOpen ? 0 : 180} width={15} />
        </ActionButton>
      )}
      <LeftSidebar />
    </LeftSidebarWrapperStyled>
  )
}

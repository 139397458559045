import { Button } from '../../button/Button'
import * as React from 'react'
import { Stack, TextField, TextFieldProps } from '@mui/material'
import { SpriteIcon } from '@/assets/SpriteIcon'

type PasswordFieldProps = TextFieldProps

export const PasswordField = (props: PasswordFieldProps): React.ReactElement => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const handleShowPasswordToggle = (): void => {
    setShowPassword((prevState) => !prevState)
  }

  return (
    <Stack direction="row" gap={1}>
      <TextField type={showPassword ? 'text' : 'password'} {...props} />
      <Button
        onClick={(): void => handleShowPasswordToggle()}
        color="info"
        variant="text"
        sx={{ padding: 0, minWidth: 0, width: 28, boxShadow: 'unset' }}
        qa-attribute="showPassword--button"
      >
        <SpriteIcon spriteId={showPassword ? 'eye' : 'eye-slash'} color="grey.600" height={17} width={17} />
      </Button>
    </Stack>
  )
}

import theme from '../../theme/defaultTheme'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

interface ProjectMiniatureProps {
  thumbnailUrl?: string
  size?: string | number
  useTeamIcon?: boolean
}

export const ProjectMiniature = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'shared' && prop !== 'addExtraMargin' && prop !== 'thumbnailUrl'
})<ProjectMiniatureProps>(({ thumbnailUrl, size }) => ({
  width: size ?? '32px',
  height: size ?? '32px',
  backgroundSize: 'cover',
  backgroundImage: `url(${thumbnailUrl}), linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.action.hover} 100%)`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  border: `1px solid ${theme.palette.grey['200']}`,
  borderRadius: '4px',
  flexShrink: '0'
}))

import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { AnalysisTypeDisplayType } from '@/components/modal/templates/ai/SelectAiTrainingAnalysisType/SelectAiTrainingAnalysisType'

type AnalysisTypePickProps = {
  analysisTypes: AnalysisTypeDisplayType[]
  value: string
  handleChange: (value: string) => void
}

export const AnalysisTypePick = ({ analysisTypes, value, handleChange }: AnalysisTypePickProps): React.ReactElement => {
  const { t } = useTranslation(['modals', 'aiTraining'])

  return (
    <Stack gap={1}>
      <Typography variant="summaryCardDescription">
        {t('selectAiTrainingAnalysisType.analysisTypeDescription')}
      </Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="analysis-type-radio-buttons"
          name="analysis-type-radio-buttons-group"
          value={value}
          onChange={(event): void => handleChange(event.target.value)}
        >
          {analysisTypes.map((analysisType) => (
            <FormControlLabel
              key={analysisType.type}
              value={analysisType.type}
              control={<Radio />}
              label={
                <Typography variant="summaryCardDescription">
                  {`${analysisType.type} (${analysisType.numberOfSuccessfulRuns} ${t('projectsCart.runs', {
                    ns: 'aiTraining'
                  })})`}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  )
}

import { Button, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { SpriteIcon } from '@/assets/SpriteIcon'

const StackStyled = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey['200']}`,
  borderRadius: 20,
  padding: 4,
  backgroundColor: theme.palette.background.default,
  width: 'fit-content'
}))

const ButtonStyled = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  '&:disabled': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.grey['400']
  }
}))

type PrivacySwitchType = {
  isPrivateProject: boolean
  onClick: (isPrivateProject: boolean) => void
  isDisabled?: boolean
}

export const PrivacySwitch = ({ isPrivateProject, onClick, isDisabled }: PrivacySwitchType): React.ReactElement => {
  const { t } = useTranslation('modals')

  return (
    <StackStyled direction="row">
      <ButtonStyled
        startIcon={
          <SpriteIcon width={13} height={13} spriteId="lock" color={!isPrivateProject ? 'grey.700' : 'common.white'} />
        }
        disableRipple
        disableElevation
        color={isPrivateProject ? 'primary' : 'background'}
        variant="contained"
        onClick={(): void => onClick(!isPrivateProject)}
        qa-attribute="private-project--button"
        disabled={isDisabled}
      >
        <Typography color={!isPrivateProject ? 'grey.700' : 'text'}>{t('share.labels.private')}</Typography>
      </ButtonStyled>
      <ButtonStyled
        startIcon={
          <SpriteIcon spriteId="globe" width={13} height={13} color={isPrivateProject ? 'grey.700' : 'common.white'} />
        }
        disableRipple
        disableElevation
        color={!isPrivateProject ? 'primary' : 'background'}
        variant="contained"
        onClick={(): void => onClick(!isPrivateProject)}
        qa-attribute="public-project--button"
        disabled={isDisabled}
      >
        <Typography color={isPrivateProject ? 'grey.700' : 'text'}>{t('share.labels.public')}</Typography>
      </ButtonStyled>
    </StackStyled>
  )
}

import { FullProject, PagingMetadata, Project, ProjectResult } from './interfaces'
import { useGetProjectsQuery } from './projectsSlice'
import { useGetProjectDetailsQuery } from './projectSlice'
import { encodeQueryParams } from '../../../../../utils/helpers'
import { SortingDirection, SortingModeV2 } from '../../../uiSlice/interfaces'
import { ApiVersion } from '../../helpers'
import { getSortingDirectionForRequest } from '../../../uiSlice/helpers'
import { isEmpty, isNil } from 'lodash'

export const PROJECT_PAGE_SIZE: number = 20

export const getProjectContentUrl = (
  spaceId: string,
  folderId: string,
  sortingModeV2: SortingModeV2,
  sortingDirection: SortingDirection,
  targetPage = 1,
  projectsPerPage = PROJECT_PAGE_SIZE
): string => {
  const sort = getSortingDirectionForRequest(sortingDirection) + sortingModeV2
  const queryParams = encodeQueryParams({ limit: projectsPerPage, page: targetPage, sort })
  if (!isNil(folderId) && spaceId !== folderId) {
    return `spaces/${spaceId}/folders/${folderId}/content/projects?${queryParams}`
  } else {
    return `spaces/${spaceId}/content/projects?${queryParams}`
  }
}

// Search uses the old api, and query params are in a different format
export const convertSortingQueryParamsToSearchFormat = (sortingModeV2: SortingModeV2): string => {
  switch (sortingModeV2) {
    case SortingModeV2.CREATED_AT:
      return 'project_creationdate'
    case SortingModeV2.NAME:
      return 'project_name'
    case SortingModeV2.LAST_MODIFIED_AT:
      return 'project_lastchange'
  }
}

// search uses the old API, which has a different interface for response
// however this format can be converted to the new one, so it's usable on our structure
export const convertSearchProjectsToProjects = (projects: FullProject[]): Project[] =>
  projects.map(
    (project) =>
      ({
        ...project,
        resourceType: 'PROJECT',
        name: project.projectName,
        createdAt: project.creationDate,
        lastModifiedAt: project.lastModificationDate
      } as Project)
  )

export const convertPageNumberToOffset = (page: number, projectPerPage: number): number => (page - 1) * projectPerPage

export const useGetProjectV3Details = (
  { projectId }: { projectId: string },
  options?: { skip: boolean }
): ProjectResult<FullProject> => {
  const { data, error, isLoading, isFetching, isError, isSuccess } = useGetProjectDetailsQuery(
    { projectId, apiVersion: ApiVersion.V3 },
    { ...options, skip: options?.skip || isNil(projectId) }
  )

  return { data, error, isSuccess, isError, isLoading: isLoading || isFetching }
}

export const useGetProjectV1Details = (
  { projectSlug }: { projectSlug: string },
  options?: { skip: boolean }
): ProjectResult<FullProject> => {
  const { data, error, isLoading, isFetching, isError, isSuccess } = useGetProjectDetailsQuery(
    { projectSlug, apiVersion: ApiVersion.V1 },
    { ...options, skip: options?.skip || isNil(projectSlug) }
  )

  return { data, error, isError, isLoading: isLoading || isFetching, isSuccess }
}

interface GetProjectsProps {
  spaceId: string
  folderId: string
  sortingModeV2: SortingModeV2
  sortingDirection: SortingDirection
  targetPage: number
}

export const useGetProjects = (
  { spaceId, folderId, sortingModeV2, sortingDirection, targetPage }: GetProjectsProps,
  options?: { skip: boolean }
): ProjectResult<{
  items: Project[]
  pagingMetadata: PagingMetadata
}> => {
  const { data, error, isError, isSuccess, isLoading, isFetching } = useGetProjectsQuery(
    { spaceId, folderId, sortingModeV2, sortingDirection, targetPage },
    { ...options, skip: options?.skip || isEmpty(spaceId) }
  )

  return { data, error, isError, isLoading: isLoading || isFetching, isSuccess }
}

import { CreateProjectPlaceholder } from '../../CreateProjectPlaceholder'
import { ProjectPagination } from '../pagination/ProjectPagination'
import { ProjectDataGridComponents } from '../ProjectDataGrid'
import { FoldersArea } from '../FoldersArea'
import { ViewMode } from '../../../../store/features/uiSlice/interfaces'
import { getViewingMode } from '../../../../store/features/uiSlice/uiSlice'
import { useAppSelector } from '../../../../store/hooks'
import theme from '../../../../theme/defaultTheme'
import { useFolderedProjects } from '../hooks'
import { useCenteredCircularProgress } from '../../../../components/progress/CenteredCircularProgress'
import { useGetFolders } from '../../../../store/features/apis/slices/folder/helpers'
import { NoProjectsWarning } from '../../NoProjectsWarning'
import { useCreateProjectOperation } from '../../../../operations/project/createProjectOperation'
import { useCurrentPath } from '../../../../hooks/useCurrentPath'
import Grid from '@mui/material/Grid'
import * as React from 'react'
import { first, isEmpty, isNil, last } from 'lodash'

export const FolderedProjectsView = (): React.ReactElement => {
  const { currentPath, isLoading: isLoadingCurrentPath } = useCurrentPath()
  const displayType = useAppSelector(getViewingMode)
  const { projects, isLoading: isLoadingProjects } = useFolderedProjects()
  const spaceId = first(currentPath)
  const folderId = last(currentPath)
  const { data: folders, isLoading: isLoadingFolders } = useGetFolders(
    {
      spaceId,
      folderId
    },
    { origin: 'folderedProjectsView', skip: isLoadingCurrentPath && isNil(spaceId) }
  )

  const [_createProject, hasCreatePermission, isLoadingPermission] = useCreateProjectOperation(currentPath)

  const isLoading = isLoadingProjects || isLoadingFolders || isLoadingPermission
  useCenteredCircularProgress(isLoading)
  if (isLoading) {
    return null
  }

  if (isEmpty(folders) && isEmpty(projects) && !hasCreatePermission) {
    return <NoProjectsWarning />
  }

  return (
    <>
      <FoldersArea folders={folders} />
      <Grid
        container
        sx={{
          display: `${displayType === ViewMode.GRID ? 'grid' : 'block'}`,
          gridTemplateColumns: 'repeat(auto-fill,minmax(230px, 1fr))',
          gridGap: theme.spacing(3),
          mb: displayType === ViewMode.GRID ? 3 : 0
        }}
      >
        {isEmpty(projects) && hasCreatePermission ? (
          <CreateProjectPlaceholder />
        ) : (
          <ProjectDataGridComponents projects={projects} />
        )}
      </Grid>
      <ProjectPagination />
    </>
  )
}

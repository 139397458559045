import * as React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const DataSelectionHeading = (): React.ReactElement => {
  const { t } = useTranslation(['aiTraining'])

  return (
    <Stack gap={2}>
      <Typography variant="summaryCardCounter">{t('modelDataSelection.selectTrainingData')}</Typography>
      <Typography variant="h2">
        {t('modelDataSelection.chooseRelevantData') + ' '}
        {/* TODO: Functionality still not decided from product */}
        {/* <Link
          underline="hover"
          onClick={(): void => {}} // functionality still not decided from product
          sx={{
            cursor: 'pointer',
            display: 'inline-flex',
            alignItems: 'center',
            gap: 1
          }}
          rel="noopener noreferrer"
          target={isInsideCypress ? '_self' : '_blank'}
        >
          {t('modelDataSelection.howToSelectRelevantData')}
          <CustomIcon icon={faArrowUpRightFromSquare} color="primary" />
        </Link> */}
      </Typography>
    </Stack>
  )
}

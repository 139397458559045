import { startCase, toLower } from 'lodash'
import { ChipPropsColorOverrides } from '@mui/material/Chip'
import { OverridableStringUnion } from '@mui/types'
import { ModelState } from '@/store/features/apis/slices/ai/interfaces'

export const getChipColorFromModelState = (
  state: ModelState
): OverridableStringUnion<
  'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning',
  ChipPropsColorOverrides
> => {
  switch (state) {
    case ModelState.READY || ModelState.RELEASED:
      return 'primary'
    case ModelState.RELEASED:
      return 'success'
    case ModelState.TRAINING:
      return 'warning'
    case ModelState.FAILED:
      return 'error'
    default:
      return 'default'
  }
}

export const getHumanReadableText = (text: string): string => {
  return text
    ?.split('_')
    .map((word) => startCase(toLower(word)))
    .join(' ')
}

export const formatVersion = (version: string): string => {
  const versionNumber = version.replace('v', '')
  const versionParts = versionNumber.split('.')
  if (versionParts.length === 1) {
    return `V${versionParts[0]}.0`
  }
  return `V${versionNumber}`
}

// export const getAccessButtonIcon = (sharedWithOrganization: boolean): React.ReactElement => {
//   return (
//     <CustomIcon
//       icon={sharedWithOrganization ? faBuildingUser : faLockKeyhole}
//       color={'primary'}
//       style={{ width: '16px', height: '16px' }}
//     />
//   )
// }

import { ModelVersionCustomChip } from './VersionsHistoryItem'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { formatVersion } from '@/containers/ai/helpers'

type VersionsFullHistoryItemProps = {
  version: ModelVersion
  isLatestVersion?: boolean
}

export const VersionsFullHistoryItem = ({
  version,
  isLatestVersion
}: VersionsFullHistoryItemProps): React.ReactElement => {
  return (
    <Stack direction="row" alignItems="center" qa-attribute="aiModel--modelVersions--fullHistory">
      <ModelVersionCustomChip label={formatVersion(version.version)} color={isLatestVersion ? 'primary' : 'default'} />
      <Stack direction="column" paddingX={3} width={180} flexShrink={1}>
        <Typography variant="textV2">{format(new Date(version.created_at), 'MMM d, yyyy')}</Typography>
        <Typography variant="textSmallV2" color="grey.700">
          {format(new Date(version.created_at), 'h:mm a')}
        </Typography>
      </Stack>
      <Typography variant="textV2" paddingX={3} width={400} flexShrink={0}>
        {version.name}
      </Typography>
      <Typography variant="textV2" paddingX={3} flexGrow={1}>
        {version.description}
      </Typography>
    </Stack>
  )
}

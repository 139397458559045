import { ValidationItemProps, ValidationResultProps } from '../helpers/validationHelpers'
import { useTranslation } from 'react-i18next'

export const useValidation = (): {
  validateField: (value: string, validations: ValidationItemProps[]) => ValidationResultProps
} => {
  const { t } = useTranslation('validation')

  const validateField = (value: string, validations: ValidationItemProps[]): ValidationResultProps => {
    for (const validationRule of validations) {
      if (validationRule.isNullable && (value === '' || value === null)) {
        return {
          valid: true,
          message: ''
        }
      }
      if (!value?.match(validationRule.regexp)) {
        return {
          valid: false,
          message: t(validationRule.message.key, { ns: 'validation', ...validationRule.message.options })
        }
      }
    }

    return {
      valid: true,
      message: ''
    }
  }

  return {
    validateField
  }
}

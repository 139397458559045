import { ModalTemplateProps } from '../interfaces'
import { ModalActions, ModalContent, ModalTitle } from '../Modal'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  addSnackbarToState,
  deselectAllItems,
  getCurrentProjectPage,
  setCurrentProjectPage
} from '../../../store/features/uiSlice/uiSlice'
import { Button } from '../../button/Button'
import { useFolderedProjects, useSearchProjects } from '../../../containers/dashboard/mainDisplay/hooks'
import { pathToRoute } from '../../../helpers/router'
import { useDeleteProjectMutation } from '../../../store/features/apis/slices/project/projectSlice'
import { useIsSearchMode } from '../../../hooks/useIsSearchMode'
import { getUsername } from '../../../helpers/cookies'
import { useCurrentPath } from '../../../hooks/useCurrentPath'
import { useDashboardNavigate } from '../../../hooks/useDashboardNavigate'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { first, last } from 'lodash'
import Typography from '@mui/material/Typography'

interface TrashProjectModalTemplateProps extends ModalTemplateProps {
  title: string
  projectName: string
  publicProjectName: string
  projectOwnerName: string
}

export const TrashProjectModalTemplate = ({
  title,
  onClose,
  onCancel,
  projectName,
  publicProjectName,
  projectOwnerName
}: TrashProjectModalTemplateProps): React.ReactElement => {
  const { navigate } = useDashboardNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation('modals')
  const [deleteProject, { isLoading }] = useDeleteProjectMutation()
  const { currentPath } = useCurrentPath()
  const currentPage = useAppSelector(getCurrentProjectPage)
  const isSearchMode = useIsSearchMode()
  const { projects: folderedProjects } = useFolderedProjects()
  const { projects: searchedProjects, hideNextPage } = useSearchProjects()
  const projects = isSearchMode ? searchedProjects : folderedProjects

  const wasLastProjectInPage = isSearchMode ? !hideNextPage : currentPage > 1 && projects.length === 1

  const onError = (): void => {
    dispatch(
      addSnackbarToState({
        severity: 'error',
        message: t(`trash.project.failed`)
      })
    )
    onClose()
  }
  const handleProjectDeletion = (): void => {
    // eslint-disable-next-line no-console
    console.info(
      `User ${getUsername()} clicked on the trash button for project ${projectName} with public name ${publicProjectName} and owner ${projectOwnerName}`
    )

    deleteProject({
      publicProjectName,
      projectOwnerName,
      parentFolderId: last(currentPath),
      spaceId: first(currentPath)
    })
      .unwrap()
      .then(() => {
        dispatch(deselectAllItems())
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: t('trash.movedToTrash', { item: projectName })
          })
        )
        navigate(`/${pathToRoute(currentPath)}`)
        if (wasLastProjectInPage) {
          dispatch(setCurrentProjectPage(currentPage - 1))
        }
      })
      .catch(() => {
        onError()
      })
      .finally(() => {
        onClose(true)
      })
  }

  return (
    <React.Fragment>
      <ModalTitle onClose={(): void => onClose(false)}>{title}</ModalTitle>
      <ModalContent onSubmit={handleProjectDeletion} submitDisabled={isLoading}>
        <Typography>{t('trash.project.willBeDeleted', { projectName })}</Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onCancel} color="secondary" variant="outlined" disabled={isLoading}>
          {t('cancel')}
        </Button>
        <Button onClick={handleProjectDeletion} color="primary" variant="contained" disabled={isLoading}>
          {t('trash.trash')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

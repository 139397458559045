import * as React from 'react'

export const useRightClick = (): void => {
  const handleRightClick = React.useCallback((event: MouseEvent) => {
    if (event.type === 'contextmenu') {
      event.preventDefault()
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener('contextmenu', handleRightClick)
    return () => {
      document.removeEventListener('contextmenu', handleRightClick)
    }
  })
}

import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import { ModalTemplateProps } from '@/components/modal/interfaces'
import { ModelVersion } from '@/store/features/apis/slices/ai/interfaces'
import { useAppDispatch } from '@/store/hooks'
import { addSnackbarToState } from '@/store/features/uiSlice/uiSlice'
import { ModalActions, ModalContent, ModalTitle } from '@/components/modal/Modal'
import { Button } from '@/components/button/Button'
import { formatVersion } from '@/containers/ai/helpers'
import { useDeleteModelVersionMutation } from '@/store/features/apis/slices/ai/aiSlice'
import { useErrorHandler } from '@/hooks/useErrorHandler'

interface DeleteModelVersionTemplateProps extends ModalTemplateProps {
  title: string
  version: ModelVersion
}

export const DeleteModelVersionTemplate = ({
  title,
  onClose,
  showCloseButton,
  version
}: DeleteModelVersionTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const dispatch = useAppDispatch()
  const { handleApiHookErrors } = useErrorHandler()
  const [deleteModelVersion] = useDeleteModelVersionMutation()

  const handleTrashOnClick = React.useCallback((): void => {
    deleteModelVersion({ modelUuid: version?.model_uuid, version: version?.version })
      .unwrap()
      .then(() => {
        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t('deleteAiModelVersion.alerts.success', { version: formatVersion(version?.version) })
          })
        )
      })
      .catch((error) => {
        handleApiHookErrors(error)
      })
      .finally(() => onClose())
  }, [version])

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{title}</ModalTitle>
      <ModalContent onSubmit={handleTrashOnClick}>
        <Typography variant="text" color="grey.700">
          <Trans
            i18nKey="deleteAiModelVersion.description"
            t={t}
            values={{ version: formatVersion(version?.version) }}
          />
        </Typography>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('deleteAiModelVersion.actions.cancel')}
        </Button>
        <Button onClick={handleTrashOnClick} color="primary" variant="contained">
          {t('deleteAiModelVersion.actions.delete')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

import Typography from '@mui/material/Typography'
import * as React from 'react'

export const SettingsTitle = ({ children }: { children: string }): React.ReactElement => {
  return (
    <Typography variant="textBold" whiteSpace="nowrap" marginTop={6}>
      {children}
    </Typography>
  )
}

import { CreateProjectPayload, FullProject, SimulationType } from './interfaces'
import { SpacePermission, SpacePermissions } from '../../../../../components/modal/templates/share/interfaces'
import { ApiVersion } from '../../helpers'
import { apiTags } from '../../apiTags'
import { ProjectResult, TopicIdResponse } from '../../../../../containers/detailsPanel/interfaces'
import { ApplicationApi } from '../../ApplicationApi'
import { isNil } from 'lodash'

const projectSlice = ApplicationApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectDetails: builder.query<FullProject, { apiVersion: ApiVersion; projectId?: string; projectSlug?: string }>(
      {
        query: ({ apiVersion, projectId, projectSlug }) =>
          `api/${apiVersion}/projects/${apiVersion === ApiVersion.V1 ? projectSlug : projectId}`,
        providesTags: (_result, _error, { projectId, apiVersion, projectSlug }) => {
          if (apiVersion === ApiVersion.V1) {
            return [{ type: apiTags.PROJECT_DETAILS, projectSlug }]
          } else {
            return [{ type: apiTags.PROJECT_DETAILS, projectIdExt: projectId }]
          }
        }
      }
    ),
    updateProject: builder.mutation<void, { projectIdExt: string; payload: any }>({
      query: ({ projectIdExt, payload }) => ({
        url: `api/${ApiVersion.V3}/projects/${projectIdExt}`,
        method: 'PUT',
        body: payload
      }),
      invalidatesTags: (_result, _error, { projectIdExt, payload }) => [
        { type: apiTags.PROJECT_DETAILS, projectIdExt },
        { type: apiTags.PROJECTS, folderId: payload.parentFolderId }
      ]
    }),
    createProject: builder.mutation<string, { userName: string; payload: CreateProjectPayload }>({
      query: ({ userName, payload }) => ({
        url: `api/${ApiVersion.V1}/projects/${userName}`,
        method: 'POST',
        body: payload
      }),
      transformResponse: (_apiResponse, meta) => meta.response.headers.get('location'),
      invalidatesTags: (_result, _error, { payload }) => {
        if (isNil(payload.parentFolderId) || payload.spaceId === payload.parentFolderId) {
          return [
            { type: apiTags.PROJECTS, spaceId: payload.spaceId },
            { type: apiTags.PROJECT_DETAILS, spaceId: payload.spaceId },
            { type: apiTags.FOLDERS_FROM_FOLDER },
            { type: apiTags.FOLDERS_FROM_SPACE }
          ]
        } else {
          return [
            { type: apiTags.PROJECTS, spaceId: payload.spaceId, folderId: payload.parentFolderId },
            { type: apiTags.FOLDER_DETAILS, spaceId: payload.spaceId, folderId: payload.parentFolderId },
            { type: apiTags.FOLDERS_FROM_FOLDER },
            { type: apiTags.FOLDERS_FROM_SPACE }
          ]
        }
      }
    }),
    deleteProject: builder.mutation<
      string,
      { projectOwnerName: string; publicProjectName: string; parentFolderId: string; spaceId: string }
    >({
      query: ({ projectOwnerName, publicProjectName }) => ({
        url: `api/${ApiVersion.V1}/projects/${projectOwnerName}/${publicProjectName}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, payload) => {
        if (isNil(payload.parentFolderId) || payload.spaceId === payload.parentFolderId) {
          return [
            { type: apiTags.PROJECTS, spaceId: payload.spaceId },
            { type: apiTags.SPACE_DETAILS, spaceId: payload.spaceId },
            { type: apiTags.FOLDERS_FROM_FOLDER },
            { type: apiTags.FOLDERS_FROM_SPACE }
          ]
        } else {
          return [
            { type: apiTags.PROJECTS, spaceId: payload.spaceId, folderId: payload.parentFolderId },
            { type: apiTags.SPACE_DETAILS, spaceId: payload.spaceId, folderId: payload.parentFolderId },
            { type: apiTags.FOLDERS_FROM_FOLDER },
            { type: apiTags.FOLDERS_FROM_SPACE }
          ]
        }
      }
    }),
    copyProject: builder.mutation<string, { ownerName: string; publicProjectName: string; payload: any }>({
      query: ({ ownerName, publicProjectName, payload }) => ({
        url: `api/${ApiVersion.V1}/projects/${ownerName}/${publicProjectName}/copy`,
        method: 'POST',
        body: payload
      }),
      transformResponse: (response: { projectIdExt: string }) => response.projectIdExt,
      invalidatesTags: (_result, _error, { payload }) => {
        if (isNil(payload.parentFolderId) || payload.parentFolderId === payload.spaceId) {
          return [
            { type: apiTags.PROJECTS, spaceId: payload.spaceId },
            { type: apiTags.FOLDERS_FROM_FOLDER },
            { type: apiTags.FOLDERS_FROM_SPACE }
          ]
        } else {
          return [
            { type: apiTags.PROJECTS, spaceId: payload.spaceId, folderId: payload.parentFolderId },
            { type: apiTags.FOLDERS_FROM_FOLDER },
            { type: apiTags.FOLDERS_FROM_SPACE }
          ]
        }
      }
    }),
    getProjectTopicId: builder.query<number | undefined, { ownerName: string; publicProjectName: string }>({
      queryFn: async ({ ownerName, publicProjectName }, _api, _extraOptions, baseQuery) => {
        const result = await baseQuery(`api/${ApiVersion.V1}/projects/${ownerName}/${publicProjectName}/topicid`)

        if (result.error?.status === 404) {
          // don't refetch on 404
          return { data: undefined }
        }

        if (result.error) {
          throw result.error
        }

        return { data: (result.data as TopicIdResponse)?.id }
      }
    }),
    getProjectResults: builder.query<ProjectResult[], { ownerName: string; publicProjectName: string }>({
      query: ({ ownerName, publicProjectName }) =>
        `api/${ApiVersion.V1}/projects/${ownerName}/${publicProjectName}/results`
    }),
    getProjectPermissions: builder.query<SpacePermission[], { ownerName: string; publicProjectName: string }>({
      query: ({ ownerName, publicProjectName }) => `api/v1/permissions/projects/${ownerName}/${publicProjectName}`,
      transformResponse: (response: SpacePermissions) => response.permissions ?? [],
      providesTags: [apiTags.PROJECT_PERMISSIONS]
    }),
    postProjectPermissions: builder.mutation<
      void,
      { ownerName: string; publicProjectName: string; permissions: SpacePermissions }
    >({
      query: ({ ownerName, publicProjectName, permissions }) => {
        return {
          url: `api/v1/permissions/projects/${ownerName}/${publicProjectName}`,
          method: 'POST',
          body: permissions
        }
      },
      invalidatesTags: [apiTags.PROJECT_PERMISSIONS]
    }),

    getProjectSimulations: builder.query<SimulationType[], { projectId: string }>({
      query: ({ projectId }) => `api/${ApiVersion.V2}/projects/${projectId}/simulations`,
      providesTags: [apiTags.PROJECT_SIMULATIONS]
    })
  })
})

export const {
  useGetProjectDetailsQuery,
  useGetProjectResultsQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useCopyProjectMutation,
  useGetProjectPermissionsQuery,
  usePostProjectPermissionsMutation,
  useGetProjectTopicIdQuery,
  useGetProjectSimulationsQuery
} = projectSlice

export const getProjectDetails = projectSlice.endpoints.getProjectDetails.initiate

import NavigationMenu from './NavigationMenu'
import { SPACING_VARIABLES } from '../../theme/themeOptions/vars'
import { ZINDEXES } from '../../constants/zindexes'
import { getBaseUrl, getLogoUrl } from '../../helpers/network'
import { Searchbar } from '../../components/searchbar/Searchbar'
import { styled } from '@mui/material/styles'
import { ReactElement } from 'react'
import * as React from 'react'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { TAB_INDEXES } from '@/constants/tabIndexes'

const HeaderStyled = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',

  borderBottom: `1px solid ${theme.palette.divider}`,
  boxSizing: 'border-box',

  height: SPACING_VARIABLES.APP_HEADER_HEIGHT,
  [theme.breakpoints.down('sm')]: {
    height: SPACING_VARIABLES.MOBILE_APP_HEADER_HEIGHT,
    img: {
      maxWidth: '159px',
      height: 'auto'
    }
  },
  background: theme.palette.common.white,

  alignItems: 'center',
  padding: '0 10px',
  zIndex: ZINDEXES.HEADER_LAYER
}))

export const Header = (): ReactElement => {
  return (
    <HeaderStyled direction="row" qa-attribute="header">
      <Link href={getBaseUrl()} display="inline-flex" mr="auto" tabIndex={TAB_INDEXES.SIMSCALE_LOGO}>
        <img src={getLogoUrl()} alt="SimScale Logo" width="180" height="32" />
      </Link>
      <Searchbar />
      <NavigationMenu />
    </HeaderStyled>
  )
}

import { ModalActions, ModalContent, ModalTitle } from '../../Modal'
import { Button } from '../../../button/Button'
import { ModalTemplateProps } from '../../interfaces'
import { Trans, useTranslation } from 'react-i18next'
import * as React from 'react'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

interface DomainDeclinedTemplateProps extends ModalTemplateProps {
  domain: string
}

export const DomainDeclinedTemplate = ({
  domain,
  onClose,
  showCloseButton
}: DomainDeclinedTemplateProps): React.ReactElement => {
  const { t } = useTranslation('modals')

  return (
    <React.Fragment>
      <ModalTitle onClose={showCloseButton && onClose}>{t('domainDeclined.title')}</ModalTitle>
      <ModalContent onSubmit={onClose}>
        <Stack direction="column" gap={3}>
          <Typography variant="text">
            <Trans i18nKey="domainDeclined.description.0" t={t} values={{ domain }} />
          </Typography>
          <Typography variant="text">
            <Trans i18nKey="domainDeclined.description.1" t={t}>
              <Link
                href="#"
                onClick={(): void => {
                  window['Intercom']?.('show')
                }}
              />
            </Trans>
          </Typography>
        </Stack>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('domainDeclined.gotIt')}
        </Button>
      </ModalActions>
    </React.Fragment>
  )
}

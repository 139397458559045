import { AddDomainForm } from './AddDomainForm'
import { DomainsList } from './DomainsList'
import { useIsDomainAreaEnabled } from '../../../../../permissions/areas'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const DomainsManagement = (): React.ReactElement => {
  const { t } = useTranslation('domains')
  const [isLoadingPermissions, isDomainAreaEnabled] = useIsDomainAreaEnabled()

  if (isLoadingPermissions || !isDomainAreaEnabled) {
    return null
  }

  return (
    <Stack
      direction="column"
      gap={4}
      padding={6}
      border={1}
      borderRadius={2}
      borderColor="grey.200"
      qa-attribute="domains-management"
    >
      <Typography variant="textBold">{t('title')}</Typography>
      <Typography variant="text">
        {t('description.0')}
        <br />
        {t('description.1')}
      </Typography>

      <AddDomainForm />
      <DomainsList />
    </Stack>
  )
}

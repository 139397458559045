declare global {
  interface Window {
    Osano?: any
    dataLayer?: any[]
  }
}

import { store } from './store/configureStore'
import defaultTheme from './theme/defaultTheme'
import { ProtectedLayout } from './pages/Layout'
import ProfilePage from './pages/profile/ProfilePage'
import UsageOverviewPage from './pages/profile/UsageOverviewPage'
import ApiKeysPage from './pages/profile/ApiKeysPage'
import SecurityPage from './pages/profile/SecurityPage'
import MyInvitationsPage from './pages/profile/MyInvitationsPage'
import ManageSpacesPage from './pages/organization/ManageSpacesPage'
import ManageUsersPage from './pages/organization/ManageUsersPage'
import ManageInvitationsPage from './pages/organization/ManageInvitationsPage'
import OrganizationSettingsPage from './pages/organization/OrganizationSettingsPage'
import SpacesPage from './pages/SpacesPage'
import ProjectsListPage from './pages/ProjectsListPage'
import AiModelTrainingPage from './pages/ai/AiModelTrainingPage'
import ProjectDetailsPanelDelayed from './containers/detailsPanel/ProjectDetailsPanel'
import SpaceDetailsPanelDelayed from './containers/detailsPanel/SpaceDetailsPanel'
import FolderDetailsPanelDelayed from './containers/detailsPanel/FolderDetailsPanel'
import SearchDetailsPanel from './containers/detailsPanel/SearchDetails'
import GenericDetailsPanel from './containers/detailsPanel/GenericDetailsPanel'
import LandingPage from './pages/LandingPage'
import CreateAiModelPage from './pages/ai/CreateAiModelPage'
import globalStyles from './globalStyles'
import { i18nInit } from './i18n'
import * as React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
// GlobalStyles usage https://stackoverflow.com/a/70261718
import './logging/logBootstrapper'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import ModelDetailsPage from '@/pages/ai/models/ModelDetailsPage'

i18nInit()

const persistor = persistStore(store)

const router = createBrowserRouter(
  [
    {
      index: true,
      element: <LandingPage />
    },
    {
      element: (
        <ProtectedLayout>
          <Outlet />
        </ProtectedLayout>
      ),
      children: [
        {
          path: '/team-spaces',
          element: <SpacesPage />
        },
        {
          path: '/profile',
          element: <ProfilePage />
        },
        {
          path: '/usage_overview',
          element: <UsageOverviewPage />
        },
        {
          path: '/api_keys',
          element: <ApiKeysPage />
        },
        {
          path: '/security',
          element: <SecurityPage />
        },
        {
          path: '/my_invitations',
          element: <MyInvitationsPage />
        },
        {
          path: '/manage-spaces',
          element: <ManageSpacesPage />
        },
        {
          path: '/manage-users',
          element: <ManageUsersPage />
        },
        {
          path: '/manage-invitations',
          element: <ManageInvitationsPage />
        },
        {
          path: '/organization-settings',
          element: <OrganizationSettingsPage />
        },
        {
          path: '/models',
          element: <AiModelTrainingPage />
        },
        {
          path: '/models/:modelUuid',
          element: <ModelDetailsPage />
        },
        {
          path: '/models/:modelUuid/create-version',
          element: <CreateAiModelPage />
        },
        {
          element: (
            <ProjectsListPage>
              <Outlet />
            </ProjectsListPage>
          ),
          children: [
            {
              path: '/my-projects',
              element: <SpaceDetailsPanelDelayed />
            },
            {
              path: '/shared-with-me',
              element: <GenericDetailsPanel />
            },
            {
              path: '/search',
              element: <SearchDetailsPanel />
            },
            {
              path: '/projects/:projectId',
              element: <ProjectDetailsPanelDelayed />
            },
            {
              path: '/projects/:projectId/:action',
              element: <ProjectDetailsPanelDelayed />
            },
            {
              path: '/spaces/:spaceId',
              element: <SpaceDetailsPanelDelayed />
            },
            {
              path: '/spaces/:spaceId/folders/:folderId',
              element: <FolderDetailsPanelDelayed />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: (
        <ProtectedLayout>
          <LandingPage />
        </ProtectedLayout>
      )
    }
  ],
  {
    basename: '/dashboard'
  }
)

function Dashboard(): JSX.Element {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <React.Suspense>
            <RouterProvider router={router} />
          </React.Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default Dashboard

import { openTab } from '../../../utils/windowUtils'
import { CustomChip } from '../../../components/chips/CustomChip'
import * as React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

export interface TagData {
  name: string
  popular?: boolean
  count?: number
}

interface TagsProps {
  tagsData: TagData[]
}

export const Tags = ({ tagsData }: TagsProps): React.ReactElement => {
  const handleTagClick = (tagName: string): void => {
    openTab(`/projects/tags/${tagName}`)
  }

  const getTagElements = (tagNames: string[]): React.ReactElement[] => {
    return tagNames.map((tagName: string) => (
      <CustomChip
        key={tagName}
        label={
          <Typography variant="textLight" sx={{ textTransform: 'uppercase' }}>
            {tagName}
          </Typography>
        }
        variant="outlined"
        size="small"
        onClick={(): void => {
          handleTagClick(tagName)
        }}
        sx={{ marginRight: 2, marginBottom: 2 }}
      />
    ))
  }

  const tagNames = tagsData?.map(({ name }) => name) ?? []
  return (
    <Box sx={{ margin: '20px 0px 0px 0' }} qa-attribute="tags">
      <Stack direction="row" flexWrap="wrap">
        {getTagElements(tagNames)}
      </Stack>
    </Box>
  )
}

import { PlaceholderBox } from '../../components/placeholders/PlaceholderBox'
import { ViewMode } from '../../store/features/uiSlice/interfaces'
import { ResourceType } from '../detailsPanel/components/DetailsList'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useCreateFolderOperation } from '@/operations/folder/createFolderOperation'

export const CreateFolderPlaceholder = ({ displayType }: { displayType: ViewMode }): React.ReactElement => {
  const { t } = useTranslation(['placeholders', 'modals'])
  const [createFolder, hasPermission, isLoading] = useCreateFolderOperation()
  if (!hasPermission || isLoading) {
    return null
  }

  return (
    <PlaceholderBox
      displayType={displayType}
      onClick={(): void => {
        createFolder()
      }}
      label={t('createFolderLabel')}
      resourceType={ResourceType.FOLDER}
    />
  )
}

import { ModelVersionsListItem, ModelVersionsListItemSkeleton } from './ModelVersionsListItem'
import * as React from 'react'
import Stack, { StackProps } from '@mui/material/Stack'
import { ModelVersion } from '@/store/features/apis/slices/ai/interfaces'

type ModelVersionsListProps = StackProps & {
  versions: ModelVersion[]
  defaultSelectedVersion?: ModelVersion['version']
}

type ModelVersionsListSkeletonProps = Omit<ModelVersionsListProps, 'versions'> & {
  itemCount?: number
}

export const ModelVersionsListSkeleton = ({
  itemCount = 5,
  ...props
}: ModelVersionsListSkeletonProps): React.ReactElement => {
  return (
    <Stack direction="column" spacing={3} {...props}>
      {[...Array(itemCount)].map((_, index) => (
        <ModelVersionsListItemSkeleton key={index} />
      ))}
    </Stack>
  )
}

export const ModelVersionsList = ({
  versions,
  defaultSelectedVersion,
  ...props
}: ModelVersionsListProps): React.ReactElement => {
  const [selectedVersion, setSelectedVersion] = React.useState<ModelVersion['version']>(defaultSelectedVersion)

  const handleVersionClick = React.useCallback((version: ModelVersion) => {
    setSelectedVersion((prevSelectedVersion) => (prevSelectedVersion === version.version ? null : version.version))
  }, [])

  return (
    <Stack direction="column" spacing={3} qa-attribute="aiModel--versionsList" {...props}>
      {versions?.map((modelVersion) => (
        <ModelVersionsListItem
          key={modelVersion.version}
          version={modelVersion}
          onClick={handleVersionClick}
          isOpen={selectedVersion === modelVersion.version}
        />
      ))}
    </Stack>
  )
}

import { CardStyled } from '../../../../components/card/Card'
import { SettingsTitle } from '../../../../components/settingsForm/SettingsTitle'
import { Button } from '../../../../components/button/Button'
import { SettingsRow } from '../../../../components/settingsForm/SettingsRow'
import { useErrorHandler } from '../../../../hooks/useErrorHandler'
import { useValidation } from '../../../../hooks/useValidation'
import { ValidationRules, isNotEmpty, isRequired, isValidPassword } from '../../../../helpers/validationHelpers'
import { usePutPasswordMutation } from '../../../../store/features/apis/slices/user/userSlice'
import { setCookie } from '../../../../helpers/cookies'
import { useAppDispatch } from '../../../../store/hooks'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useOrganizationId } from '../../../../hooks/newWorld'
import { useGetOrganizationSettingsQuery } from '../../../../store/features/apis/slices/organization/organizationSlice'
import { useRedirectIfPermissionDenied } from '../../../../permissions/areas'
import Stack from '@mui/material/Stack'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'

export const SecurityDisplay = (): React.ReactElement => {
  const { t } = useTranslation(['profileArea'])
  const dispatch = useAppDispatch()

  const { data: organizationId, isLoading: isLoadingOrganizationId } = useOrganizationId()
  const { data: settings, isLoading: isLoadingSettings } = useGetOrganizationSettingsQuery(
    { organizationId },
    { skip: isNil(organizationId) }
  )
  const showSecurity = isNil(organizationId) || settings?.enablePasswordLogin

  useRedirectIfPermissionDenied([[isLoadingOrganizationId || isLoadingSettings, showSecurity]])

  const [putPassword, { isLoading: isLoadingPutPassword }] = usePutPasswordMutation()

  const [oldPassword, setOldPassword] = React.useState<string>('')
  const [newPassword, setNewPassword] = React.useState<string>('')

  const { validationErrors, setValidationErrors, handleApiHookErrors } = useErrorHandler({
    oldPassword: { valid: true, message: '' },
    newPassword: { valid: true, message: '' }
  })
  const { validateField } = useValidation()

  const validationRules: ValidationRules = {
    oldPassword: [isRequired, isNotEmpty],
    newPassword: [isRequired, isNotEmpty, isValidPassword]
  }

  const onOldPasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setOldPassword(event.target.value)
    const validation = validateField(event.target.value, validationRules.oldPassword)
    setValidationErrors((prevState) => ({ ...prevState, oldPassword: validation }))
  }, [])

  const onNewPasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(event.target.value)
    const validation = validateField(event.target.value, validationRules.newPassword)
    setValidationErrors((prevState) => ({ ...prevState, newPassword: validation }))
  }, [])

  const onSaveClick = React.useCallback((): void => {
    if (Object.values(validationErrors).some((error) => !error.valid)) {
      return
    }

    putPassword({ oldPassword, newPassword })
      .unwrap()
      .then((response) => {
        setOldPassword('')
        setNewPassword('')
        setValidationErrors((prevState) => ({
          ...prevState,
          oldPassword: { valid: true, message: '' },
          newPassword: { valid: true, message: '' }
        }))

        setCookie('token', response.token)
        setCookie('sid', response.sid)

        dispatch(
          addSnackbarToState({
            severity: 'success',
            message: t('security.labels.passwordChangeSuccessful')
          })
        )
      })
      .catch(handleApiHookErrors)
  }, [oldPassword, newPassword, validationErrors])

  return (
    <Stack direction="column" mx="auto" width="600px" mt={2.5} mb={4} qa-attribute="security--wrapper">
      <Stack flexDirection="column" gap={4}>
        <SettingsTitle>{t('security.labels.changeYourPassword')}</SettingsTitle>
        <CardStyled notInteractive sx={{ padding: 4, gap: 4, width: '100%', display: 'flex', flexDirection: 'column' }}>
          <SettingsRow
            required
            label={t('security.labels.currentPassword')}
            value={oldPassword}
            onChange={onOldPasswordChange}
            error={!validationErrors['oldPassword']?.valid}
            helperText={validationErrors['oldPassword']?.message}
            qaAttributePrefix="oldPassword"
            disabled={isLoadingPutPassword}
            type="password"
          />
          <SettingsRow
            required
            label={t('security.labels.newPassword')}
            value={newPassword}
            onChange={onNewPasswordChange}
            error={!validationErrors['newPassword']?.valid}
            helperText={validationErrors['newPassword']?.message}
            qaAttributePrefix="newPassword"
            disabled={isLoadingPutPassword}
            type="password"
          />
          <Stack flexDirection="row" gap={2} justifyContent="end" width="100%">
            <Button variant="contained" className="save--button" onClick={onSaveClick} disabled={isLoadingPutPassword}>
              {t('security.labels.save')}
            </Button>
          </Stack>
        </CardStyled>
      </Stack>
    </Stack>
  )
}

import { useFirstSelectedItemName } from './hooks'
import { MoveItem } from './interfaces'
import { addSnackbarToState } from '../../../../store/features/uiSlice/uiSlice'
import { useGetFolderDetails, useGetSpaceDetails } from '../../../../store/features/apis/slices/folder/helpers'
import { first, isNil, last } from 'lodash'
import { useDispatch } from 'react-redux'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const useMoveToasts = (items: MoveItem[], targetPath: string[]): [() => void, () => void] => {
  const dispatch = useDispatch()
  const firstSelectedItemName = useFirstSelectedItemName(items)
  const { t } = useTranslation(['modals', 'tooltips'])

  const isSpace = first(targetPath) === last(targetPath)

  const { data: selectedFolder, isLoading: isFolderLoading } = useGetFolderDetails(
    {
      spaceId: first(targetPath),
      folderId: last(targetPath)
    },
    { skip: isSpace }
  )

  const { data: selectedSpace, isLoading: isSpaceLoading } = useGetSpaceDetails(
    { spaceId: first(targetPath) },
    { skip: !isSpace }
  )

  const showSuccessfulToast = React.useCallback((): void => {
    // TODO: Think of a better way to do it, I had to stop using isFolderLoading and isSpaceLoading because when you make a move action it will always be loading, so it was never showing the toast, I had to use !isNil(selectedFolder) instead.
    if ((isSpace && !isNil(selectedSpace)) || (!isSpace && !isNil(selectedFolder))) {
      if (items.length === 1) {
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: t('move.successfulMove', {
              itemName: firstSelectedItemName,
              destinationName: isSpace ? selectedSpace.name : selectedFolder?.name
            })
          })
        )
      } else {
        dispatch(
          addSnackbarToState({
            severity: 'info',
            message: t('move.successfulMultiselectionMove', {
              itemQuantity: items.length,
              destinationName: isSpace ? selectedSpace.name : selectedFolder?.name
            })
          })
        )
      }
    }
  }, [
    firstSelectedItemName,
    selectedFolder?.name,
    items.length,
    dispatch,
    isSpaceLoading,
    isFolderLoading,
    isSpace,
    selectedSpace
  ])

  const showErrorToast = React.useCallback((): void => {
    dispatch(
      addSnackbarToState({
        severity: 'error',
        message: t('move.failedMove')
      })
    )
  }, [dispatch])

  return [showSuccessfulToast, showErrorToast]
}

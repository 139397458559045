import * as React from 'react'
import { Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type RadioElementType = {
  value: boolean
  label: string
  description: string
  selectedValue: boolean
  disabled?: boolean
}

const RadioElement = ({ value, label, description, selectedValue, disabled }: RadioElementType): React.ReactElement => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: value === selectedValue && !disabled ? 'primary.main' : 'divider',
        borderRadius: 1,
        padding: 4,
        width: '100%',
        backgroundColor: value === selectedValue && !disabled ? 'primary.200' : 'white'
      }}
    >
      <Stack direction="column">
        <Typography variant="textBold" component="p">
          {label}
        </Typography>
        <Typography variant="text" component="p">
          {description}
        </Typography>
      </Stack>
      <Radio value={value} disabled={disabled} />
    </Stack>
  )
}

export const OutlierIdentification = (): React.ReactElement => {
  const { t } = useTranslation('aiTraining')
  const [includeAllData, setIncludeAllData] = React.useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIncludeAllData(event.target.value === 'true')
  }

  return (
    <Stack direction="column" gap={4}>
      <Typography variant="title">{t('modelSettings.outlierIdentification')}</Typography>
      <RadioGroup
        aria-labelledby="analysis-type-radio-buttons"
        name="analysis-type-radio-buttons-group"
        value={includeAllData.toString()}
        onChange={handleChange}
      >
        <Stack direction="row" gap={5} sx={{ minWidth: 800 }}>
          <RadioElement
            value={false}
            label={t('modelSettings.identifyOutliers')}
            description={t('modelSettings.automaticallyExclude')}
            selectedValue={includeAllData}
            disabled // currently disabled because it's not yet supported
          />
          <RadioElement
            value={true}
            label={t('modelSettings.includeAllData')}
            description={t('modelSettings.doNotExclude')}
            selectedValue={includeAllData}
            disabled // currently disabled because it's not yet supported
          />
        </Stack>
      </RadioGroup>
    </Stack>
  )
}

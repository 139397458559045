import { isEmpty } from 'lodash'
import { SchemaType } from '@/store/features/apis/slices/ai/interfaces'

export function getSchemaDefaultValues<T>(properties: SchemaType['properties']): T | undefined {
  if (isEmpty(properties)) {
    return undefined
  }

  return Object.keys(properties).reduce((acc, key) => {
    const property = properties[key]
    if (property.type === 'object') {
      acc[key] = getSchemaDefaultValues(property.properties)
    } else {
      acc[key] = property.default
    }
    return acc
  }, {} as T)
}

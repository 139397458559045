import { DomainsListItem } from './DomainsListItem'
import { useGetOrganizationDomainsQuery } from '../../../../../store/features/apis/slices/organization/organizationSlice'
import { useOrganizationId } from '../../../../../hooks/newWorld'
import { useAppDispatch } from '../../../../../store/hooks'
import { setIsLoading } from '../../../../../store/features/uiSlice/uiSlice'
import Stack from '@mui/material/Stack'
import * as React from 'react'

export const DomainsList = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { data: organizationId, isLoading: isLoadingOrganization } = useOrganizationId()
  const { data: domains, isLoading: isLoadingDomains } = useGetOrganizationDomainsQuery(
    { organizationId },
    { skip: isLoadingOrganization }
  )

  React.useEffect(() => {
    dispatch(setIsLoading(isLoadingDomains || isLoadingOrganization))
  }, [isLoadingOrganization, isLoadingDomains])

  return (
    <Stack direction="column" borderTop={1} borderColor="grey.150" qa-attribute="domains-management--list">
      {domains?.map((domain) => (
        <DomainsListItem key={domain.domain} domain={domain} />
      ))}
    </Stack>
  )
}

import { SwitchStyled } from '../elements/Switch'
import Stack from '@mui/material/Stack'
import * as React from 'react'

type ToggleWithDescriptionType = {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  children: React.ReactNode
  'qa-attribute'?: string
}

export const ToggleWithDescription = ({
  checked,
  onChange,
  disabled,
  children,
  ...props
}: ToggleWithDescriptionType): React.ReactElement => {
  const qaAttribute = props['qa-attribute'] ?? 'switch-with-description--switch'
  return (
    <Stack direction="row" gap={4} alignItems="center">
      <SwitchStyled
        onChange={(_, checked): void => onChange(checked)}
        checked={checked}
        qa-attribute={qaAttribute}
        disabled={disabled}
      />
      {children}
    </Stack>
  )
}

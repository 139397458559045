import { PrivacySelectorAlerts } from './PrivacySelectorAlerts'
import { FormRow } from '../../../../components/modal/inputComponents/FormRow'
import { PrivacySwitch } from '../../../../components/elements/PrivacySwitch'
import { getQaSelectorText } from '../../../../helpers/qaSelectors'
import Stack from '@mui/material/Stack'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { isNil } from 'lodash'

type CustomChangeType = {
  target: { checked: boolean }
}

interface PrivacySelectorProps {
  onChange: (event: CustomChangeType) => void
  isPrivateProjectDisabled: boolean
  isChecked: boolean
  isPublicProjectDisabled?: boolean
  isLoading: boolean
  isDisabled?: boolean
}

export const PrivacySelector = ({
  onChange,
  isPrivateProjectDisabled,
  isChecked,
  isPublicProjectDisabled,
  isLoading,
  isDisabled
}: PrivacySelectorProps): React.ReactElement => {
  const { t } = useTranslation('modals')
  const [privateChecked, setPrivateChecked] = React.useState<boolean | undefined>()

  const label = isChecked ? t('project.fields.privateProject') : t('project.fields.publicProject')
  const subLabel = isChecked
    ? t('project.fields.privateProjectDescription')
    : t('project.fields.publicProjectDescription')

  const handlePrivacySwitchOnClick = React.useCallback(
    (privateProjectChecked: boolean) => {
      if (isChecked !== privateProjectChecked) {
        setPrivateChecked(privateProjectChecked)

        if (
          (privateProjectChecked && !isPrivateProjectDisabled) ||
          (!privateProjectChecked && !isPublicProjectDisabled)
        ) {
          const event: CustomChangeType = {
            target: {
              checked: privateProjectChecked
            }
          }
          onChange(event)
        }
      }
    },
    [isChecked, isPrivateProjectDisabled, isPublicProjectDisabled]
  )

  const qaSelectorText = getQaSelectorText(label)
  return (
    <React.Fragment>
      <FormRow label={label} subLabel={subLabel} alignItems="center">
        <Stack alignItems="center" justifyContent="flex-end" minHeight={8}>
          {isLoading || isNil(isChecked) ? (
            <CircularProgress size="28px" />
          ) : (
            <PrivacySwitch
              onClick={handlePrivacySwitchOnClick}
              isPrivateProject={isChecked}
              qa-attribute={`${qaSelectorText}--switch`}
              isDisabled={isDisabled}
            />
          )}
        </Stack>
      </FormRow>
      <PrivacySelectorAlerts
        isPrivateProjectChecked={privateChecked}
        isPrivateProjectDisabled={isPrivateProjectDisabled}
        isPublicProjectDisabled={isPublicProjectDisabled}
      />
    </React.Fragment>
  )
}

import * as React from 'react'
import { MainDisplay } from '@/containers/dashboard/MainDisplay'
import { ProfileDisplay } from '@/containers/dashboard/personalArea/profile/ProfileDisplay'
import { ProfilePages } from '@/hooks/useIsProfileSectionPage'

const ApiKeysPage = (): React.ReactElement => {
  return (
    <MainDisplay>
      <ProfileDisplay activeTab={ProfilePages.API_KEYS} />
    </MainDisplay>
  )
}

export default ApiKeysPage

import { Alert, Grow } from '@mui/material'
import { isNil } from 'lodash'
import * as React from 'react'

export const PrivateProjectAlert = ({ children }): React.ReactElement => {
  return (
    <Grow in={!isNil(children)}>
      <Alert icon={false} severity="warning">
        {children}
      </Alert>
    </Grow>
  )
}
